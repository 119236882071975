import React, { useCallback, useMemo, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { ContentIdentifier, isInherited } from '@warehouse/title/core';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// HOOK
import { makeItInherited, titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import useTitleRecordsAutoSave from '../../../../../src/views/title/hooks/useTitleRecordsAutoSave';
import useMemoJsonPath from '../../../../../src/views/title/hooks/useMemoJsonPath';
import { fromBasic, getTitleData } from '../../../../../src/utils/titleGetProperty';
import { WithUuid } from '../../../../../src/views/title/hooks/useTitleRecordsAutoSave/recordToArray';

// LIBRARY
import TitleEditableField from '../../../../../src/components/titles/TitleEditableField';
import { CustomColumnDef } from '../../../../../src/components/library/SimpleTable/customColumnDef';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../src/components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import deepEquality from '../../../../../src/utils/deepEquality';

import { CellIdentifier } from './CellIdentifier';
import { CellNamespace } from './CellNamespace';
import { CellLocation } from './CellLocation';
import { computeErrors } from './computeErrors';
import {
	RenderReadOnlyIdentifier,
	RenderReadOnlyLocation,
	RenderReadOnlyNamespace,
	RenderReadOnlyScope,
} from './ReadOnlyRenderers';
import { sortScope, sortNamespace, sortLocation, sortIdentifier } from './sortingFns';
import useTooltip from '../../../../../src/utils/hooks/useTooltips';
import { isATmpRow } from '../../../../../src/views/title/hooks/useTitleRecordsAutoSave/common';
import { CanDeleteOutput } from '../../../../../src/components/library/SimpleTable/can-delete';

export function AlternateIDs() {
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const [internalRows, setInternalRows] = useState<Inherited<WithUuid<ContentIdentifier>>[]>([]);

	const altIdentifier = useTitleRecordsAutoSave<Inherited<ContentIdentifier>>({
		path: useMemoJsonPath(fromBasic(['altIdentifiers'])),
		label: 'Alternate IDs',
		isValidRow: (row) =>
			row.displayValue.identifier !== undefined &&
			row.displayValue.identifier.trim() !== '' &&
			row.displayValue.namespace !== undefined &&
			row.displayValue.namespace.trim() !== '',
	});
	const identifierTooltip = useTooltip('coreMetadata.basic.altIdentifiers.identifier');
	const namespaceTooltip = useTooltip('coreMetadata.basic.altIdentifiers.namespace');
	const locationTooltip = useTooltip('coreMetadata.basic.altIdentifiers.location');
	const scopeTooltip = useTooltip('coreMetadata.basic.altIdentifiers.scope.scope');

	const workType = getTitleData<string | undefined>(title, useMemoJsonPath(fromBasic(['workType'])));
	const workTypeDetails = getTitleData<string[] | undefined>(title, useMemoJsonPath(fromBasic(['workTypeDetails'])));

	const columns = useMemo<CustomColumnDef<Inherited<ContentIdentifier>>[]>(
		() => [
			{
				id: 'identifier',
				header: 'Identifier',
				tooltip: identifierTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyIdentifier,
				sortingFn: sortIdentifier,
				cell: CellIdentifier,
				width: 170,
			},
			{
				id: 'namespace',
				header: 'Namespace',
				tooltip: namespaceTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyNamespace,
				sortingFn: sortNamespace,
				cell: CellNamespace,
				width: 130,
			},
			{
				id: 'location',
				header: 'Location',
				tooltip: locationTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyLocation,
				sortingFn: sortLocation,
				cell: CellLocation,
				width: 150,
			},
			{
				id: 'scope',
				header: 'Scope',
				tooltip: scopeTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: RenderReadOnlyScope,
				sortingFn: sortScope(workType, workTypeDetails?.[0]),
				editable: false,
				width: 150,
			},
		],
		[
			identifierTooltip?.tooltip,
			locationTooltip?.tooltip,
			namespaceTooltip?.tooltip,
			scopeTooltip?.tooltip,
			workType,
			workTypeDetails,
		],
	);

	const handleInternalRowChange = (rows: Row<Inherited<WithUuid<ContentIdentifier>>>[]) => {
		if (
			!deepEquality(
				rows.map((row) => row.original),
				internalRows,
			)
		) {
			setInternalRows(rows.map((row) => row.original));
		}
	};

	const handleRowAdd = useCallback(() => {
		altIdentifier.addRecord(
			makeItInherited({
				identifier: '',
				namespace: '',
				scope: {
					scope: workType,
				},
			}),
		);
	}, [altIdentifier, workType]);

	const handleRowDelete = useCallback(
		(selectedIndexes: string[]) => {
			altIdentifier.removeRecord(selectedIndexes);
		},
		[altIdentifier],
	);

	const errors = useMemo(() => (editMode ? computeErrors(internalRows) : {}), [editMode, internalRows]);

	return (
		<div aria-label="Alternate IDs Table Wrapper">
			<TitleEditableField
				label="Alternate IDs"
				path="coreMetadata.basic.alternateIdentifiers"
				direction="column"
				readComponent={null}
				editComponent={null}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<Inherited<ContentIdentifier>>
				selectionKey="__uuid"
				sorting={[
					{ id: 'scope', desc: false },
					{ id: 'namespace', desc: false },
					{ id: 'location', desc: false },
					{ id: 'identifier', desc: false },
				]}
				canDelete={(selectedIndexes: string[]) => canDeleteAlternateIds(selectedIndexes, altIdentifier.data)}
				isRowReadonlyFn={(row) => isInherited({ inheritedObject: row }).isInherited}
				editMode={editMode}
				showBottomActionsBar={editMode}
				columns={columns}
				data={editMode ? altIdentifier.data : altIdentifier.readOnlyValue ?? []}
				canAddRow={!altIdentifier.data?.some?.((row) => isATmpRow(row))}
				tableStyle="border"
				onRowAdd={handleRowAdd}
				onRowDelete={handleRowDelete}
				customPropsCell={{
					editRecord: altIdentifier.editRecord,
				}}
				onInternalRowsChange={handleInternalRowChange}
				errors={errors}
				selectRowModeByDefault
				enableAddMultipleRows={false}
				noOverflow
			/>
		</div>
	);
}

export function canDeleteAlternateIds(
	selectedIndexes: string[],
	altIdentifiers: Inherited<ContentIdentifier>[],
): CanDeleteOutput {
	const intSelectedIndexes = selectedIndexes.map((index) => parseInt(index, 10));
	const hasInheritedRows = intSelectedIndexes.some(
		(index) => isInherited({ inheritedObject: altIdentifiers[index] }).isInherited,
	);

	if (hasInheritedRows) {
		return {
			canDelete: false,
			reason: 'You cannot delete alternate IDs coming from the parent',
		};
	}
	return { canDelete: true };
}
