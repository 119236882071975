import { useCallback, useMemo, useRef, useState } from 'react';
import { PopupCancelledError, PopupTimeoutError, useAuth0, GenericError } from '@auth0/auth0-react';
import { singlePromiseRunner } from '@warehouse/shared/util';

export interface TokenGetterOutput {
	tokenGetter: () => Promise<string | null>;
	interactionRequired: boolean;
	error: string | null;
	retry: () => void;
}

// Since the error message we receive come from a catch, this could be of any type
function generateErrorMessageForAccessTokenWithPopup(err: any) {
	if (err instanceof Error && err.message.includes('window.open returned `null`')) {
		return 'The authorization window could not be opened. Please retry.';
	}
	if (err instanceof PopupCancelledError) {
		return 'The authorization window was closed.';
	}
	if (err instanceof PopupTimeoutError) {
		return 'The authorization window has timed out.';
	}
	return err.message ?? err.toString() ?? 'Unexpected error occurred.';
}

export function useTokenGetter(): TokenGetterOutput {
	const [interactionRequired, setInteractionRequired] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const promiseBlockerRef = useRef<(token: string | null) => void>(() => {});

	const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

	const tokenGetter = useCallback(async () => {
		setError(null);
		try {
			const token = await getAccessTokenSilently();
			setInteractionRequired(false);
			return token;
		} catch (err: any) {
			// If the authorization request returns a login_required error, the Auth0 SDK will set the isAuthenticated
			// variable to false automatically, which will trigger a full authentication flow (because of
			// withAuthenticationRequired in the router). To avoid showing a popup while the browser is navigating to the
			// full authentication flow, we stop the execution here.
			if (err instanceof GenericError && err.error === 'login_required') {
				return null;
			}
		}
		setInteractionRequired(true);
		try {
			const token = await getAccessTokenWithPopup();
			if (token) {
				setInteractionRequired(false);
				return token;
			}
			setError('Could not fetch access token');
		} catch (err: any) {
			setError(generateErrorMessageForAccessTokenWithPopup(err));
		}
		return new Promise<string | null>((resolve) => {
			promiseBlockerRef.current = resolve;
		});
	}, [getAccessTokenSilently, getAccessTokenWithPopup, promiseBlockerRef]);
	const singleRunnerTokenGetter = useMemo(() => singlePromiseRunner(tokenGetter), [tokenGetter]);

	const retry = useCallback(async () => {
		setError(null);
		try {
			const token = await getAccessTokenWithPopup();
			if (token) {
				setInteractionRequired(false);
				promiseBlockerRef.current(token);
				return;
			}
			setError('Could not fetch access token');
		} catch (err: any) {
			setError(generateErrorMessageForAccessTokenWithPopup(err));
		}
	}, [getAccessTokenWithPopup]);
	const singleRunnerRetry = useMemo(() => singlePromiseRunner(retry), [retry]);

	return {
		tokenGetter: singleRunnerTokenGetter,
		interactionRequired,
		error,
		retry: singleRunnerRetry,
	};
}
