import React, { useMemo, useState } from 'react';
import { isInherited, OptionalNullInherited } from '@warehouse/title/core';
import { BottomActionClear, DropdownV2 } from '@warehouse/shared/ui';
import useTitleAutoSave, {
	optionalNullInheritedToOptionalInherited,
} from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import useMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';
import { useUpdateValue } from '../../domain/useUpdateValue';

interface TitleSingleDropdownFieldProps {
	label: string;
	path: string;
	options: Option[];
	view: 'titleOverview' | 'titleSettings' | 'productMetadata';
	pickList?: boolean;
	searchInputType?: 'text' | 'number';
	ariaLabel?: string;
	canClear?: boolean;
	jumpToParentButtonDisabled?: boolean;
	tooltipDisabled?: boolean;
}

interface Option {
	value: string;
	label: string;
}

export function TitleSingleDropdownField({
	label,
	path,
	options,
	searchInputType = 'text',
	pickList = false,
	ariaLabel,
	view,
	canClear = false,
	jumpToParentButtonDisabled = false,
	tooltipDisabled = false,
}: TitleSingleDropdownFieldProps) {
	const { readOnlyValue, setValue, value, commit } = useTitleAutoSave<OptionalNullInherited<string>>({
		path: useMemoJsonPath(path.split('.')),
		label,
	});

	const [activeOptions, setActiveOptions] = useState<Option[]>(options);
	const displayedReadonlyValue = useMemo(
		() => computeDisplayedReadonlyValue({ readonlyValue: readOnlyValue, path, options: activeOptions }),
		[activeOptions, readOnlyValue, path],
	);

	const handleNewOptionAdded = (newOption: string) => {
		setActiveOptions((prev) => [
			...prev,
			{
				value: newOption,
				label: newOption,
			},
		]);
	};

	const { updateValue } = useUpdateValue<string, string | null>({ value });

	const dropdownV2 = (
		<DropdownV2
			withSearch
			searchInputType={searchInputType}
			variant={getVariant(value)}
			options={activeOptions}
			pickList={pickList}
			onNewOptionAdded={handleNewOptionAdded}
			value={value?.displayValue ?? null}
			onChange={(v) => setValue(updateValue(v))}
			onBlur={commit}
			height={38}
			disabledMargin
			bottomActions={
				canClear ? (
					<BottomActionClear
						action={() => {
							setValue(updateValue(null));
							commit?.();
						}}
					/>
				) : null
			}
			ariaLabel={ariaLabel}
		/>
	);

	const wrappedDropdownV2 =
		view === 'titleOverview' ? <div style={{ width: '100%', minWidth: 0 }}>{dropdownV2}</div> : dropdownV2;

	return (
		<TitleEditableField
			label={label}
			path={path}
			jumpToParentButtonDisabled={jumpToParentButtonDisabled}
			tooltipDisabled={tooltipDisabled}
			wrapperStyle={view === 'titleSettings' ? { padding: 0, marginBottom: 14 } : undefined}
			direction={view === 'titleOverview' ? 'row' : 'column'}
			forcedEditMode={view === 'titleSettings' ? true : undefined}
			height={view === 'titleOverview' ? 48 : undefined}
			readComponent={
				<ReadOnlyTypography
					noWrap
					title={displayedReadonlyValue}
					variant={getVariant(value)}
					style={{ height: view === 'productMetadata' ? 36 : undefined }}
				>
					{displayedReadonlyValue}
				</ReadOnlyTypography>
			}
			editComponent={wrappedDropdownV2}
		/>
	);
}

function getVariant(value: OptionalNullInherited<string> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<string>({ inheritedObject: optionalNullInheritedToOptionalInherited(value) }).isInherited
		? 'prefilled'
		: 'default';
}

function computeDisplayedReadonlyValue({
	readonlyValue,
	path,
	options,
}: {
	readonlyValue: OptionalNullInherited<string> | undefined;
	path: string;
	options: Option[];
}) {
	if (!readonlyValue) return undefined;
	switch (path) {
		default:
			return computeReadonlyLabel(readonlyValue, options);
	}
}

function computeReadonlyLabel(readonlyValue: OptionalNullInherited<string> | undefined, options: Option[]) {
	if (!readonlyValue) return undefined;
	return options.find((option) => option.value === readonlyValue.displayValue)?.label;
}
