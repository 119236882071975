import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTerritories } from '@warehouse/global-entities/core';
import ChipDisplay from '../../../../../../components/library/ChipDisplay';
import { CompanyDisplayCreditContext } from '../providers/companyDisplayCreditsProvider';
import { sortAlphabetically } from '../../../../../../utils/sortAlphabeticallyByLabel';
import { TerritoriesSelector } from '../../../../../../components/titles/TerritoriesSelector';
import { SelectDropdownWrapper } from '../../../../../../components/titles/TitleOverview/fields/style';
import TitleEditableField from '../../../../../../components/titles/TitleEditableField';
import useBlurAndDebounceValue from '../../../../hooks/useTitleRecordsAutoSave/useBlurAndDebounceValue';

interface RegionsProps {
	companyDisplayCreditDisplayIndex: number;
}

export default function Regions({ companyDisplayCreditDisplayIndex }: RegionsProps) {
	const companyDisplayCreditContext = useContext(CompanyDisplayCreditContext);
	const { isInherited } = companyDisplayCreditContext;

	const { getKey, getLabel } = useTerritories();

	const displayValues = useMemo(
		() =>
			companyDisplayCreditContext.readonlyData?.displayValue?.[companyDisplayCreditDisplayIndex]?.regions
				?.map((region) => getLabel(region.country || region.countryRegion || ''))
				.filter((item): item is string => item !== undefined) || [],
		[companyDisplayCreditContext.readonlyData?.displayValue?.[companyDisplayCreditDisplayIndex]?.regions],
	);

	const initialIds =
		companyDisplayCreditContext.data?.displayValue?.[companyDisplayCreditDisplayIndex]?.regions?.map(
			(region) => region.countryRegion || region.country!,
		) || [];

	const saveRef = useRef<(regionIds: string[] | undefined) => void>();

	useEffect(() => {
		saveRef.current = (val: string[] | undefined) => {
			if (val) {
				companyDisplayCreditContext.setRegions(
					val.map((id) => ({ [getKey(id)]: id })),
					companyDisplayCreditDisplayIndex,
				);
				companyDisplayCreditContext.commit();
			}
		};
	}, [companyDisplayCreditContext.editDisplayName, companyDisplayCreditContext.commit]);

	const {
		value: selectedIds,
		setValue: setSelectedIds,
		commit: commitBlurAndDebounce,
	} = useBlurAndDebounceValue<string[]>({
		initialValue: initialIds,
		save: useCallback((val) => saveRef.current?.(val), [saveRef]),
	});

	return (
		<TitleEditableField
			direction="column"
			path="coreMetadata.companyDisplayCredits.regions"
			label="Regions"
			readComponent={
				<ChipDisplay
					variant={isInherited ? 'prefilled' : 'default'}
					horizontalPadding={0}
					allowOverflow
					calcWidth="calc(100% - 20px)"
					values={displayValues.sort(sortAlphabetically)}
				/>
			}
			editComponent={
				<SelectDropdownWrapper>
					<TerritoriesSelector
						variant={isInherited ? 'prefilled' : 'default'}
						disabledMargin
						height={38}
						multiple
						value={selectedIds}
						onBlur={commitBlurAndDebounce}
						onChange={(ids) => setSelectedIds(ids)}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}
