import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// LIBRARY
import { DropdownV2 } from '@warehouse/shared/ui';

// JSON
import { ContentIdentifier } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { CellWrapper } from '../../../../../src/components/library/SimpleTable/style';

export function CellNamespace({
	getValue,
	table,
}: CellContext<Inherited<ContentIdentifier>, Inherited<ContentIdentifier>>) {
	const { displayValue } = getValue();
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;
	const availableNamespaces = useTitleEditorStore(titleEditorStoreSelector.altIdentifiers.availableNamespaces);

	return (
		<CellWrapper aria-label="Namespace Cell">
			<DropdownV2
				value={displayValue.namespace}
				onChange={(value) => {
					editRecord({
						...getValue(),
						displayValue: {
							...getValue().displayValue,
							namespace: value,
						},
					});
				}}
				options={availableNamespaces}
				transparentBorder
				backgroundColor="transparent"
			/>
		</CellWrapper>
	);
}
