import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { isInherited } from '@warehouse/title/core';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import TextArea from '../../../../src/components/library/TextArea';
import SanitizedHTML from '../../../../src/views/title/SanitizedHTML';
import { Pre } from '../../../../src/views/title/tab/productMetadata/localizedInfo';

export function TitleTextAreaField({ path, label, ariaLabel }: { path: string; label: string; ariaLabel?: string }) {
	const { readOnlyValue, setValue, value, commit, error } = useTitleAutoSave<OptionalInherited<string>>({
		path: useMemo(() => path.split('.'), [path]),
		label,
	});

	const isValueInherited = useMemo(
		() => (value ? isInherited<string>({ inheritedObject: value }).isInherited : false),
		[value],
	);

	return (
		<TitleEditableField
			label={label}
			path={path}
			direction="column"
			readComponent={
				<ReadOnlyTypography variant={getVariant(value)}>
					<Pre>
						<SanitizedHTML htmlContent={readOnlyValue?.displayValue || ''} />
					</Pre>
				</ReadOnlyTypography>
			}
			editComponent={
				<TextArea
					aria-label={ariaLabel}
					value={isValueInherited ? '' : value?.displayValue}
					variant={getVariant(value)}
					placeholder={value?.inherited.value || ''}
					onChange={(e) => {
						setValue((prev) => ({
							...prev,
							displayValue: e.target.value === '' ? prev.inherited.value || '' : e.target.value,
						}));
					}}
					onBlur={() => commit()}
					errorMessage={error}
				/>
			}
		/>
	);
}

function getVariant(value: OptionalInherited<string> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<string>({ inheritedObject: value }).isInherited ? 'prefilled' : 'default';
}
