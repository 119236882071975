import React from 'react';
import styled, { css } from 'styled-components';

// TYPES
import { ReleaseDateField } from '@warehouse/title/feature-title-overview';
import { TitleOverviewByTypeProps } from './type';

// HOOKS
import useTiniestSummaryTitleData from '../../../../views/title/hooks/useTiniestSummaryTitleData';

// UTILS
import getPlaceholderArea from './getPlaceholderArea';

// LIBRARY
import { TitleOverviewLeftWrapperBase, Data, NonCenteredData } from './style';
import { LicensorField, TiniestSummary } from '../fields';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar'
			'licensor synopsis'
			'releaseDate synopsis'
			'ph-1 synopsis'
			'. synopsis';
		grid-template-columns: 25% 75%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

function Compilation({ topBar, displayVideoPlayer, title }: TitleOverviewByTypeProps) {
	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	return (
		<TitleOverviewLeftWrapper displayVideoPlayer={displayVideoPlayer}>
			{topBar}
			<NonCenteredData enableRightBorder enableBottomBorder={false} area="synopsis">
				<TiniestSummary
					tiniestSummary={tiniestSummary?.data?.displayValue || ''}
					isInherited={tiniestSummary.isInherited}
				/>
			</NonCenteredData>
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField title={title} />
			</Data>
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			{getPlaceholderArea({ bottom: true, name: 'ph-1' })}
		</TitleOverviewLeftWrapper>
	);
}

export default Compilation;
