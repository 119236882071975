import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Typography, Divider } from '@mui/material';
import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import Button from '../../components/library/Button';
import Tooltip from '../../components/library/Tooltip';
import TitleTypeInfo from '../../components/titles/TitleTypeInfo';
import { CheckIcon } from '../../../libs/title/ui/CheckIcon';

export function TopBarLegacy({
	title,
	subTitle1,
	subTitle2,
	onClickBack,
	actions,
	children,
	hideTitleCard = false,
	isPrimaryAndDerivedFrom,
}: DetailsTopBarProps) {
	const titleRef = useRef<HTMLSpanElement>(null);
	const actionRef = useRef<HTMLDivElement>(null);
	const [isOverflowed, setIsOverflowed] = useState(false);
	const checkOverflow = () => {
		const element = titleRef.current;
		if (element) {
			const isOverflowing = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
			setIsOverflowed(isOverflowing);
		}
	};

	useEffect(() => {
		checkOverflow();
		window.addEventListener('resize', checkOverflow);

		return () => {
			window.removeEventListener('resize', checkOverflow);
		};
	}, [title]);

	return (
		<Wrapper>
			<TopWrapper $noPadding={!!subTitle1 || !!subTitle2}>
				<TitleWrapper>
					{onClickBack && (
						<CustomButton
							aria-label="Go Back Button"
							$fullHeight
							$disablePadding
							nxstyle="normal"
							onClick={onClickBack}
							style={{ minWidth: 32 }}
						>
							<Icon path={mdiChevronLeft} size="16px" />
						</CustomButton>
					)}
					{!hideTitleCard && <TitleTypeInfo />}
					<Tooltip title={title} placement="right" disableHoverListener={!isOverflowed}>
						<TitleGroupWrapper aria-label="Title Display HTML">
							<TitleDisplayWrapper>
								<StyledTitle
									aria-level={1}
									role="heading"
									ref={titleRef}
									variant="h4Regular"
									$hasSubTitle={!!subTitle1 || !!subTitle2}
								>
									{title}
								</StyledTitle>
								{isPrimaryAndDerivedFrom && <CheckIcon dataTestId="primary-tick" />}
							</TitleDisplayWrapper>
							{subTitle1 && <StyledSubTitle>{subTitle1}</StyledSubTitle>}
							{subTitle2 && <StyledSubTitle>{subTitle2}</StyledSubTitle>}
						</TitleGroupWrapper>
					</Tooltip>
					{actions && <div ref={actionRef}>{actions}</div>}
				</TitleWrapper>
			</TopWrapper>
			{children && (
				<>
					<Divider />
					<BottomWrapper>{children}</BottomWrapper>
				</>
			)}
		</Wrapper>
	);
}

interface DetailsTopBarProps {
	title: string;
	subTitle1?: string;
	subTitle2?: string;
	onClickBack?: () => void;
	actions?: ReactNode;
	children?: ReactNode;
	hideTitleCard?: boolean;
	isPrimaryAndDerivedFrom?: boolean;
}

const Wrapper = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		display: flex;
		flex-direction: column;
	`,
);

const TopWrapper = styled.div<{ $noPadding?: boolean }>(
	({ theme, $noPadding = false }) => css`
		align-items: center;
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		display: flex;
		justify-content: space-between;
		padding: ${$noPadding ? '0' : theme.spacing(2, 0)};
		width: 100%;
	`,
);

const BottomWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		background-color: ${theme.palette.light.main};
		border-radius: ${theme.spacing(2)};
		display: flex;
		justify-content: flex-start;
	`,
);

const TitleGroupWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 250px;
`;

const TitleDisplayWrapper = styled.div`
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 8px;
`;
const StyledTitle = styled(Typography)<{ $hasSubTitle: boolean }>(
	({ theme, $hasSubTitle }) => css`
		color: ${theme.palette.text.primary};
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		${$hasSubTitle &&
		css`
			font-size: 20px;
		`}
	`,
);

const StyledSubTitle = styled(Typography)(
	({ theme }) => css`
		color: ${theme.palette.text.secondary};
		font-size: 14px;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

const TitleWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(2)};
		width: 100%;
	`,
);

const CustomButton = styled(Button)(
	({ theme }) => css`
		padding: 0 !important;
		width: 32px;

		svg {
			color: ${theme.palette.text.secondary};
		}
	`,
);
