import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';
import { Row } from '@tanstack/react-table';

import { ContentIdentifier } from '@warehouse/title/core';
import workTypes from '../../../../../src/assets/json-administration-profiles/workTypes.json';
import availWTDByWT from '../../../../../src/assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import availContentIDNS from '../../../../../src/assets/json-administration-profiles/availableContentIDNamespaces.json';
import { WithUuid } from '../../../../../src/views/title/hooks/useTitleRecordsAutoSave/recordToArray';

const A_SHOULD_BE_AFTER_B = 1;
const A_SHOULD_BE_BEFORE_B = -1;
const B_SHOULD_BE_AFTER_A = -1;
const B_SHOULD_BE_BEFORE_A = 1;

function isLocalRow(row: WithUuid<Inherited<ContentIdentifier>>): boolean {
	return row.__uuid.startsWith('tmp-');
}

function isSameWorkTypeAndWTD(row: WithUuid<Inherited<ContentIdentifier>>, wt: string, wtd: string): boolean {
	return row.displayValue.scope?.scope === wt && row.displayValue.scope?.subscope === wtd;
}

function getWTLabel(wt?: string): string {
	if (!wt) return '';
	return workTypes.find(({ value }) => value === wt)?.label ?? '';
}

function getWTDLabel(wt?: string, wtd?: string): string {
	if (!wt || !wtd) return '';
	const workType = Object.values(availWTDByWT).find(({ uuid }) => uuid === wt);
	if (!workType) return '';
	return workType.availableWorkTypeDetails.find(({ value }) => value === wtd)?.label ?? '';
}

function getNSOrdinal(ns?: string): number | undefined {
	if (!ns) return undefined;
	return availContentIDNS.find(({ value }) => value === ns)?.ordinal ?? undefined;
}

export function sortIdentifier(
	rowA: Row<Inherited<ContentIdentifier>>,
	rowB: Row<Inherited<ContentIdentifier>>,
	columnId: string,
): number {
	const valueA = rowA.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);
	const valueB = rowB.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);

	const identifierA = valueA.displayValue.identifier;
	const identifierB = valueB.displayValue.identifier;

	if (!identifierA && !identifierB) return 0;
	if (identifierA && !identifierB) return A_SHOULD_BE_BEFORE_B;
	if (!identifierA && identifierB) return B_SHOULD_BE_BEFORE_A;
	if (identifierA !== identifierB) return identifierA.localeCompare(identifierB);
	return 0;
}

export function sortNamespace(
	rowA: Row<Inherited<ContentIdentifier>>,
	rowB: Row<Inherited<ContentIdentifier>>,
	columnId: string,
): number {
	const valueA = rowA.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);
	const valueB = rowB.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);

	const nsA = getNSOrdinal(valueA.displayValue.namespace);
	const nsB = getNSOrdinal(valueB.displayValue.namespace);

	if (!nsA && !nsB) return 0;
	if (nsA && !nsB) return A_SHOULD_BE_BEFORE_B;
	if (!nsA && nsB) return B_SHOULD_BE_BEFORE_A;
	if (nsA !== nsB) return nsA! < nsB! ? A_SHOULD_BE_BEFORE_B : B_SHOULD_BE_BEFORE_A;
	return 0;
}

export function sortLocation(
	rowA: Row<Inherited<ContentIdentifier>>,
	rowB: Row<Inherited<ContentIdentifier>>,
	columnId: string,
): number {
	const valueA = rowA.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);
	const valueB = rowB.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);

	const locationA = valueA.displayValue.location;
	const locationB = valueB.displayValue.location;

	if (!locationA && !locationB) return 0;
	if (locationA && !locationB) return A_SHOULD_BE_BEFORE_B;
	if (!locationA && locationB) return B_SHOULD_BE_BEFORE_A;
	if (locationA !== locationB) return locationA!.localeCompare(locationB!);
	return 0;
}

export function sortScope(wt?: string, wtd?: string) {
	return (
		rowA: Row<Inherited<ContentIdentifier>>,
		rowB: Row<Inherited<ContentIdentifier>>,
		columnId: string,
	): number => {
		const valueA = rowA.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);
		const valueB = rowB.getValue<WithUuid<Inherited<ContentIdentifier>>>(columnId);

		// Sort tmp rows at the end
		if (isLocalRow(valueA) && !isLocalRow(valueB)) return A_SHOULD_BE_AFTER_B;
		if (!isLocalRow(valueA) && isLocalRow(valueB)) return B_SHOULD_BE_AFTER_A;

		// Sort same work type and work type detail first
		if (wt && wtd) {
			if (isSameWorkTypeAndWTD(valueA, wt, wtd) && !isSameWorkTypeAndWTD(valueB, wt, wtd)) return A_SHOULD_BE_BEFORE_B;
			if (!isSameWorkTypeAndWTD(valueA, wt, wtd) && isSameWorkTypeAndWTD(valueB, wt, wtd)) return B_SHOULD_BE_BEFORE_A;
			if (isSameWorkTypeAndWTD(valueA, wt, wtd) && isSameWorkTypeAndWTD(valueB, wt, wtd)) return 0;
		}

		const wtA = getWTLabel(valueA.displayValue.scope?.scope);
		const wtdA = getWTDLabel(valueA.displayValue.scope?.scope, valueA.displayValue.scope?.subscope);
		const wtB = getWTLabel(valueB.displayValue.scope?.scope);
		const wtdB = getWTDLabel(valueB.displayValue.scope?.scope, valueB.displayValue.scope?.subscope);

		// Sort for undefined scope
		if (wtA && wtB) {
			if (wtdA && !wtdB) return A_SHOULD_BE_BEFORE_B;
			if (!wtdA && wtdB) return A_SHOULD_BE_AFTER_B;
		}
		if (wtA && !wtB) return A_SHOULD_BE_BEFORE_B;
		if (!wtA && wtB) return A_SHOULD_BE_AFTER_B;

		// Sort scope
		if (wtA === wtB && wtdA !== wtdB) return wtdA.localeCompare(wtdB);
		if (wtA !== wtB) return wtA.localeCompare(wtB);

		return 0;
	};
}
