import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { TitleFull } from '@warehouse/title/core';
import ParentField from '../fields/ParentField';

export interface ManifestationProps {
	workType: string;
	watch: UseFormWatch<any>;
	control: Control<any>;
	trigger: UseFormTrigger<any>;
	setValue: UseFormSetValue<any>;
	errors: FieldErrors<any>;
	parentTitle?: {
		title: IndexedTitleV2 | undefined;
		parentType: string | undefined;
	};
	selectedParentTitle?: TitleFull;
}

export function Manifestation({
	workType,
	selectedParentTitle,
	watch,
	control,
	trigger,
	setValue,
	errors,
	parentTitle,
}: ManifestationProps) {
	const licensor = watch('licensor');
	return (
		<ParentField
			control={control}
			setValue={setValue}
			trigger={trigger}
			licensor={licensor}
			errors={errors}
			parentTitle={parentTitle}
			workType={workType}
			selectedParentTitle={selectedParentTitle}
			isRequired
		/>
	);
}
