import React from 'react';
import { Typography } from '@mui/material';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { FieldWrapper } from '../../../../src/views/title/TitleSettings/style';
import TitleTooltip from '../../../../src/components/titles/TitleTooltip';
import { ReadOnlyIdField } from './ReadOnlyIdField';

export function TitleId() {
	const titleUuid = useTitleEditorStore(titleEditorStoreSelector.titleUuid);

	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonLargeMedium">
				Title ID
				<TitleTooltip path="coreMetadata.contentId" />
			</Typography>
			<ReadOnlyIdField aria-label="Title ID" variant="buttonMediumRegular">
				{titleUuid}
			</ReadOnlyIdField>
		</FieldWrapper>
	);
}
