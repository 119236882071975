import React, { useMemo } from 'react';
import { DropdownV2, DropdownV2Option, DropdownV2Props } from '@warehouse/shared/ui';
import { useTerritories } from '@warehouse/global-entities/core';
import { regionUuidSortingFn } from '../../views/title/tab/productMetadata/regionalizedInfos/ReleasesTable/regionSortingFn';

export interface TerritoriesSelectorProps<ValueType extends string | string[]> extends DropdownV2Props<ValueType> {
	multiple?: boolean;
	showAllOptions?: boolean;
}

export function TerritoriesSelector<ValueType extends string | string[]>({
	multiple,
	showAllOptions,
	pickList,
	...args
}: TerritoriesSelectorProps<ValueType>) {
	const { categorizedTerritories } = useTerritories();

	const options = useMemo(() => {
		const opts: DropdownV2Option[] = [];
		if (showAllOptions) {
			opts.push({ label: 'Show All', value: 'default' });
		}
		opts.push(...categorizedTerritories);
		return opts;
	}, [categorizedTerritories, showAllOptions]);

	const handleOnChange = (values: ValueType) => {
		if (multiple && Array.isArray(values)) {
			args?.onChange?.(values.sort(regionUuidSortingFn) as ValueType, options);
		} else {
			args?.onChange?.(values, options);
		}
	};

	return (
		<DropdownV2
			{...args}
			options={options}
			onChange={handleOnChange}
			withSearch
			mode={multiple && !pickList ? 'multiple' : undefined}
			pickList={pickList}
		/>
	);
}
