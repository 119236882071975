export type StorageTierKey = keyof typeof StorageTierLabels;

export const StorageTierLabels = {
	STANDARD: 'Standard',
	REDUCED_REDUNDANCY: 'Reduced Redundancy',
	STANDARD_IA: 'Standard IA',
	ONEZONE_IA: 'Onezone IA',
	INTELLIGENT_TIERING: 'Intelligent Tiering',
	GLACIER: 'Glacier',
	DEEP_ARCHIVE: 'Deep Archive',
	OUTPOSTS: 'Outposts',
	GLACIER_IR: 'Glacier IR',
	SNOW: 'Snow',
} as const;
