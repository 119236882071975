import { TitleFull } from './title';
import { flattenRegions } from './models/region';
import { filterCreativeCountriesOfOrigin } from './countries-of-origin';

export function areCreativeCountriesOfOriginInherited(
	creativeCountriesOfOrigin: string[] | undefined,
	dryRanTitle: TitleFull | undefined,
): boolean {
	const inheritedCountriesOfOrigin = dryRanTitle?.metadata.coreMetadata.basic.countriesOfOrigin.inherited.value || [];

	const flattenedCreativeInheritedCountriesOfOrigin = flattenRegions(
		filterCreativeCountriesOfOrigin(inheritedCountriesOfOrigin),
	);

	const countriesOfOriginSet = new Set(creativeCountriesOfOrigin || []);
	const inheritedCountriesOfOriginSet = new Set(flattenedCreativeInheritedCountriesOfOrigin);

	return (
		inheritedCountriesOfOriginSet.size === countriesOfOriginSet.size &&
		[...countriesOfOriginSet].every((country) => inheritedCountriesOfOriginSet.has(country))
	);
}

export function areOriginalLanguagesInherited(
	originalLanguages: string[] | undefined,
	dryRanTitle: TitleFull | undefined,
): boolean {
	const inheritedOriginalLanguages = dryRanTitle?.metadata.coreMetadata.basic.originalLanguages.inherited.value || [];

	const inheritedOriginalLanguagesStrings = inheritedOriginalLanguages.map((language) => language.originalLanguage);

	const originalLanguagesSet = new Set(originalLanguages || []);
	const inheritedOriginalLanguagesSet = new Set(inheritedOriginalLanguagesStrings);

	return (
		inheritedOriginalLanguagesSet.size === originalLanguagesSet.size &&
		[...originalLanguagesSet].every((language) => inheritedOriginalLanguagesSet.has(language))
	);
}
