import { WorkType } from '@warehouse/title/core';

export function computeTitlePlaceholder({
	workType,
	sequenceNumber,
	index,
}: {
	workType: WorkType;
	sequenceNumber: string;
	index?: number;
}) {
	let number: number;
	if (sequenceNumber !== '') {
		number = parseInt(sequenceNumber, 10);
	} else if (index) {
		number = index + 1;
	} else {
		return undefined;
	}

	if (workType === WorkType.Episode) {
		return `Episode ${number}`;
	}
	if (workType === WorkType.Season) {
		return `Season ${number}`;
	}

	return undefined;
}
