import { BehaviorSubject, Observable } from 'rxjs';
import { singletonFactory } from '@warehouse/shared/util';
import { Bucket, BucketRepository } from '../core';
import availableNxObjectBucket from '../../../src/assets/json-administration-profiles/available-nx-object-buckets/available-nx-object-buckets.json';
import { BucketAdapter } from './bucket.adapter';

export class BucketRepositoryJson implements BucketRepository {
	#subject = new BehaviorSubject<Bucket[]>(availableNxObjectBucket.map(BucketAdapter.adapt));

	watchBuckets(): Observable<Bucket[]> {
		return this.#subject.asObservable();
	}
}

export const bucketRepositorySingleton = singletonFactory<BucketRepository>({
	factory: () => new BucketRepositoryJson(),
});
