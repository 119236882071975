import React, { useCallback, useState, MouseEventHandler, MouseEvent, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { IButtonProps, Button } from './Button';
import Loader from './Loader';

export interface ButtonLoadingStepProps extends IButtonProps {
	loading?: boolean;
}

// This component render a Button with a loading props, if the loading state is set to true and the button is clicked,
// a loader will be shown, then the onClick handler will be delayed until the loading state change back to false
export function ButtonLoadingStep({ loading, disabled, onClick, children, ...props }: ButtonLoadingStepProps) {
	const [showLoading, setShowLoading] = useState(false);
	const clickRef = useRef<MouseEvent<HTMLButtonElement> | null>(null);

	const theme = useTheme();

	// This effect execute the onClick handler if it was previously registered and the loading state change
	useEffect(() => {
		if (!loading && clickRef.current !== null) {
			onClick?.(clickRef.current);
			clickRef.current = null;
			setShowLoading(false);
		}
	}, [loading, onClick]);

	const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(evt) => {
			// If there is a current click registered we ignore all next ones
			if (clickRef.current !== null) return;

			if (!loading) onClick?.(evt);
			else {
				clickRef.current = evt;
				setShowLoading(true);
			}
		},
		[loading, onClick],
	);

	return (
		<Button {...props} onClick={handleClick} disabled={disabled || showLoading}>
			{children}
			{showLoading && (
				<>
					&nbsp;
					<Loader size={20} color={theme.palette.light.main} />
				</>
			)}
		</Button>
	);
}
