import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

// LIBRARY
import FullTable from '../../../../components/table/FullTable';
import columns from './table/columns';
import fakeOrders from './fakeOrders.json';

const Wrapper = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)};
	`,
);

const formatData = (data: any, title: any) =>
	data.map((item: any) => ({
		...item,
		title,
	}));

function OrdersTab() {
	const data = formatData(fakeOrders, undefined);

	const columnsData = useMemo(() => columns, []);

	return (
		<Wrapper>
			<FullTable<any>
				totalDocuments={fakeOrders.length}
				columns={columnsData}
				initialSorting={[]}
				data={data}
				filterFields={[]}
				setFiltering={() => {}}
				fullTableStyle="border"
				maxHeight={800}
			/>
		</Wrapper>
	);
}
export default OrdersTab;
