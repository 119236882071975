import React from 'react';
import { Typography } from '@mui/material';
import { ReadOnlyField } from '@warehouse/shared/legacy-ui';
import { FieldWrapper } from '../../ui/FieldWrapper';

export function RelationshipTypeField({ relationshipType }: RelationshipTypeFieldProps) {
	return (
		<FieldWrapper>
			<Typography variant="buttonMediumMedium" color="text.primary">
				Relationship Type
			</Typography>
			<ReadOnlyField>{relationshipType}</ReadOnlyField>
		</FieldWrapper>
	);
}

interface RelationshipTypeFieldProps {
	relationshipType: string;
}
