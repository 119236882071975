import { TitleFull as Title } from '@warehouse/title/core';
import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { ReadOnlyField } from '@warehouse/shared/legacy-ui';
import { FieldWrapper } from './style';
import getDeepProperty from '../../../utils/getDeepProperty';
import { fromBasic } from '../../../utils/titleGetProperty';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';
import TitleTooltip from '../../../components/titles/TitleTooltip';

type TitleTypeProps = {
	title: Title | undefined;
};

function TitleType({ title }: TitleTypeProps) {
	const { getNameByUuid } = useWorkTypes();

	const workType = useMemo(() => getDeepProperty<string | undefined>(title, fromBasic(['workType'])), [title]);

	return useMemo(
		() => (
			<FieldWrapper className="field-wrapper">
				<Typography variant="buttonLargeMedium">
					Title Type
					<TitleTooltip path="coreMetadata.basic.workType" />
				</Typography>
				<ReadOnlyField>{getNameByUuid(workType || '')}</ReadOnlyField>
			</FieldWrapper>
		),
		[workType],
	);
}

export default TitleType;
