import { GetPosterOutput } from '@warehouse/graphql';
import { Poster } from '../core/models/poster';

export function posterGQLResponseToPoster(gql: GetPosterOutput): Poster {
	if (gql.poster === undefined) throw new Error('GQL: Poster is required but it was not requested');
	if (gql.backdrop === undefined) throw new Error('GQL: Backdrop is required but it was not requested');
	return {
		poster: gql.poster === null ? undefined : gql.poster,
		backdrop: gql.backdrop === null ? undefined : gql.backdrop,
	};
}
