import { FolderPath, FolderPathUtils, FullPath } from '@warehouse/object-browser/core';
import {
	RootTreeItems,
	TreeItem,
	TreeItemBucket,
	TreeItemChildren,
	TreeItemFolder,
	TreeItemLoading,
	TreeItems,
	TreeItemType,
} from '../core/models';

export interface PushTreeItemProps {
	treeItems: RootTreeItems;
	fullPath: FullPath;
	itemsToPush: TreeItemChildren;
}

function isLoadingTreeItem(treeItem: TreeItem): treeItem is TreeItemLoading {
	return treeItem.type === TreeItemType.Loading;
}

function isValidTreeItem(treeItem: TreeItem): treeItem is TreeItemBucket | TreeItemFolder {
	return treeItem.type === TreeItemType.Bucket || treeItem.type === TreeItemType.Folder;
}

function isTreeItemsLoading(treeItems: TreeItems): treeItems is [TreeItemLoading] {
	return treeItems.length === 1 && treeItems[0].type === TreeItemType.Loading;
}

function findTreeItemInTreeItemBucket(startingPoint: TreeItem, path: FolderPath): TreeItem | undefined {
	const pathSegment = FolderPathUtils.decompose(path);
	let treeItemRef: TreeItem = startingPoint;
	for (let i = 1; i < pathSegment.length; i += 1) {
		if (isLoadingTreeItem(treeItemRef)) {
			console.error('Trying to locate a path in a loading tree item');
			return undefined;
		}
		const pathIndex: number = treeItemRef.children.findIndex(
			(item) => item.type === TreeItemType.Folder && FolderPathUtils.drillInto(item.path, item.name) === pathSegment[i],
		);
		if (pathIndex === -1) {
			console.error(`Could not find subPath ${pathSegment[i]} in tree items`);
			return undefined;
		}
		treeItemRef = treeItemRef.children[pathIndex];
	}
	return treeItemRef;
}

export function pushTreeItems({
	treeItems,
	fullPath: { bucketId, path },
	itemsToPush,
}: PushTreeItemProps): RootTreeItems {
	// find bucket tree item
	const bucketIndex = treeItems.findIndex((item) => item.type === TreeItemType.Bucket && item.name === bucketId);
	if (bucketIndex === -1) {
		console.error(`Could not push folders into bucket ${bucketId} as it could not be found.`);
		return treeItems;
	}

	// find folder tree item
	const treeItemRef = findTreeItemInTreeItemBucket(treeItems[bucketIndex], path);
	if (!treeItemRef) return treeItems;

	// merge item into children
	if (isValidTreeItem(treeItemRef)) {
		// TODO: improve merge logic here
		if (isTreeItemsLoading(treeItemRef.children) || isTreeItemsLoading(itemsToPush)) {
			treeItemRef.children = itemsToPush;
		}
	}
	return treeItems;
}
