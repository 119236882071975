import React from 'react';
import styled, { css } from 'styled-components';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { ObjectBrowserBreadcrumbs, Table, Tree } from './ui';

export function ObjectBrowser() {
	const isInSearchMode = useObjectBrowserStore(objectBrowserStoreSelector.isInSearchMode);

	return (
		<ObjectBrowserWrapper $showTree={!isInSearchMode}>
			<Tree />
			<BreadcrumbsTableWrapper $showTree={!isInSearchMode}>
				<ObjectBrowserBreadcrumbs />
				<Table />
			</BreadcrumbsTableWrapper>
		</ObjectBrowserWrapper>
	);
}

const ObjectBrowserWrapper = styled.div<{ $showTree: boolean }>(
	({ theme, $showTree }) => css`
		display: flex;

		height: 100%;

		.MuiRichTreeViewPro-root {
			display: ${$showTree ? 'block' : 'none'};
		}

		& > *:first-child {
			border-right: solid 1px ${theme.palette.light.backgroundAlt};
			flex: 1;
		}
	`,
);

const BreadcrumbsTableWrapper = styled.div<{ $showTree: boolean }>(
	({ $showTree }) => css`
		display: flex;
		flex-direction: column;
		height: 100%;

		${$showTree
			? css`
					width: 75%;
				`
			: css`
					width: 100%;
				`}
	`,
);
