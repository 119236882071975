import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Order, SearchInput, SearchTitlesV2Output, SEARCH_TITLE_ENTITIES_V2 } from '@warehouse/graphql';
import { FilterStep } from '@warehouse/shared/filters';
import { computeWildcardSearch } from './useSearchTitlesV2';

interface UseSearchTitlesProps {
	filtering?: FilterStep;
	pollInterval: number;
	perPage?: number;
	page?: number;
	sorting?: Order[];
	searchFields: string[];
	searchValue: string;
}

// TODO Remove this when needed
export const filterOutStatus = (filters?: FilterStep): FilterStep => {
	const tmp: FilterStep = {
		step: {
			combinationOperator: 'AND',
			filters: [
				{
					field: 'status.value',
					operator: 'doesNotEqual',
					value: 'in development',
				},
			],
		},
	};

	if (filters) tmp.step.filters.push(filters);

	return tmp;
};

function useSearchTitlesDropdownV2({
	filtering,
	pollInterval,
	perPage = 1000,
	page = 1,
	sorting = [],
	searchValue,
	searchFields,
}: UseSearchTitlesProps) {
	const wildcardSearch = useMemo(() => computeWildcardSearch(searchValue, searchFields), [searchValue, searchFields]);
	const { loading, error, data, networkStatus, startPolling, stopPolling, refetch, fetchMore } = useQuery<
		{
			searchTitlesV2: SearchTitlesV2Output;
		},
		{
			search: SearchInput;
		}
	>(SEARCH_TITLE_ENTITIES_V2, {
		variables: {
			search: {
				pagination: {
					page,
					perPage,
				},
				orderBy: sorting,
				filters: JSON.stringify(filtering),
				wildcardSearch,
			},
		},
		notifyOnNetworkStatusChange: true,
	});

	// Fire search after search input or filtering
	useEffect(() => {
		refetch({
			search: {
				pagination: { page, perPage },
				filters: JSON.stringify(filtering),
				orderBy: sorting,
				wildcardSearch,
			},
		});
	}, [filtering, page, wildcardSearch, perPage]);

	// Query polling
	useEffect(() => {
		startPolling(pollInterval);
	}, [startPolling]);

	return {
		loading,
		error,
		data,
		startPolling,
		stopPolling,
		refetch,
		networkStatus,
		fetchMore,
	};
}

export default useSearchTitlesDropdownV2;
