import { useCallback, useEffect, useMemo, useSyncExternalStore } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';

type QueryParam = { key: string; value: string };

export abstract class QueryParamsManager {
	abstract get(key: string): string | undefined;
	abstract set(key: string, value: string): void;
	abstract delete(key: string): void;
}

export class QueryParamsManagerImpl implements QueryParamsManager {
	private static instance: QueryParamsManagerImpl;

	public wantedState: BehaviorSubject<QueryParam[]>;

	constructor() {
		const qs = new URLSearchParams(window.location.search);
		const initialQS: QueryParam[] = [];
		qs.forEach((value, key) => initialQS.push({ key, value }));
		this.wantedState = new BehaviorSubject<QueryParam[]>(initialQS);
	}

	static getInstance(): QueryParamsManagerImpl {
		if (!QueryParamsManagerImpl.instance) {
			QueryParamsManagerImpl.instance = new QueryParamsManagerImpl();
			return QueryParamsManagerImpl.instance;
		}
		return QueryParamsManagerImpl.instance;
	}

	// eslint-disable-next-line class-methods-use-this
	get(key: string): string | undefined {
		const qs = new URLSearchParams(window.location.search.slice(1));
		return qs.get(key) ?? undefined;
	}

	set(key: string, value: string): void {
		const existingIndex = this.wantedState.getValue().findIndex(({ key: internalKey }) => internalKey === key);
		if (existingIndex === -1) {
			this.wantedState.next([...this.wantedState.getValue(), { key, value }]);
		} else {
			const tmp = structuredClone(this.wantedState.getValue());
			tmp[existingIndex] = { key, value };
			this.wantedState.next(tmp);
		}
	}

	delete(key: string): void {
		const existingIndex = this.wantedState.getValue().findIndex(({ key: internalKey }) => internalKey === key);
		if (existingIndex !== -1) {
			this.wantedState.next([
				...this.wantedState.getValue().slice(0, existingIndex),
				...this.wantedState.getValue().slice(existingIndex + 1),
			]);
		}
	}
}

export function useQueryParamsManager() {
	const { search } = useLocation();
	const navigate = useNavigate();
	const wantedStateStore = useSyncExternalStore(
		useCallback((callback) => {
			const subscription = QueryParamsManagerImpl.getInstance().wantedState.subscribe(callback);
			return () => subscription.unsubscribe();
		}, []),
		() => QueryParamsManagerImpl.getInstance().wantedState.getValue(),
	);

	const wantedSearch = useMemo((): string => {
		const nextSearch = wantedStateStore.reduce(
			(acc, { key, value }) => `${acc}${acc !== '' ? '&' : ''}${key}${value ? `=${encodeURIComponent(value)}` : ''}`,
			'',
		);
		if (nextSearch) return `?${nextSearch}`;
		return '';
	}, [wantedStateStore]);

	useEffect(() => {
		if (wantedSearch !== search)
			setTimeout(
				() => navigate(`${window.location.pathname}${wantedSearch}${window.location.hash}`, { replace: true }),
				0,
			);
	}, [navigate, wantedSearch, search]);
}
