import { CompilationObjectEntry } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/TitleMetadata';
import useTitleAutoSave from '../../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../../../src/views/title/hooks/useMemoJsonPath';
import { updateEntryNumberInCompilationEntries } from '../../../feature-edit-relationship/comp-obj-relationship.adapter';

export interface UseEditEntryNumberOutput {
	updateEntryNumber: (newEntryNumber: string | undefined, compObjContentId: string) => void;
	commit: () => void;
	compilationEntries: CompilationObjectEntry[] | undefined;
}

export function useEditEntryNumber(): UseEditEntryNumberOutput | null {
	const {
		value: compilationEntries,
		setValue: setCompilationEntries,
		commit,
	} = useTitleAutoSave<CompilationObjectEntry[]>({
		path: useMemoJsonPath(['metadata', 'coreMetadata', 'compilationObject', 'compilationEntries']),
		label: 'Entry Number',
		isInheritable: false,
	});

	if (!compilationEntries) return null;

	const updateEntryNumber = (newEntryNumber: string | undefined, compObjContentId: string) => {
		setCompilationEntries(updateEntryNumberInCompilationEntries(newEntryNumber, compObjContentId, compilationEntries));
	};

	return {
		updateEntryNumber,
		commit,
		compilationEntries,
	};
}
