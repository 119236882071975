import React from 'react';
import styled, { css } from 'styled-components';
import Pagination from '../../../../src/components/library/Pagination';
import ItemsPerPageSelector from '../../../../src/components/library/ItemsPerPageSelector';
import { PaginatorStore } from './paginator.store';

export function PaginationNavigatorCore({
	actions,
	page,
	totalPages,
	perPage,
	centered = false,
}: PaginationNavigatorProps) {
	return (
		<PaginationWrapper centered={centered}>
			{centered && <div />}
			<Pagination value={page} lastPage={totalPages} onChange={(v) => actions.setPage(v)} />
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				<ItemsPerPageSelector
					value={perPage}
					onChange={(v) => {
						actions.setPerPage(v);
					}}
				/>
			</div>
		</PaginationWrapper>
	);
}

interface PaginationNavigatorProps {
	centered?: boolean;
	page: number;
	totalPages: number;
	perPage: number;
	actions: PaginatorStore['actions'];
}

const PaginationWrapper = styled.div<{ centered: boolean }>(
	({ theme, centered }) => css`
		box-sizing: border-box;
		display: grid;
		grid-area: pagination;
		grid-template-columns: ${centered ? '1fr 3fr 1fr' : '5fr 2fr'};
		padding: 0 ${theme.spacing(2)};
		width: 100%;

		.MuiGrid-container {
			width: auto;
		}
	`,
);
