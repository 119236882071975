import { WorkType } from '@warehouse/title/core';
import { getWorkTypeObject } from '@warehouse/title/infra';
import * as _ from 'lodash';
import { createFilter, FilterStep, FilterWithDate } from '@warehouse/shared/filters';
import { SearchFilters } from '../../title/domain/SearchFilters';

export class RelationshipFilters extends SearchFilters {
	static childrenOf({
		titleUuid,
		excludeHidden,
		relationshipType,
		includedWorkTypes,
	}: {
		titleUuid: string;
		excludeHidden?: boolean;
		relationshipType?: string;
		includedWorkTypes?: WorkType[];
	}): FilterStep {
		return {
			step: {
				combinationOperator: 'AND',
				filters: _.compact([
					relationshipType && {
						field: 'relationship.type',
						operator: 'equals' as const,
						value: relationshipType,
					},
					{
						field: 'relationship.contentId',
						operator: 'equals',
						value: titleUuid,
					},
					{
						field: 'direction',
						operator: 'equals',
						value: 'parent',
					},
					excludeHidden && {
						field: 'indexed.titleStatus',
						operator: 'doesNotEqual' as const,
						value: 'hidden',
					},
					includedWorkTypes && createWorkTypeFilters(includedWorkTypes),
				]),
			},
		};
	}
}

function createWorkTypeFilters(includedWorkTypes: WorkType[]): FilterStep {
	return {
		step: {
			combinationOperator: 'OR',
			filters: includedWorkTypes.map(createWorkTypeFilter),
		},
	};
}

function createWorkTypeFilter(workType: WorkType): FilterWithDate<'equals'> {
	const workTypeId = getWorkTypeObject(workType).uuid;

	return createFilter({
		field: 'indexed.workType.uuid',
		operator: 'equals',
		value: workTypeId,
	});
}
