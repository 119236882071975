import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import { DatePickerWrapper } from '../style';
import DatePicker from '../../../../components/library/DatePicker/DatePicker';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface ReleaseDateFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function ReleaseDateField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	dryRanTitle,
}: ReleaseDateFieldProps) {
	const releaseDateTooltip = useTooltip('coreMetadata.basic.releaseDate');
	const inheritedReleaseDate = getTitleData<OptionalInherited<string>>(dryRanTitle, fromBasic(['releaseDate']));

	return useMemo(
		() => (
			<Controller
				name="releaseDate"
				control={control}
				render={({ field }) => (
					<DatePickerWrapper>
						<DatePicker
							ariaLabel="Release Date Field"
							placeholder={inheritedReleaseDate?.inherited.value}
							variant={!field.value && inheritedReleaseDate?.inherited.value ? 'prefilled' : 'default'}
							tooltip={releaseDateTooltip?.tooltip}
							error={errors?.releaseDate?.message ? 'Release Date is required and must be a valid date' : ''}
							label={getLabelWithRequired('Release Date', isRequired)}
							value={field.value}
							onChange={async (e) => {
								setValue('releaseDate', e);
								if (isRequired && !inheritedReleaseDate?.inherited.value) {
									await trigger('releaseDate');
								}
							}}
						/>
					</DatePickerWrapper>
				)}
			/>
		),
		[control, inheritedReleaseDate?.inherited.value, errors?.releaseDate?.message, setValue, trigger],
	);
}

export default ReleaseDateField;
