import React from 'react';
import BreadcrumbsMUI from '@mui/material/Breadcrumbs';
import { Skeleton } from '@mui/material';
import { To } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TypoFakeLink, TypoLink } from '../TypoLink';

export interface BreadcrumbItem {
	label: string;
	to?: To;
	icon?: React.ReactNode;
	onClick?: () => void;
}

interface BreadcrumbsProps {
	items: BreadcrumbItem[];
	loading?: boolean;
	maxItems?: number;
}

export function Breadcrumbs({ items, loading = false, maxItems }: BreadcrumbsProps) {
	// If we are in loading mode and there is no items to show, we push a fake one to still display a skeleton
	if (loading && items.length === 0) items.push({ label: 'fake', to: 'fake' });

	return (
		<div role="presentation">
			<BreadcrumbsMUI maxItems={maxItems ?? 6} aria-label="breadcrumb">
				{items.map((item, idx) => {
					if (loading) {
						return <Skeleton key={getUniqueKey(item.to, idx)} sx={{ width: idx === 0 ? 250 : 170 }} />;
					}
					return (
						<ItemWrapper key={getUniqueKey(item.to, idx)}>
							{item.icon}
							{item.to ? (
								<TypoLink onClick={item.onClick} to={item.to} color="inherit">
									{item.label}
								</TypoLink>
							) : (
								<TypoFakeLink color="inherit">{item.label}</TypoFakeLink>
							)}
						</ItemWrapper>
					);
				})}
			</BreadcrumbsMUI>
		</div>
	);
}

function getUniqueKey(to: To | undefined, idx: number) {
	if (typeof to === 'string') return to;
	if (to?.pathname) return to.pathname;
	return `fake-${idx}`;
}

const ItemWrapper = styled.div(
	() => css`
		a:hover {
			text-decoration: underline;
		}

		align-items: center;
		display: flex;
		gap: 4px;
	`,
);
