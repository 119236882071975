import React from 'react';
import styled, { css } from 'styled-components';
import { Divider } from '@mui/material';
import FileInformation from './FileInformation';
import LinkedTracks from './LinkedTracks';

const Wrapper = styled.div(
	() => css`
		width: 700px;
	`,
);

function TrackAssets() {
	return (
		<Wrapper>
			<FileInformation trackData={undefined} />
			<Divider style={{ marginTop: 16, marginBottom: 16 }} />
			<LinkedTracks trackData={undefined} />
			{/* <Divider style={{ marginTop: 16, marginBottom: 16 }} /> */}
			{/* <Assignment data={trackAsset} /> */}
		</Wrapper>
	);
}

export default TrackAssets;
