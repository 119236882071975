import { NxObjectFull } from '@warehouse/object-browser/core';
import { operations } from './inventory.openapi';
import { NxObjectAdapter } from './nx-object.adapter';

type GetNxObjectResponse = operations['get-nx-object']['responses']['200']['content']['application/json'];

export class NxObjectFullAdapter {
	static adapt(item: GetNxObjectResponse): NxObjectFull {
		return {
			nxObject: NxObjectAdapter.adapt(item.nxObject),
			metadata: item.metadata,
		};
	}
}
