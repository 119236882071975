import { GridColDef } from '@mui/x-data-grid-pro';
import { filesize } from 'filesize';
import { NxObject, NxObjectType, StorageTierService } from '@warehouse/object-browser/core';
import { timeToString } from '../../../src/components/library/DatePicker/DatePicker';
import { IconCell, StorageTierCell } from './ui/fields';
import { getCustomGridSingleSelectOperators } from '../../shared/ui/table/muix.adapter';
import { NameCell } from './ui/fields/NameCell';

export const objectBrowserTableColumns = [
	{
		field: 'itemType',
		headerName: 'Type',
		renderHeader: () => null,
		filterOperators: getCustomGridSingleSelectOperators(),
		align: 'center',
		disableColumnMenu: true,
		disableReorder: true,
		pinnable: true,
		hideable: false,
		filterable: false,
		sortable: false,
		renderCell: IconCell,
		resizable: false,
		width: 40,
	},
	{
		field: 'name',
		headerName: 'Name',
		minWidth: 325,
		flex: 1,
		hideable: false,
		renderCell: NameCell,
	},
	{
		field: 'bucket',
		headerName: 'Bucket',
		width: 300,
	},
	{
		field: 'fileSize',
		headerName: 'Size',
		type: 'number',
		valueFormatter: (_, row) => (row.itemType === NxObjectType.File ? filesize(row.fileSize, { round: 1 }) : ''),
		width: 100,
	},
	{
		field: 'lastModifiedAt',
		headerName: 'Last Modified',
		type: 'date',
		valueFormatter: (_, row) => (row.lastModifiedAt ? timeToString(row.lastModifiedAt) : undefined),
		width: 160,
	},
	{
		field: 'storageTier',
		headerName: 'Storage Tier',
		renderCell: StorageTierCell,
		width: 170,
		type: 'singleSelect',
		valueOptions: StorageTierService.getStorageTierOptions(),
		filterOperators: getCustomGridSingleSelectOperators(),
	},
	{
		field: 'eTag',
		headerName: 'ETag',
		width: 300,
	},
	{
		field: 'online',
		headerName: 'Online',
		type: 'boolean',
		width: 140,
	},
	{
		field: 'key',
		headerName: 'Key',
		width: 300,
	},
	{
		field: 'uuid',
		headerName: 'ID',
		width: 300,
	},
	{
		field: 'uploadedByOrganization',
		headerName: 'Uploaded By Organization',
		width: 150,
	},
	{
		field: 'uploadedByUser',
		headerName: 'Uploaded By User',
		width: 150,
	},
	{
		field: 's3Uri',
		headerName: 'S3 URI',
		width: 300,
	},
] as const satisfies readonly GridColDef<NxObject>[];

export type ColumnName = (typeof objectBrowserTableColumns)[number]['field'];
