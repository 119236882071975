import { ApolloError } from '@apollo/client';
import { FilterStep, FilterWithDate, RestrictorType } from '@warehouse/shared/filters';

export type PaginatedVirtualizedQueryHook = ({
	pollInterval,
	perPage,
	page,
	searchValue,
	filtering,
}: {
	searchValue?: string;
	pollInterval: number;
	perPage?: number;
	page?: number;
	filtering?: FilterStep;
}) => {
	loading: boolean;
	error: ApolloError | undefined;
	data: any;
	refetch: (args: any) => any;
	fetchMore: (args: any) => any;
};

type FilterFieldsRequirements = {
	id: string;
	label: string;
	type: RestrictorType;
	options?: {
		label: string;
		value: string;
	}[];
};

export interface FilterField {
	id: string;
	label: string;
	type: RestrictorType;
	options?: {
		label: string;
		value: string;
	}[];
}

export interface FilterWithId extends FilterWithDate {
	id?: string;
}

export interface FilterStepWithId extends FilterStep {
	id?: string;
	step: {
		combinationOperator: 'AND' | 'OR';
		filters: Array<FilterWithId | FilterStepWithId>;
	};
}

// Type guard to use in recursion
export function isStep(item: FilterWithId | FilterStepWithId | FilterWithDate | FilterStep): item is FilterStepWithId {
	return (item as FilterStepWithId).step !== undefined;
}

export function isFilter(item: FilterWithId | FilterStepWithId | FilterWithDate | FilterStep): item is FilterWithId {
	return (item as FilterWithId).field !== undefined;
}
export function dataToFilterFields(requirements: FilterFieldsRequirements[]): FilterField[] {
	const res: FilterField[] = requirements.map((i) => ({
		options: [],
		...i,
	}));

	// Removing duplicates and sorting
	res.forEach((r) => {
		// eslint-disable-next-line no-param-reassign
		r.options = r.options
			?.filter((value, index, self) => index === self.findIndex((t) => t.value === value.value))
			.sort((a, b) => a.label.localeCompare(b.label));
	});
	return res.sort((a, b) => a.label.localeCompare(b.label));
}

const formatDate = (d: Date) => d.toISOString().substring(0, 10);

const minusDate = (d: Date, delta: number) => new Date(d.setDate(d.getDate() + delta));

// Utility to remove Ids and convert strings to real types (boolean, number, ...) for API
export function prepareDataForAPI(data: FilterStepWithId, fields: FilterField[]) {
	const tmp: FilterStepWithId = structuredClone(data);

	const browseItems = (items: (FilterStepWithId | FilterWithId)[]) => {
		items.forEach((i, index) => {
			const filter = items[index];
			if (isStep(filter)) {
				delete filter.id;
				// eslint-disable-next-line no-param-reassign
				items[index] = filter;
				browseItems(filter.step.filters);
			}
			if (isFilter(filter)) {
				delete filter.id;
				const field = fields.find((f) => f.id === filter.field);
				if (field) {
					if (field.type === 'float' && filter.value) filter.value = parseFloat(filter.value as string);
					else if (field.type === 'int' && filter.value) filter.value = parseInt(filter.value as string, 10);
					else if (field.type === 'boolean' && filter.value) filter.value = filter.value === 'true';
					else if (field.type === 'date') {
						const dateAux = filter.dateAuxiliary;
						if (dateAux?.operator === 'theDate') filter.value = formatDate(new Date(filter.value as string));
						else if (dateAux?.operator === 'today') filter.value = formatDate(new Date());
						else if (dateAux?.operator === 'yesterday') {
							filter.value = formatDate(minusDate(new Date(), -1));
						} else if (dateAux?.operator === 'tomorrow') {
							filter.value = formatDate(minusDate(new Date(), +1));
						} else if (dateAux?.operator === 'daysInThePast' && dateAux?.number !== undefined) {
							filter.value = formatDate(minusDate(new Date(), -dateAux.number));
						} else if (dateAux?.operator === 'daysInTheFuture' && dateAux?.number !== undefined) {
							filter.value = formatDate(minusDate(new Date(), +dateAux.number));
						}
					}
					delete filter.dateAuxiliary;

					// eslint-disable-next-line no-param-reassign
					items[index] = filter;
				}
			}
		});
		return items;
	};

	delete tmp.id;
	tmp.step.filters = browseItems(tmp.step.filters);
	return tmp;
}
