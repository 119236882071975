import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiPencil, mdiPlus, mdiCheckboxMarkedCircle, mdiCloseCircle, mdiTrashCan } from '@mdi/js';
import { DialogContent, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

// TYPES
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';
import {
	LocalizedInfoExcludedRegion,
	LocalizedInfoRegion,
} from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// HOOKS
import { When } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/VersionIntent';
import { DropdownV2, ErrorAlert } from '@warehouse/shared/ui';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { isInherited, isPrimaryAndDerivedFrom as getIsPrimaryAndDerivedFrom } from '@warehouse/title/core';
import { territoriesHelper } from '@warehouse/global-entities/core';
import { useTransactionalBatchOperations } from '../../../../src/utils/hooks/titles/useTransactionalBatchOperations';
import useLocalizedInfosIntervals from '../../../../src/views/title/tab/productMetadata/localizedInfo/useLocalizedInfoIntervals';

// UTILS
import { fromBasic, getTitleData, mapPathDefaultAndFirstToUuid } from '../../../../src/utils/titleGetProperty';
import ajvResolver from '../../../../src/utils/ajvResolver';
import sortAlphabeticallyByLabel from '../../../../src/utils/sortAlphabeticallyByLabel';

// LIBRARIES
import DialogActions from '../../../../src/components/library/DialogActions';
import Loader from '../../../../src/components/library/Loader';
import DialogTitle, { CloseButton } from '../../../../src/components/library/DialogTitle';
import Button from '../../../../src/components/library/Button';
import Dialog from '../../../../src/components/library/Dialog';
import TextInput from '../../../../src/components/library/TextInput';
import DatePicker, { dateToString, stringToDate } from '../../../../src/components/library/DatePicker/DatePicker';
import ConfirmationModal from '../../../../src/components/library/ConfirmationModal';
import Alert from '../../../../src/components/library/Alert';

// JSON
import languageWrittenJSON from '../../../../src/assets/json-administration-profiles/availableLanguageWritten.json';
import { useRemoveRecord } from '../../../../src/utils/hooks/titles/useRemoveRecord';
import { TerritoriesSelector } from '../../../../src/components/titles/TerritoriesSelector';
import IncludeExcludeBottomAction from '../../../../src/components/titles/IncludeExcludeBottomAction';
import useTooltip from '../../../../src/utils/hooks/useTooltips';
import { LocalizedInfo } from '../../core/models/localized-info';

const { getKey } = territoriesHelper;

export function EditLocalizedInfoModal({
	open,
	onClose,
	selectedLocalizedInfo,
	setSelectedLocalizedInfo,
}: EditLocalizedInfoModalProps) {
	const theme = useTheme();
	const [transactionalBatchOperations] = useTransactionalBatchOperations();
	const languageWritten = languageWrittenJSON.sort(sortAlphabeticallyByLabel);
	const title = useTitleEditorStore(titleEditorStoreSelector.titleRequired);

	const intervals = useLocalizedInfosIntervals(title);
	const [intervalMode, setIntervalMode] = useState<'add' | 'edit' | 'select'>('select');
	const [removeRecord] = useRemoveRecord();
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
	const [deleteValueInput, setDeleteValueInput] = useState<string>('');
	const [deleteMutationIsLoading, setDeleteMutationIsLoading] = useState<boolean>(false);
	const [deleteMutationError, setDeleteMutationError] = useState<string>('');
	const [editMutationError, setEditMutationError] = useState<any>(undefined);
	const localizedInfoPath = ['localizedInfos', selectedLocalizedInfo || '__default'];

	const localeTooltip = useTooltip('coreMetadata.basic.localizedInfos.language');
	const regionsTooltip = useTooltip('coreMetadata.basic.localizedInfos.regions');
	const excludedRegionsTooltip = useTooltip('coreMetadata.basic.localizedInfos.excludedRegions');
	const intervalTooltip = useTooltip('coreMetadata.basic.localizedInfos.targetAudiences.audience.whens');
	const intervalLabelTooltip = useTooltip('coreMetadata.basic.localizedInfos.targetAudiences.audience.whens.when');
	const intervalStartDateTooltip = useTooltip(
		'coreMetadata.basic.localizedInfos.targetAudiences.audience.whens.startDate',
	);
	const intervalEndDateTooltip = useTooltip('coreMetadata.basic.localizedInfos.targetAudiences.audience.whens.endDate');

	const {
		reset,
		control,
		getValues,
		setValue,
		handleSubmit,
		formState: { isDirty, dirtyFields, errors, isSubmitting: updateMutationIsLoading },
	} = useForm<FormDataType>({
		resolver: ajvResolver(validationSchema as any),
		defaultValues: {
			interval: {
				startDate: '',
				endDate: '',
				description: '',
			},
			regions: {
				regions: [],
				regionsAreExcluded: false,
			},
		},
	});

	const excludedRegions = getTitleData<OptionalInherited<LocalizedInfoExcludedRegion[]>>(
		title,
		fromBasic([...localizedInfoPath, 'excludedRegions']),
	);
	const isRegionExcluded = !!(excludedRegions?.displayValue && excludedRegions?.displayValue?.length > 0);

	const getCurrentInterval = () => {
		const description = getTitleData<Inherited<string>>(
			title,
			fromBasic([...localizedInfoPath, 'targetAudiences', '__first', 'description']),
		);
		const interval = getTitleData<OptionalInherited<When[]>>(
			title,
			fromBasic([...localizedInfoPath, 'targetAudiences', '__first', 'whens']),
		);
		return {
			startDate: interval?.displayValue?.[0]?.startDate || null,
			endDate: interval?.displayValue?.[0]?.endDate || null,
			description: description?.displayValue || '',
		};
	};

	useEffect(() => {
		if (updateMutationIsLoading) return;
		const regions = getTitleData<OptionalInherited<LocalizedInfoRegion[]>>(
			title,
			fromBasic([...localizedInfoPath, 'regions']),
		);
		reset({
			interval: getCurrentInterval(),
			regions: {
				regions: isRegionExcluded
					? excludedRegions?.displayValue?.map((exclRegion) => exclRegion.countryRegion || exclRegion.country) || []
					: regions?.displayValue?.map((region) => region.countryRegion || region.country) || [],
				regionsAreExcluded: isRegionExcluded,
			},
		});
		setEditMutationError(undefined);
		setDeleteMutationError('');
		setIntervalMode('select');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, reset]);

	useEffect(() => {
		setDeleteMutationError('');
	}, [deleteModalIsOpen]);

	const locale = getTitleData<Inherited<string>>(title, fromBasic([...localizedInfoPath, 'language']));

	const onConfirmDelete = async () => {
		setDeleteMutationIsLoading(true);
		setDeleteValueInput('');

		try {
			await removeRecord({
				variables: {
					input: {
						titleUuid: title?.uuid || '',
						path: fromBasic(['localizedInfos']).slice(1).join('.'),
						recordUuids: [selectedLocalizedInfo || ''],
					},
				},
				// We update the current selected localized info in the onQueryUpdated callback to make sure the selected
				// localized info is back to default when we recompute the list of available localized info to ensure we
				// are not showing the warning message of deletion
				onQueryUpdated() {
					setSelectedLocalizedInfo('default');
				},
			});
			setDeleteModalIsOpen(false);
			onClose();
		} catch (gqlError: any) {
			setDeleteMutationError(gqlError.message);
		} finally {
			setDeleteMutationIsLoading(false);
		}
	};

	const mapAndStripIntervalPath = (keys: string[]) =>
		mapPathDefaultAndFirstToUuid(
			fromBasic([...localizedInfoPath, 'targetAudiences', '__first', ...keys]).join('.'),
			title!,
		)
			?.split('.')
			.slice(1)
			.join('.') || '';

	const handleIntervalDirty = (
		values: FormDataType,
		titleEdits: { path: string; value: string }[],
		addRecordMutation: { path: string; value: string }[],
	) => {
		const targetAudiencePath = mapAndStripIntervalPath([]);
		if (targetAudiencePath) {
			titleEdits.push({
				path: mapAndStripIntervalPath(['description']),
				value: JSON.stringify(values.interval.description),
			});
			titleEdits.push({
				path: mapAndStripIntervalPath(['whens']),
				value: JSON.stringify([
					{
						startDate: values.interval.startDate,
						endDate: values.interval.endDate,
					},
				]),
			});
		} else if (dirtyFields.interval) {
			addRecordMutation.push({
				path: fromBasic([...localizedInfoPath, 'targetAudiences'])
					.slice(1)
					.join('.'),
				value: JSON.stringify({
					description: values.interval.description,
					whens: [
						{
							endDate: values.interval.endDate,
							startDate: values.interval.startDate,
						},
					],
				}),
			});
		}
	};

	const handleRegionsDirty = (values: FormDataType, titleEdits: { path: string; value: string }[]) => {
		titleEdits.push(
			{
				path: fromBasic([...localizedInfoPath, values.regions.regionsAreExcluded ? 'excludedRegions' : 'regions'])
					.slice(1)
					.join('.'),
				value: JSON.stringify(values.regions.regions.map((reg) => ({ [getKey(reg)]: reg }))),
			},
			{
				path: fromBasic([...localizedInfoPath, values.regions.regionsAreExcluded ? 'regions' : 'excludedRegions'])
					.slice(1)
					.join('.'),
				value: JSON.stringify([]),
			},
		);
	};

	const onSubmit = async (values: FormDataType) => {
		setEditMutationError(undefined);
		if (!title) return;
		if (Object.keys(dirtyFields).length === 0) return;
		const titleEdits: { path: string; value: string }[] = [];
		const addRecordMutation: { path: string; value: string }[] = [];

		if (dirtyFields.interval) {
			handleIntervalDirty(values, titleEdits, addRecordMutation);
		}
		if (dirtyFields.regions) handleRegionsDirty(values, titleEdits);

		try {
			await transactionalBatchOperations({
				variables: {
					input: {
						addRecords: addRecordMutation || [],
						editType: 'explicit',
						edits: titleEdits,
						removeRecords: [],
						titleUuid: title.uuid,
					},
				},
			});
			onClose();
		} catch (error: any) {
			if (typeof error?.message === 'string') {
				setEditMutationError(error);
			} else {
				setEditMutationError({ message: "Couldn't edit localized info" });
			}
		}
	};

	const parentsMapped =
		title.metadata.coreMetadata.basic.parents?.map((parent) => ({
			relationshipType: parent.relationshipType,
			primary: parent.primary,
		})) || [];

	const isPrimary = getIsPrimaryAndDerivedFrom(parentsMapped);

	return (
		<>
			<Dialog maxWidth="md" open={open} fullWidth onClose={onClose}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogTitle>
						Edit Localized Info
						<CloseButton onClose={onClose} />
					</DialogTitle>
					<DialogContent>
						<ContentWrapper>
							{editMutationError && (
								<ErrorAlert errorMessage={editMutationError.message} onClose={() => setEditMutationError(undefined)} />
							)}
							<DropdownV2
								disabled
								options={languageWritten.map(({ uuid, label }) => ({
									label,
									value: uuid,
								}))}
								tooltip={localeTooltip?.tooltip}
								value={locale?.displayValue || ''}
								onChange={() => {}}
								variant={
									isLocalizedInfoInherited(getTitleData(title, fromBasic([...localizedInfoPath])))
										? 'prefilled'
										: 'default'
								}
								label="Locale"
							/>
							{intervalMode === 'select' && (
								<IntervalRowWrapper>
									<Controller
										control={control}
										name="interval"
										render={({ field }) => (
											<>
												<div style={{ width: '100%' }}>
													<DropdownV2
														ariaLabel="Interval Field"
														variant={
															isLocalizedInfoInherited(getTitleData(title, fromBasic([...localizedInfoPath])))
																? 'prefilled'
																: 'default'
														}
														withSearch
														disabled={
															!isPrimary &&
															isLocalizedInfoInherited(getTitleData(title, fromBasic([...localizedInfoPath])))
														}
														tooltip={intervalTooltip?.tooltip}
														options={intervals.options}
														value={
															field.value.endDate !== null && field.value.startDate !== null
																? JSON.stringify(field.value, Object.keys(field.value).sort())
																: null
														}
														onChange={(e) => {
															if (e) {
																const interval = JSON.parse(e);
																field.onChange({ ...interval });
															}
														}}
														error={errors?.interval?.message || errors?.interval?.root?.message}
														label="Interval"
														bottomActions={
															<AddButton
																startIcon={<Icon path={mdiPlus} size="16px" />}
																nxstyle="tertiary-light"
																onClick={() => {
																	setIntervalMode('add');
																	field.onChange({
																		description: '',
																		startDate: null,
																		endDate: null,
																	});
																}}
															>
																<AddButtonMessageWrapper>
																	Add
																	<CustomAlert severity="info">
																		Create a new interval by clicking the add button
																	</CustomAlert>
																</AddButtonMessageWrapper>
															</AddButton>
														}
													/>
												</div>
												<Button
													aria-label="Edit Interval Button"
													onClick={() => {
														setIntervalMode('edit');
													}}
													nxstyle="secondary-blue"
													disabled={
														(!field.value.description &&
															field.value.startDate !== null &&
															field.value.endDate !== null) ||
														(!isPrimary && isLocalizedInfoInherited(getTitleData(title, fromBasic(localizedInfoPath))))
													}
													style={{
														height: '48px',
														width: '48px',
														top: '14px',
													}}
												>
													<Icon size="16px" path={mdiPencil} />
												</Button>
											</>
										)}
									/>
								</IntervalRowWrapper>
							)}
							{['add', 'edit'].includes(intervalMode) && (
								<EditIntervalWrapper>
									<Typography variant="s2Regular">{intervalMode === 'add' ? 'Add' : 'Edit'} Interval</Typography>
									<Row>
										<Controller
											control={control}
											name="interval.description"
											render={({ field }) => (
												<div style={{ width: '100%' }}>
													<TextInput
														value={field.value}
														onChange={(e) => field.onChange(e.target.value)}
														tooltip={intervalLabelTooltip?.tooltip}
														label="Label"
														error={field.value.length <= 0 ? errors?.interval?.root?.message : ''}
													/>
												</div>
											)}
										/>
									</Row>
									<Row>
										<Controller
											control={control}
											name="interval.startDate"
											render={({ field }) => (
												<DatePicker
													ariaLabel="Start Date Field"
													tooltip={intervalStartDateTooltip?.tooltip}
													label="Start Date"
													value={field.value ? stringToDate(field.value) : undefined}
													onChange={(e) => {
														field.onChange(e ? dateToString(e) : null);
													}}
													error={
														errors?.interval?.root?.message &&
														getValues().interval.description !== '' &&
														getValues().interval.startDate === null
															? 'Start date required'
															: ''
													}
												/>
											)}
										/>
										<Controller
											control={control}
											name="interval.endDate"
											render={({ field }) => (
												<DatePicker
													ariaLabel="End Date Field"
													tooltip={intervalEndDateTooltip?.tooltip}
													label="End Date"
													value={field.value ? stringToDate(field.value) : undefined}
													onChange={(e) => {
														field.onChange(e ? dateToString(e) : null);
													}}
													error={
														errors?.interval?.root?.message &&
														getValues().interval.description !== '' &&
														getValues().interval.endDate === null
															? 'End date required'
															: errors?.interval?.endDate?.message
													}
												/>
											)}
										/>
									</Row>
									{intervals.intervals.length !== 0 && (
										<Button
											onClick={() => {
												setIntervalMode('select');
												setValue('interval', getCurrentInterval());
											}}
											nxstyle="secondary-black"
											style={{
												height: '36px',
												marginTop: '8px',
												width: '100%',
											}}
										>
											Use existing intervals
										</Button>
									)}
								</EditIntervalWrapper>
							)}
							<Controller
								control={control}
								name="regions"
								render={({ field }) => (
									<TerritoriesSelector
										ariaLabel="Regions Field"
										variant={
											isLocalizedInfoInherited(getTitleData(title, fromBasic([...localizedInfoPath])))
												? 'prefilled'
												: 'default'
										}
										disabled={!isPrimary && isLocalizedInfoInherited(getTitleData(title, fromBasic(localizedInfoPath)))}
										tooltip={field.value.regionsAreExcluded ? excludedRegionsTooltip?.tooltip : regionsTooltip?.tooltip}
										labelIcon={
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '8px',
												}}
											/>
										}
										multiple
										onChange={(value) => {
											field.onChange({
												...field.value,
												regions: value,
											});
										}}
										value={field.value.regions}
										label={field.value.regionsAreExcluded ? 'Excluded Regions' : 'Regions'}
										error={errors?.regions?.message as string}
										bottomActions={
											<IncludeExcludeBottomAction
												spacing={20}
												value={field.value.regionsAreExcluded ? 'excludes' : 'includes'}
												defaultValue="includes"
												onChange={() => {
													field.onChange({
														...field.value,
														regionsAreExcluded: !field.value.regionsAreExcluded,
													});
												}}
											/>
										}
									/>
								)}
							/>
							<ButtonWrapper>
								<Button nxstyle="secondary-red" onClick={() => setDeleteModalIsOpen(true)}>
									<Icon path={mdiTrashCan} size="16px" />
								</Button>
							</ButtonWrapper>
						</ContentWrapper>
					</DialogContent>
					<DialogActions>
						<Button
							nxstyle="secondary-black"
							endIcon={<Icon path={mdiCloseCircle} size="16px" />}
							onClick={onClose}
							disabled={updateMutationIsLoading}
						>
							Cancel
						</Button>
						<Button
							nxstyle="primary-blue"
							endIcon={updateMutationIsLoading ? null : <Icon path={mdiCheckboxMarkedCircle} size="16px" />}
							disabled={!isDirty}
							type="submit"
						>
							{updateMutationIsLoading ? <Loader size={20} color={theme.palette.light.main} /> : 'Save'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<ConfirmationModal
				title="Delete Localized Info"
				message="Are you sure you want to delete this Localized Info? To proceed, please type “DELETE” in the box below."
				topElement={
					<>
						{deleteMutationError && (
							<ErrorAlert
								style={{ margin: '16px 16px 0px 16px' }}
								onClose={() => setDeleteMutationError('')}
								errorMessage={deleteMutationError}
							/>
						)}
						<Alert style={{ margin: '16px 16px 0px 16px' }} severity="error">
							Deleting a Localized Info will also delete any custom product metadata associated with it. This may affect
							other items in the system including Orders and Other Titles. You cannot undo this action.
						</Alert>
					</>
				}
				element={
					<DeleteInputWrapper>
						<TextInput
							placeholder="DELETE"
							value={deleteValueInput}
							onChange={(e) => {
								if (deleteMutationError) {
									setDeleteMutationError('');
								}
								setDeleteValueInput(e.target.value);
							}}
						/>
					</DeleteInputWrapper>
				}
				confirmText="Delete"
				variant="danger"
				open={deleteModalIsOpen}
				confirmButtonDisabled={deleteValueInput.toUpperCase() !== 'DELETE'}
				confirmButtonLoading={deleteMutationIsLoading}
				onClose={() => setDeleteModalIsOpen(false)}
				onConfirm={onConfirmDelete}
			/>
		</>
	);
}

const validationSchema = {
	type: 'object',
	properties: {
		interval: {
			type: 'object',
			oneOf: [
				{
					type: 'object',
					properties: {
						startDate: { type: 'string', format: 'date' },
						endDate: {
							type: 'string',
							format: 'date',
							formatMinimum: { $data: '1/startDate' },
							errorMessage: {
								formatMinimum: 'End date must be after start date',
							},
						},
						description: { type: 'string', minLength: 1 },
					},
					required: ['startDate', 'endDate', 'description'],
					additionalProperties: false,
				},
				{
					type: 'object',
					properties: {
						startDate: { type: 'null' },
						endDate: { type: 'null' },
						description: { type: 'string', const: '' },
					},
					required: ['startDate', 'endDate', 'description'],
					additionalProperties: false,
				},
			],
			errorMessage: {
				_: 'Invalid interval',
			},
		},
		regions: {
			type: 'object',
			properties: {
				regions: { type: 'array', items: { type: 'string' } },
				regionsAreExcluded: { type: 'boolean' },
			},
			required: ['regions', 'regionsAreExcluded'],
			additionalProperties: false,
		},
	},
	required: ['interval', 'regions'],
	additionalProperties: false,
} as const;

const DeleteInputWrapper = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(0, 3)};
	`,
);

const ContentWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		padding: ${theme.spacing(3)};
	`,
);

const Row = styled.div(
	({ theme }) => css`
		align-items: end;
		display: flex;
		gap: ${theme.spacing(2)};

		.MuiButton-root {
			height: 48px;
			margin-bottom: ${theme.spacing(1.75)};
			min-width: 220px !important;
		}
	`,
);

const IntervalRowWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(2)};
	`,
);

const ButtonWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: flex-end;
		margin-top: ${theme.spacing(2)};
	`,
);

const EditIntervalWrapper = styled.div(
	({ theme }) => css`
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: ${theme.spacing(1)};
		padding: ${theme.spacing(2)};
	`,
);

const AddButtonMessageWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 100%;
	`,
);

const AddButton = styled(Button)(
	() => css`
		border-top: 1px solid rgba(177, 188, 195, 0.45);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		height: 48px;
		justify-content: flex-start;
		width: 100%;
	`,
);

const CustomAlert = styled(Alert)(
	({ theme }) => css`
		border-radius: 4px;
		height: 32px;
		margin: 0;
		padding: 0 12px 0 4px;

		svg {
			width: 20px;
			height: 20px;
		}

		.MuiAlert-icon {
			margin-right: 4px;
			position: relative;
			top: -2px;
		}

		.MuiAlert-message {
			font-size: ${theme.typography.buttonSmallMedium.fontSize};
			font-weight: ${theme.typography.buttonSmallMedium.fontWeight};
			line-height: ${theme.typography.buttonSmallMedium.lineHeight};
		}
	`,
);

interface EditLocalizedInfoModalProps {
	open: boolean;
	onClose: () => void;
	selectedLocalizedInfo: string | undefined;
	setSelectedLocalizedInfo: (value: string) => void;
}

type FormDataType = {
	interval: {
		startDate: string | null;
		endDate: string | null;
		description: string;
	};
	regions: {
		regions: string[];
		regionsAreExcluded: boolean;
	};
};

function isLocalizedInfoInherited(localizedInfo: LocalizedInfo | undefined) {
	const language = localizedInfo?.language;

	return isInherited({ inheritedObject: language }).isInherited;
}
