import React from 'react';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import AltRouteSharp from '@mui/icons-material/AltRouteSharp';
import { useTheme } from '@mui/material';
import { StyledLink } from '../../shared/ui/StyledLink';
import { jumpToParentButtonPresenter } from './JumpToParentButton.presenter';

export function JumpToParentButton({ path, disabled }: { path: string; disabled?: boolean }) {
	const theme = useTheme();
	const title = useTitleEditorStore(titleEditorStoreSelector.titleRequired);

	const { shouldDisplayJumpToParentButton, parentTitleLink } = jumpToParentButtonPresenter({ path, title, disabled });

	if (!shouldDisplayJumpToParentButton) return null;

	return (
		<StyledLink
			className="jump-to-parent-button"
			onClick={(e) => e.stopPropagation()}
			style={{ display: 'flex', cursor: 'pointer', visibility: 'hidden', alignItems: 'center' }}
			tabIndex={-1}
			to={parentTitleLink}
		>
			<AltRouteSharp
				style={{
					cursor: 'pointer',
					color: theme.palette.text.secondary,
					transform: 'rotate(180deg)',
					fontSize: '16px',
				}}
			/>
		</StyledLink>
	);
}
