import { StateCreator } from 'zustand';
import { Subscription } from 'rxjs';
import { bucketRepositorySingleton } from '@warehouse/object-browser/infra';
import { Bucket, BucketId, FolderPath, FolderPathUtils } from '@warehouse/object-browser/core';
import { deepClone } from '@warehouse/shared/util';
import { RootTreeItems, TreeItem, TreeItemType } from '../core/models';
import { TreeItemAdapter } from '../core/tree-item.adapter';
import { TreeFolderSubscriptionManager } from './tree-folder-subscription-manager';
import { pushTreeItems } from './push-tree-items';

export interface ObjectBrowserTreeManagerStore {
	treeItems: RootTreeItems;
	buckets: Bucket[];
	actions: {
		onTreeItemToggle: (treeItem: TreeItem, isExpanded: boolean) => void;
	};
	internalActions: {
		initialize: () => void;
		destroy: () => void;
	};
}

export const createObjectBrowserTreeManagerSlice: StateCreator<ObjectBrowserTreeManagerStore> = (set, get) => {
	let isBucketListLoaded = false;

	const bucketRepo = bucketRepositorySingleton.get();
	const subscriptionManager = new TreeFolderSubscriptionManager();
	let bucketSubscription: Subscription | undefined;

	// Bucket repository management
	const subscribeToBucket = () => {
		unsubscribeToBucket();
		bucketSubscription = bucketRepo.watchBuckets().subscribe((buckets) => {
			if (get().treeItems.length === 0) set({ treeItems: TreeItemAdapter.adaptBuckets(buckets), buckets });
			if (!isBucketListLoaded) {
				if (buckets.length > 0) openBucket(buckets[0].id);
				isBucketListLoaded = true;
			}
		});
	};

	const unsubscribeToBucket = () => {
		if (bucketSubscription) {
			bucketSubscription.unsubscribe();
			bucketSubscription = undefined;
		}
	};

	// Bucket repository management
	const openBucket = (bucketId: BucketId) => {
		subscriptionManager.openBucket(bucketId, (res) => {
			if (res.isSuccess)
				set({
					treeItems: pushTreeItems({
						treeItems: deepClone(get().treeItems),
						fullPath: { bucketId, path: FolderPathUtils.root() },
						itemsToPush: TreeItemAdapter.adaptFolders(res.data.documents),
					}),
				});
		});
	};

	const closeBucket = (bucketId: BucketId) => {
		subscriptionManager.closeBucket(bucketId);
		set({
			treeItems: pushTreeItems({
				treeItems: deepClone(get().treeItems),
				fullPath: {
					bucketId,
					path: FolderPathUtils.root(),
				},
				itemsToPush: [TreeItemAdapter.createTreeItemLoading()],
			}),
		});
	};

	const openFolder = (bucketId: BucketId, path: FolderPath) => {
		subscriptionManager.openFolder({ bucketId, path }, (res) => {
			if (res.isSuccess)
				set({
					treeItems: pushTreeItems({
						treeItems: deepClone(get().treeItems),
						fullPath: { bucketId, path },
						itemsToPush: TreeItemAdapter.adaptFolders(res.data.documents),
					}),
				});
		});
	};

	const closeFolder = (bucketId: BucketId, path: FolderPath) => {
		subscriptionManager.closeFolder({ bucketId, path });
		set({
			treeItems: pushTreeItems({
				treeItems: deepClone(get().treeItems),
				fullPath: { bucketId, path },
				itemsToPush: [TreeItemAdapter.createTreeItemLoading()],
			}),
		});
	};

	return {
		treeItems: [],
		buckets: [],
		actions: {
			onTreeItemToggle(treeItem, isExpanded) {
				if (treeItem.type === TreeItemType.Bucket) {
					(isExpanded ? openBucket : closeBucket)(treeItem.name);
				} else if (treeItem.type === TreeItemType.Folder) {
					(isExpanded ? openFolder : closeFolder)(
						treeItem.bucketId,
						FolderPathUtils.drillInto(treeItem.path, treeItem.name),
					);
				}
			},
		},
		internalActions: {
			initialize() {
				subscribeToBucket();
			},
			destroy() {
				unsubscribeToBucket();
				subscriptionManager.unsubscribeAll();
			},
		},
	} satisfies ObjectBrowserTreeManagerStore;
};

export const objectBrowserTreeManagerSelector = {
	treeItems: (state: ObjectBrowserTreeManagerStore) => state.treeItems,
	buckets: (state: ObjectBrowserTreeManagerStore) => state.buckets,
	actions: (state: ObjectBrowserTreeManagerStore) => state.actions,
};
