import { ToggleButtonGroup, ToggleButton as ToggleButtonMUI } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';

export function ToggleButtons<T extends string>({
	options,
	value,
	onChange,
	defaultValue,
	size = 'md',
}: ToggleButtonProps<T>) {
	const [localValue, setLocalValue] = useState<T | undefined>(defaultValue);

	const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: T) => {
		if (localValue && !newValue) return; // Prevent unselecting

		setLocalValue(newValue);
		onChange?.(newValue);
	};

	useEffect(() => {
		if (value) setLocalValue(value);
	}, [value]);

	return (
		<ToggleButtonGroup
			color={'blue' as ToggleButtonGroupProps['color']}
			value={localValue}
			exclusive
			onChange={handleChange}
			aria-label="Platform"
		>
			{options.map((option, index) => (
				<CustomToggleButtonMUI $size={size} key={`${option.value}-${index}`} value={option.value}>
					{option.label}
				</CustomToggleButtonMUI>
			))}
		</ToggleButtonGroup>
	);
}

type Size = 'xs' | 'sm' | 'md';

interface ToggleButtonProps<T> {
	options: ToggleButtonOption<T>[];
	value?: T;
	onChange?: (value: T) => void;
	defaultValue?: T;
	size?: Size;
}

export interface ToggleButtonOption<T> {
	value: T;
	label: string;
}

const CustomToggleButtonMUI = styled(ToggleButtonMUI)<{ $size: Size }>(
	({ $size, theme }) => css`
		border-radius: 8px;
		${$size === 'xs' &&
		css`
			padding: ${theme.spacing(0.25, 1.5)};
		`}

		${$size === 'sm' &&
		css`
			padding: ${theme.spacing(0.5, 3)};
		`}

		${$size === 'md' &&
		css`
			padding: ${theme.spacing(1, 6)};
		`}
		text-transform: capitalize;
	`,
);
