import React from 'react';
import styled, { css } from 'styled-components';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { Data, LicensorField, TitleOverviewLeftWrapperBase } from '@warehouse/legacy-title-overview';
import { ManifestationOverviewByAbstractTypeProps } from './types';

// FIELDS
import { CountriesOfOriginField } from '../../CountriesOfOriginField';
import { SequenceNumberField } from '../../SequenceNumberField';
import { EditUseField } from '../../EditUseField';
import { ReleaseDateField } from '../../ReleaseDateField';
import { ProductionCountriesField } from '../../ProductionCountriesField';
import { DistributionNumberField } from '../../DistributionNumberField';
import { EditClassesField } from '../../EditClassesField';
import { AspectRatioField } from '../../AspectRatioField';
import { OriginalLanguagesField } from '../../OriginalLanguagesField';
import { HouseSequenceNumberField } from '../../HouseSequenceNumberField';
import { MadeForRegionField } from '../../MadeForRegionField';
import { FrameRateField } from '../../FrameRateField';
import { RunLengthField } from '../../RunLengthField';
import { PlatformSpecificationField } from '../../PlatformSpecificationField';
import { AlidField } from '../../AlidField';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar topbar topbar'
			'licensor countriesOfOrigin sequenceNumber editUse'
			'releaseDate productionCountries distributionNumber editClass'
			'aspectRatio originalLanguage houseSequence madeForRegion'
			'frameRate runLength specification alid';
		grid-template-columns: 25% 25% 25% 25%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

export function Episode({ topBar }: ManifestationOverviewByAbstractTypeProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);

	return (
		<TitleOverviewLeftWrapper>
			{topBar}
			{/* ROW 1 */}
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField title={title} />
			</Data>
			<Data area="countriesOfOrigin">
				<CountriesOfOriginField />
			</Data>
			<Data area="sequenceNumber">
				<SequenceNumberField />
			</Data>
			<Data area="editUse" enableRightBorder>
				<EditUseField />
			</Data>
			{/* ROW 2 */}
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			<Data area="productionCountries">
				<ProductionCountriesField />
			</Data>
			<Data area="distributionNumber">
				<DistributionNumberField />
			</Data>
			<Data area="editClass" enableRightBorder>
				<EditClassesField />
			</Data>
			{/* ROW 3 */}
			<Data enableLeftBorder={false} area="aspectRatio">
				<AspectRatioField />
			</Data>
			<Data area="originalLanguage">
				<OriginalLanguagesField />
			</Data>
			<Data area="houseSequence">
				<HouseSequenceNumberField />
			</Data>
			<Data area="madeForRegion" enableRightBorder>
				<MadeForRegionField />
			</Data>
			{/* ROW 4 */}
			<Data enableBottomBorder={false} enableLeftBorder={false} area="frameRate">
				<FrameRateField />
			</Data>
			<Data enableBottomBorder={false} area="runLength">
				<RunLengthField />
			</Data>
			<Data enableBottomBorder={false} area="specification">
				<PlatformSpecificationField />
			</Data>
			<Data enableBottomBorder={false} enableRightBorder area="alid">
				<AlidField />
			</Data>
		</TitleOverviewLeftWrapper>
	);
}
