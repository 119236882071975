import { ContentIdentifier } from '@warehouse/title/core';
import platformSpecsJSON from '../../../src/assets/json-administration-profiles/available-platform-specs/tenant-platform-specs.json';

export interface PlatformSpec {
	platformUuid: string;
	platformSpecUuid: string;
	audienceIdentification: {
		namespace: string;
		location: string;
		scope: string;
		subscope: string;
		label: string;
		identifierRegex: string;
	};
}

/**
 * Not to be confused with the platform spec UUID. There is an issue where the platform spec UUID is not unique (e.g.
 * the same UUID can be used for different studios, such as iTunes for Criterion, and iTunes for Relativity). To get
 * around this, we combine the platform spec UUID with the audience location to create a unique identifier.
 */
export type PlatformSpecId = string & {
	__brand: 'platformSpecId';
};

export class PlatformSpecService {
	private static getAvailable(): PlatformSpec[] {
		return platformSpecsJSON;
	}

	public static getPlatformSpecification(platformSpecId: PlatformSpecId): PlatformSpec | undefined {
		const {
			platformSpecUuid: wantedPlatformSpecUuid,
			location,
			namespace,
			scope,
			subscope,
			label,
		} = parsePlatformSpecId(platformSpecId);
		const platformSpecs = PlatformSpecService.getAvailable();
		return platformSpecs.find(
			({ platformSpecUuid, audienceIdentification }) =>
				platformSpecUuid === wantedPlatformSpecUuid &&
				audienceIdentification.location === location &&
				audienceIdentification.namespace === namespace &&
				audienceIdentification.scope === scope &&
				audienceIdentification.subscope === subscope &&
				audienceIdentification.label === label,
		);
	}

	public static getPlatformSpecificationByContentIdentifier(
		contentIdentifier: ContentIdentifier,
	): PlatformSpec | undefined {
		const avail = PlatformSpecService.getAvailable();
		return avail.find(
			({ audienceIdentification }) =>
				audienceIdentification.namespace === contentIdentifier.namespace &&
				audienceIdentification.location === contentIdentifier.location &&
				audienceIdentification.scope === contentIdentifier.scope?.scope &&
				audienceIdentification.subscope === contentIdentifier.scope.subscope,
		);
	}

	public static getOptions(): { value: PlatformSpecId; label: string }[] {
		const platformSpecs = PlatformSpecService.getAvailable();
		return platformSpecs.map((platformSpec) => ({
			value: computePlatformSpecId(platformSpec),
			label: platformSpec.audienceIdentification.label,
		}));
	}

	public static isAlidValidForPlatformSpec({
		alid,
		platformSpecId,
	}: {
		alid: string;
		platformSpecId: PlatformSpecId;
	}): boolean {
		const platformSpec = PlatformSpecService.getPlatformSpecification(platformSpecId);

		if (!platformSpec) {
			console.error(`Platform spec with UUID ${platformSpecId} not found`);
			return false;
		}

		const identifierRegex = new RegExp(platformSpec.audienceIdentification.identifierRegex);

		return identifierRegex.test(alid);
	}
}

function computePlatformSpecId(platformSpec: PlatformSpec): PlatformSpecId {
	return JSON.stringify({
		platformSpecUuid: platformSpec.platformSpecUuid,
		location: platformSpec.audienceIdentification.location,
		namespace: platformSpec.audienceIdentification.namespace,
		scope: platformSpec.audienceIdentification.scope,
		subscope: platformSpec.audienceIdentification.subscope,
		label: platformSpec.audienceIdentification.label,
	}) as PlatformSpecId;
}

export function isPlatformSpecId(platformSpecId: string): platformSpecId is PlatformSpecId {
	const parsed = JSON.parse(platformSpecId);
	return (
		'platformSpecUuid' in parsed &&
		'namespace' in parsed &&
		'scope' in parsed &&
		'subscope' in parsed &&
		'label' in parsed &&
		'location' in parsed
	);
}

function parsePlatformSpecId(platformSpecId: PlatformSpecId): {
	platformSpecUuid: string;
	location: string;
	namespace: string;
	scope: string;
	subscope: string;
	label: string;
} {
	const parsed = JSON.parse(platformSpecId);

	return {
		platformSpecUuid: parsed.platformSpecUuid,
		location: parsed.location,
		namespace: parsed.namespace,
		scope: parsed.scope,
		subscope: parsed.subscope,
		label: parsed.label,
	};
}
