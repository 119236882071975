import React, { useCallback, useMemo } from 'react';
import { useTheme, Portal } from '@mui/material';

// HOOKS
import { RatingsTable } from '@warehouse/title/feature-regionalized-info-tab';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { useURLFragment } from '../../../../../utils/hooks/useURLFragment';
import useSnackbar from '../../../../../utils/hooks/useSnackbar';

// LIBRARY
import { BottomRow, Column } from '../style';
import ReleasesTable from './ReleasesTable';
import { FullWidthHeightWrapper } from '../../../../../components/common';
import { Field, TopRow } from '../localizedInfo';
import { TerritoriesSelector } from '../../../../../components/titles/TerritoriesSelector';
import getDefaultLocale from '../../../utils/getDefaultLocale';

const regionalizedInfoFragmentStartWith = 'product-metadata/ratings-releases/';

function ProductMetadataTabTerritoriesTab() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const theme = useTheme();
	const [fragment, setFragment] = useURLFragment();
	const snackbar = useSnackbar();
	const defaultLocale = getDefaultLocale(title);

	const selectedTerritory = useMemo(() => {
		if (!fragment.startsWith(regionalizedInfoFragmentStartWith)) return 'default';
		return fragment.slice(regionalizedInfoFragmentStartWith.length);
	}, [fragment]);

	const ratingTable = useMemo(
		() => <RatingsTable defaultLocale={defaultLocale || ''} selectedTerritory={selectedTerritory} />,
		[defaultLocale, selectedTerritory],
	);

	const releasesTable = useMemo(() => <ReleasesTable selectedTerritoryUuid={selectedTerritory} />, [selectedTerritory]);

	const handleDropdownKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Escape' && selectedTerritory !== 'default')
				setFragment('product-metadata/ratings-releases/default');
		},
		[selectedTerritory, setFragment],
	);

	return (
		<FullWidthHeightWrapper style={{ flexDirection: 'column' }}>
			<Portal container={document.body}>{snackbar.snackbar()}</Portal>
			<TopRow>
				<Field rightBorder>
					<TerritoriesSelector
						disabledMargin
						height={36}
						listItemHeight={36}
						width={300}
						backgroundColor={theme.palette.light.main}
						showAllOptions
						value={selectedTerritory ?? 'default'}
						onChange={(selected) => setFragment(`${regionalizedInfoFragmentStartWith}${selected}`)}
						onKeyDown={handleDropdownKeyDown}
					/>
				</Field>
			</TopRow>
			<BottomRow withColumnFlex={false}>
				<Column width="60%">{ratingTable}</Column>
				<Column width="40%">{releasesTable}</Column>
			</BottomRow>
		</FullWidthHeightWrapper>
	);
}

export default ProductMetadataTabTerritoriesTab;
