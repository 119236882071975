import React from 'react';
import { paginatorStoreSelector, usePaginatorStore } from './paginator.store';
import { PaginationNavigatorCore } from './PaginationNavigatorCore';

export function PaginationNavigator({ centered = false }: PaginationNavigatorProps) {
	const actions = usePaginatorStore(paginatorStoreSelector.actions);
	const page = usePaginatorStore(paginatorStoreSelector.page);
	const totalPages = usePaginatorStore(paginatorStoreSelector.totalPages);
	const perPage = usePaginatorStore(paginatorStoreSelector.perPage);

	return (
		<PaginationNavigatorCore
			centered={centered}
			actions={actions}
			page={page}
			totalPages={totalPages}
			perPage={perPage}
		/>
	);
}

interface PaginationNavigatorProps {
	centered?: boolean;
}
