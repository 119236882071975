import React, { useMemo, useState } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import TimeInput, { EIDRtoPlaceholder } from '../../../../components/library/TimeInput';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface RunLengthFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function RunLengthField({ control, errors, setValue, trigger, isRequired = false, dryRanTitle }: RunLengthFieldProps) {
	const [isFocused, setIsFocused] = useState(false);

	const runLengthTooltip = useTooltip('coreMetadata.basic.runLength');
	const inheritedRunLength = getTitleData<OptionalInherited<string>>(dryRanTitle, fromBasic(['runLength']));

	return useMemo(
		() => (
			<Controller
				name="approximateLength"
				control={control}
				render={({ field }) => (
					<TimeInput
						ariaLabel="Run Length"
						placeholder={
							inheritedRunLength?.inherited.value ? EIDRtoPlaceholder(inheritedRunLength.inherited.value) : undefined
						}
						variant={!field.value && !isFocused ? 'prefilled' : 'default'}
						tooltip={runLengthTooltip?.tooltip}
						error={errors?.approximateLength?.message as string}
						value={field.value}
						onChange={async (e) => {
							setValue('approximateLength', e);
							if (isRequired && !inheritedRunLength?.displayValue) {
								await trigger('approximateLength');
							}
						}}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						label={getLabelWithRequired('Run Length', isRequired)}
					/>
				)}
			/>
		),
		[control, isFocused, errors?.approximateLength?.message, inheritedRunLength?.inherited.value, setValue, trigger],
	);
}

export default RunLengthField;
