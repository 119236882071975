import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiClose, mdiMagnify } from '@mdi/js';
import React, { useEffect, useMemo, useState } from 'react';
import { paginatorStoreSelector, usePaginatorStore, searchStoreSelector, useSearchStore } from '@warehouse/shared/ui';
import { debounce } from '@mui/material';
import Button from '../../../../src/components/library/Button';
import TextInput from '../../../../src/components/library/TextInput';

export function SearchInput({ label }: SearchInputProps) {
	const [inputSearchValue, setInputSearchValue] = useState<string>('');

	const { setSearchValue } = useSearchStore(searchStoreSelector.actions);
	const storeSearchValue = useSearchStore(searchStoreSelector.searchValue);

	useEffect(() => {
		setInputSearchValue(storeSearchValue);
	}, [storeSearchValue]);

	const { setPage } = usePaginatorStore(paginatorStoreSelector.actions);

	const debouncedSetSearchValue = useMemo(
		() =>
			debounce((v) => {
				setPage(1);
				setSearchValue(v);
			}, 300),
		[setPage, setSearchValue],
	);

	return (
		<StyledInput
			placeholder={`Search ${label.toLowerCase()}`}
			startIcon={<Icon path={mdiMagnify} size="24px" />}
			endIcon={
				<Button
					$disablePadding
					nxstyle="tertiary-light"
					onClick={() => {
						setSearchValue('');
						setInputSearchValue('');
					}}
				>
					<Icon path={mdiClose} size="16px" />
				</Button>
			}
			onChange={(event) => {
				debouncedSetSearchValue(event.target.value);
				setInputSearchValue(event.target.value);
			}}
			height={32}
			value={inputSearchValue}
		/>
	);
}

interface SearchInputProps {
	label: string;
}

const StyledInput = styled(TextInput)(
	() => css`
		flex: 1;
	`,
);
