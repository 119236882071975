import React, { useMemo } from 'react';
import { DropdownV2 } from '@warehouse/shared/ui';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { PlatformSpecService } from '@warehouse/global-entities/core';
import useTooltip from '../../../../utils/hooks/useTooltips';
import getLabelWithRequired from '../getLabelWithRequired';

export interface PlatformSpecificationFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
}

export function PlatformSpecificationField({
	control,
	setValue,
	trigger,
	errors,
	isRequired,
}: PlatformSpecificationFieldProps) {
	const platformSpecTooltip = useTooltip('coreMetadata.basic.versionIntent.audience.identifications');

	const options = useMemo(() => PlatformSpecService.getOptions(), []);

	return (
		<Controller
			control={control}
			name="platformSpecification"
			render={({ field }) => (
				<DropdownV2
					ariaLabel="Select Platform Specification"
					value={field.value}
					tooltip={platformSpecTooltip?.tooltip}
					options={options}
					error={isRequired ? (errors?.platformSpecification?.message as string) : ''}
					onChange={async (next) => {
						setValue('platformSpecification', next);
						if (isRequired) {
							await trigger('platformSpecification');
						}
					}}
					label={getLabelWithRequired('Platform Specification', isRequired === true)}
				/>
			)}
		/>
	);
}
