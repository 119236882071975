import { DependencyList, useEffect, useRef } from 'react';

export function useSkipInitialEffect(cb: () => void, dependencies: DependencyList) {
	const isFirstRender = useRef(true);

	useEffect(() => {
		isFirstRender.current = true;
		return () => {
			isFirstRender.current = false;
		};
	}, [isFirstRender]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			cb();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
}
