import React from 'react';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { Breadcrumbs } from '@warehouse/shared/ui';
import { StoreResourceIdentifier } from '@warehouse/object-browser/core';
import styled, { css } from 'styled-components';
import { BucketIcon, FolderIcon } from './icons';
import { FileIcon } from './icons/file';
import { ObjectBrowserLinkBuilder } from '../domain/object-browser-link-builder';

export function ObjectBrowserBreadcrumbs() {
	const breadcrumbsItems = useObjectBrowserStore(objectBrowserStoreSelector.breadcrumbsItems) || [];
	const isBreadcrumbsItemsPending = useObjectBrowserStore(objectBrowserStoreSelector.isBreadcrumbsItemsPending);
	const showBreadcrumb = useObjectBrowserStore(objectBrowserStoreSelector.showBreadcrumb);
	const actions = useObjectBrowserStore(objectBrowserStoreSelector.actions);

	if (!showBreadcrumb) return null;

	return (
		<BreadcrumbsWrapper>
			<Breadcrumbs
				maxItems={5}
				loading={isBreadcrumbsItemsPending}
				items={breadcrumbsItems.map((item) => ({
					to: getItemTo(item.resourceIdentifier),
					label: item.label,
					icon: getItemIcon(item.type),
					onClick: () => {
						if (item.resourceIdentifier) actions.setResourceIdentifier(item.resourceIdentifier);
						// If we are on search mode, clicking on breadcrumb will clear the search query
						actions.setSearchQuery('');
					},
				}))}
			/>
		</BreadcrumbsWrapper>
	);
}

function getItemTo(resourceIdentifier: StoreResourceIdentifier | undefined) {
	if (!resourceIdentifier) return undefined;
	if (resourceIdentifier?.type === 'bucket') {
		return ObjectBrowserLinkBuilder.buildBucketLink(resourceIdentifier.bucketId);
	}
	if (resourceIdentifier?.type === 'folderUuid') {
		return ObjectBrowserLinkBuilder.buildFolderLink(resourceIdentifier.folderUuid);
	}
	return undefined;
}

function getItemIcon(type: 'bucket' | 'folder' | 'file') {
	switch (type) {
		case 'bucket':
			return <BucketIcon />;
		case 'folder':
			return <FolderIcon />;
		case 'file':
			return <FileIcon />;
		default:
			return undefined;
	}
}

const BreadcrumbsWrapper = styled.div(
	({ theme }) => css`
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		padding: 8px 20px;
	`,
);
