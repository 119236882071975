import { SequenceInfo } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/SequenceInfo';
import {
	RelationshipType,
	CommonReadonly,
	TitleLightWithSelectedParent,
	TitleFull,
	Parent,
	ParentSequenceInfo,
} from '@warehouse/title/core';
import {
	getRelationshipType,
	getRelationshipTypeObject,
	addSelectedParent,
	getRelationshipSubtypeObject,
} from '@warehouse/title/infra';
import { RelationshipSequenceInfo } from './parent-relationship.model';
import { ChildNotFoundError } from './common-relationship.error';

export interface ParentRelationshipEditorAdapterInput {
	parentUuid: string;
	relationshipTypeId: string;
	parents: Parent[];
	childData: TitleFull;
	parentData: TitleFull;
}

export interface ParentRelationshipEditorAdapterOutput {
	childTitle: TitleLightWithSelectedParent;
	parentTitle: CommonReadonly;
	relationshipType: RelationshipType;
	relationshipTypeLabel: string;
	relationshipSubtype?: string;
	relationshipSubtypeLabel?: string;
	sequenceInfo: RelationshipSequenceInfo;
	sequenceInfoPlaceholders: RelationshipSequenceInfo;
	parent: Parent;
}

export function adapt({
	parentUuid,
	parents,
	relationshipTypeId,
	childData,
	parentData,
}: ParentRelationshipEditorAdapterInput): ParentRelationshipEditorAdapterOutput {
	if (!childData.readonly) {
		throw new Error(`Inconsistent state: child data not indexed for title ${childData.uuid}`);
	}

	if (!parentData.readonly || !parentData.uuid) {
		throw new Error(`Inconsistent state: parent data not indexed for title ${parentData.uuid}`);
	}

	const childTitle = addSelectedParent(childData, parentData.uuid);

	const relationshipType = getRelationshipType(relationshipTypeId);
	const relationshipTypeObject = getRelationshipTypeObject(relationshipType);

	const relationshipSubtype = childTitle.selectedParent.relationshipSubtypes?.[0];
	const relationshipSubtypeObject = relationshipSubtype
		? (() => {
				try {
					return getRelationshipSubtypeObject(relationshipSubtype);
				} catch (_) {
					return { uuid: relationshipSubtype, label: relationshipSubtype };
				}
		  })()
		: undefined;

	const parent = backendParentsToParent(parents, relationshipTypeId, parentUuid);

	const sequenceInfo = mapParentToSequenceInfo(parent);

	const sequenceInfoPlaceholders = childData.metadata.coreMetadata.basic.sequenceInfo
		? mapBasicSequenceInfo(childData.metadata.coreMetadata.basic.sequenceInfo)
		: ({} as RelationshipSequenceInfo);

	return {
		childTitle,
		parentTitle: parentData.readonly,
		relationshipType,
		relationshipTypeLabel: relationshipTypeObject.label,
		relationshipSubtype: relationshipSubtypeObject?.uuid,
		relationshipSubtypeLabel: relationshipSubtypeObject?.label,
		sequenceInfo,
		sequenceInfoPlaceholders,
		parent,
	};
}

export function updateRelationshipSubtypeInParents(
	relationshipSubtype: string | undefined,
	parent: Parent,
	parents: Parent[],
) {
	const parentIndex = findParentIndex({ parents, parent });
	if (parentIndex === -1)
		throw new Error(`Parent not found for relationship ${parent.relationshipType}, parent ${parent.parentContentId}`);

	const clone: Parent[] = structuredClone(parents);

	clone[parentIndex] = updateRelationshipSubtypeInParent(relationshipSubtype, parent);

	return clone;
}

export function updateSequenceNumberInParents(
	sequenceNumber: number | undefined,
	parent: Parent,
	parents: Parent[],
): Parent[] {
	const parentIndex = findParentIndex({ parents, parent });
	if (parentIndex === -1)
		throw new Error(`Parent not found for relationship ${parent.relationshipType}, parent ${parent.parentContentId}`);

	const clone: Parent[] = structuredClone(parents);

	clone[parentIndex] = updateSequenceNumberInParent(sequenceNumber, parent);

	return clone;
}

export function updateDistributionNumberInParents(
	distributionNumber: string | undefined,
	parent: Parent,
	parents: Parent[],
): Parent[] {
	const parentIndex = findParentIndex({ parents, parent });
	if (parentIndex === -1)
		throw new Error(`Parent not found for relationship ${parent.relationshipType}, parent ${parent.parentContentId}`);

	const clone: Parent[] = structuredClone(parents);

	clone[parentIndex] = updateDistributionNumberInParent(distributionNumber, parent);

	return clone;
}

export function updateHouseSequenceInParents(
	houseSequence: string | undefined,
	parent: Parent,
	parents: Parent[],
): Parent[] {
	const parentIndex = findParentIndex({ parents, parent });
	if (parentIndex === -1)
		throw new Error(`Parent not found for relationship ${parent.relationshipType}, parent ${parent.parentContentId}`);

	const clone: Parent[] = structuredClone(parents);

	clone[parentIndex] = updateHouseSequenceInParent(houseSequence, parent);

	return clone;
}

export function updatePrimaryInParents({
	primary,
	parent,
	parents,
}: {
	primary: boolean | undefined;
	parent: Parent;
	parents: Parent[];
}): Parent[] {
	const parentIndex = findParentIndex({ parents, parent });
	if (parentIndex === -1)
		throw new Error(`Parent not found for relationship ${parent.relationshipType}, parent ${parent.parentContentId}`);

	const clone: Parent[] = structuredClone(parents);

	clone[parentIndex] = updatePrimaryInParent(primary, parent);

	return clone;
}

export function updateParentDelete(parentUuidToDelete: string, parents: Parent[]): Parent[] {
	return parents.filter((parent) => parentUuidToDelete !== parent.parentContentId);
}

function mapParentToSequenceInfo(parent: Parent): RelationshipSequenceInfo {
	const sequenceInfo = parent.sequenceInfo || {};
	return mapSequenceInfo(sequenceInfo);
}

function mapSequenceInfo(sequenceInfo: ParentSequenceInfo): RelationshipSequenceInfo {
	return {
		sequenceNumber: mapSequenceNumber(sequenceInfo),
		distributionNumber: mapDistributionNumber(sequenceInfo),
		houseSequence: mapHouseSequence(sequenceInfo),
	};
}

function mapBasicSequenceInfo(sequenceInfo: SequenceInfo): RelationshipSequenceInfo {
	return mapSequenceInfo({
		number: sequenceInfo.number.displayValue,
		distributionNumber: sequenceInfo.distributionNumber.displayValue,
		houseSequence: sequenceInfo.houseSequence.displayValue,
	});
}

function updateRelationshipSubtypeInParent(relationshipSubtype: string | undefined, parent: Parent): Parent {
	return {
		...parent,
		relationshipSubtypes: relationshipSubtype ? [relationshipSubtype] : undefined,
	};
}

function updateSequenceNumberInParent(sequenceNumber: number | undefined, parent: Parent): Parent {
	return {
		...parent,
		sequenceInfo: {
			...parent.sequenceInfo,
			number: sequenceNumber,
		},
	};
}

function updateDistributionNumberInParent(distributionNumber: string | undefined, parent: Parent): Parent {
	return {
		...parent,
		sequenceInfo: {
			...parent.sequenceInfo,
			distributionNumber:
				distributionNumber === undefined || distributionNumber === ''
					? undefined
					: {
							...parent.sequenceInfo?.distributionNumber,
							distributionNumber,
					  },
		},
	};
}

function updateHouseSequenceInParent(houseSequence: string | undefined, parent: Parent): Parent {
	return {
		...parent,
		sequenceInfo: {
			...parent.sequenceInfo,
			houseSequence:
				houseSequence === undefined || houseSequence === ''
					? undefined
					: {
							...parent.sequenceInfo?.houseSequence,
							houseSequence,
					  },
		},
	};
}

function updatePrimaryInParent(primary: boolean | undefined, parent: Parent): Parent {
	return {
		...parent,
		primary,
	};
}

function mapSequenceNumber(sequenceInfo: ParentSequenceInfo): number | undefined {
	return sequenceInfo.number;
}

function mapDistributionNumber(sequenceInfo: ParentSequenceInfo): string | undefined {
	if (sequenceInfo.distributionNumber?.distributionNumber === undefined) {
		return undefined;
	}
	return sequenceInfo.distributionNumber.distributionNumber;
}

function mapHouseSequence(sequenceInfo: ParentSequenceInfo): string | undefined {
	if (sequenceInfo.houseSequence?.houseSequence === undefined) {
		return undefined;
	}
	return sequenceInfo.houseSequence.houseSequence;
}

function backendParentsToParent(parents: Parent[], relationshipType: string, parentUuid: string): Parent {
	const parent = findParent(parents, relationshipType, parentUuid);

	if (!parent)
		throw new ChildNotFoundError(`Parent not found for relationship ${relationshipType}, parent ${parentUuid}`);

	return {
		...parent,
		primary: parent.primary === undefined ? false : parent.primary,
	};
}

function findParent(parents: Parent[], relationshipType: string, parentUuid: string): Parent | undefined {
	return parents.find(
		(parent) => parent.relationshipType === relationshipType && parent.parentContentId === parentUuid,
	);
}

function findParentIndex({ parents, parent }: { parents: Parent[]; parent: Parent }): number {
	return parents.findIndex(
		(p) => p.relationshipType === parent.relationshipType && p.parentContentId === parent.parentContentId,
	);
}
