import React, { forwardRef, Ref } from 'react';
import { TreeItem2Props } from '@mui/x-tree-view-pro';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { TypoLink } from '@warehouse/shared/ui';
import { TreeItemFolder } from '../../core/models';
import { ObjectBrowserLinkBuilder } from '../../domain/object-browser-link-builder';
import { StyledTreeItem2 } from './styled-tree-item2';
import { FolderIcon } from '../icons';

export interface CustomTreeItemFolderProps extends TreeItem2Props {
	item: TreeItemFolder;
}

export const CustomTreeItemFolder = forwardRef(
	({ item, ...props }: CustomTreeItemFolderProps, ref: Ref<HTMLLIElement>) => {
		const resourceIdentifier = useObjectBrowserStore(objectBrowserStoreSelector.resourceIdentifier);
		return (
			<StyledTreeItem2
				{...props}
				ref={ref}
				$isHighlighted={resourceIdentifier?.type === 'folderUuid' && resourceIdentifier?.folderUuid === item.id}
				label={
					<TypoLink to={ObjectBrowserLinkBuilder.buildFolderLink(item.id)}>
						<FolderIcon />
						&nbsp;
						{item.name}
					</TypoLink>
				}
			/>
		);
	},
);
