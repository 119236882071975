import React, { useContext, useMemo } from 'react';
import { DisplayString } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/TitleMetadata';
import { Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { CustomColumnDef } from '../../../../../../components/library/SimpleTable/customColumnDef';
import { CompanyDisplayCreditContext } from '../providers/companyDisplayCreditsProvider';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import CellDisplayName from './CellDisplayName';
import CellLanguage from './CellLanguage';
import IndexedWrittenLanguages from '../../../../../../assets/json-administration-profiles/availableLanguageWritten.map.value.json';
import useTooltip from '../../../../../../utils/hooks/useTooltips';

interface DisplayStringTableProps {
	companyDisplayCreditDisplayIndex: number;
	defaultLocale: string | undefined;
}

function mapLanguageUuidToLabel(uuid: string) {
	return (IndexedWrittenLanguages as Record<string, { label: string }>)[uuid]?.label || '';
}

function computeErrors(displayStrings: DisplayString[]) {
	const result: { [columnId: string]: number[] } = {
		displayName: [],
	};

	displayStrings.forEach((displayString, index) => {
		if (!displayString?.displayString) {
			result.displayName.push(index);
		}
	});

	return result;
}

const DisplayNameReadOnlyTypography = styled(Typography)(
	({ theme }) => css`
		max-width: 12vw;
		overflow: hidden;
		padding-left: ${theme.spacing(1.5)};
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

const Wrapper = styled.div(
	() => css`
		table {
			table-layout: fixed;
		}
	`,
);

export default function DisplayStringTable({
	companyDisplayCreditDisplayIndex,
	defaultLocale,
}: DisplayStringTableProps) {
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);
	const companyDisplayCreditContext = useContext(CompanyDisplayCreditContext);
	const { isInherited } = companyDisplayCreditContext;
	const displayNameTooltip = useTooltip('coreMetadata.companyDisplayCredits.displayStrings.displayString');
	const languageTooltip = useTooltip('coreMetadata.companyDisplayCredits.displayStrings.language');

	const displayStrings = useMemo(() => {
		if (editMode) {
			return companyDisplayCreditContext.data?.displayValue?.[companyDisplayCreditDisplayIndex]?.displayStrings || [];
		}
		return (
			companyDisplayCreditContext.readonlyData?.displayValue?.[companyDisplayCreditDisplayIndex]?.displayStrings || []
		);
	}, [
		editMode,
		companyDisplayCreditContext.readonlyData?.displayValue,
		companyDisplayCreditContext.data?.displayValue,
		companyDisplayCreditDisplayIndex,
	]);

	const displayStringsColumns: CustomColumnDef<DisplayString>[] = useMemo(
		() => [
			...(editMode ? [{ id: 'actions', width: 35 }] : []),
			{
				id: 'displayName',
				header: 'Display Name',
				tooltip: displayNameTooltip?.tooltip,
				accessorFn: (r) => r?.displayString,
				renderReadOnly: (value) => (
					<DisplayNameReadOnlyTypography className={isInherited ? 'inherited' : 'default'} variant="tableCell">
						{value}
					</DisplayNameReadOnlyTypography>
				),
				cell: (info) => CellDisplayName({ info, companyDisplayCreditDisplayIndex }),
			},
			{
				id: 'language',
				header: 'Language',
				tooltip: languageTooltip?.tooltip,
				accessorFn: (r) => r?.language,
				renderReadOnly: (value) => (
					<DisplayNameReadOnlyTypography className={isInherited ? 'inherited' : 'default'} variant="tableCell">
						{value ? mapLanguageUuidToLabel(value) : ''}
					</DisplayNameReadOnlyTypography>
				),
				cell: (info) =>
					CellLanguage({
						info,
						companyDisplayCreditDisplayIndex,
					}),
			},
		],
		[editMode, companyDisplayCreditDisplayIndex, displayNameTooltip?.tooltip, languageTooltip?.tooltip, isInherited],
	);

	return (
		<Wrapper>
			<InheritanceOnValueChangeSimpleTableWrapper
				noOverflow
				editMode={editMode}
				columns={displayStringsColumns}
				selectRowModeByDefault
				enableAddMultipleRows={false}
				data={displayStrings}
				onRowAdd={() => {
					if (displayStrings.map((displayString) => displayString.language).includes(defaultLocale)) {
						companyDisplayCreditContext.addDisplayString({ displayString: '' }, companyDisplayCreditDisplayIndex);
					} else {
						companyDisplayCreditContext.addDisplayString(
							{ displayString: '', language: defaultLocale },
							companyDisplayCreditDisplayIndex,
						);
					}
				}}
				onRowDelete={(selectedIndexes) => {
					companyDisplayCreditContext.removeDisplayString(
						selectedIndexes.map((index) => parseInt(index, 10)),
						companyDisplayCreditDisplayIndex,
					);
					companyDisplayCreditContext.commit();
				}}
				canDelete={(selectedIndexes: string[]) => ({ canDelete: selectedIndexes.length < displayStrings.length })}
				tableStyle="border"
				showBottomActionsBar={editMode}
				errors={computeErrors(
					companyDisplayCreditContext.data?.displayValue?.[companyDisplayCreditDisplayIndex]?.displayStrings || [],
				)}
			/>
		</Wrapper>
	);
}
