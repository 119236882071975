import React, { FC, useCallback, useEffect, useState } from 'react';
import { RichTreeViewPro, RichTreeViewProProps, useTreeViewApiRef } from '@mui/x-tree-view-pro';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import styled, { css } from 'styled-components';
import { TreeItem, TreeItemType } from '../core/models';
import { CustomTreeItem } from './custom-tree-items/CustomTreeItem';
import { TreeItemAdapter } from '../core/tree-item.adapter';

export function Tree() {
	const { onTreeItemToggle } = useObjectBrowserStore(objectBrowserStoreSelector.actions);
	const treeItems = useObjectBrowserStore(objectBrowserStoreSelector.treeItems);
	const buckets = useObjectBrowserStore(objectBrowserStoreSelector.buckets);
	const [expanded, setExpanded] = useState<string[]>([]);
	const apiRef = useTreeViewApiRef();

	const expandItem = useCallback((itemId: string) => {
		setExpanded((prev) => Array.from(new Set([...prev, itemId])));
	}, []);

	const getAllFoldersToClose = useCallback((item: TreeItem): string[] => {
		if (item.type === TreeItemType.Loading) return [];
		return [
			item.id,
			...item.children.map((child) => getAllFoldersToClose(child)).reduce((acc, val) => [...acc, ...val], []),
		];
	}, []);

	const closeAllFolder = useCallback(
		(item: TreeItem) => {
			const folderToClose = getAllFoldersToClose(item);
			setExpanded((prev) => prev.filter((expandedItemId) => !folderToClose.includes(expandedItemId)));
		},
		[getAllFoldersToClose],
	);

	/**
	 * Expand the first bucket by default
	 * We have to recreate the TreeItemBucket object from the buckets list because this code is executed too early,
	 * and the RichTreeView did not load the items yet.
	 */
	useEffect(() => {
		if (buckets.length === 0) return;
		expandItem(buckets[0].id);
		onTreeItemToggle(TreeItemAdapter.adaptBucket(buckets[0]), true);
	}, [expandItem, buckets, apiRef, onTreeItemToggle]);

	return (
		<StyledRichTreeViewPro<FC<RichTreeViewProProps<TreeItem, undefined>>>
			apiRef={apiRef}
			items={treeItems}
			getItemLabel={(item) => (item.type === TreeItemType.Loading ? 'Loading' : item.name)}
			slots={{ item: CustomTreeItem }}
			expansionTrigger="iconContainer"
			isItemDisabled={(treeItem) => treeItem.type === TreeItemType.Loading}
			expandedItems={expanded}
			onItemExpansionToggle={(_, itemId, isExpanded) => {
				// This is an issue related to our outdated typescript verion. When typescript is updated to 5.6,
				// We can remove the any casting.
				// TODO: Upgrade typescript to 5.6 and remove the any casting.
				const item = (apiRef.current! as any).getItem(itemId) as TreeItem | undefined;
				if (!item) return;

				if (!isExpanded) {
					closeAllFolder(item);
				} else {
					expandItem(item.id);
				}
				onTreeItemToggle(item, isExpanded);
			}}
		/>
	);
}

const StyledRichTreeViewPro = styled(RichTreeViewPro)(
	() => css`
		overflow-y: auto;
	`,
);
