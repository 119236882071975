import { useMemo } from 'react';
import {
	compilationUuid,
	editUuid,
	episodeUuid,
	manifestationUuid,
	movieUuid,
	seasonUuid,
	seriesUuid,
} from '@warehouse/title/infra';
import { isEntryOf, WorkTypesService } from '@warehouse/title/core';
import workTypesJson from '../../../assets/json-administration-profiles/workTypes.json';
import workTypeDetailsJson from '../../../assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';

export type Options = { value: string; label: string }[];

function getAllWorkTypeDetails(): { value: string; label: string }[] {
	return Object.values(workTypeDetailsJson)
		.map((value) => value.availableWorkTypeDetails)
		.reduce((acc, val) => [...acc, ...val], [])
		.map((value) => ({
			value: value.value,
			label: value.label,
		}))
		.filter((obj, index, self) => index === self.findIndex((t) => t.value === obj.value));
}

export function getNameByUuid(uuid: string) {
	return workTypesJson.find((workType) => workType.value === uuid)?.label || 'All';
}

// TODO: Remove this when manifestations are covered
export function filterOutManifestation(options: { value: string; label: string }[]) {
	return options.filter((option) => option.label !== 'Manifestation');
}

export function filterOutTypesByName(filters: string[], options: { value: string; label: string }[]) {
	return options.filter((option) => !filters.includes(option.label));
}

export function getDefaultTab(workTypeUuid: string) {
	const wt = workTypesJson.find((workType) => workType.value === workTypeUuid);
	if (!wt) return undefined;
	if (wt.tabsToDisplay.length === 0) return undefined;
	return wt.tabsToDisplay[0];
}

function useWorkTypes() {
	return useMemo(
		() => ({
			getWorkType: (workTypeUuid: string) => workTypesJson.find((wt) => wt.value === workTypeUuid),
			getDefaultTabToDisplayByUuid: (workTypeUuid: string): string | undefined => getDefaultTab(workTypeUuid)?.value,
			options: WorkTypesService.getOptions(),
			workTypeDetailsOptions: getAllWorkTypeDetails(),
			data: workTypesJson,
			getDefaultParentByUuid: (uuid: string): string | undefined => {
				const workType = workTypesJson.find((_workType) => _workType.value === uuid);
				if (workType) {
					return workType.parentTitleTypeDefault?.value || workType.parentTitleType[0].value;
				}
				return undefined;
			},
			getWorkTypesByRelationshipTarget: (relationship: string): Options =>
				workTypesJson.reduce<Options>((acc, wt) => {
					if (wt.supportedParentRelationshipTypeAsTarget.includes(relationship)) {
						return [...acc, { value: wt.value, label: wt.label }];
					}
					return acc;
				}, []),
			getWorkTypesByRelationshipSource: (relationship: string): Options => {
				if (relationship === isEntryOf()) {
					return workTypesJson.map((wt) => ({ value: wt.value, label: wt.label }));
				}
				return workTypesJson.reduce<Options>((acc, wt) => {
					if (wt.supportedParentRelationshipTypeAsSource.includes(relationship)) {
						return [...acc, { value: wt.value, label: wt.label }];
					}
					return acc;
				}, []);
			},
			getSupportedChildrenTypesByUuid: (uuid: string): Options =>
				workTypesJson
					.map((wk) => {
						if (wk.parentTitleType.find((pt) => pt.value === uuid)) {
							return {
								value: wk.value as string,
								label: wk.label as string,
							};
						}

						return { value: '', label: '' };
					})
					?.filter((wk) => !!wk.value && !!wk.label),
			getSupportedParentTypesByUuid: (uuid: string): Options => {
				const workType = workTypesJson.find((_workType) => _workType.value === uuid);
				const parentTypes = workType ? workType.parentTitleType : [];
				return [
					{ value: 'all', label: 'All' },
					...parentTypes.map((pt) => ({
						value: pt.value,
						label: pt.label,
					})),
				];
			},
			getNameByUuid: (uuid: string): string | undefined => getNameByUuid(uuid),
			getUuidByName: (name: string): string | undefined =>
				workTypesJson.find((_workType) => _workType.label === name)?.value,
			editUuid: (): string => editUuid(),
			manifestationUuid: (): string => manifestationUuid(),
			compilationUuid: (): string => compilationUuid(),
			seasonsUuid: (): string => seasonUuid(),
			seriesUuid: (): string => seriesUuid(),
			episodesUuid: (): string => episodeUuid(),
			movieUuid: (): string => movieUuid(),
		}),
		[],
	);
}

// eslint-disable-next-line import/no-default-export
export default useWorkTypes;
