import React, { useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

// TYPES
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Release } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { ReleaseRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';

// HOOK
import { useTerritories } from '@warehouse/global-entities/core';
import { CellWrapper } from '../../../../../../components/library/SimpleTable/style';

// LIBRARY
import { TerritoriesSelector } from '../../../../../../components/titles/TerritoriesSelector';
import regionSortingFn from './regionSortingFn';

function CellTerritory(info: CellContext<Release, Release>) {
	const { getValue, row, table } = info;
	const setValue = table.options?.meta?.customPropsCell.setValue;
	const commit = table.options?.meta?.customPropsCell.commit;
	const isInherited = table.options.meta?.customPropsCell.isInherited;
	const [localState, setLocalState] = useState<ReleaseRegion[]>(getValue().distrTerritories || []);
	const { getKey } = useTerritories();

	useEffect(() => {
		setLocalState(getValue().distrTerritories || []);
	}, [getValue().distrTerritories]);

	const handleBlur = () => {
		setValue((prev: OptionalInherited<Release[]>) => ({
			...prev,
			displayValue: prev.displayValue?.map((item, index) => {
				if (index === row.index) {
					return {
						...item,
						distrTerritories: localState,
					};
				}
				return item;
			}),
		}));
		commit();
	};

	const handleOnTagRemoved = (value: string | number) => {
		setValue((prev: OptionalInherited<Release[]>) => ({
			...prev,
			displayValue: prev.displayValue?.map((item, index) => {
				if (index === row.index) {
					return {
						...item,
						distrTerritories: localState.filter((terr) => terr.country !== value && terr.countryRegion !== value),
					};
				}
				return item;
			}),
		}));
		commit();
	};

	return (
		<CellWrapper>
			<TerritoriesSelector
				variant={isInherited ? 'prefilled' : 'default'}
				autoFocus={localState.length === 0}
				value={localState.map((locale) => locale.country ?? locale.countryRegion ?? '')}
				width={204}
				multiple
				withSearch
				onTagRemoved={handleOnTagRemoved}
				transparentBorder
				backgroundColor="transparent"
				onSelect={(value) => setLocalState((prev) => [...prev, { [getKey(value)]: value }].sort(regionSortingFn))}
				onDeselect={(value) =>
					setLocalState((prev) =>
						prev.filter((i) => i.country !== value && i.countryRegion !== value).sort(regionSortingFn),
					)
				}
				onBlur={handleBlur}
			/>
		</CellWrapper>
	);
}

export default CellTerritory;
