import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Licensor } from '@warehouse/graphql';
import useLicensors from '../../../utils/hooks/licensor/useLicensors';
import { DropdownOption } from '../../../components/library/Dropdown/types';

interface LicensorPicklistContextInterface {
	options: DropdownOption[];
	onNewOptionAdded: (option: DropdownOption) => void;
	getDefault: () => Licensor | undefined;
}

export const LicensorPicklistContext = createContext<LicensorPicklistContextInterface>({
	options: [],
	onNewOptionAdded: () => {},
	getDefault: () => undefined,
});

function LicensorPicklistContextProvider({ children }: { children: React.ReactNode }) {
	const [options, setOptions] = useState<DropdownOption[]>([]);
	const { data: licensorsData, getDefault } = useLicensors({});

	useEffect(() => {
		if (!options.length) {
			setOptions(
				licensorsData?.getLicensors?.map((licensor) => ({
					value: licensor.displayName,
					label: licensor.displayName,
				})) || [],
			);
		}
	}, [licensorsData]);

	const onNewOptionAdded = useCallback(
		(newOption: DropdownOption) => {
			if (
				(typeof newOption.label === 'string' && newOption.label?.trim() === '') ||
				(typeof newOption.value === 'string' && newOption.value?.trim() === '')
			)
				return;
			setOptions((prev) => [...prev, newOption]);
		},
		[setOptions],
	);

	return (
		<LicensorPicklistContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				options,
				onNewOptionAdded,
				getDefault,
			}}
		>
			{children}
		</LicensorPicklistContext.Provider>
	);
}

export default LicensorPicklistContextProvider;
