// Can have one or the other in the validation but not both or none
import { UnreachableError } from '@warehouse/shared/errors';
import { isCountry, isCountryRegion } from '@warehouse/global-entities/core';

export type Region = {
	country?: string; // country uuid
	countryRegion?: string; // countryRegion uuid
};

export type CountryOfOriginRegion = Region & {
	interpretation?: string;
	listingOrder?: number;
};

export type CompanyDisplayCreditRegion = Region;

export type ReleaseRegion = Region;

export type LocalizedInfoRegion = Region;

export type LocalizedInfoExcludedRegion = Region;

export type MadeForRegion = Region;

export type MadeForRegionExclude = Region;

export function flattenRegions(regions: Region[]): string[] {
	return regions.map(flattenRegion);
}

export function flattenRegion(region: Region): string {
	const regionString = region.countryRegion || region.country;
	if (!regionString) throw new UnreachableError('Region has neither country nor countryRegion');
	return regionString;
}

export function unflattenRegions(regionUuids: string[]): Region[] {
	return regionUuids.map(unflattenRegion);
}

export function unflattenRegion(regionUuid: string): Region {
	if (isCountry(regionUuid)) return { country: regionUuid };
	if (isCountryRegion(regionUuid)) return { countryRegion: regionUuid };
	throw new UnreachableError('Region uuid is neither country nor countryRegion');
}
