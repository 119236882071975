import React, { useMemo } from 'react';

// TYPES
import { CountryOfOriginRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// HOOKS
import { titlePaths } from '@warehouse/title/core';
import { useTerritories } from '@warehouse/global-entities/core';
import { computeMaxListingOrder } from '@warehouse/title/domain';
import { sortListingOrderNonInherited } from '../../../../src/components/titles/TitleOverview/sortListingOrder';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import { SelectDropdownWrapper } from '../../../../src/components/titles/TitleOverview/fields/style';
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import { TerritoriesSelector } from '../../../../src/components/titles/TerritoriesSelector';
import { isInherited as isInheritedFunction } from '../../core/title-inheritance';

export function CountriesOfOriginField() {
	const { getKey, getLabel } = useTerritories();

	const countriesOfOrigin = useTitleAutoSave<OptionalInherited<CountryOfOriginRegion[]>>({
		label: 'Countries of Origin',
		path: useMemo(() => titlePaths.countriesOfOrigin.split('.'), []),
	});

	const maxListingOrder = useMemo(
		() => computeMaxListingOrder(countriesOfOrigin?.value?.displayValue),
		[countriesOfOrigin?.value?.displayValue],
	);

	const filteredCountriesOfOriginValue = useMemo(
		() => countriesOfOrigin?.value?.displayValue?.filter((coo) => coo?.interpretation === 'creative') || [],
		[countriesOfOrigin?.value?.displayValue],
	);

	const filteredCountriesOfOriginReadOnlyValue = useMemo(
		() => countriesOfOrigin?.readOnlyValue?.displayValue?.filter((coo) => coo?.interpretation === 'creative') || [],
		[countriesOfOrigin?.readOnlyValue?.displayValue],
	);

	const selectedIds = useMemo(
		() =>
			[...(filteredCountriesOfOriginValue || [])]
				?.sort(sortListingOrderNonInherited)
				?.map((pc) => pc.country || pc.countryRegion || '') || [],
		[filteredCountriesOfOriginValue],
	);

	const displayValues = useMemo(
		() =>
			[...(filteredCountriesOfOriginReadOnlyValue || [])]
				?.sort(sortListingOrderNonInherited)
				?.map((pc) => getLabel(pc.country || pc.countryRegion || ''))
				?.filter((item): item is string => item !== undefined) || [],
		[filteredCountriesOfOriginReadOnlyValue, getLabel],
	);

	const { isInherited } = isInheritedFunction({ inheritedObject: countriesOfOrigin?.value });

	return (
		<TitleEditableField
			label="Countries of Origin:"
			path={titlePaths.countriesOfOrigin}
			jumpToParentButtonDisabled
			tooltipDisabled
			readComponent={
				<ChipDisplay
					allowOverflow
					horizontalPadding={0}
					variant={isInherited ? 'prefilled' : 'default'}
					calcWidth="calc(100% - 170px)"
					values={displayValues}
					height={26}
					ariaLabel="Countries Of Origin Field"
				/>
			}
			editComponent={
				<SelectDropdownWrapper style={{ width: 'calc(100% - 145px)' }}>
					<TerritoriesSelector
						multiple
						variant={isInherited ? 'prefilled' : 'default'}
						value={selectedIds}
						height={38}
						listItemHeight={38}
						chipHeight={16}
						onBlur={() => countriesOfOrigin?.commit()}
						ariaLabel="Countries of Origin Field"
						onSelect={(value) => {
							const regionToAdd: CountryOfOriginRegion = {
								[getKey(value)]: value,
								listingOrder: maxListingOrder + 1,
								interpretation: 'creative',
							};
							countriesOfOrigin?.setValue((prev) => ({
								...prev,
								displayValue: [...(prev?.displayValue || []), regionToAdd],
							}));
						}}
						onDeselect={(value) => {
							const check = filteredCountriesOfOriginValue?.find((coo) => coo[getKey(value)] === value);
							if (check)
								countriesOfOrigin?.setValue((prev) => ({
									...prev,
									displayValue: prev?.displayValue?.filter((coo) => {
										if (coo.interpretation !== 'creative') return true;
										return coo[getKey(value)] !== check[getKey(value)];
									}),
								}));
						}}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}
