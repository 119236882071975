import styled, { css } from 'styled-components';
import { TreeItem2 } from '@mui/x-tree-view-pro';
import { alpha } from '@mui/material';

export const StyledTreeItem2 = styled(TreeItem2)<{ $isHighlighted: boolean }>(
	({ theme, $isHighlighted }) => css`
		& > .MuiTreeItem-content {
			flex-direction: row-reverse;
			background-color: transparent;
			border-radius: 0 !important;
		}

		& > .MuiTreeItem-content:hover {
			${!$isHighlighted &&
			css`
				background-color: ${alpha(theme.palette.primary.main, 0.2)};
			`}
		}

		${$isHighlighted &&
		css`
			& > .MuiTreeItem-content {
				background-color: ${theme.palette.primary.main};
				svg,
				a {
					color: white;
				}
			}
		`}
	`,
);
