export function singlePromiseRunner<FN extends (...args: any[]) => Promise<RETURN>, RETURN = Awaited<ReturnType<FN>>>(
	inputFn: FN,
) {
	let promiseRef: Promise<RETURN> | null = null;

	return (...args: Parameters<FN>): Promise<RETURN> => {
		// If we don't have any instance of the promise currently running
		if (promiseRef === null) {
			// Start a new instance of the promise
			promiseRef = inputFn(...args);
			// Save the reference of this instance
			// Reset the saved reference when the promise resolve (either resolution or rejection)
			promiseRef.finally(() => {
				promiseRef = null;
			});
			// Return the newly created promise instance to be awaited by the consumer
			return promiseRef;
		}
		// If we have a promise currently running, return the same reference to be awaited by the consumer
		return promiseRef;
	};
}
