import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { NxObject, NxObjectType } from '@warehouse/object-browser/core';

function getNxObjectIconsByObjectType(itemType?: NxObjectType) {
	switch (itemType) {
		case NxObjectType.File:
			return InsertDriveFileIcon;
		case NxObjectType.Folder:
			return FolderIcon;
		default:
			return HelpCenterIcon;
	}
}

export function IconCell({ value }: GridRenderCellParams<NxObject, NxObjectType>) {
	const Icon = getNxObjectIconsByObjectType(value);

	return <Icon color={value === NxObjectType.Folder ? 'primary' : undefined} style={{ verticalAlign: 'middle' }} />;
}
