import { To } from 'react-router-dom';

export class ObjectBrowserLinkBuilder {
	static buildFolderLink(folderUuid: string): To {
		const searchParams = new URLSearchParams(window.location.search);
		// We delete the search query as we never want to transfer it through navigation for now.
		// We only have a search query in searchMode, and we don't have drill down in search mode.
		searchParams.delete('searchQuery');

		return {
			pathname: `/object-browser/folder/${encodeURIComponent(folderUuid)}`,
			// This is a test, we are not sure if we can use it like this without the useLocation hook
			search: searchParams.toString() ? `?${searchParams.toString()}` : '',
		};
	}

	static buildBucketLink(bucketId: string): To {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.delete('searchQuery');

		return {
			pathname: `/object-browser/bucket/${encodeURIComponent(bucketId)}`,
			search: searchParams.toString() ? `?${searchParams.toString()}` : '',
		};
	}
}
