import React, { useEffect, useMemo } from 'react';
import { OriginalLanguage } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Control, FieldErrors, useFieldArray, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import OriginalLanguagesTable, { OriginalLanguageCreationType } from '../OriginalLanguagesTable';
import getLabelWithRequired from '../getLabelWithRequired';
import { emptyOrUndefinedArray } from './utils';
import { createOriginalLanguage } from '../../../../../libs/title/core/models/original-languages';

interface OriginalLanguagesFieldProps {
	control: Control<{ originalLanguages: OriginalLanguageCreationType[] }>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
	graphQLError?: string;
}

function OriginalLanguagesField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	dryRanTitle,
	graphQLError,
}: OriginalLanguagesFieldProps) {
	const { fields: originalLanguages, append: appendOriginalLanguage } = useFieldArray({
		control,
		name: 'originalLanguages',
	});

	const inheritedOriginalLanguages = getTitleData<OptionalInherited<OriginalLanguage[]>>(
		dryRanTitle,
		fromBasic(['originalLanguages']),
	);

	// Set inherited original language value if available
	useEffect(() => {
		if (originalLanguages.length === 0 && !emptyOrUndefinedArray(inheritedOriginalLanguages?.inherited.value))
			setValue(
				'originalLanguages',
				inheritedOriginalLanguages?.inherited.value?.map(
					({ originalLanguage, listingOrder, spoken, written, signed }) => ({
						originalLanguage,
						listingOrder,
						spoken,
						written,
						signed,
					}),
				) ?? [],
			);
	}, [inheritedOriginalLanguages?.inherited.value]);

	// Set default original language value if no inherited value is available
	useEffect(() => {
		if (originalLanguages.length === 0 && emptyOrUndefinedArray(inheritedOriginalLanguages?.inherited.value)) {
			setValue('originalLanguages', [
				createOriginalLanguage({
					listingOrder: 1,
				}),
			]);
		}
	}, [originalLanguages]);

	return useMemo(
		() => (
			<OriginalLanguagesTable
				errors={errors}
				setValue={setValue}
				isRequired={isRequired}
				trigger={trigger}
				appendLanguage={appendOriginalLanguage}
				graphQLError={graphQLError}
				controlName="originalLanguages"
				tableVariant={
					!emptyOrUndefinedArray(inheritedOriginalLanguages?.inherited.value) &&
					emptyOrUndefinedArray(inheritedOriginalLanguages?.explicitValue)
						? 'prefilled'
						: 'default'
				}
				label={getLabelWithRequired('Original Languages', isRequired)}
				languages={originalLanguages}
			/>
		),
		[
			control,
			errors?.originalLanguages?.message,
			setValue,
			trigger,
			inheritedOriginalLanguages?.displayValue,
			appendOriginalLanguage,
			graphQLError,
			originalLanguages,
		],
	);
}

export default OriginalLanguagesField;
