import React from 'react';
import {
	CommonReadonly,
	getCombinedWorktype,
	isPrimaryAndDerivedFrom as getIsPrimaryAndDerivedFrom,
	TitleLightWithSelectedParent,
} from '@warehouse/title/core';
import titleSearchDisplayNameBuilder from '../../../../src/utils/titles/titleSearchDisplayNameBuilder';
import { ColoredTitleCard } from './ColoredTitleCard';
import { DefaultTitleCard } from './DefaultTitleCard';
import { TitleCardLink } from './TitleCardLink';

type TitleCardPropsBase = {
	isLink?: boolean;
	variant?: 'default' | 'colored';
	minWidth?: number;
	hideReleaseYear?: boolean;
};

type CommonTitleCardProps = TitleCardPropsBase & {
	context?: 'common';
	titleLight: CommonReadonly;
};

type RelationshipTabChildrenTitleCardProps = TitleCardPropsBase & {
	context: 'relationship-tab-children';
	titleLight: TitleLightWithSelectedParent;
};

type TitleCardProps = RelationshipTabChildrenTitleCardProps | CommonTitleCardProps;

export function TitleCard({
	titleLight,
	minWidth,
	variant = 'default',
	isLink = true,
	hideReleaseYear = false,
	context,
}: TitleCardProps) {
	const commonProps = mapTitleLightToTitleCardProps(titleLight);

	let showPrimaryTick = false;
	if (context === 'common' || context === undefined) {
		showPrimaryTick = shouldDisplayPrimaryTickInCommonContext(titleLight);
	} else if (context === 'relationship-tab-children') {
		showPrimaryTick = shouldDisplayPrimaryTickInRelationshipTabChildrenContext(titleLight);
	}

	const titleCardCompProps = {
		...commonProps,
		hideReleaseYear,
		minWidth,
		showPrimaryTick,
	};

	const TitleCardVariant = variant === 'colored' ? ColoredTitleCard : DefaultTitleCard;

	if (isLink) {
		return (
			<TitleCardLink uuid={titleLight.uuid} fullWidth>
				<TitleCardVariant {...titleCardCompProps} />
			</TitleCardLink>
		);
	}
	return <TitleCardVariant {...titleCardCompProps} />;
}

function mapTitleLightToTitleCardProps(titleLight: CommonReadonly) {
	const { title, subTitle1, subTitle2 } = titleSearchDisplayNameBuilder({
		tokens: titleLight.titleSearchDisplayNameFullHTML,
	});

	const combinedWorkType = getCombinedWorktype(titleLight);

	return {
		title,
		subTitle1,
		subTitle2,
		releaseYear: titleLight.releaseYear,
		workType: combinedWorkType,
	};
}

function shouldDisplayPrimaryTickInCommonContext(titleLight: CommonReadonly) {
	return titleLight.parents ? getIsPrimaryAndDerivedFrom(titleLight.parents) : false;
}

function shouldDisplayPrimaryTickInRelationshipTabChildrenContext(titleLight: TitleLightWithSelectedParent) {
	return titleLight.selectedParent.primary;
}
