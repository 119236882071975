import { TitleFull } from '@warehouse/title/core';
import titleSearchDisplayNameBuilder from '../../../src/utils/titles/titleSearchDisplayNameBuilder';

function replaceIllegalCharacters(input: string): string {
	return input.replaceAll(':', ' ').replaceAll('/', ' ').replaceAll('_', ' ').replaceAll('-', ' ').replaceAll(',', ' ');
}

function trimMultipleSpace(input: string): string {
	return input.replace(/\s\s+/g, ' ');
}

// cleanToken does in order:
//  1 - remove any illegal character (any characters that will be changed anyway by the web browser)
//  2 - replace any resulting multiple space by a single one
//  3 - trim any space before or after the token
function cleanToken(input?: string): string | undefined {
	if (!input) return undefined;
	return trimMultipleSpace(replaceIllegalCharacters(input)).trim();
}

export function getExportedTitleFileName(options: { title: TitleFull; fileExtension: string }): string {
	const { title, subTitle1, subTitle2 } = titleSearchDisplayNameBuilder({
		tokens: options.title.readonly.titleSearchDisplayNameFullHTML,
	});
	const cleanedTitle = cleanToken(title);
	const cleanedSubTitle1 = cleanToken(subTitle1);
	const cleanedSubTitle2 = cleanToken(subTitle2);
	return `${cleanedTitle}${cleanedSubTitle1 ? `-${cleanedSubTitle1}` : ''}${
		cleanedSubTitle2 ? `-${cleanedSubTitle2}` : ''
	}-${options.title.uuid}.${options.fileExtension}`.replaceAll(' ', '_');
}
