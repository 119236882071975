import React from 'react';
import styled, { css } from 'styled-components';

// TYPES
import {
	DistributionNumber,
	HouseSequence,
} from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/SequenceInfo';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// HOOKS
import {
	AspectRatioField,
	CountriesOfOriginField,
	FrameRateField,
	OriginalLanguagesField,
	ProductionCountriesField,
	ReleaseDateField,
	RunLengthField,
} from '@warehouse/title/feature-title-overview';
import useMemoJsonPath from '../../../../views/title/hooks/useMemoJsonPath';
import useTiniestSummaryTitleData from '../../../../views/title/hooks/useTiniestSummaryTitleData';
import useTitleAutoSave from '../../../../views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// UTILS
import { fromBasic } from '../../../../utils/titleGetProperty';

// LIBRARY
import TiniestSummary from '../fields/TiniestSummary';
import { TitleOverviewByTypeProps } from './type';
import { TitleOverviewLeftWrapperBase, Data, NonCenteredData } from './style';
import { DistributionNumberField, LicensorField, SequenceNumberField } from '../fields';
import HouseSequenceNumberField from '../fields/HouseSequenceNumberField';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar topbar topbar'
			'licensor countriesOfOrigin sequenceNumber synopsis'
			'releaseDate productionCountries distributionNumber synopsis'
			'aspectRatio originalLanguage houseSequence synopsis'
			'frameRate runLength . synopsis';
		grid-template-columns: 25% 25% 25% 25%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

function Episode({ editMode, topBar, displayVideoPlayer, title }: TitleOverviewByTypeProps) {
	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	const sequenceNumber = useTitleAutoSave<OptionalInherited<number | null>>({
		label: 'Sequence Number',
		path: useMemoJsonPath(fromBasic(['sequenceInfo', 'number'])),
	});

	const distributionNumber = useTitleAutoSave<OptionalInherited<DistributionNumber | null>>({
		label: 'Distribution Number',
		path: useMemoJsonPath(fromBasic(['sequenceInfo', 'distributionNumber'])),
	});

	const houseSequenceNumber = useTitleAutoSave<OptionalInherited<HouseSequence | null>>({
		label: 'House Sequence',
		path: useMemoJsonPath(fromBasic(['sequenceInfo', 'houseSequence'])),
	});

	return (
		<TitleOverviewLeftWrapper displayVideoPlayer={displayVideoPlayer}>
			{topBar}
			<NonCenteredData enableRightBorder enableBottomBorder={false} area="synopsis">
				<TiniestSummary
					tiniestSummary={tiniestSummary?.data?.displayValue || ''}
					isInherited={tiniestSummary.isInherited}
				/>
			</NonCenteredData>
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField title={title} />
			</Data>
			<Data enableRightBorder area="countriesOfOrigin">
				<CountriesOfOriginField />
			</Data>
			<Data enableLeftBorder={false} area="sequenceNumber">
				<SequenceNumberField
					isInherited={!!sequenceNumber?.value?.inherited?.value}
					sequenceNumber={
						editMode
							? sequenceNumber?.value?.displayValue?.toString() || ''
							: sequenceNumber?.readOnlyValue?.displayValue?.toString() || ''
					}
					setSequenceNumber={(v) =>
						sequenceNumber?.setValue((prev) => ({
							...prev,
							displayValue: parseInt(v, 10) || null,
						}))
					}
					onBlur={sequenceNumber.commit}
				/>
			</Data>
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			<Data enableRightBorder area="productionCountries">
				<ProductionCountriesField />
			</Data>
			<Data enableLeftBorder={false} area="distributionNumber">
				<DistributionNumberField
					isInherited={!!distributionNumber?.value?.inherited?.value}
					distributionNumber={
						editMode
							? distributionNumber?.value?.displayValue?.distributionNumber || ''
							: distributionNumber?.readOnlyValue?.displayValue?.distributionNumber || ''
					}
					setDistributionNumber={(v) => {
						if (!v) {
							distributionNumber?.setValue((prev) => ({
								...prev,
								displayValue: null,
							}));
							return;
						}
						if (distributionNumber?.value?.displayValue?.domain) {
							distributionNumber?.setValue((prev) => ({
								...prev,
								displayValue: {
									distributionNumber: v,
									domain: distributionNumber?.value?.displayValue?.domain,
								},
							}));
						} else {
							distributionNumber?.setValue((prev) => ({
								...prev,
								displayValue: {
									distributionNumber: v,
									domain: 'nexspec.com/warehouse',
								},
							}));
						}
					}}
					onBlur={distributionNumber.commit}
				/>
			</Data>
			<Data enableLeftBorder={false} area="aspectRatio">
				<AspectRatioField />
			</Data>
			<Data enableRightBorder area="originalLanguage">
				<OriginalLanguagesField />
			</Data>
			<Data enableLeftBorder={false} area="houseSequence">
				<HouseSequenceNumberField
					isInherited={!!houseSequenceNumber?.value?.inherited?.value}
					houseSequenceNumber={
						editMode
							? houseSequenceNumber.value?.displayValue?.houseSequence || ''
							: houseSequenceNumber.readOnlyValue?.displayValue?.houseSequence || ''
					}
					setHouseSequenceNumber={(v) => {
						if (!v) {
							houseSequenceNumber?.setValue((prev) => ({
								...prev,
								displayValue: null,
							}));
							return;
						}
						if (houseSequenceNumber?.value?.displayValue?.domain) {
							houseSequenceNumber?.setValue((prev) => ({
								...prev,
								displayValue: {
									houseSequence: v,
									domain: houseSequenceNumber?.value?.displayValue?.domain,
								},
							}));
						} else {
							houseSequenceNumber?.setValue((prev) => ({
								...prev,
								displayValue: {
									houseSequence: v,
									domain: 'nexspec.com/warehouse',
								},
							}));
						}
					}}
					onBlur={houseSequenceNumber.commit}
				/>
			</Data>
			<Data enableBottomBorder={false} enableLeftBorder={false} area="frameRate">
				<FrameRateField />
			</Data>
			<Data enableRightBorder enableBottomBorder={false} area="runLength">
				<RunLengthField />
			</Data>
		</TitleOverviewLeftWrapper>
	);
}

export default Episode;
