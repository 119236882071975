import React from 'react';
import { isInherited, resetInheritedArrayValues, titlePaths } from '@warehouse/title/core';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import SelectDropdown from '../../../../src/components/library/SelectDropdown/SelectDropdown';
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import { SelectDropdownWrapper } from '../../../../src/components/titles/TitleOverview/fields/style';
import editClassJson from '../../../../src/assets/json-administration-profiles/editClass.json';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';

export function EditClassesField() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const {
		readOnlyValue: readonlyEditClasses,
		setValue: setEditClasses,
		value: editClasses,
		commit,
	} = useTitleAutoSave<OptionalInherited<string[]>>({
		path: useMemoJsonPath(titlePaths.editClasses.split('.')),
		label: 'Edit Classes',
	});

	const isInheritedResult = isInherited({ inheritedObject: editClasses });

	return (
		<TitleEditableField
			label="Edit Class:"
			path={titlePaths.editClasses}
			jumpToParentButtonDisabled
			tooltipDisabled
			height={48}
			readComponent={
				<ChipDisplay
					allowOverflow
					calcWidth="calc(100% - 170px)"
					horizontalPadding={0}
					variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
					values={
						readonlyEditClasses?.displayValue?.map(
							(item) => editClassJson?.find((option) => option.value === item)?.label || 'Unknown',
						) || []
					}
					height={26}
				/>
			}
			editComponent={
				<SelectDropdownWrapper style={{ width: 'calc(100% - 145px)' }}>
					<SelectDropdown
						enablePortal
						ariaLabel="Edit Classes"
						variant={isInheritedResult.isInherited ? 'prefilled' : 'default'}
						options={editClassJson}
						values={editClasses?.displayValue || []}
						onBlur={commit}
						onSelect={(value) => {
							const check = editClasses?.displayValue?.includes(value);

							if (check) {
								const newValue = editClasses?.displayValue?.filter((item) => item !== value) || [];
								setEditClasses(resetInheritedArrayValues(newValue, title));
							} else {
								setEditClasses((prev) => {
									const newValue = [...(prev?.displayValue || []), value];
									return {
										...prev,
										displayValue: newValue,
									};
								});
							}
						}}
						height={36}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}
