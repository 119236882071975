import { TitleFull as Title, TitleLightWithSelectedParent } from '@warehouse/title/core';
import { TitleIndexedPartOfCompilation } from '@warehouse/graphql';
import { ChildrenColumnType } from '../children-compilation-extended.model';

export function mapEntryClassAndEntryNumberToData(
	data: TitleLightWithSelectedParent[],
	compilation: Title,
): ChildrenColumnType[] {
	return data.map((child) => {
		const compilationObjectEntry = getCompilationObjectEntry(child, compilation.uuid);

		return {
			...child,
			entryClass: compilationObjectEntry?.compilationEntryClass?.label,
			entryNumber: compilationObjectEntry?.compilationEntryNumber,
		};
	});
}

function getCompilationObjectEntry(
	child: TitleLightWithSelectedParent,
	compilationUuid: string,
): TitleIndexedPartOfCompilation | undefined {
	return child.partOfCompilations?.find((compilation) => compilation.compilationId === compilationUuid);
}
