import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Typography } from '@mui/material';
import {
	IndexedCountryOrSubdivision,
	IndexedOriginalLanguage,
	IndexedTitleV2,
	IndexedVersionLanguages,
} from '@warehouse/graphql';
import { TitleCard } from '@warehouse/title/ui';
import { indexedTitleToCommonReadonly } from '@warehouse/title/infra';
import { SelectAllTypography } from '../../../components/library/SelectAllTypography';
import MultiTags from '../../../components/table/columnsItems/MultiTags';
import { timeToString } from '../../../components/library/DatePicker/DatePicker';
import { EIDRtoTime } from '../../../components/library/TimeInput';
import SummaryCell from './SummaryCell';
import availableAspectRatio from '../../../assets/json-administration-profiles/availableAspectRatio.json';
import titleStatusJSON from '../../../assets/json-administration-profiles/availableTitleStatus.json';
import { ColumnDefType } from '../../../utils/table/sortingUtils';

const columns = (): ColumnDefType<Maybe<IndexedTitleV2>>[] => [
	{
		header: 'Licensor',
		id: 'licensor',
		indexedTitleKey: 'licensor.label',
		accessorFn: (row) => row?.licensor?.label,
	},
	{
		header: 'Title',
		id: 'titleSearchDisplayNameFull',
		indexedTitleKey: 'titleSearchSortFull', // Hack to sort using titleSearchSortFull. There is no actual key for this field
		highlight: true,
		accessorFn: (row) => row?.titleSearchDisplayNameFull,
		// TODO: This type adaptation should be done in the infra.
		cell: (info: any) => <TitleCard titleLight={indexedTitleToCommonReadonly(info.row.original)} minWidth={600} />,
	},
	{
		header: 'Release Date',
		accessorFn: (row) => row?.releaseDate || null,
		id: 'releaseDate',
		indexedTitleKey: 'releaseDate',
	},
	{
		header: 'Title Type',
		accessorFn: (row) => (row?.workType?.name === 'Performance' ? 'Edit' : row?.workType?.label),
		id: 'workType',
		indexedTitleKey: 'workType.label',
	},
	{
		header: 'Run length',
		id: 'runLength',
		indexedTitleKey: 'runLength',
		accessorFn: (row) => row?.runLength,
		cell: (info) => {
			const cellInfo = info as CellContext<IndexedTitleV2, any>;

			if (cellInfo.row.original.runLength) {
				return <Typography variant="tableCell">{EIDRtoTime(cellInfo.row.original.runLength)}</Typography>;
			}
			return null;
		},
	},
	{
		header: 'Original Languages',
		id: 'originalLanguages',
		indexedTitleKey: 'originalLanguages.label',
		accessorFn: (row) => row?.originalLanguages,
		size: 250,
		cell: ({ getValue }) => <MultiTags tags={(getValue() || [])?.map((l: IndexedOriginalLanguage) => l.label)} />,
	},
	{
		header: 'Version Languages',
		id: 'versionLanguages',
		indexedTitleKey: 'versionLanguages.label',
		accessorFn: (row) => row?.versionLanguages,
		size: 250,
		cell: ({ getValue }) => <MultiTags tags={(getValue() || [])?.map((l: IndexedVersionLanguages) => l.label)} />,
	},
	{
		header: 'Countries of Origin',
		id: 'countriesOfOrigin',
		indexedTitleKey: 'countriesOfCreation.label',
		accessorFn: (row) => row?.countriesOfCreation,
		size: 250,
		cell: ({ getValue }) => <MultiTags tags={(getValue() || [])?.map((l: IndexedCountryOrSubdivision) => l.label)} />,
	},
	{
		header: 'Production Countries',
		id: 'productionCountries',
		indexedTitleKey: 'countriesOfProduction.label',
		accessorFn: (row) => row?.countriesOfProduction,
		size: 250,
		cell: ({ getValue }) => <MultiTags tags={(getValue() || [])?.map((l: IndexedCountryOrSubdivision) => l.label)} />,
	},
	{
		header: 'ID',
		indexedTitleKey: 'uuid',
		accessorKey: 'uuid',
		id: 'uuid',
		cell: ({ getValue }) => <SelectAllTypography variant="body2">{getValue()}</SelectAllTypography>,
	},
	{
		header: 'EIDR-S ID',
		id: 'eidrId',
		indexedTitleKey: 'eidrId',
		accessorFn: (row) => row?.eidrId,
	},
	{
		header: 'Aspect Ratio',
		id: 'aspectRatio',
		indexedTitleKey: 'aspectRatio.aspectRatio',
		accessorFn: (row) =>
			availableAspectRatio.find((a) => a.value === row?.aspectRatio?.aspectRatio?.toString() || '')?.label || '',
	},
	{
		header: 'Frame Rate',
		id: 'frameRate',
		indexedTitleKey: 'frameRate.frameRate',
		accessorFn: (row) => row?.frameRate?.frameRate,
	},
	{
		header: 'Display Title',
		id: 'titleDisplayUnlimited',
		indexedTitleKey: 'titleDisplayUnlimited',
		accessorFn: (row) => row?.titleDisplayUnlimited,
	},
	{
		header: 'Sort Title',
		id: 'titleSort',
		indexedTitleKey: 'titleSort',
		accessorFn: (row) => row?.titleSort,
	},
	{
		header: 'Original Title',
		id: 'originalTitle',
		indexedTitleKey: 'originalTitle',
		accessorFn: (row) => row?.originalTitle,
	},
	{
		header: 'Sequence Number',
		id: 'sequenceNumber',
		accessorFn: (row) => row?.sequenceNumber,
		indexedTitleKey: 'sequenceNumber',
	},
	{
		header: 'House Sequence',
		id: 'houseSequence',
		indexedTitleKey: 'houseSequence',
		accessorFn: (row) => row?.houseSequence,
	},
	{
		header: 'Distribution Number',
		id: 'distributionNumber',
		indexedTitleKey: 'distributionNumber',
		accessorFn: (row) => row?.distributionNumber,
	},
	{
		header: 'Summary 190',
		id: 'summary190',
		indexedTitleKey: 'summary190',
		accessorFn: (row) => row?.summary190,
		cell: ({ getValue }) => <SummaryCell summary={getValue()} />,
	},
	{
		header: 'Summary 400',
		id: 'summary400',
		indexedTitleKey: 'summary400',
		accessorFn: (row) => row?.summary400,
		cell: ({ getValue }) => <SummaryCell summary={getValue()} />,
	},
	{
		header: 'Summary 4000',
		id: 'summary4000',
		indexedTitleKey: 'summary4000',
		accessorFn: (row) => row?.summary4000,
		cell: ({ getValue }) => <SummaryCell summary={getValue()} />,
	},
	{
		header: 'Title Type Detail',
		id: 'workTypeDetails',
		indexedTitleKey: 'workTypeDetails.label',
		accessorFn: (row) => row?.workTypeDetails?.[0]?.label,
	},
	{
		header: 'Status',
		id: 'titleStatus',
		indexedTitleKey: 'titleStatus',
		accessorFn: (row) => titleStatusJSON.find((s) => s.value === row?.titleStatus)?.label || '',
	},
	{
		header: 'Release Year',
		id: 'releaseYear',
		indexedTitleKey: 'releaseYear',
		accessorFn: (row) => row?.releaseYear,
	},
	{
		header: 'Abbreviated Title',
		id: 'abbreviatedTitle',
		indexedTitleKey: 'titleAbbreviated',
		accessorFn: (row) => row?.titleAbbreviated,
	},
	{
		header: 'Ratings',
		id: 'ratings',
		indexedTitleKey: 'ratings',
		accessorFn: (row) => row?.ratings,
		cell: ({ getValue }) => <MultiTags tags={getValue() || []} />,
	},
	{
		header: 'Created At',
		id: 'createdAt',
		indexedTitleKey: 'createdAt',
		accessorFn: (row) => (row?.createdAt ? timeToString(new Date(row?.createdAt)) : ''),
	},
	{
		header: 'Last Modified',
		id: 'editedAt',
		indexedTitleKey: 'editedAt',
		accessorFn: (row) => (row?.editedAt ? timeToString(new Date(row?.editedAt)) : ''),
	},
	{
		header: 'Created By',
		id: 'createdBy',
		indexedTitleKey: 'createdBy.name',
		accessorFn: (row) => row?.createdBy.name,
	},
	{
		header: 'Last Modified By',
		id: 'editedBy',
		indexedTitleKey: 'editedBy.name',
		accessorFn: (row) => row?.editedBy.name,
	},
];

export default columns;
