import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARIES
import ReleaseDate from '../fields/ReleaseDate';
import SortTitleField from '../fields/SortTitle';

// STYLES
import { Row } from './styles';

interface CompilationProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	dryRanTitle?: Title | undefined;
}

function Compilation({ control, errors, setValue, trigger, dryRanTitle }: CompilationProps) {
	return (
		<div>
			<Row>
				<ReleaseDate
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
					isRequired
				/>
				<SortTitleField
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
				/>
			</Row>
		</div>
	);
}

export default Compilation;
