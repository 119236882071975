import React, { useContext, useMemo } from 'react';
import { DisplayName } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/People';
import styled, { css } from 'styled-components';
import { CustomColumnDef } from '../../../../../../components/library/SimpleTable/customColumnDef';
import TitleEditableField from '../../../../../../components/titles/TitleEditableField';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';
import TextInputCell from './TextInputCell';
import LocaleCell from './LocaleCell';
import computeErrors from '../../../../utils/computeErrors';

interface DisplayNameTableProps {
	editMode: boolean;
	displayNames: DisplayName[] | undefined;
	canAddRow: boolean;
	placement?: 'bottom' | 'top';
	peopleIndex: number;
}

const Wrapper = styled.div(
	({ theme }) => css`
		.title-editable-field {
			padding: 0;
		}
		.title-editable-field-label {
			color: ${theme.palette.text.secondary};
			font-size: 14px;
			margin-bottom: ${theme.spacing(0.5)};
		}
	`,
);

function DisplayNamesTable({
	editMode,
	displayNames,
	canAddRow,
	placement = 'bottom',
	peopleIndex,
}: DisplayNameTableProps) {
	const { addToArray, removeFromArray, currentLocale, data, isInherited } = useContext(CastAndCrewContext);

	const displayNamesColumns: CustomColumnDef<DisplayName>[] = useMemo(
		() => [
			{
				header: 'Display Name',
				id: 'displayName',
				accessorFn: (originalRow) => originalRow.displayName,
				cell: (info) =>
					TextInputCell({
						info,
						width: undefined,
						pathPrefix: `people.${peopleIndex}.name.displayNames`,
						autoFocusOnEmpty: true,
						ariaLabel: 'Display Name Cell',
					}),
			},
			{
				id: 'languages',
				header: 'Languages',
				cell: (info: any) => LocaleCell(info, placement, `people.${peopleIndex}.name.displayNames`),
				width: 200,
				accessorFn: (originalRow) => originalRow.language,
			},
		],
		[peopleIndex, isInherited],
	);

	const getNewRoadPayload = (): DisplayName => {
		const displayNameList = data?.displayValue?.[peopleIndex]?.name?.displayNames;

		const localeToSelect = displayNameList?.some((displayName) => displayName.language === currentLocale)
			? ''
			: currentLocale;
		return { displayName: '', language: localeToSelect || '' };
	};

	const onRowAdd = () => {
		addToArray(`people.${peopleIndex}.name.displayNames`, getNewRoadPayload());
	};

	const onRowDelete = (selectedIndexes: string[]) => {
		removeFromArray(`people.${peopleIndex}.name.displayNames`, selectedIndexes);
	};

	const errors: { [key: string]: number[] } = useMemo(
		() => ({
			languages: computeErrors<DisplayName>(displayNames || [], 'language'),
			displayName: computeErrors<DisplayName>(displayNames || [], 'displayName'),
		}),
		[displayNames],
	);

	return (
		<Wrapper>
			<TitleEditableField
				label="Display Names"
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<DisplayName>
				onRowDelete={(selectedIndexes) => onRowDelete(selectedIndexes)}
				selectRowModeByDefault
				enableAddMultipleRows={false}
				showBottomActionsBar={editMode}
				tableStyle="border"
				canDelete={(selectedIndexes: string[]) => ({
					canDelete: !selectedIndexes.includes('0') || selectedIndexes.length !== (displayNames || []).length,
				})}
				columns={displayNamesColumns}
				canAddRow={canAddRow}
				data={displayNames || []}
				onRowAdd={onRowAdd}
				errors={errors}
				newRowPayload={getNewRoadPayload()}
				onRowEdit={() => {}}
				editMode={editMode}
			/>
		</Wrapper>
	);
}

// eslint-disable-next-line import/no-default-export
export default DisplayNamesTable;
