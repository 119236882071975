/* eslint-disable react-hooks/exhaustive-deps */
import { Rating } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { CellContext } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { DropdownV2 } from '@warehouse/shared/ui';
import { isInherited } from '@warehouse/title/core';
import { findRatingSystem } from './utils';
import { WithUuid } from '../../../../src/views/title/hooks/useTitleRecordsAutoSave/recordToArray';
import { CellWrapper } from '../../../../src/components/library/SimpleTable/style';

function CellSystemValue(info: CellContext<WithUuid<Inherited<Rating>>, WithUuid<Inherited<Rating>>>) {
	const { table, getValue } = info;
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;

	const options = useMemo(
		() =>
			(findRatingSystem(getValue()?.displayValue)?.ratingValues || []).map((ratingValue) => ({
				value: ratingValue.uuid,
				label: ratingValue.name,
			})),
		[
			getValue()?.displayValue?.region?.countryRegion,
			getValue()?.displayValue?.region?.country,
			getValue()?.displayValue?.system,
		],
	);

	if (options.length > 0) {
		return (
			<CellWrapper>
				<DropdownV2
					variant={isInherited({ inheritedObject: getValue() }).isInherited ? 'prefilled' : 'default'}
					value={getValue()?.displayValue?.value}
					transparentBorder
					backgroundColor="transparent"
					onChange={(e) => {
						editRecord?.({
							...getValue(),
							displayValue: {
								...getValue()?.displayValue,
								value: e,
							},
						});
					}}
					options={options}
				/>
			</CellWrapper>
		);
	}
	return null;
}

// eslint-disable-next-line import/no-default-export
export default CellSystemValue;
