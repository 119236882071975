import { useMemo } from 'react';

import languageWrittenJSON from '../../../src/assets/json-administration-profiles/availableLanguageWritten.json';
import languageWrittenMappedByValueJSON from '../../../src/assets/json-administration-profiles/availableLanguageWritten.map.value.json';
import sortAlphabeticallyByLabel from '../../../src/utils/sortAlphabeticallyByLabel';
import { Locale } from '../../../src/views/titles/localeType';

export const defaultLanguageWritten = languageWrittenJSON.find(({ name }) => name === 'en-US') as Locale;

export const defaultLanguageWrittenUuid = defaultLanguageWritten.uuid;

export function useLocales() {
	const sortedLanguageWritten = useMemo<Locale[]>(() => languageWrittenJSON.sort(sortAlphabeticallyByLabel), []);
	return useMemo(
		() => ({
			languagesWritten: sortedLanguageWritten,
			getLanguageWrittenByValue: (uuid: string) =>
				languageWrittenMappedByValueJSON[uuid as keyof typeof languageWrittenMappedByValueJSON],
			defaultLanguageWritten,
		}),
		[sortedLanguageWritten],
	);
}
