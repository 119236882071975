import React, { useMemo } from 'react';
import { isInherited, OptionalNullInherited } from '@warehouse/title/core';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import DatePicker, { dateToString, stringToDate } from '../../../../src/components/library/DatePicker/DatePicker';
import { SelectDropdownWrapper } from '../../../../src/components/titles/TitleOverview/fields/style';
import { useUpdateValue } from '../../domain/useUpdateValue';

interface TitleDatePickerFieldProps {
	label: string;
	path: string;
	style?: React.CSSProperties;
	ariaLabel?: string;
	jumpToParentButtonDisabled?: boolean;
	tooltipDisabled?: boolean;
}

function getVariant(value: OptionalNullInherited<string> | undefined): 'default' | 'prefilled' {
	if (!value) return 'default';

	return isInherited<string>({ inheritedObject: value }).isInherited ? 'prefilled' : 'default';
}

export function TitleDatePickerField({
	label,
	path,
	style,
	ariaLabel,
	jumpToParentButtonDisabled = false,
	tooltipDisabled = false,
}: TitleDatePickerFieldProps) {
	const { readOnlyValue, setValue, value, commit } = useTitleAutoSave<OptionalNullInherited<string>>({
		path: useMemo(() => path.split('.'), [path]),
		label,
	});

	const { updateValue } = useUpdateValue<string | undefined, Date | undefined>({
		value,
		transform: (date) => (date ? dateToString(date) : undefined),
	});

	return (
		<TitleEditableField
			labelStyle={style}
			label={label}
			path={path}
			jumpToParentButtonDisabled={jumpToParentButtonDisabled}
			tooltipDisabled={tooltipDisabled}
			readComponent={
				<ReadOnlyTypography
					noWrap
					title={readOnlyValue?.displayValue ?? undefined}
					variant={getVariant(value)}
					style={{ paddingRight: 8, marginRight: 0 }}
					aria-label={ariaLabel}
				>
					{readOnlyValue?.displayValue}
				</ReadOnlyTypography>
			}
			editComponent={
				<SelectDropdownWrapper
					aria-label={ariaLabel}
					style={{ width: 'calc(100% - 145px)' }}
					onBlur={() => {
						commit();
					}}
				>
					<DatePicker
						placeholder={value?.inherited?.value}
						value={value?.explicitValue ? stringToDate(value.explicitValue) : undefined}
						onChange={(date) => setValue(updateValue(date))}
						height={36}
						variant={getVariant(value)}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}
