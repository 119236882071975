import React, { useEffect, useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { CountryOfOriginRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger, useWatch } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// HOOKS
import { useTerritories } from '@warehouse/global-entities/core';

// LIBRARIES
import { TerritoriesSelector } from '../../../../components/titles/TerritoriesSelector';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';
import { emptyOrUndefinedArray } from './utils';

interface CountriesOfOriginFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function CountriesOfOriginField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	dryRanTitle,
}: CountriesOfOriginFieldProps) {
	const { territories, defaultTerritory } = useTerritories();
	const cooValue = useWatch({ name: 'countriesOfOrigin', control });
	const inheritedCOO = getTitleData<OptionalInherited<CountryOfOriginRegion[]>>(
		dryRanTitle,
		fromBasic(['countriesOfOrigin']),
	);
	const tooltip = useTooltip('coreMetadata.basic.countriesOfOrigin');

	useEffect(() => {
		// If dryRun has inherited countries of origin we skip setting a default value
		if (cooValue.length === 0 && emptyOrUndefinedArray(inheritedCOO?.inherited.value)) {
			setValue('countriesOfOrigin', [defaultTerritory?.value]);
			trigger('countriesOfOrigin');
		}
	}, []);

	return useMemo(
		() => (
			<Controller
				name="countriesOfOrigin"
				control={control}
				render={({ field }) => (
					<TerritoriesSelector
						tooltip={tooltip?.tooltip}
						ariaLabel="Countries Of Origin"
						label={getLabelWithRequired('Countries of Origin', isRequired)}
						width={576}
						multiple
						error={errors?.countriesOfOrigin?.message as string}
						placeholder={
							(inheritedCOO?.displayValue?.map(
								(coo) => territories?.find((territory) => territory.value === coo.country)?.label,
							) as string[]) || []
						}
						value={field.value}
						onSelect={async (value) => {
							setValue('countriesOfOrigin', [...field.value, value]);
							if (isRequired && !inheritedCOO?.displayValue?.length) {
								await trigger('countriesOfOrigin');
							}
						}}
						onDeselect={async (value) => {
							setValue(
								'countriesOfOrigin',
								field.value.filter((item: any) => item !== value),
							);
							if (isRequired && !inheritedCOO?.displayValue?.length) {
								await trigger('countriesOfOrigin');
							}
						}}
					/>
				)}
			/>
		),
		[control, inheritedCOO?.displayValue, territories, errors?.countriesOfOrigin?.message, trigger],
	);
}

export default CountriesOfOriginField;
