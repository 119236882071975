import React, { useMemo } from 'react';

// TYPES
import { isInherited, OptionalNullInherited, titlePaths } from '@warehouse/title/core';

// HOOKS
import { BottomActionClear, DropdownV2 } from '@warehouse/shared/ui';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';

// UTILS
import { fromBasic } from '../../../../src/utils/titleGetProperty';

// JSON
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import { useUpdateValue } from '../../domain/useUpdateValue';

export function WorkTypeDetails() {
	const { label, options } = useTitleEditorStore(titleEditorStoreSelector.workTypeDetails.availableWorkTypeDetails);
	const { value, setValue, commit } = useTitleAutoSave<OptionalNullInherited<string[]>>({
		label: 'Work Type Details',
		path: useMemoJsonPath(fromBasic(['workTypeDetails'])),
	});
	const { updateValue } = useUpdateValue<string[], string[] | null>({ value });

	if (!label) return null;

	const inheritedResult = isInherited({ inheritedObject: value }).isInherited;

	return useMemo(
		() => (
			<TitleEditableField
				label={label}
				path={titlePaths.workTypeDetails}
				wrapperStyle={{ padding: 0, marginBottom: 14 }}
				direction="column"
				forcedEditMode
				readComponent={undefined}
				editComponent={
					<DropdownV2
						height={38}
						disabledMargin
						ariaLabel="Work Type Details"
						withSearch
						variant={inheritedResult ? 'prefilled' : 'default'}
						options={options}
						value={value?.displayValue?.[0] || ''}
						onChange={(e) => {
							if (e) {
								setValue(updateValue([e]));
								commit();
							}
						}}
						bottomActions={
							<BottomActionClear
								action={() => {
									setValue(updateValue(null));
									commit?.();
								}}
							/>
						}
					/>
				}
			/>
		),
		[updateValue, inheritedResult, setValue, label, options, value, commit],
	);
}
