import React from 'react';
import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid-pro';
import { CustomColumnMenuPinningItem } from './CustomColumnMenuPinningItem';

export function CustomColumnMenu(props: GridColumnMenuProps) {
	return (
		<GridColumnMenu
			{...props}
			slots={{
				columnMenuPinningItem: CustomColumnMenuPinningItem,
			}}
		/>
	);
}
