import React, { useMemo, useState } from 'react';

import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { useExportTitle } from '../../../../src/utils/hooks/titles/useExportMEC';
import ErrorSnackbar from '../../../../src/views/title/hooks/ErrorSnackbar';
import { AutoButtonGroup } from '../../../../src/components/library/AutoButtonGroup';
import { getExportedTitleFileName } from '../get-exported-title-file-name';

export function ExportMECButton() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { loadExport, loading, error } = useExportTitle(title.uuid);
	const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);

	const handleClick = async (type: string) => {
		const { data } = await loadExport(type);

		if (!data) {
			setErrorSnackbarOpen(true);
			return;
		}

		const blob = new Blob([data.exportTitle.mec], { type: 'text/plain' });

		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = getExportedTitleFileName({ title, fileExtension: type });
		document.body.appendChild(link);
		link.click();

		URL.revokeObjectURL(url);
		document.body.removeChild(link);
	};

	const errorSnackbar = useMemo(
		() => (
			<ErrorSnackbar
				open={errorSnackbarOpen}
				onClose={() => setErrorSnackbarOpen(false)}
				title={error?.name || ''}
				errors={[error?.message || '']}
			/>
		),
		[error?.message, error?.name, errorSnackbarOpen],
	);

	const exportMecOptions = useMemo(
		() => [
			{ id: 'xml', label: 'Export MEC (XML)', default: true },
			{ id: 'json', label: 'Export MEC (JSON)' },
		],
		[],
	);

	return (
		<>
			{errorSnackbar}
			<AutoButtonGroup
				nxstyle="secondary-black"
				loading={loading}
				storeKey="export-format"
				height={32}
				options={exportMecOptions}
				onOptionClick={handleClick}
			/>
		</>
	);
}
