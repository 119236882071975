import {
	BucketIdUtils,
	FolderPathUtils,
	NxObject,
	NxObjectFile,
	NxObjectFolder,
	NxObjects,
	NxObjectType,
} from '@warehouse/object-browser/core';
import { UnreachableError } from '@warehouse/shared/errors';
import { assertUnreachable } from '@warehouse/shared/util';
import { components } from './inventory.openapi';
import { byte } from '../core/byte.service';
import { StorageTierAdapter } from './storage-tier.adapter';

export class NxObjectAdapter {
	static adapt(item: components['schemas']['NxObjectFile'] | components['schemas']['NxObjectFolder']): NxObject {
		if (item.itemType === 'file') return this.#adaptFile(item);
		if (item.itemType === 'folder') return this.#adaptFolder(item);
		assertUnreachable(item, `Invalid item type: ${(item as any).itemType}`);
		throw new UnreachableError(`Invalid item type: ${(item as any).itemType}`);
	}

	static adaptArray(
		items: (components['schemas']['NxObjectFile'] | components['schemas']['NxObjectFolder'])[],
	): NxObjects {
		return items
			.map((item) => {
				try {
					return NxObjectAdapter.adapt(item);
				} catch (e) {
					return null;
				}
			})
			.filter((item): item is NxObject => !!item);
	}

	static #adaptFile(item: components['schemas']['NxObjectFile']): NxObjectFile {
		return {
			...item,
			fileSize: byte(item.fileSize),
			lastModifiedAt: new Date(item.lastModifiedAt),
			bucketId: BucketIdUtils.cast(item.bucket),
			storageTier: StorageTierAdapter.adapt(item.storageTier),
			itemType: NxObjectType.File,
			folder: FolderPathUtils.cast(item.folder),
		} satisfies NxObjectFile;
	}

	static #adaptFolder(item: components['schemas']['NxObjectFolder']): NxObjectFolder {
		return {
			...item,
			lastModifiedAt: item.lastModifiedAt ? new Date(item.lastModifiedAt) : undefined,
			bucketId: BucketIdUtils.cast(item.bucket),
			itemType: NxObjectType.Folder,
			folder: FolderPathUtils.cast(item.folder),
		} satisfies NxObjectFolder;
	}
}
