import React from 'react';
import { Navigate } from 'react-router-dom';
import { CenteredNxLoader } from '@warehouse/shared/ui';
import {
	ObjectBrowserStoreProvider,
	objectBrowserStoreSelector,
	useObjectBrowserStore,
} from '@warehouse/object-browser/domain';
import { ObjectBrowserLinkBuilder } from '../../../libs/object-browser/feature-object-browser/domain/object-browser-link-builder';

function ObjectBrowserRedirecterCore() {
	const buckets = useObjectBrowserStore(objectBrowserStoreSelector.buckets);

	if (buckets.length === 0) {
		return <CenteredNxLoader />;
	}

	return <Navigate replace to={ObjectBrowserLinkBuilder.buildBucketLink(buckets[0].id)} />;
}

export function ObjectBrowserRedirecter() {
	return (
		<ObjectBrowserStoreProvider>
			<ObjectBrowserRedirecterCore />
		</ObjectBrowserStoreProvider>
	);
}
