import { useMutation } from '@apollo/client';
import { RemovedRecord, RemoveRecord, REMOVE_RECORD, GET_TITLE_V2 } from '@warehouse/graphql';

// eslint-disable-next-line import/prefer-default-export
export function useRemoveRecord() {
	return useMutation<
		{
			removeRecord: RemovedRecord;
		},
		{ input: RemoveRecord }
	>(REMOVE_RECORD, {
		update(cache, { data }, { context }) {
			try {
				if (!data?.removeRecord) return;
				context?.onSuccess?.();

				const title = data?.removeRecord?.title;

				cache.writeQuery({
					query: GET_TITLE_V2,
					variables: {
						uuid: title?.uuid,
					},
					data: {
						getTitleV2: title,
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
	});
}
