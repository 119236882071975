import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const TypoLink = styled(Link)(
	({ theme }) => css`
		color: ${theme.palette.text.primary};
		&:hover {
			text-decoration: none;
		}
		display: block;
		text-decoration: none;
	`,
);

export const TypoFakeLink = styled.span(
	({ theme }) => css`
		color: ${theme.palette.text.primary};
		&:hover {
			text-decoration: none;
		}
		display: block;
		text-decoration: none;
	`,
);
