import { Icon } from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { alpha, debounce } from '@mui/material';
import { paginatorStoreSelector, usePaginatorStore } from '@warehouse/shared/ui';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { addChildrenStoreSelector, useAddChildrenStore } from '@warehouse/title/feature-add-children';
import { TitleSearchFilterWorkType } from './TitleSearchFilterWorkType';

export function TitleSearchFilter({ allowedWorkTypeUuids, placeholder, disabled }: TitleSearchFilterProps) {
	const actions = useAddChildrenStore(addChildrenStoreSelector.actions);
	const { setPage } = usePaginatorStore(paginatorStoreSelector.actions);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const workTypeUuidSelected = useAddChildrenStore(addChildrenStoreSelector.selectedWorkTypeUuid);
	const setWorkTypeUuidSelected = useAddChildrenStore(addChildrenStoreSelector.actions).setSelectedWorkTypeUuid;
	const [localSearchValue, setLocalSearchValue] = useState<string>('');

	const debouncedSetSearchValue = debounce((v) => {
		setPage(1);
		actions.setSearchValue(v);
	}, 300);

	useEffect(() => {
		// once the component has been mounted and the ref has been assigned.
		if (inputRef.current) {
			// If the input element is available, call its focus method
			// to automatically focus the input field when the component mounts.
			inputRef.current.focus();
		}
	}, []);

	return (
		<SearchWrapper>
			<ErrorInputWrapper>
				<SubWrapper hoverDisabled={disabled}>
					<InputWrapper>
						<TitleSearchFilterWorkType
							disabled={disabled}
							allowedWorkTypeUuids={allowedWorkTypeUuids}
							onWorkTypeChange={(workTypeUuid) => {
								setPage(1);
								actions.setWorkTypeUuids(workTypeUuid ? [workTypeUuid] : getAllowedWorkTypes(allowedWorkTypeUuids));
							}}
							workTypeUuid={workTypeUuidSelected}
							setWorkTypeUuid={setWorkTypeUuidSelected}
						/>
						<TextInput
							className="search-input"
							disabledMargin
							autoFocus
							disabled={disabled}
							ref={inputRef}
							style={{
								flex: 1,
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
								minWidth: 200,
								backgroundColor: 'white',
								width: '100%',
							}}
							height={40}
							onChange={(event) => {
								setLocalSearchValue(event.target.value);
								debouncedSetSearchValue(event.target.value);
							}}
							startIcon={<Icon size="16px" path={mdiMagnify} />}
							placeholder={placeholder}
							value={localSearchValue}
						/>
					</InputWrapper>
				</SubWrapper>
			</ErrorInputWrapper>
		</SearchWrapper>
	);
}

interface TitleSearchFilterProps {
	allowedWorkTypeUuids: string[];
	placeholder: string;
	disabled: boolean;
}

function getAllowedWorkTypes(allowedWorkTypeUuids?: string[]): [string, ...string[]] | null {
	if (allowedWorkTypeUuids && isNonEmptyStringArray(allowedWorkTypeUuids)) {
		return allowedWorkTypeUuids;
	}
	return null;
}

function isNonEmptyStringArray(arr: string[]): arr is [string, ...string[]] {
	return Array.isArray(arr) && arr.length > 0;
}

const SearchWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: column;
	`,
);

const SubWrapper = styled.div<{ error?: boolean; hoverDisabled?: boolean }>(
	({ theme, error, hoverDisabled }) => css`
		border: solid 2px transparent;
		border-radius: 8px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 100%;

		${!hoverDisabled &&
		css`
			:hover {
				border: solid 2px ${theme.palette.primary.main};
			}
		`}

		${error &&
		css`
			& .input-wrapper > * {
				background-color: ${alpha(theme.palette.error.main, 0.05)} !important;
				color: ${theme.palette.error.text} !important;
			}
			border: solid 1px ${theme.palette.error.main} !important;

			border-bottom-right-radius: 0;
		`}

		& .input-wrapper:hover,
      .input-wrapper:focus-within {
			background-color: ${theme.palette.light.main};
			border-color: ${theme.palette.light.backgroundAlt};

			input {
				color: ${theme.palette.text.primary};
			}

			svg {
				color: ${theme.palette.text.secondary};
			}
		}
	`,
);

const ErrorInputWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		width: 100%;
	`,
);

const InputWrapper = styled.div(
	() => css`
		display: flex;
		min-width: 150px;
		width: 100%;

		& .search-input {
			width: 100%;
		}
	`,
);
