import { gql } from '@apollo/client';

export const titleFragment = gql`
	fragment TitleFragment on GetTitleOutput {
		uuid
		state
		indexed {
			...TitleIndexedFragment
		}
		ultraMezzUuid
	}
`;
