import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';
import { MadeForRegionField } from '@warehouse/title/feature-create-single-title';
import VersionLanguagesV2Field from '../fields/VersionLanguageV2';

// LIBRARIES
import EditUseField from '../fields/EditUse';
import EditClassField from '../fields/EditClass';
import { Row } from './styles';

interface PerformanceProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	dryRanTitle?: Title | undefined;
}

function Performance({ control, errors, setValue, trigger, dryRanTitle }: PerformanceProps) {
	return (
		<div>
			<Row>
				<EditUseField
					control={control}
					setValue={setValue}
					trigger={trigger}
					isRequired
					dryRanTitle={dryRanTitle}
					errors={errors}
				/>
				<EditClassField
					control={control}
					setValue={setValue}
					trigger={trigger}
					dryRanTitle={dryRanTitle}
					errors={errors}
				/>
			</Row>
			<Row>
				<MadeForRegionField
					control={control}
					setValue={setValue}
					trigger={trigger}
					dryRanTitle={dryRanTitle}
					errors={errors}
				/>
				<VersionLanguagesV2Field
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
				/>
			</Row>
		</div>
	);
}

export default Performance;
