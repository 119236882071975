import React, { memo, useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import isEqual from 'lodash/isEqual';

// LIBRARY
import styled, { css } from 'styled-components';
import { DropdownV2 } from '@warehouse/shared/ui';
import { batchCreateStoreSelector, useBatchCreateStore } from '@warehouse/title/feature-create-titles';
import { isSetEqual } from '@warehouse/shared/util';
import { CellWrapper } from '../../../components/library/SimpleTable/style';
import TextInput from '../../../components/library/TextInput';
import TimeInput from '../../../components/library/TimeInput';
import { CustomColumnDef } from '../../../components/library/SimpleTable/customColumnDef';
import DatePicker from '../../../components/library/DatePicker/DatePicker';
import Dropdown from '../../../components/library/Dropdown/Dropdown';
import IncludeExcludeBottomAction from '../../../components/titles/IncludeExcludeBottomAction';
import { EditTitleCreationFormData } from '../../../../libs/title/feature-create-titles/title-creation-form-data';

export const NumberInputCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding style={{ width: '100px' }}>
		<TextInput
			variant={isInherited(column, getValue()) ? 'prefilled' : 'default'}
			height={32}
			type="number"
			value={(getValue() as string) || ''}
			onChange={(e) => {
				table.options.meta?.updateData?.(row.index, column.id!, e.target.value);
			}}
		/>
	</CellWrapper>
));

export const StringInputCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding style={{ width: '100%' }}>
		<TextInput
			variant={isInherited(column, getValue()) ? 'prefilled' : 'default'}
			height={32}
			type="text"
			value={(getValue() as string) || ''}
			onChange={(e) => {
				table.options.meta?.updateData?.(row.index, column.id!, e.target.value);
			}}
		/>
	</CellWrapper>
));

export const TimeInputCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding>
		<TimeInput
			variant={isInherited(column, getValue()) ? 'prefilled' : 'default'}
			value={getValue() as number}
			onChange={(e) => {
				table.options.meta?.updateData?.(row.index, column.id!, e);
			}}
			height={30}
		/>
	</CellWrapper>
));

export const DatePickerCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper withHorizontalPadding>
		<DatePicker
			variant={isInherited(column, getValue()) ? 'prefilled' : 'default'}
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
			value={getValue() ? (getValue() as Date) : undefined}
			onChange={(date) => {
				table.options.meta?.updateData?.(row.index, column.id!, date);
			}}
			height={32}
		/>
	</CellWrapper>
));

export const SelectDropdownCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper style={{ overflow: 'hidden' }}>
		<DropdownV2
			variant={isInherited(column, getValue()) ? 'prefilled' : 'default'}
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			getPopupContainer={() => document.body}
			mode="multiple"
			value={(getValue() as string[]) || []}
			transparentBorder
			backgroundColor="transparent"
			withSearch
			onChange={(values) => {
				table.options.meta?.updateData?.(row.index, column.id!, values);
			}}
		/>
	</CellWrapper>
));

export const DropdownCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper>
		<Dropdown
			valueStyle={isInherited(column, getValue()) ? 'italic' : 'default'}
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			value={getValue() as string}
			onChange={(e) => table.options.meta?.updateData?.(row.index, column.id!, e as string)}
			enablePortal
			transparentBorder
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
		/>
	</CellWrapper>
));

export const SearchDropdownCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper>
		<Dropdown
			valueStyle={isInherited(column, getValue()) ? 'italic' : 'default'}
			withSearch
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			value={getValue() as string}
			onChange={(e) => table.options.meta?.updateData?.(row.index, column.id!, e as string)}
			enablePortal
			transparentBorder
		/>
	</CellWrapper>
));

const CustomTextInput = styled(TextInput)(
	({ theme }) => css`
		input {
			&:focus {
				border-radius: 0;
				border: solid 1px ${theme.palette.blue.main} !important;
			}

			&:focus-visible {
				outline: transparent;
			}
		}

		.input-wrapper {
			border: none;
			input {
				::placeholder {
					font-style: italic !important;
				}
			}
		}
	`,
);

export const TitleWrapper = styled.div(
	() => css`
		height: 48px;
		width: 100%;
		input {
			width: 100%;
		}
		& > * {
			.input-wrapper {
				background-color: inherit;
			}
			border-radius: 0 !important;
			margin: 0 !important;
		}
	`,
);

export const TitleCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => {
	const [value, setValue] = useState<string | undefined>(getValue());
	useEffect(() => {
		setValue(getValue());
	}, [getValue()]);

	const titlePlaceholder = useBatchCreateStore(batchCreateStoreSelector.computedPlaceholderDisplayTitle(row.index));

	return (
		<TitleWrapper>
			<CustomTextInput
				onBlur={() => {
					table.options.meta?.updateData?.(row.index, column.id!, value);
				}}
				placeholder={titlePlaceholder}
				value={value || ''}
				onChange={(e) => setValue(e.target.value)}
			/>
		</TitleWrapper>
	);
});

export const AlidCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => {
	const [value, setValue] = useState<string | undefined>(getValue());
	useEffect(() => {
		setValue(getValue());
	}, [getValue()]);

	return (
		<TitleWrapper>
			<CustomTextInput
				onBlur={() => {
					table.options.meta?.updateData?.(row.index, column.id!, value);
				}}
				value={value || ''}
				onChange={(e) => setValue(e.target.value)}
			/>
		</TitleWrapper>
	);
});

export const SortTitleCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => {
	const [value, setValue] = useState<string | undefined>(getValue());
	useEffect(() => {
		setValue(getValue());
	}, [getValue()]);

	const placeholderSort = useBatchCreateStore(batchCreateStoreSelector.computedPlaceholderSortTitle(row.index));

	return (
		<TitleWrapper>
			<CustomTextInput
				placeholder={placeholderSort}
				onBlur={() => {
					table.options.meta?.updateData?.(row.index, column.id!, value);
				}}
				value={value || ''}
				onChange={(e) => setValue(e.target.value)}
			/>
		</TitleWrapper>
	);
});

export function showExclLabel(madeForRegions: EditTitleCreationFormData['madeForRegions']) {
	if (!madeForRegions) return false;
	return madeForRegions?.isInclude === false;
}

export const MadeForRegionsCell = memo(({ getValue, table, row, column }: CellContext<any, any>) => (
	<CellWrapper style={{ overflow: 'hidden' }}>
		<DropdownV2
			{...(column.columnDef as CustomColumnDef<any>).cellProps}
			variant={isMadeForRegionsInherited(column, getValue()) ? 'prefilled' : 'default'}
			options={(column.columnDef as CustomColumnDef<any>).subHeaderDropdownOptions || []}
			getPopupContainer={() => document.body}
			mode="multiple"
			leadingElement={
				showExclLabel(row.original.madeForRegions) ? <span style={{ marginLeft: '8px' }}>Excl.</span> : undefined
			}
			value={(getValue()?.madeForRegions as string[]) || []}
			transparentBorder
			bottomActions={
				<IncludeExcludeBottomAction
					value={row.original.madeForRegions?.isInclude === false ? 'excludes' : 'includes'}
					defaultValue="includes"
					onChange={(e) => {
						table.options.meta?.updateData?.(row.index, column.id!, {
							madeForRegions: {
								isInclude: e.target.value === 'includes',
								madeForRegions: row.original.madeForRegions?.madeForRegions || [],
							},
						});
					}}
				/>
			}
			backgroundColor="transparent"
			withSearch
			onChange={(values) => {
				table.options.meta?.updateData?.(row.index, column.id!, {
					madeForRegions: {
						isInclude: row.original.madeForRegions?.isInclude,
						madeForRegions: values,
					},
				});
			}}
		/>
	</CellWrapper>
));

function isInherited(column: CellContext<any, any>['column'], value: any): boolean {
	const { cellProps } = column.columnDef as CustomColumnDef<any>;
	if (!cellProps?.inheritedValue) return false;
	if (!value) return false;

	if (Array.isArray(cellProps?.inheritedValue) && Array.isArray(value)) {
		return isSetEqual(cellProps?.inheritedValue, value);
	}

	return isEqual(cellProps?.inheritedValue, value);
}

function isMadeForRegionsInherited(
	column: CellContext<any, any>['column'],
	value: EditTitleCreationFormData['madeForRegions'],
) {
	const cellProps = (column.columnDef as CustomColumnDef<any>).cellProps as {
		inheritedValue: EditTitleCreationFormData['madeForRegions'];
	};
	if (!cellProps?.inheritedValue) return false;
	if (!value) return false;

	if (value.isInclude !== cellProps.inheritedValue.isInclude) return false;

	return isSetEqual(value.madeForRegions, cellProps.inheritedValue.madeForRegions);
}
