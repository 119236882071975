import { useMemo } from 'react';
import countriesJson from '../../../src/assets/json-administration-profiles/availableCountry.json';
import mappedCountriesJson from '../../../src/assets/json-administration-profiles/availableCountry.map.value.json';

import countryRegionsJson from '../../../src/assets/json-administration-profiles/availableCountryRegion.json';
import mappedCountryRegionsJson from '../../../src/assets/json-administration-profiles/availableCountryRegion.map.value.json';

import territoryGroupJson from '../../../src/assets/json-administration-profiles/availableTerritoryGroup.json';
import mappedTerritoryGroupJson from '../../../src/assets/json-administration-profiles/availableTerritoryGroup.map.value.json';

const territories = [...countriesJson, ...territoryGroupJson, ...countryRegionsJson];

function getCategory(uuid: string): 'country' | 'territoryGroup' | 'countryRegion' {
	if (mappedCountriesJson[uuid as keyof typeof mappedCountriesJson]) {
		return 'country';
	}
	if (mappedTerritoryGroupJson[uuid as keyof typeof mappedTerritoryGroupJson]) {
		return 'territoryGroup';
	}
	return 'countryRegion';
}

function getKey(uuid: string): 'country' | 'countryRegion' {
	return mappedCountriesJson[uuid as keyof typeof mappedCountriesJson] ? 'country' : 'countryRegion';
}

function getLabel(uuid: string) {
	const category = getCategory(uuid);
	if (category === 'country') {
		return mappedCountriesJson[uuid as keyof typeof mappedCountriesJson]?.label;
	}
	if (category === 'territoryGroup') {
		return mappedTerritoryGroupJson[uuid as keyof typeof mappedTerritoryGroupJson]?.label;
	}

	return mappedCountryRegionsJson[uuid as keyof typeof mappedCountryRegionsJson]?.label;
}

export const territoriesHelper = {
	getKey,
	getLabel,
	getCategory,
	territories,
};

const defaultTerritory = territories.find(({ name }) => name === 'US');
export function useTerritories() {
	return useMemo(
		() => ({
			getKey,
			getCategory,
			getLabel,
			getByLabel: (label: string) => territories.find((territory) => territory.label === label),
			isCountry: (uuid: string): boolean => !!mappedCountriesJson[uuid as keyof typeof mappedCountriesJson],
			isCountryRegion: (uuid: string): boolean =>
				!!mappedCountryRegionsJson[uuid as keyof typeof mappedCountryRegionsJson],
			isTerritoryGroup: (uuid: string): boolean =>
				!!mappedTerritoryGroupJson[uuid as keyof typeof mappedTerritoryGroupJson],
			territories,
			defaultTerritory,
			categorizedTerritories: [
				{ label: 'Countries', options: countriesJson },
				{ label: 'Territory Groups', options: territoryGroupJson },
				{ label: 'Subdivisions', options: countryRegionsJson },
			],
		}),
		[],
	);
}
