import React, { ReactElement, useCallback } from 'react';
import { Cell, ColumnOrderState, flexRender, Row, Table } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Maybe } from 'graphql/jsutils/Maybe';
import { TD } from './TableUtils';
import { ColumnStickyState, getStickyPosition } from '../../utils/table/stickyUtils';
import { ConditionalTree } from '../../utils/ConditionalTree';

interface SimpleRowProps<T = any> {
	row: Row<Maybe<T>>;
	columnSticky: ColumnStickyState;
	table: Table<Maybe<T>>;
	selectedColumn: string | null;
	columnOrder: ColumnOrderState;
	columnsTrueSize: number[];
	rowLink?: (row: Row<Maybe<T>>) => string;
	isScrolled?: boolean;
}

const StyledLink = styled(Link)(
	({ theme }) => css`
		all: unset;

		cursor: pointer;
		display: block;
		height: 100%;
		line-height: 48px;
		padding: ${theme.spacing(0, 2)} !important;
	`,
);

function SimpleRow<T = any>({
	row,
	columnSticky,
	table,
	selectedColumn,
	columnOrder,
	columnsTrueSize,
	rowLink,
	isScrolled,
}: SimpleRowProps<T>) {
	const hasRowLinkWrapper = useCallback(
		(children: ReactElement | ReactElement[]) => <StyledLink to={rowLink?.(row) ?? '/'}>{children}</StyledLink>,
		[row, rowLink],
	);

	return (
		<>
			{row.getVisibleCells().map((cell) => (
				<TD
					key={cell.id}
					className={`
						${
							columnSticky.includes(cell.column.id) &&
							columnSticky.indexOf(cell.column.id) === columnSticky.length - 1 &&
							isScrolled
								? 'sticky-shadow'
								: ''
						}
						${
							(!table.getState().columnSizingInfo.isResizingColumn && selectedColumn === cell.column.id) ||
							table.getState().columnSizingInfo.isResizingColumn === cell.column.id
								? 'selected'
								: ''
						}
					`}
					sticky={
						columnSticky.includes(cell.column.id)
							? getStickyPosition(table.getAllColumns(), cell.column, columnOrder, columnsTrueSize, columnSticky)
							: undefined
					}
					enablePadding={rowLink === undefined}
					data-testid={`cell-${cell.column.columnDef.id}`}
				>
					<ConditionalTree condition={rowLink !== undefined} wrapper={hasRowLinkWrapper}>
						<div className={shouldHighlight<T>(cell) ? 'highlighted-cell' : ''}>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</div>
					</ConditionalTree>
				</TD>
			))}
		</>
	);
}

function shouldHighlight<T>(cell: Cell<Maybe<T>, unknown>): boolean {
	if ('highlight' in cell.column.columnDef && typeof cell.column.columnDef.highlight === 'boolean') {
		return cell.column.columnDef.highlight;
	}
	return false;
}

export default SimpleRow;
