import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ExportTitleInput, ExportTitleOutput, EXPORT_TITLE } from '@warehouse/graphql';

export function useExportTitle(uuid: string) {
	const [loadExport, { data, loading, error }] = useLazyQuery<
		{
			exportTitle: ExportTitleOutput;
		},
		{
			input: ExportTitleInput;
		}
	>(EXPORT_TITLE, {
		variables: {
			input: {
				uuid,
			},
		},
		fetchPolicy: 'no-cache',
	});

	const wrappedLoadExport = useCallback(
		(type: string) => loadExport({ variables: { input: { uuid, type } } }),
		[loadExport, uuid],
	);

	return { loadExport: wrappedLoadExport, data, loading, error };
}
