import { FilterStep, FilterWithDate } from '@warehouse/shared/filters';
import { SearchFilters } from './SearchFilters';

export class CommonTitleFilters extends SearchFilters {
	static excludeHiddenTitles(): FilterWithDate {
		return { field: 'titleStatus', operator: 'doesNotEqual', value: 'hidden' };
	}

	static searchByCompilationId(titleUuid: string): FilterWithDate {
		return { field: 'compilationId', operator: 'equals', value: titleUuid };
	}

	static searchByParentContentId(titleUuid: string): FilterWithDate {
		return { field: 'parents.parentContentId', operator: 'equals', value: titleUuid };
	}

	static searchByParentRelationshipType(relationshipType: string): FilterWithDate {
		return { field: 'parents.relationshipType', operator: 'equals', value: relationshipType };
	}

	static searchByLicensorDisplayName(licensorDisplayName: string): FilterWithDate {
		return { field: 'licensor.label', operator: 'equals', value: licensorDisplayName };
	}

	static excludeUuids(uuids: string[]): FilterWithDate {
		return { field: 'uuid', operator: 'isNoneOf', value: uuids };
	}

	static includeUuids(uuids: string[]): FilterWithDate {
		return { field: 'uuid', operator: 'isAnyOf', value: uuids };
	}

	static searchByWorkType(workTypeUuid: string): FilterWithDate {
		return { field: 'workType.uuid', operator: 'equals', value: workTypeUuid };
	}

	static searchByWorkTypes(workTypeUuids: string[]): FilterStep {
		const workTypeFilters: FilterWithDate[] = workTypeUuids
			.filter((wt) => wt !== 'all')
			.map((wt) => ({
				field: 'workType',
				operator: 'equals',
				value: wt,
			}));

		return {
			step: {
				combinationOperator: 'OR',
				filters: workTypeFilters?.length ? workTypeFilters : [],
			},
		};
	}
}
