import { FolderPath } from '../folder-path-utils';
import { StorageTierKey, StorageTierLabels } from './storage-tier';
import { BucketId } from '../bucket-id-utils';

export enum NxObjectType {
	Folder = 'folder',
	File = 'file',
}

export interface NxObjectCommon {
	online: boolean;
	uuid: string;
	name: string;
	bucketId: BucketId;
	key: string;
	folder: FolderPath;
	s3Uri: string;
}

export interface NxObjectFolder extends NxObjectCommon {
	itemType: NxObjectType.Folder;
	lastModifiedAt?: Date;
}

export interface NxObjectFile extends NxObjectCommon {
	itemType: NxObjectType.File;
	fileSize: Byte;
	eTag: string;
	storageTier: StorageTier;
	lastModifiedAt: Date;
	uploadedByOrganization: string;
	uploadedByUser: string;
}

export type Byte = number & {
	__brand: 'Byte';
};

export type StorageTier<K extends StorageTierKey = StorageTierKey> = {
	value: K;
	label: (typeof StorageTierLabels)[K];
};

export type NxObject = NxObjectFile | NxObjectFolder;

export type NxObjectMetadata = {
	path: { name: string; uuid: string }[];
};

export type NxObjectFull = {
	nxObject: NxObject;
	metadata: NxObjectMetadata;
};

export type NxObjects = NxObject[];
