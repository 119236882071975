import { useCallback, useEffect, useMemo, useState } from 'react';
import deepEquality from '../deepEquality';
import { useURLSearchParams } from './useURLSearchParams';

type PossibleType = number | string | Object | undefined;

export interface SessionShareableParamsProps<Type extends PossibleType> {
	defaultValue?: Type;
	name: string;
	uniqueKey?: string;
	transformState?: (state: Type) => Type;
}

export type SessionShareableParams<Type extends PossibleType, ReturnType extends Type | undefined = Type> = [
	ReturnType, // value
	(v: Type) => void, // setValue
	() => void, // clearValue
];

function serializeValue<Type extends PossibleType>(data: Type): string {
	if (typeof data === 'object') return btoa(JSON.stringify(data));
	if (typeof data === 'string' || typeof data === 'number') return data.toString();
	return btoa(JSON.stringify({ __internalType: 'undefined' }));
}

function deserializeValue<Type extends PossibleType>(data: string): Type {
	try {
		const objectData = JSON.parse(atob(data));
		if (objectData.__internalType === 'undefined') {
			return undefined as Type;
		}
		return objectData;
	} catch (_) {
		/* Continue execution as it is not a base64 */
	}

	const dataAsNumber = parseInt(data, 10);
	if (!Number.isNaN(dataAsNumber) && dataAsNumber.toString().trim() === data.trim()) return dataAsNumber as Type;
	return data as Type;
}

export function useSessionShareableParams<Type extends PossibleType, ReturnType extends Type | undefined = Type>({
	defaultValue,
	name,
	uniqueKey,
	transformState,
}: SessionShareableParamsProps<Type>): SessionShareableParams<Type, ReturnType> {
	const { searchParams, setQuery, deleteQuery } = useURLSearchParams();
	const storageKey = useMemo<string>(() => `${uniqueKey ? `${uniqueKey}-` : ''}${name}`, [uniqueKey, name]);

	const [value, setValue] = useState<Type | undefined>(() => {
		const fromQueryParam = searchParams[name];
		if (fromQueryParam) return deserializeValue<Type>(fromQueryParam);
		const fromSessionStorage = window.sessionStorage.getItem(storageKey);
		if (fromSessionStorage) return deserializeValue<Type>(fromSessionStorage);
		return defaultValue;
	});

	const isValueDefault = useCallback(
		(v: Type | undefined): boolean => {
			const valueToUse = transformState && v ? transformState(v) : v;
			if (typeof defaultValue === 'object') return deepEquality(valueToUse, defaultValue);
			return valueToUse === defaultValue;
		},
		[defaultValue, transformState],
	);

	// Update storage based on current value
	useEffect(() => {
		if (defaultValue === undefined) {
			if (value !== undefined) {
				const valueToStore = serializeValue(value);
				setQuery(name, valueToStore);
				window.sessionStorage.setItem(storageKey, valueToStore);
			} else {
				deleteQuery(name);
				window.sessionStorage.removeItem(storageKey);
			}
		} else if (!isValueDefault(value)) {
			const valueToStore = serializeValue(value);
			setQuery(name, valueToStore);
			window.sessionStorage.setItem(storageKey, valueToStore);
		} else {
			deleteQuery(name);
			window.sessionStorage.removeItem(storageKey);
		}
	}, [value, defaultValue, isValueDefault, deleteQuery, storageKey, name, setQuery]);

	const valueSetter = useCallback(
		(next: Type) => {
			setValue(next);
		},
		[setValue],
	);

	const clearValue = useCallback(() => {
		setValue(defaultValue);
		deleteQuery(name);
		window.sessionStorage.removeItem(storageKey);
	}, [setValue, storageKey, name, defaultValue, deleteQuery]);

	// On unmount, remove query from url
	useEffect(
		() => () => {
			deleteQuery(name);
		},
		[name, deleteQuery],
	);

	return [value as ReturnType, valueSetter, clearValue];
}
