import React from 'react';
import {
	GridPinnedColumnPosition,
	GridColumnMenuItemProps,
	useGridApiContext,
	useGridRootProps,
} from '@mui/x-data-grid-pro';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export function CustomColumnMenuPinningItem(props: GridColumnMenuItemProps) {
	const { colDef, onClick } = props;
	const apiRef = useGridApiContext();
	const rootProps = useGridRootProps();

	const pinColumn = React.useCallback(
		(side: GridPinnedColumnPosition) => (event: React.MouseEvent<HTMLElement>) => {
			apiRef.current.pinColumn(colDef.field, side);
			onClick(event);
		},
		[apiRef, colDef.field, onClick],
	);

	const unpinColumn = (event: React.MouseEvent<HTMLElement>) => {
		apiRef.current.unpinColumn(colDef.field);
		onClick(event);
	};

	if (!colDef) {
		return null;
	}

	const side = apiRef.current.isColumnPinned(colDef.field);

	if (side) {
		const otherSide =
			side === GridPinnedColumnPosition.RIGHT ? GridPinnedColumnPosition.LEFT : GridPinnedColumnPosition.RIGHT;
		const Icon =
			side === GridPinnedColumnPosition.RIGHT
				? rootProps.slots.columnMenuPinLeftIcon
				: rootProps.slots.columnMenuPinRightIcon;
		return (
			<>
				{side === GridPinnedColumnPosition.RIGHT && (
					<MenuItem onClick={pinColumn(otherSide)}>
						<ListItemIcon>
							<Icon fontSize="small" />
						</ListItemIcon>
						<ListItemText>{apiRef.current.getLocaleText('pinToLeft')}</ListItemText>
					</MenuItem>
				)}
				<MenuItem onClick={unpinColumn}>
					<ListItemIcon />
					<ListItemText>{apiRef.current.getLocaleText('unpin')}</ListItemText>
				</MenuItem>
			</>
		);
	}
	return (
		<MenuItem onClick={pinColumn(GridPinnedColumnPosition.LEFT)}>
			<ListItemIcon>
				<rootProps.slots.columnMenuPinLeftIcon fontSize="small" />
			</ListItemIcon>
			<ListItemText>{apiRef.current.getLocaleText('pinToLeft')}</ListItemText>
		</MenuItem>
	);
}
