import { Rating } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import territoriesRating from '../../../../src/assets/json-administration-profiles/availableRatingSystems.json';
import { WithUuid } from '../../../../src/views/title/hooks/useTitleRecordsAutoSave/recordToArray';

export function findTerritory(rating: Rating) {
	return territoriesRating.find(
		(territory) => territory.uuid === rating?.region?.country || territory.uuid === rating?.region?.countryRegion,
	);
}

export function findRatingSystem(rating: Rating) {
	const availableRatingSystems = findTerritory(rating)?.ratingSystems || [];
	return availableRatingSystems.find((system) => system.uuid === rating.system);
}

export function findRatingValue(rating: Rating) {
	const availableRatingValues = findRatingSystem(rating)?.ratingValues || [];
	return availableRatingValues.find((value) => value.uuid === rating.value);
}

export function findRatingReason(rating: Rating) {
	const availableRatingReasons = findRatingSystem(rating)?.ratingTags || [];
	return rating.reasons?.map(
		(reason) => availableRatingReasons.find((availableReason) => availableReason.uuid === reason.reason)?.label,
	);
}

export function filterSelectedTerritory(
	ratings: WithUuid<Inherited<Rating>>[],
	selectedTerritory: string,
): WithUuid<Inherited<Rating>>[] {
	return selectedTerritory === 'default'
		? ratings
		: ratings.filter(
				(rating) =>
					rating.displayValue?.region?.country === selectedTerritory ||
					rating.displayValue?.region?.countryRegion === selectedTerritory,
		  );
}
