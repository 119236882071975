import { TitleFull as Title, OptionalNullInherited } from '@warehouse/title/core';
import { SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { EMPTY, map, merge, mergeScan, of, Subject, switchMap } from 'rxjs';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { deepEqualityWithOrWithoutInheritance, transformEmptyStrings } from '@warehouse/shared/util';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { useEditPreventer } from '@warehouse/title/feature-edit-preventer-popup';
import { JsonPointer } from '../../../../utils/getDeepProperty';
import { getTitleData, mapPathDefaultAndFirstToUuid } from '../../../../utils/titleGetProperty';
import { EditObject, OnErrorFn, TitleAutoSaveQueueContext } from '../../contexts/TitleAutoSaveQueueContext';
import ErrorsJsonMap from '../../../../assets/custom-jsons/error-messages';
import useControl from '../useControl';
import {
	defaultIsNewValueDifferent,
	filterInvalidRow,
	getRealValueFromInheritance,
	hasInvalidRow,
	isFunction,
	trimString,
} from '../utils';
import { createCancellableTimer } from './cancellableTimer';

/**
 * @param isNewValueDifferent Used to determine if the new value should set a new dirty value. By default, we check that newValue.trim()
 * and currentValue are different (inheritance agnostic).
 * @param trimValue Used to trim the value before committing it. By default, we simply trim the string (inheritance agnostic).
 */
type UseTitleAutoSaveProps<T, RowType = T> = {
	path: JsonPointer;
	label: string;
	mutatePayloadBeforeSave?: (value: T | undefined) => any;
	mutatePayloadOnLoad?: (value: T | undefined) => any;
	shouldDistinctUntilChanged?: boolean;
	isNewValueDifferent?: (newValue: T | undefined, currentValue: T | undefined) => boolean;
	trimValue?: (value: T | undefined) => T | undefined;
	onSave?: (
		value: T | undefined,
		addToQueue: (editObject: EditObject<any>) => void,
		addToQueueParams: {
			type: string;
			label: string;
			onSuccess: () => void;
			onError: OnErrorFn;
			onStart: () => void;
			signal: AbortSignal;
			titleUuid: string;
		},
	) => void;
	onSaved?: () => void;
	isRowValid?: (row: RowType) => boolean;
	isInheritable?: boolean;
};

export type CommitOptions = {
	force?: boolean;
};

export type UseTitleAutoSaveOutput<T> = {
	value: T | undefined;
	readOnlyValue: T | undefined;
	setValue: (setState: SetStateAction<T>, commit?: boolean) => void;
	commit: (options?: CommitOptions) => void;
	error: string | undefined;
	hasInvalidRow?: boolean;
};

export function optionalNullInheritedToOptionalInherited<T>(value: OptionalNullInherited<T>): OptionalInherited<T> {
	return {
		...value,
		displayValue: value.displayValue ?? undefined,
		explicitValue: value.explicitValue ?? undefined,
	};
}

const DELAY = 5000;

function useTitleAutoSave<T, RowType = T>({
	path,
	label,
	mutatePayloadBeforeSave = (value) => value,
	mutatePayloadOnLoad,
	isNewValueDifferent = defaultIsNewValueDifferent,
	trimValue = trimString,
	shouldDistinctUntilChanged = true,
	onSave,
	onSaved,
	isRowValid,
	isInheritable = true,
}: UseTitleAutoSaveProps<T, RowType>): UseTitleAutoSaveOutput<T> {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { act } = useEditPreventer();

	const [error, setError] = useState<string>();
	const getMutatedTitleData = useCallback(
		(hookTitle: Title | undefined, hookPath: JsonPointer) =>
			mutatePayloadOnLoad
				? mutatePayloadOnLoad(getTitleData<T>(hookTitle, hookPath))
				: getTitleData<T>(hookTitle, hookPath),
		[mutatePayloadOnLoad],
	);
	const { addToQueue } = useContext(TitleAutoSaveQueueContext);
	const { value, setValue, dirtyValue, setDirtyValue } = useControl<T | undefined>(
		path.join('.'),
		getMutatedTitleData(title, path),
	);
	const [[timerObs, timerTrigger, cancelTrigger]] = useState(() => createCancellableTimer(DELAY));
	const dirtyValueRef = useRef(structuredClone(dirtyValue));
	const valueRef = useRef(structuredClone(value));
	const lastEmittedValueRef = useRef<T | undefined>(getTitleData<T>(title, path));
	const inputSubject = useRef(new Subject<T | undefined>());
	const commitSubject = useRef(new Subject<void>());
	const isPendingRef = useRef(false);
	const abortRef = useRef(new AbortController());
	const pathRef = useRef(path);
	const titleDataRef = useRef(title);
	titleDataRef.current = title;

	const setValueWithRef = useCallback(
		(newValue: T | undefined) => {
			setValue(newValue);
			valueRef.current = structuredClone(newValue);
		},
		[setValue],
	);

	/**
	 * This effect synchronizes the backend title state with the local state when the backend title changes.
	 * It is used to hydrate the state after optimistic updates.
	 */
	useEffect(() => {
		setValueWithRef(getMutatedTitleData(title, path));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [title, path]);

	const onSuccess = () => {
		if (isInheritable) {
			setValueWithRef({
				...dirtyValueRef.current,
				displayValue: getRealValueFromInheritance(filterInvalidRow(dirtyValueRef.current, isRowValid)),
			});
			if (!hasInvalidRow(dirtyValueRef.current, isRowValid)) {
				setDirtyValue(undefined);
				isPendingRef.current = false;
				setError(undefined);
			}
		} else {
			setValueWithRef(structuredClone(dirtyValueRef.current));
			setDirtyValue(undefined);
		}
		if (onSaved) onSaved();
	};

	const onStart = () => {
		isPendingRef.current = true;
	};

	const onError: OnErrorFn = (_error) => {
		isPendingRef.current = false;
		if (_error?.message in ErrorsJsonMap) setError(ErrorsJsonMap[_error?.message]);
		else setError(_error?.message);
	};

	useEffect(() => {
		dirtyValueRef.current = structuredClone(dirtyValue);
	}, [dirtyValue]);

	useEffect(() => {
		pathRef.current = path;
	}, [path]);

	const save = (_value: T) => {
		if (titleDataRef.current) {
			const addToQueueParams = {
				type: 'explicit',
				label,
				onSuccess,
				onError,
				onStart,
				signal: abortRef.current?.signal,
				titleUuid: titleDataRef.current?.uuid,
			};
			if (onSave) {
				onSave(_value, addToQueue, addToQueueParams);
				return;
			}
			const pathFormatted = mapPathDefaultAndFirstToUuid(pathRef.current.join('.'), titleDataRef.current)?.split('.');
			if (pathFormatted && dirtyValueRef.current !== undefined) {
				addToQueue({
					...addToQueueParams,
					mutation: 'edit',
					path: pathFormatted,
					value: _value,
				});
			}
		}
	};

	const commit = (options?: CommitOptions) => {
		cancelTrigger.next();
		if (options?.force) {
			setDirtyValue(value);
			dirtyValueRef.current = structuredClone(value);
		}
		setDirtyValue((prev) => trimValue(prev));
		setValue((prev) => {
			valueRef.current = structuredClone(trimValue(prev));
			return trimValue(prev);
		});
		commitSubject.current.next();
	};

	const handleInputChange = (_valueOrFunction: SetStateAction<T>, _commit = true) => {
		if (isPendingRef.current && _commit) {
			abortRef?.current?.abort('aborted');
			abortRef.current = new AbortController();
		}

		const newValue = isFunction(_valueOrFunction) ? _valueOrFunction(dirtyValue || value!) : _valueOrFunction;
		if (isNewValueDifferent(mutatePayloadBeforeSave(newValue), getTitleData<T>(title, path))) {
			setDirtyValue(newValue);
			dirtyValueRef.current = newValue;
		} else if (
			deepEqualityWithOrWithoutInheritance(mutatePayloadBeforeSave(trimValue(newValue)), getTitleData<T>(title, path))
		) {
			setDirtyValue(undefined);
			dirtyValueRef.current = undefined;
		}
		if (_commit) {
			inputSubject.current.next(newValue);
			timerTrigger.next();
		}
	};

	// Save changes to the server with debounce and blur streams
	useEffect(() => {
		const debounce$ = inputSubject.current;
		const commit$ = commitSubject.current;

		const merged$ = debounce$
			.pipe(
				switchMap((_value) => merge(commit$, timerObs).pipe(map(() => _value))),
				map((nextValue) => filterInvalidRow<T, RowType>(nextValue, isRowValid)),
				mergeScan((previousValue, nextValue) => {
					const shouldEmit = shouldDistinctUntilChanged
						? !deepEqualityWithOrWithoutInheritance(lastEmittedValueRef.current, nextValue)
						: true;
					if (shouldEmit) {
						lastEmittedValueRef.current = nextValue;
						return of(nextValue);
					}
					return EMPTY;
				}, getTitleData<T>(title, path)),
				map((e) => (mutatePayloadBeforeSave ? mutatePayloadBeforeSave(e) : e)),
				map(transformEmptyStrings),
			)
			.subscribe((v) => {
				act({
					// @ts-ignore
					save: () => save(v),
					current: valueRef.current,
					onDenied: () => {
						setDirtyValue(undefined);
						dirtyValueRef.current = undefined;
						lastEmittedValueRef.current = undefined;
					},
					title: titleDataRef.current,
					path,
				});
			});

		return () => merged$.unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path]);

	return {
		value: dirtyValue || value,
		readOnlyValue: getMutatedTitleData(title, path),
		setValue: handleInputChange,
		commit,
		error,
		hasInvalidRow: useMemo(
			() => (isRowValid ? hasInvalidRow(dirtyValue, isRowValid) : undefined),
			[dirtyValue, isRowValid],
		),
	};
}

// eslint-disable-next-line import/no-default-export
export default useTitleAutoSave;
