import { Observable } from 'rxjs';
import { Client, Middleware } from 'openapi-fetch';
import { QueryObserver, QueryObserverOptions } from '@tanstack/react-query';
import { QueryObserverResult } from '@warehouse/shared/core';
import { queryClient } from './query-client';

export function createOpenApiAdapter<Paths extends {}>(client: Client<Paths, `${string}/${string}`>) {
	let tokenGetter: (() => Promise<string | null>) | null = null;

	const middleWare: Middleware = {
		async onRequest({ request }) {
			if (tokenGetter === null) throw new Error('Token getter unset');
			const token = await tokenGetter();
			if (token === null) throw new Error('An error happened during authentication');
			request.headers.set('Authorization', `Bearer ${token}`);
			return request;
		},
	};

	client.use(middleWare);

	return {
		client,
		setTokenGetter(_token: (() => Promise<string | null>) | null) {
			tokenGetter = _token;
		},
		watchObservableQuery<TData>(options: QueryObserverOptions<TData>) {
			return new Observable<QueryObserverResult<TData, Error>>((observer) => {
				const queryObserver = new QueryObserver<TData>(queryClient, {
					...options,
				});

				return queryObserver.subscribe((result) => {
					observer.next(result);
				});
			});
		},
	};
}
