import React from 'react';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { PaginationNavigatorCore } from '@warehouse/shared/ui';

export function ObjectBrowserPaginationNavigator({ centered = false }: PaginationNavigatorProps) {
	const actions = useObjectBrowserStore(objectBrowserStoreSelector.actions);
	const page = useObjectBrowserStore(objectBrowserStoreSelector.page);
	const totalPages = useObjectBrowserStore(objectBrowserStoreSelector.totalPages);
	const perPage = useObjectBrowserStore(objectBrowserStoreSelector.perPage);

	return (
		<PaginationNavigatorCore
			centered={centered}
			actions={actions}
			page={page}
			totalPages={totalPages}
			perPage={perPage}
		/>
	);
}

interface PaginationNavigatorProps {
	centered?: boolean;
}
