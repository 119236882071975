import React, { ImgHTMLAttributes, useEffect, useState } from 'react';

interface SmoothImageProps extends Partial<ImgHTMLAttributes<HTMLImageElement>> {
	transitionTime?: number;
}

function SmoothImage({ transitionTime, alt, src, onLoad, style, ...props }: SmoothImageProps) {
	const [isLoading, setIsLoading] = useState(true);
	const animationTime = transitionTime ?? 200;

	useEffect(() => {
		if (src !== undefined && src !== '') setIsLoading(true);
	}, [src]);

	const handleLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
		setIsLoading(false);
		if (onLoad) onLoad(event);
	};

	if (!src) return null;

	return (
		<img
			alt={alt ?? 'An image from the application'}
			style={{
				transition: `opacity ${animationTime}ms`,
				opacity: isLoading ? 0 : 1,
				...style,
			}}
			onLoad={handleLoad}
			onError={() => setIsLoading(false)}
			src={src}
			{...props}
		/>
	);
}

export default SmoothImage;
