import React, { useContext } from 'react';
import { CellContext } from '@tanstack/react-table';
import { TitleAlternate } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { BottomActionClear, DropdownV2 } from '@warehouse/shared/ui';
import { CellWrapper } from '../../../../../src/components/library/SimpleTable/style';
import { AlternateTitleTypePicklistContext } from './AlternateTitleContext';

function TypeCell(info: CellContext<TitleAlternate, TitleAlternate>) {
	const { onNewOptionAdded, options } = useContext(AlternateTitleTypePicklistContext);
	const { getValue, table, row } = info;
	const setValue = table.options?.meta?.customPropsCell?.setValue;
	const commit = table.options?.meta?.customPropsCell?.commit;
	const isInherited = table.options?.meta?.customPropsCell?.isInherited;

	const editType = (value: string | undefined) => (prev: OptionalInherited<TitleAlternate[]>) => ({
		...prev,
		displayValue: prev.displayValue?.map((ta, index) => {
			if (index === row.index) {
				return {
					...ta,
					type: value,
				};
			}
			return ta;
		}),
	});

	return (
		<CellWrapper>
			<DropdownV2
				withSearch
				variant={isInherited ? 'prefilled' : 'default'}
				pickList
				bottomActions={
					<BottomActionClear
						action={() => {
							setValue(editType(undefined));
							commit();
						}}
					/>
				}
				value={getValue()?.type ?? null}
				onChange={(e) => {
					setValue(editType(e));
					commit();
				}}
				transparentBorder
				backgroundColor="transparent"
				options={options || []}
				onNewOptionAdded={(newOption) => {
					onNewOptionAdded(newOption);
				}}
			/>
		</CellWrapper>
	);
}

// eslint-disable-next-line import/no-default-export
export default TypeCell;
