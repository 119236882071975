import React from 'react';
import { Parent, TitleFull } from '@warehouse/title/core';
import { ParentRelationshipEditor } from './ParentRelationshipEditor';
import useTitleAutoSave from '../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import { fromBasic } from '../../../src/utils/titleGetProperty';
import useMemoJsonPath from '../../../src/views/title/hooks/useMemoJsonPath';
import {
	updateSequenceNumberInParents,
	updateDistributionNumberInParents,
	updateHouseSequenceInParents,
	adapt,
	updatePrimaryInParents,
	updateParentDelete,
	updateRelationshipSubtypeInParents,
} from './parent-relationship.adapter';
import { LoaderWrapper } from '../../../src/components/library/LoaderWrapper';

export interface ParentRelationshipEditorAdapterProps {
	parentUuid: string;
	relationshipTypeId: string;
	childData: TitleFull;
	parentData: TitleFull;
	parentRefetch: () => void;
	onClose: () => void;
}

export function ParentRelationshipEditorAdapter({
	parentUuid,
	relationshipTypeId,
	childData,
	parentData,
	parentRefetch,
	onClose,
}: ParentRelationshipEditorAdapterProps) {
	const {
		value: parents,
		setValue: setParents,
		commit,
	} = useTitleAutoSave<Parent[]>({
		path: useMemoJsonPath(fromBasic(['parents'])),
		label: 'Relationship',
		isInheritable: false,
		onSaved() {
			parentRefetch();
		},
	});

	// UseTitleAutoSave can return undefined on the first render
	if (!parents) {
		return (
			<div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<LoaderWrapper loading opacity={100} />
			</div>
		);
	}

	const {
		childTitle,
		parentTitle,
		relationshipType,
		relationshipTypeLabel,
		relationshipSubtype,
		sequenceInfo,
		sequenceInfoPlaceholders,
		parent,
	} = adapt({
		parentUuid,
		parents,
		relationshipTypeId,
		childData,
		parentData,
	});

	const updateRelationshipSubtype = (nextRelationshipSubtype: string | undefined) => {
		setParents(updateRelationshipSubtypeInParents(nextRelationshipSubtype, parent, parents));
	};

	const updateSequenceNumber = (sequenceNumber: number | undefined) => {
		setParents(updateSequenceNumberInParents(sequenceNumber, parent, parents));
	};

	const updateDistributionNumber = (distributionNumber: string | undefined) => {
		setParents(updateDistributionNumberInParents(distributionNumber, parent, parents));
	};

	const updateHouseSequence = (houseSequence: string | undefined) => {
		setParents(updateHouseSequenceInParents(houseSequence, parent, parents));
	};

	const updatePrimary = (nextPrimary: boolean) => {
		setParents(updatePrimaryInParents({ primary: nextPrimary, parent, parents }));
	};

	const deleteChild = () => {
		setParents(updateParentDelete(parentUuid, parents));
		commit();
		onClose();
	};

	return (
		<ParentRelationshipEditor
			childTitle={childTitle}
			parentTitle={parentTitle}
			relationshipType={relationshipType}
			relationshipTypeLabel={relationshipTypeLabel}
			relationshipSubtype={relationshipSubtype}
			isPrimaryRelationship={parent.primary ?? false}
			sequenceInfo={sequenceInfo}
			sequenceInfoPlaceholders={sequenceInfoPlaceholders}
			primary={parent.primary}
			updateRelationshipSubtype={updateRelationshipSubtype}
			updateSequenceNumber={updateSequenceNumber}
			updateDistributionNumber={updateDistributionNumber}
			updateHouseSequence={updateHouseSequence}
			updatePrimary={updatePrimary}
			deleteChild={deleteChild}
			commitChanges={commit}
		/>
	);
}
