import { featureFlagSingleton } from '@warehouse/shared/infra';

export interface FeatureConfig {
	'top-bar-object-browser-button': boolean;
	'top-bar-profiles-button': boolean;
	'top-bar-editor-button': boolean;
	'title-tracks-tab': boolean;
	'title-artworks-tab': boolean;
	'title-orders-tab': boolean;
	'title-activity-tabs': boolean;
	'title-report-export-button': boolean;
	'title-settings-export-mmc-xml-button': boolean;
}

export type FeatureId =
	| 'top-bar-object-browser-button'
	| 'top-bar-profiles-button'
	| 'top-bar-editor-button'
	| 'title-tracks-tab'
	| 'title-artworks-tab'
	| 'title-orders-tab'
	| 'title-activity-tabs'
	| 'title-report-export-button'
	| 'title-settings-export-mmc-xml-button';

export function isFeatureEnabledForCurrentTenant(featureId: FeatureId) {
	const config = featureFlagSingleton.get().getConfig();
	return config[featureId];
}
