import React, { useContext, useMemo } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import CompanyDisplayCreditCard from './CompanyDisplayCreditCard';
import { CompanyDisplayCreditContext } from '../providers/companyDisplayCreditsProvider';
import Button from '../../../../../../components/library/Button';
import CollapsableCard from '../../../../../../components/titles/CollapsableCard';
import getDefaultLocale from '../../../../utils/getDefaultLocale';

export default function CompanyDisplayCreditList() {
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const companyDisplayCreditContext = useContext(CompanyDisplayCreditContext);

	const defaultLocale = useMemo(() => getDefaultLocale(title), [title]);

	const addCdc = () => {
		companyDisplayCreditContext.addCompanyDisplayCredit({
			displayStrings: [
				{
					displayString: '',
					language: defaultLocale,
				},
			],
		});
	};

	return (
		<CollapsableCard label="Company Display Credits">
			{editMode &&
				companyDisplayCreditContext.data?.displayValue?.map((cdc, index) => (
					<CompanyDisplayCreditCard defaultLocale={defaultLocale} index={index} key={cdc.__uuid} />
				))}
			{!editMode &&
				companyDisplayCreditContext.readonlyData?.displayValue?.map((cdc, index) => (
					<CompanyDisplayCreditCard defaultLocale={defaultLocale} index={index} key={cdc.__uuid} />
				))}
			{editMode && (
				<Button onClick={addCdc} nxstyle="secondary-black" startIcon={<Icon path={mdiPlus} size={1} />}>
					Add Company
				</Button>
			)}
		</CollapsableCard>
	);
}
