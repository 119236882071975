import React, { CSSProperties, memo } from 'react';
import { useTheme } from '@mui/material';
import { MenuItem } from './styles';
import { DropdownOption } from './types';
import { getOption } from '../DropdownUtils';

const Row = memo(
	({
		data,
		index,
		style,
	}: {
		data: {
			highlightedIndex: number;
			isSearchBackend?: boolean;
			searchValue: string;
			handleSelect: (value: string | null) => void;
			options: DropdownOption[];
			filteredOptions: DropdownOption[];
			height?: number;
			defaultOptions?: DropdownOption[];
			ariaLabel?: string;
		};
		index: number;
		style: CSSProperties;
	}) => {
		const theme = useTheme();
		const {
			highlightedIndex,
			searchValue,
			isSearchBackend = false,
			handleSelect,
			options,
			filteredOptions,
			defaultOptions,
			height,
			ariaLabel,
		} = data;

		const getMenuItem = (option: DropdownOption | undefined, withDivider = false) => {
			if (!option) return null;

			return (
				<MenuItem
					highlighted={highlightedIndex === index}
					tabIndex={0}
					key={option.value}
					onKeyDown={(e) => {
						if (e.code === 'Space' || e.code === 'Enter') handleSelect(option.value);
					}}
					data-testid={ariaLabel ? `${ariaLabel} Dropdown Option` : undefined}
					onClick={(e) => {
						e.stopPropagation();
						handleSelect(option.value);
					}}
					style={{
						...style,
						...(style.height ? { lineHeight: `${parseInt(style.height.toString(), 10) - 16}px` } : {}),
						...(withDivider
							? {
									// @ts-ignore
									borderBottom: `solid 1px ${theme.palette.light.backgroundAlt}`,
							  }
							: {}),
					}}
					height={height}
				>
					{option.label}
				</MenuItem>
			);
		};

		return getOption(getMenuItem, index, searchValue, options, defaultOptions, filteredOptions, isSearchBackend);
	},
);

export default Row;
