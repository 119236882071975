import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import { transformEmptyStrings } from '@warehouse/shared/util';
import TextInput from '../../../../components/library/TextInput';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface SortTitleFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function SortTitleField({ control, errors, setValue, trigger, isRequired = false, dryRanTitle }: SortTitleFieldProps) {
	const computedTitleSort = getTitleData<string>(
		dryRanTitle,
		fromBasic(['localizedInfos', '__default', 'titleSort', 'displayValue']),
	);
	const sortTitleTooltip = useTooltip('coreMetadata.basic.localizedInfos.titleSort');

	return useMemo(
		() => (
			<Controller
				control={control}
				name="sortTitle"
				render={({ field }) => (
					<TextInput
						placeholder={computedTitleSort}
						variant={computedTitleSort && !transformEmptyStrings(field.value) ? 'prefilled' : 'default'}
						tooltip={sortTitleTooltip?.tooltip}
						value={field.value}
						error={isRequired ? (errors?.sortTitle?.message as string) : ''}
						onChange={async (e) => {
							setValue('sortTitle', e.target.value);
							if (isRequired && !computedTitleSort) {
								await trigger('sortTitle');
							}
						}}
						label={getLabelWithRequired('Sort Title', isRequired)}
					/>
				)}
			/>
		),
		[control, computedTitleSort, isRequired, errors?.title?.message, setValue, trigger],
	);
}

export default SortTitleField;
