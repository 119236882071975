import { assertUnreachable } from '@warehouse/shared/util';
import { ColumnName } from '../../feature-object-browser/columns';
import { components } from '../../infra/inventory.openapi';

export type TableVisibility = {
	[key in ColumnName]?: boolean;
};

type BackendColumnName = keyof components['schemas']['NxObjectFile'] | keyof components['schemas']['NxObjectFolder'];

export class TableVisibilityUtils {
	static toKeysToSearch(tableVisibility: TableVisibility): BackendColumnName[] {
		return (Object.keys(tableVisibility) as ColumnName[])
			.filter((key) => tableVisibility[key])
			.map((visibleColumn) => {
				switch (visibleColumn) {
					case 'bucket':
						return 'bucket';
					case 'name':
						return 'name';
					case 'itemType':
						return 'itemType';
					case 'lastModifiedAt':
						return 'lastModifiedAt';
					case 'storageTier':
						return 'storageTier';
					case 'eTag':
						return 'eTag';
					case 'online':
						return 'online';
					case 'fileSize':
						return 'fileSize';
					case 'key':
						return 'key';
					case 'uuid':
						return 'uuid';
					case 'uploadedByOrganization':
						return 'uploadedByOrganization';
					case 'uploadedByUser':
						return 'uploadedByUser';
					case 's3Uri':
						return 's3Uri';
					default:
						assertUnreachable(visibleColumn, `Invalid column: ${visibleColumn}`);
						return visibleColumn;
				}
			})
			.filter((key): key is ColumnName => key !== undefined);
	}
}
