import React, { FC, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
	DataGridPro,
	DataGridProProps,
	GridColDef,
	gridColumnsStateSelector,
	GridSortModel,
	gridSortModelSelector,
	useGridApiRef,
} from '@mui/x-data-grid-pro';
import { NxObject } from '@warehouse/object-browser/core';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { objectBrowserTableColumns } from '../columns';
import { CustomColumnMenu } from './CustomColumnMenu';
import { ObjectBrowserPaginationNavigator } from './ObjectBrowserPaginationNavigator';
import {
	gridFilterModelToMuixFilterModel,
	muixFilterModelToGridFilterModel,
	muixGridVisibilityModelToTableVisibility,
	muixPinnedToTablePinned,
} from '../../../shared/ui/table/muix.adapter';
import { CustomGridCell } from './custom-grid-cell/CustomGridCell';

export function Table({ disableVirtualization }: TableProps) {
	const filter = useObjectBrowserStore(objectBrowserStoreSelector.filter);
	const sort = useObjectBrowserStore(objectBrowserStoreSelector.sort);
	const visibility = useObjectBrowserStore(objectBrowserStoreSelector.visibility);
	const pinned = useObjectBrowserStore(objectBrowserStoreSelector.pinned);
	const order = useObjectBrowserStore(objectBrowserStoreSelector.order);
	const items = useObjectBrowserStore(objectBrowserStoreSelector.items);
	const isPending = useObjectBrowserStore(objectBrowserStoreSelector.isPending);
	const isInSearchMode = useObjectBrowserStore(objectBrowserStoreSelector.isInSearchMode);
	const selectedItem = useObjectBrowserStore(objectBrowserStoreSelector.selectedItem);
	const { setOrder, setSort, setPinned, setFilter, setVisibility, selectItem } = useObjectBrowserStore(
		objectBrowserStoreSelector.actions,
	);

	const apiRef = useGridApiRef();

	const pagination = useCallback(() => <ObjectBrowserPaginationNavigator centered />, []);

	const gridColumnsState = apiRef.current.state ? gridColumnsStateSelector(apiRef.current.state) : undefined;

	/**
	 * This use-effect synchronizes the field order in the store and the internal field order maintained by the table.
	 * There is a known issue where there is small lag (~500ms) between the moment the reordering (through dnd or pinning)
	 * takes place and the moment the useEffect fires. We consider this an acceptable tradeoff. Push back against
	 * any request to "bugfix" this.
	 */
	useEffect(() => {
		if (gridColumnsState) {
			setOrder(gridColumnsState.orderedFields);
		}
	}, [gridColumnsState, gridColumnsState?.orderedFields, setOrder]);

	/**
	 * It runs code when entering or exiting search mode
	 */
	useEffect(() => {
		if (!apiRef) return;
		const currentSortModel = gridSortModelSelector(apiRef);

		if (isInSearchMode) {
			// Side effect when entering search mode
			apiRef.current.setSortModel(replaceNameByKey(currentSortModel));
			apiRef.current.setColumnVisibility('bucket', true);
		} else {
			// Side effect when exiting search mode
			apiRef.current.setSortModel(replaceKeyByName(currentSortModel));
			apiRef.current.setColumnVisibility('bucket', false);
		}
	}, [apiRef, isInSearchMode]);

	return (
		<StyledDataGridPro<FC<DataGridProProps<NxObject>>>
			$enableDrillableStyleOnFolders={!isInSearchMode}
			apiRef={apiRef}
			columns={objectBrowserTableColumns}
			rows={items ?? []}
			initialState={{
				pinnedColumns: {
					left: [...(pinned.left || [])],
				},
				sorting: {
					sortModel: sort,
				},
				columns: {
					columnVisibilityModel: visibility,
					orderedFields: order,
				},
				filter: {
					filterModel: gridFilterModelToMuixFilterModel(filter),
				},
			}}
			getRowId={(row) => row.uuid}
			loading={isPending}
			slotProps={{
				loadingOverlay: {
					variant: 'skeleton',
					noRowsVariant: 'skeleton',
				},
				// This prevents the itemType column from showing up in the columns visibility menu
				columnsManagement: {
					getTogglableColumns: (columns: GridColDef[]) =>
						columns.filter((column) => column.field !== 'itemType').map((column) => column.field),
				},
			}}
			onRowClick={({ row }) => selectItem(row)}
			rowSelection={false}
			sortingMode="server"
			onSortModelChange={setSort}
			filterMode="server"
			onFilterModelChange={(filterModel) => {
				setFilter(muixFilterModelToGridFilterModel(filterModel));
			}}
			onColumnVisibilityModelChange={(visibilityModel) => {
				setVisibility(muixGridVisibilityModelToTableVisibility(visibilityModel));
			}}
			onPinnedColumnsChange={(arg) => {
				setPinned(muixPinnedToTablePinned(arg));
			}}
			getRowClassName={({ row }) => `${row.itemType} ${selectedItem && row.key === selectedItem.key ? 'selected' : ''}`}
			slots={{
				pagination,
				columnMenu: CustomColumnMenu,
				cell: CustomGridCell,
			}}
			pagination
			disableVirtualization={disableVirtualization}
		/>
	);
}

export interface TableProps {
	/**
	 * @deprecated The method should only be used for component tests
	 */
	disableVirtualization?: boolean;
}

function replaceNameByKey(sortModel: GridSortModel) {
	return sortModel.map((sort) => {
		if (sort.field === 'name') {
			return { ...sort, field: 'key' };
		}
		return sort;
	});
}

function replaceKeyByName(sortModel: GridSortModel) {
	return sortModel.map((sort) => {
		if (sort.field === 'key') {
			return { ...sort, field: 'name' };
		}
		return sort;
	});
}

const StyledDataGridPro = styled(DataGridPro)<{ $enableDrillableStyleOnFolders: boolean }>(
	({ theme, $enableDrillableStyleOnFolders }) => css`
		border: none !important;
		.MuiDataGrid-virtualScrollerContent,
		.MuiDataGrid-overlay {
			background-color: ${theme.palette.light.background};
		}

		.MuiDataGrid-root {
			border-radius: 0 !important;
		}

		.MuiDataGrid-row:nth-child(odd) .MuiDataGrid-cell {
			background-color: ${theme.palette.white.main};
		}

		.MuiDataGrid-row:nth-child(even) .MuiDataGrid-cell {
			background-color: ${theme.palette.light.background};
		}

		.MuiDataGrid-filler {
			background-color: ${theme.palette.light.background};
		}

		// Display headers and odd rows gray
		.MuiDataGrid-columnHeader {
			background-color: ${theme.palette.light.background};
		}

		// Set hovering cell styling
		.MuiDataGrid-cell[data-field='name'] {
			border: 1px solid transparent;
		}

		// Set the border on the name cell when hovering over a folder, or when the row is selected
		${$enableDrillableStyleOnFolders &&
		css`
			.MuiDataGrid-row.folder:hover .MuiDataGrid-cell[data-field='name'] {
				border: 1px solid ${theme.palette.blue.main};
				border-radius: 8px;
				cursor: pointer;
				outline: none;
			}
		`}

		.MuiDataGrid-row {
			cursor: pointer;
		}

		// Set blue background on row hover or on selected row
		.MuiDataGrid-row:hover,
		.MuiDataGrid-row:hover .MuiDataGrid-cell,
		.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft,
		.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight,
		.selected,
		.selected .MuiDataGrid-cell,
		.selected .MuiDataGrid-cell--pinnedLeft,
		.selected .MuiDataGrid-cell--pinnedRight {
			background-color: ${theme.palette.blue.background} !important;
		}

		// Set pinned divider color
		.MuiDataGrid-columnHeader--withRightBorder,
		.MuiDataGrid-row .MuiDataGrid-cell--withRightBorder {
			border-right-color: ${theme.palette.action.divider};
		}

		// Hide focused cell styling
		.MuiDataGrid-columnHeader:focus,
		.MuiDataGrid-cell:focus {
			outline: none;
		}
	`,
);
