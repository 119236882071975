import React, { ReactNode, useMemo } from 'react';
import { PersistQueryParamsConfig } from '@warehouse/shared/util';
import { TitleEditorStoreProviderInternal, TitleEditorStore } from './title-editor.store';

export function TitleEditorStoreProvider({ children }: { children: ReactNode }) {
	const config = useMemo(
		(): PersistQueryParamsConfig<TitleEditorStore> => ({
			editMode: {
				type: 'boolean',
				keyName: 'edit',
				defaultValue: false,
			},
		}),
		[],
	);

	return <TitleEditorStoreProviderInternal config={config}>{children}</TitleEditorStoreProviderInternal>;
}
