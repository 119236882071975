import { PlatformSpecService } from '@warehouse/global-entities/core';
import { WorkType } from '@warehouse/title/worktype';
import { CreateTitleV2, CreateTitleV2SequenceInfo, IndexedTitleV2 } from '@warehouse/graphql';
import { isEpisodeOf, isSeasonOf, TitleFull } from '@warehouse/title/core';
import * as _ from 'lodash/fp';
import { workTypeFromLabel } from './worktype';
import { secondsToIBMDurationFormat } from '../../../src/components/library/TimeInput';
import availableWorkTypeDetailsByWorkType from '../../../src/assets/json-administration-profiles/availableWorkTypeDetailsByWorkType.json';
import { getNameByUuid } from '../../../src/utils/hooks/titles/useWorkTypes';
import { dateToString, stringToDate } from '../../../src/components/library/DatePicker/DatePicker';
import { CreateTitleInput } from './CreateTitleInput';
import { CountryOfOriginRegion, unflattenRegions } from '../core/models/region';
import { addCreativeInterpretations } from '../core/countries-of-origin';

export function createTitleInputToInfraInput({
	data,
	workType,
	forcedRelationshipType,
}: CreateTitleInput): CreateTitleV2 | undefined {
	const workTypeName = getNameByUuid(workType);
	const workTypeDetails =
		availableWorkTypeDetailsByWorkType[getNameByUuid(workType) as keyof typeof availableWorkTypeDetailsByWorkType];

	const getWorkTypeDetails = (_workTypeName: string) => {
		switch (_workTypeName) {
			case 'Series':
			case 'Season':
				return [workTypeDetails?.availableWorkTypeDetails[0]?.value];
			case 'Manifestation':
				return [];
			default:
				return [valueGetters.workTypeDetail(data)];
		}
	};

	const getTitleDisplayUnlimited = () => {
		if (workTypeName === 'Episode') return valueGetters.title(data) || `Episode ${valueGetters.sequenceNumber(data)}`;
		if (workTypeName === 'Season') return valueGetters.title(data) || `Season ${valueGetters.sequenceNumber(data)}`;
		return valueGetters.title(data);
	};

	const getRelationshipType = () => {
		if (forcedRelationshipType) return forcedRelationshipType;
		return workTypeName === 'Supplemental'
			? valueGetters.relationshipType(data)
			: getRelationshipTypePerWorkTypeName(workTypeName || '');
	};

	const getLocalizedInfos = () => {
		if (workTypeName === 'Edit') {
			if (data.parentTitle?.title?.uuid) return [];

			return [
				{
					default: true,
					language: '8fa1f157-131a-4461-93fa-acf6ed5a18f5',
					titleDisplayUnlimited: safeGetTitleDisplayUnlimited(data?.parentTitle?.title),
				},
			];
		}
		if (valueGetters.titleLanguage(data) === '') return [];
		return [
			{
				default: true,
				language: valueGetters.titleLanguage(data),
				titleSort: valueGetters.titleSort(data),
				titleDisplayUnlimited: getTitleDisplayUnlimited(),
			},
		];
	};

	const relationshipType = getRelationshipType();
	const base: CreateTitleV2 = {
		metadata: {
			coreMetadata: {
				basic: {
					...fillIfValueIsDefined(
						{
							parents: [
								{
									// TODO: Remove the casting when the relationship type is properly typed
									primary: valueGetters.parentPrimary(data, relationshipType as string),
									parentContentId: valueGetters.parentContentID(data),
									relationshipType,
								},
							],
						},
						valueGetters.parentContentID(data),
					),
					terms: {
						titleStatus: undefined,
					},
					countriesOfOrigin: valueGetters.countriesOfOrigin(data),
					runLength: valueGetters.runLength(data),
					releaseYear:
						valueGetters.releaseYear(data) || stringToDate(valueGetters.releaseDate(data) as string).getFullYear(),
					releaseDate: valueGetters.releaseDate(data),
					workType,
					workTypeDetails: getWorkTypeDetails(workTypeName || ''),
					originalLanguages: workTypeName !== 'Edit' ? valueGetters.originalLanguages(data) : undefined,
					localizedInfos: getLocalizedInfos(),
				},
			},
		},
	};

	if (valueGetters.licensor(data)) {
		base.metadata.coreMetadata.basic.associatedOrgs = [
			{
				displayName: valueGetters.licensor(data),
				role: 'licensor',
			},
		];
	}

	if (workTypeName === 'Season' && valueGetters.madeForRegions(data)) {
		base.metadata.coreMetadata.basic.versionIntent = {
			[getMadeForRegionsKey(valueGetters.isIncludeMadeForRegions(data))]: valueGetters.madeForRegions(data),
		};
	}

	if (workTypeName === 'Edit') {
		base.metadata.coreMetadata.basic.versionIntent = {
			editUse: valueGetters.editUse(data),
			editClasses: valueGetters.editClass(data),
			[getMadeForRegionsKey(valueGetters.isIncludeMadeForRegions(data))]: valueGetters.madeForRegions(data),
		};
		base.metadata.coreMetadata.basic.versionLanguages = valueGetters.versionLanguages(data);
		base.metadata.coreMetadata.basic.countriesOfOrigin = undefined;
		base.metadata.coreMetadata.basic.workTypeDetails = undefined;
		base.metadata.coreMetadata.basic.releaseYear = undefined;
	}

	if (workTypeName === 'Manifestation' && data.platformSpecification && data.alid) {
		const platformSpec = PlatformSpecService.getPlatformSpecification(valueGetters.platformSpecification(data));
		if (!platformSpec) throw new Error(`Cannot find platform specification "${data.platformSpecification}"`)
		base.metadata.coreMetadata.basic.versionIntent = {
			[getMadeForRegionsKey(valueGetters.isIncludeMadeForRegions(data))]: valueGetters.madeForRegions(data),
			audience: {
				identifications: [{
					identifier: valueGetters.alid(data),
					namespace: platformSpec.audienceIdentification.namespace,
					location: platformSpec.audienceIdentification.location,
					scope: {
						scope: platformSpec.audienceIdentification.scope,
						subscope: platformSpec.audienceIdentification.subscope,
					},
				}]
			}
		}
	}

	if (workTypeName && ['Episode', 'Season'].includes(workTypeName)) {
		if (base.metadata.coreMetadata.basic.parents) {
			base.metadata.coreMetadata.basic.parents[0].sequenceInfo = {
				number: valueGetters.sequenceNumber(data),
				...(valueGetters.distributionNumber(data)
					? {
							distributionNumber: {
								distributionNumber: valueGetters.distributionNumber(data)?.toString(),
								domain: 'nexspec.com/warehouse',
							},
					  }
					: {}),
				...(valueGetters.houseSequence(data)
					? {
							houseSequence: {
								houseSequence: valueGetters.houseSequence(data)?.toString(),
								domain: 'nexspec.com/warehouse',
							},
					  }
					: {}),
				// TODO: Remove the casting when the sequence info is properly typed
			} as CreateTitleV2SequenceInfo;
		}

		base.metadata.coreMetadata.basic.sequenceInfo = {
			number: valueGetters.sequenceNumber(data),
			...(valueGetters.distributionNumber(data)
				? {
						distributionNumber: {
							distributionNumber: valueGetters.distributionNumber(data)?.toString(),
							domain: 'nexspec.com/warehouse',
						},
				  }
				: {}),
			...(valueGetters.houseSequence(data)
				? {
						houseSequence: {
							houseSequence: valueGetters.houseSequence(data)?.toString(),
							domain: 'nexspec.com/warehouse',
						},
				  }
				: {}),
		} as CreateTitleV2SequenceInfo;
	}

	return base;
}

function getMadeForRegionsKey(isInclude?: boolean): 'madeForRegions' | 'madeForRegionsExclude' {
	return isInclude ? 'madeForRegions' : 'madeForRegionsExclude';
}

function fillIfValueIsDefined(obj: any, optionalValue: any) {
	if (optionalValue) return obj;
	return {};
}

const valueGetters = {
	alid: (data) => {
		if (!data.alid) throw new Error('ALID is not defined');
		return data.alid;
	},
	platformSpecification: (data) => {
		if (!data.platformSpecification) throw new Error('Platform specification is not defined');
		return data.platformSpecification;
	},
	releaseDate: (data) =>
		data.releaseDate ? dateToString(new Date(data.releaseDate)) : undefined,
	releaseYear: (data) => data.releaseDate?.getFullYear(),
	titleLanguage: (data) => data.titleLanguage,
	titleSort: (data) => data.sortTitle || undefined,
	sequenceNumber: (data) => {
		if (!data.sequenceNumber) return undefined;
		const parsedInt = parseInt(data.sequenceNumber, 10);
		if (data.sequenceNumber && !Number.isNaN(parsedInt)) {
			return parsedInt;
		}
		return undefined;
	},
	distributionNumber: (data) => data.distributionNumber,
	houseSequence: (data) => data.houseSequence,
	parentPrimary: (data, relationshipType: string) => {
		if ([isEpisodeOf(), isSeasonOf()].includes(relationshipType)) {
			return true;
		}
		return data.parentTitle?.primary;
	},
	parentContentID: (data) => data.parentTitle?.title?.uuid || undefined,
	runLength: (data) =>
		data.approximateLength ? secondsToIBMDurationFormat(data.approximateLength) : undefined,
	countriesOfOrigin: (data) => {
		const creativeCountryUuids = data.countriesOfOrigin?.length ? data.countriesOfOrigin : [];
		const creativeCountries: CountryOfOriginRegion[] = _.pipe(unflattenRegions, addCreativeInterpretations)(creativeCountryUuids);

		const productionCountries = data.productionCountries?.length ? data.productionCountries : [];
		return [...creativeCountries, ...productionCountries];
	},
	originalLanguages: (data) =>
		data?.originalLanguages?.length
			? data.originalLanguages.map((item, index: number) => ({
					originalLanguage: item.originalLanguage,
					listingOrder: item.listingOrder || index + 1,
					spoken: item.spoken,
	written: item.written,
	signed: item.signed,
}))
: undefined,
	title: (data) => data.title || undefined,
	editUse: (data) => data.editUse || undefined,
	editClass: (data) => data.editClass || undefined,
	madeForRegions: (data) =>
	!data.madeForRegions?.madeForRegions?.length
		? undefined
		: unflattenRegions(data.madeForRegions.madeForRegions),
	isIncludeMadeForRegions: (data) => data.madeForRegions?.isInclude ?? true,
	licensor: (data) => data.licensor || undefined,
	workTypeDetail: (data) => data.workTypeDetail || undefined,
	versionLanguages: (data) => data.versionLanguages || undefined,
	relationshipType: (data) => data.relationshipType || undefined,
} satisfies Record<string, (data: CreateTitleInput['data'], ...args: any[]) => unknown>;

function safeGetTitleDisplayUnlimited(title: IndexedTitleV2 | TitleFull | undefined): string | undefined {
	if (!title) return undefined;
	if (!('titleDisplayUnlimited' in title)) return undefined;
	const { titleDisplayUnlimited } = title;
	if (!titleDisplayUnlimited) return undefined;
	return titleDisplayUnlimited;
}

export function getRelationshipTypePerWorkTypeName(_workTypeName: string) {
	if (_workTypeName.toLowerCase() === 'all') return null;
	const workType = workTypeFromLabel(_workTypeName);

	switch (workType) {
		case WorkType.Series:
		case WorkType.Movie:
		case WorkType.Compilation:
			return 'isdescendentof';
		case WorkType.Edit:
			return 'isderivedfrom';
		case WorkType.Manifestation:
			return 'isderivedfrom';
		case WorkType.Supplemental:
			return 'issupplementto';
		case WorkType.Episode:
			return 'isepisodeof';
		case WorkType.Season:
			return 'isseasonof';
		default:
			return null;
	}
}
