import { titlePaths } from '@warehouse/title/core';
import { TitleSingleDropdownField } from '@warehouse/title/ui';
import React from 'react';
import editUseJson from '../../../../src/assets/json-administration-profiles/editUse.json';

export function EditUseField() {
	return (
		<TitleSingleDropdownField
			canClear
			label="Edit Use:"
			path={titlePaths.editUse}
			jumpToParentButtonDisabled
			tooltipDisabled
			options={editUseJson}
			view="titleOverview"
		/>
	);
}
