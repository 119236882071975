import { BucketId, FolderPath } from '@warehouse/object-browser/core';

export enum TreeItemType {
	Bucket = 'BUCKET',
	Folder = 'FOLDER',
	Loading = 'LOADING',
}

export interface TreeItemLoading {
	id: string;
	type: TreeItemType.Loading;
}
export interface TreeItemFolder {
	id: string;
	key: string;
	type: TreeItemType.Folder;
	bucketId: BucketId;
	path: FolderPath;
	name: string;
	children: TreeItemChildren;
}
export interface TreeItemBucket {
	id: string;
	type: TreeItemType.Bucket;
	name: BucketId;
	children: TreeItemChildren;
}

export type TreeItemChildren = TreeItemFolder[] | [TreeItemLoading];
export type RootTreeItems = TreeItemBucket[] | [TreeItemLoading];

export type TreeItem = TreeItemBucket | TreeItemFolder | TreeItemLoading;
export type TreeItems = TreeItem[];
