import { gql } from '@apollo/client';

export const relationshipFragment = gql`
	fragment RelationshipFragment on SearchRelationship {
		contentId
		primary
		sequenceInfo {
			distributionNumber {
				distributionNumber
				domain
			}
			houseSequence {
				houseSequence
				domain
			}
			number
		}
		subtypes {
			label
			uuid
		}
		type
	}
`;
