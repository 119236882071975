import React, { forwardRef, Ref, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view-pro';
import NxLoader from '../../../../../src/components/library/NxLoader';

export interface CustomTreeItemBucketProps extends TreeItem2Props {}

export const CustomTreeItemLoading = forwardRef(({ ...props }: CustomTreeItemBucketProps, ref: Ref<HTMLLIElement>) => {
	const Icon = useCallback(() => <NxLoader size={14} />, []);

	return (
		<StyledTreeItem2
			{...props}
			ref={ref}
			slots={{
				icon: Icon,
			}}
		/>
	);
});

const StyledTreeItem2 = styled(TreeItem2)(
	() => css`
		& .Mui-disabled {
			opacity: 1;
		}
	`,
);
