import { useLocation, useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { AddChildrenPrompt, AddChildrenStoreProvider } from '@warehouse/title/feature-add-children';
import { TitleFull as Title } from '@warehouse/title/core';
import { editUuid, getRelationshipType, manifestationUuid } from '@warehouse/title/infra';
import { assertUnreachable } from '@warehouse/shared/util';
import compact from 'lodash/compact';
import { AutoButtonGroup, AutoButtonGroupOption } from '../../../../src/components/library/AutoButtonGroup';
import { CreateTitleModal } from '../../../../src/views/titles/createTitle';
import { ChildrenTabNameButtonId, ChildTabDefinition } from '../../../../src/views/title/hooks/useChildrenTabs';
import getDeepProperty from '../../../../src/utils/getDeepProperty';
import { fromBasic } from '../../../../src/utils/titleGetProperty';

interface CreateRelationshipsButtonProps {
	title: Title;
	tabProperties: ChildTabDefinition;
}

export function CreateRelationshipsButton({ title, tabProperties }: CreateRelationshipsButtonProps) {
	const [addChildrenModalIsOpen, setAddChildrenModalIsOpen] = useState<boolean>(false);
	const [createTitleModalIsOpen, setCreateTitleModalIsOpen] = useState<boolean>(false);
	const workTypeUuid = useMemo(() => getDeepProperty<string>(title, fromBasic(['workType'])), [title]);
	const navigate = useNavigate();
	const location = useLocation();

	const handleAddChildrenClick = (id: ChildrenTabNameButtonId) => {
		switch (id) {
			case 'add-single':
				setCreateTitleModalIsOpen(true);
				break;
			case 'add-multiple':
				navigate('/titles/create', {
					state: {
						selectedParentUuid: title.uuid,
						selectedTitleTypeUuid: tabProperties.childWorkTypeUuid,
					},
				});
				break;
			case 'add-existing':
				setAddChildrenModalIsOpen(true);
				break;
			case 'create-multiple-manifestations':
				navigate('/titles/create', {
					state: {
						selectedParentUuid: title.uuid,
						selectedTitleTypeUuid: manifestationUuid(),
					},
				});
				break;
			case 'create-multiple-edits':
				navigate('/titles/create', {
					state: {
						selectedParentUuid: title.uuid,
						selectedTitleTypeUuid: editUuid(),
					},
				});
				break;
			default:
				assertUnreachable(id, `Unhandled create relationship button id on children tab : ${id}`);
				break;
		}
	};

	const childButtonOptions = tabProperties.addChildButtonOptions;

	return (
		<>
			<AutoButtonGroup
				nxstyle="secondary-blue"
				onOptionClick={handleAddChildrenClick}
				storeKey={`title-add-children-${workTypeUuid}`}
				height={34}
				expandButtonAriaLabel="Expand Add Children Options"
				options={useMemo(
					() =>
						compact([
							childButtonOptions.includes('add-single') &&
								createButtonProps({ id: 'add-single', label: `Create Single ${tabProperties.singularLabel}` }),
							childButtonOptions.includes('add-multiple') &&
								createButtonProps({ id: 'add-multiple', label: `Create Multiple ${tabProperties.pluralLabel}` }),
							childButtonOptions.includes('add-existing') &&
								createButtonProps({ id: 'add-existing', label: `Add Existing ${tabProperties.pluralLabel}` }),
							childButtonOptions.includes('create-multiple-manifestations') &&
								createButtonProps({ id: 'create-multiple-manifestations', label: `Create Multiple Manifestations` }),
							childButtonOptions.includes('create-multiple-edits') &&
								createButtonProps({ id: 'create-multiple-edits', label: `Create Multiple Edits` }),
						]),
					[tabProperties, childButtonOptions],
				)}
			/>
			{workTypeUuid && title.uuid && (
				<AddChildrenStoreProvider>
					<AddChildrenPrompt
						titleUuid={title.uuid}
						open={addChildrenModalIsOpen}
						onClose={() => setAddChildrenModalIsOpen(false)}
						forceRelationshipType={getRelationshipType(tabProperties.relationshipType)}
						searchAndSelectedPluralLabel={tabProperties.pluralLabel}
						headerText={`Add Existing ${tabProperties.pluralLabel}`}
					/>
				</AddChildrenStoreProvider>
			)}
			<CreateTitleModal
				/**
				 * This is a hack to force the modal to re-render when the location changes
				 * This is necessary because the modal has a form with default values. Default values are set in the first mount of the modal
				 * according to the given props, but they are not updated when props change.
				 *
				 * When a user goes to Page 1, then Page 2, then Page 1 again, the modal will still have the default values from Page 2
				 * because the Page 1 was in the React router cache, so the component was not unmounted and remounted.
				 */
				key={location.pathname}
				open={createTitleModalIsOpen}
				closeOnCreate
				handleCancel={() => {
					setCreateTitleModalIsOpen(false);
				}}
				handleClose={() => setCreateTitleModalIsOpen(false)}
				selectedParentUuid={title.uuid || undefined}
				parentTitleProperties={tabProperties}
			/>
		</>
	);
}

function createButtonProps({
	label,
	id,
}: {
	label: string;
	id: ChildrenTabNameButtonId;
}): AutoButtonGroupOption<ChildrenTabNameButtonId> {
	return {
		id,
		icon: <Icon path={mdiPlus} size="18px" />,
		label,
	};
}
