import React, { useEffect } from 'react';
import { Result } from 'antd';
import styled, { css } from 'styled-components';
import { TitleOverviewLeftWrapperBase } from '@warehouse/legacy-title-overview';
import { ManifestationOverviewByAbstractTypeProps } from './types';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar'
			'unhandled';
		grid-template-columns: 100%;
		grid-template-rows: 80px 176px;
	`,
);

export function Unhandled({
	topBar,
	parentAbstractWorkType,
}: ManifestationOverviewByAbstractTypeProps & { parentAbstractWorkType?: string }) {
	useEffect(() => {
		console.error(
			`Trying to display an unhandled parent abstract work type "${parentAbstractWorkType}" in the manifestation title overview`,
		);
	}, [parentAbstractWorkType]);

	return (
		<TitleOverviewLeftWrapper>
			{topBar}
			<ResultWrapper>
				<StyledResult
					status="error"
					title="Rendering error"
					subTitle={`Unhandled abstract work type: "${parentAbstractWorkType}"`}
				/>
			</ResultWrapper>
		</TitleOverviewLeftWrapper>
	);
}

const ResultWrapper = styled.div(
	({ theme }) => css`
		border-right: 1px solid ${theme.palette.light.backgroundAlt};
		display: grid;
		grid-area: unhandled;
	`,
);
const StyledResult = styled(Result)`
	margin: auto;
	padding: 0;
	width: 100%;
`;
