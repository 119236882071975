import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { titlePaths } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import TitleEditableField from '../../../../../components/titles/TitleEditableField';
import { CustomColumnDef } from '../../../../../components/library/SimpleTable/customColumnDef';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import castAndCrewRoles from '../../../../../assets/json-administration-profiles/availableRoleType.json';
import { CastAndCrewContext } from './providers/castAndCrewProvider';
import { formatName } from './CastTable';
import { computeCrewErrors } from './computeTableErrors';
import useTooltip from '../../../../../utils/hooks/useTooltips';

interface CrewTableProps {
	localeUuid: string | null;
	defaultLocaleUuid?: string;
	onCrewClick?: (peopleIndex: number) => void;
	onAddCrew?: () => void;
}

const StyledDiv = styled.div(
	({ theme }) => css`
		.table-wrapper {
			margin-left: ${theme.spacing(0.75)};
			margin-right: ${theme.spacing(0.75)};
			width: auto !important;
		}
	`,
);

function getClassName(isInherited: boolean) {
	return isInherited ? 'inherited' : '';
}

function CrewTable({ localeUuid, defaultLocaleUuid, onCrewClick, onAddCrew }: CrewTableProps) {
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);
	const { crewData, isInherited } = useContext(CastAndCrewContext);
	const billingBlockOrderTooltip = useTooltip('coreMetadata.basic.people.jobs.billingBlockOrders.billingBlockOrder');
	const nameTooltip = useTooltip('coreMetadata.basic.people.name');
	const roleTooltip = useTooltip('coreMetadata.basic.people.jobs.jobFunction');

	const castAndCrewRolesMapped = useMemo<{
		[k: string]: typeof castAndCrewRoles[number];
	}>(() => castAndCrewRoles.reduce((acc, role) => ({ ...acc, [role.value]: role }), {}), [castAndCrewRoles]);

	const formattedCrewData = useMemo(
		() =>
			crewData?.map((crew) => ({
				...crew,
				name: formatName(crew.name, localeUuid, defaultLocaleUuid),
			})),
		[crewData, localeUuid],
	);

	const crewColumns: CustomColumnDef<any>[] = useMemo(
		() => [
			{
				id: 'billingBlockOrder',
				header: '#',
				tooltip: billingBlockOrderTooltip?.tooltip,
				accessorFn: (row) => row.billingBlockOrder,
				width: 25,
			},
			{
				id: 'name',
				header: 'Name',
				tooltip: nameTooltip?.tooltip,
				accessorFn: (row) => row.name,
				renderReadOnly: (value) =>
					value.label ? (
						<div style={{ marginLeft: '12px' }}>
							<Typography
								className={getClassName(isInherited)}
								fontWeight={400}
								fontSize="14px"
								color={value.default ? 'text.disabled' : 'text.primary'}
							>
								{value.label}
							</Typography>
						</div>
					) : null,
			},
			{
				id: 'role',
				header: 'Role',
				tooltip: roleTooltip?.tooltip,
				accessorFn: (row) => row.role,
				renderReadOnly: (v) => {
					const role = castAndCrewRolesMapped[v]?.label || v;
					const toDisplay = role ? role.charAt(0).toUpperCase() + role.slice(1) : '';
					return (
						<Typography className={getClassName(isInherited)} fontWeight={400} fontSize="14px">
							{toDisplay}
						</Typography>
					);
				},
			},
		],
		[isInherited],
	);

	return (
		<StyledDiv>
			<TitleEditableField
				label="Crew"
				path={titlePaths.people}
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper
				showBottomActionsBar={editMode}
				editMode={false}
				enableAddMultipleRows={false}
				addRowLabel="Add Crew"
				forceHover
				tableStyle="border"
				cantSelectRows
				errors={{ __rows: computeCrewErrors(crewData) }}
				columns={crewColumns}
				data={formattedCrewData || []}
				onClickRow={({ peopleIndex }) => {
					if (onCrewClick) onCrewClick(peopleIndex);
				}}
				onRowAdd={() => {
					if (onAddCrew) onAddCrew();
				}}
			/>
		</StyledDiv>
	);
}

export default CrewTable;
