import React, { ReactNode, useMemo } from 'react';
import { SearchProvider, PaginatorProvider, PaginatorStore, SearchStore } from '@warehouse/shared/ui';
import { PersistQueryParamsConfig } from '@warehouse/shared/util';

export function ChildrenListSearchStoreProvider({ children }: { children: ReactNode }) {
	const storeConfig = useMemo(
		(): PersistQueryParamsConfig<SearchStore> => ({
			searchValue: {
				type: 'string',
				keyName: 'search-children',
			},
		}),
		[],
	);

	return <SearchProvider config={storeConfig}>{children}</SearchProvider>;
}

export function ChildrenListPaginationStoreProvider({ children }: { children: ReactNode }) {
	const storeConfig = useMemo(
		(): PersistQueryParamsConfig<PaginatorStore> => ({
			page: {
				type: 'number',
				defaultValue: 1,
			},
			perPage: {
				type: 'number',
				defaultValue: 10,
				sessionStorageKeyPrefix: 'children-list',
			},
		}),
		[],
	);
	return <PaginatorProvider config={storeConfig}>{children}</PaginatorProvider>;
}
