import { TitleFull as Title, isDerivedFrom, isEpisodeOf, isSeasonOf, Parent } from '@warehouse/title/core';
import { editUuid, episodeUuid, manifestationUuid, seasonUuid } from '@warehouse/title/infra';

export function getGoToParentUuid(title: Title): string | undefined {
	const { workType } = title.metadata.coreMetadata.basic;

	if (![seasonUuid(), episodeUuid(), manifestationUuid(), editUuid()].includes(workType)) {
		throw new Error('Unsupported work type for getting parent UUID.');
	}

	switch (workType) {
		case episodeUuid():
			return getPrimaryParentByRelationshipType(title.metadata.coreMetadata.basic.parents, isEpisodeOf());
		case seasonUuid():
			return getPrimaryParentByRelationshipType(title.metadata.coreMetadata.basic.parents, isSeasonOf());
		case manifestationUuid():
		case editUuid():
			return getParentByRelationshipType(title.metadata.coreMetadata.basic.parents, isDerivedFrom());
		default:
			throw new Error('Unsupported work type for getting parent UUID.');
	}
}

function getPrimaryParentByRelationshipType(
	parents: Parent[] | undefined,
	relationshipType: string,
): string | undefined {
	const parent = parents?.find(
		(relationship) => relationship.relationshipType === relationshipType && relationship.primary === true,
	);

	return parent?.parentContentId;
}

function getParentByRelationshipType(parents: Parent[] | undefined, relationshipType: string): string | undefined {
	const parent = parents?.find((relationship) => relationship.relationshipType === relationshipType);

	return parent?.parentContentId;
}
