import { FilterStep, FilterWithDate } from '@warehouse/shared/filters';

export type CombinationOperator = 'AND' | 'OR';

export class SearchFilters {
	static buildSimpleStep(
		filters: (FilterWithDate | FilterStep)[],
		combinationOperator: CombinationOperator,
	): FilterStep {
		return {
			step: {
				combinationOperator,
				filters,
			},
		};
	}

	static buildNestedStep(
		nested: string,
		filters: FilterWithDate[],
		combinationOperator: CombinationOperator,
	): FilterStep {
		return {
			nested,
			step: {
				combinationOperator,
				filters,
			},
		};
	}
}
