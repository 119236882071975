import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircle, mdiCloseCircle, mdiPlus } from '@mdi/js';
import { DialogContent, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as Ajv from 'ajv';
import styled, { css } from 'styled-components';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// HOOKS
import { ErrorAlert, DropdownV2 } from '@warehouse/shared/ui';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { useTerritories } from '@warehouse/global-entities/core';
import useLocalizedInfosIntervals from './useLocalizedInfoIntervals';
import { useAddRecord } from '../../../../../utils/hooks/titles/useAddRecord';

// UTILS
import ajvResolver from '../../../../../utils/ajvResolver';
import sortAlphabeticallyByLabel from '../../../../../utils/sortAlphabeticallyByLabel';

// LIBRARY
import DialogTitle, { CloseButton } from '../../../../../components/library/DialogTitle';
import DialogActions from '../../../../../components/library/DialogActions';
import Button from '../../../../../components/library/Button';
import Dialog from '../../../../../components/library/Dialog';
import Dropdown from '../../../../../components/library/Dropdown/Dropdown';
import Alert from '../../../../../components/library/Alert';
import TextInput from '../../../../../components/library/TextInput';
import DatePicker, { dateToString, stringToDate } from '../../../../../components/library/DatePicker/DatePicker';
import Loader from '../../../../../components/library/Loader';
import IncludeExcludeBottomAction from '../../../../../components/titles/IncludeExcludeBottomAction';

// JSON
import languageWrittenJSON from '../../../../../assets/json-administration-profiles/availableLanguageWritten.json';
import { TerritoriesSelector } from '../../../../../components/titles/TerritoriesSelector';
import useTooltip from '../../../../../utils/hooks/useTooltips';

const EditIntervalWrapper = styled.div(
	({ theme }) => css`
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: ${theme.spacing(1)};
		padding: ${theme.spacing(2)};
		width: 100%;
	`,
);

const ContentWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		padding: ${theme.spacing(3)};
	`,
);

const Row = styled.div(
	({ theme }) => css`
		align-items: end;
		display: flex;
		gap: ${theme.spacing(2)};

		.MuiButton-root {
			height: 48px;
			margin-bottom: ${theme.spacing(1.75)};
			min-width: 220px !important;
		}
	`,
);

const AddButtonMessageWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 100%;
	`,
);

const AddButton = styled(Button)(
	() => css`
		border-top: 1px solid rgba(177, 188, 195, 0.45);
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		height: 48px;
		justify-content: flex-start;
		width: 100%;
	`,
);

const CustomAlert = styled(Alert)(
	({ theme }) => css`
		border-radius: 4px;
		height: 32px;
		margin: 0;
		padding: 0 12px 0 4px;

		svg {
			width: 20px;
			height: 20px;
		}

		.MuiAlert-icon {
			margin-right: 4px;
			position: relative;
			top: -2px;
		}

		.MuiAlert-message {
			font-size: ${theme.typography.buttonSmallMedium.fontSize};
			font-weight: ${theme.typography.buttonSmallMedium.fontWeight};
			line-height: ${theme.typography.buttonSmallMedium.lineHeight};
		}
	`,
);

interface NewLocalizedInfoModalProps {
	open: boolean;
	onClose: () => void;
	title: Title | undefined;
	onLocalizedInfoCreated: (uuid: string) => void;
}

// interpretation not required, pas de default

// @ts-ignore
const schema: Ajv.JSONSchemaType<any> = {
	type: 'object',
	properties: {
		locale: {
			type: 'string',
			minLength: 1,
			errorMessage: { minLength: 'The locale is required' },
		},
		interval: {
			type: 'object',
			oneOf: [
				{
					type: 'object',
					properties: {
						startDate: { type: 'string', format: 'date' },
						endDate: {
							type: 'string',
							format: 'date',
							formatMinimum: { $data: '1/startDate' },
							errorMessage: {
								formatMinimum: 'End date must be after start date',
							},
						},
						description: { type: 'string', minLength: 1 },
					},
					required: ['startDate', 'endDate', 'description'],
					additionalProperties: false,
				},
				{
					type: 'object',
					properties: {
						startDate: { type: 'null' },
						endDate: { type: 'null' },
						description: { type: 'string', const: '' },
					},
					required: ['startDate', 'endDate', 'description'],
					additionalProperties: false,
				},
			],
			errorMessage: {
				_: 'Invalid interval',
			},
		},
		regions: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					country: { type: 'string' },
					countryRegion: { type: 'string' },
				},
			},
		},
		regionsAreExcluded: {
			type: 'boolean',
		},
	},
	required: ['locale', 'interval'],
	additionalProperties: false,
};

type FormDataType = {
	locale: string;
	interval: {
		startDate: string | null;
		endDate: string | null;
		description: string;
	};
	regions: any[]; // TODO: type it
	regionsAreExcluded: boolean;
};

function getTargetAudiences(data: FormDataType) {
	if (!data.interval.endDate && !data.interval.startDate && !data.interval.description) {
		return undefined;
	}
	return [
		{
			description: data.interval.description,
			whens: [
				{
					endDate: data.interval.endDate,
					startDate: data.interval.startDate,
				},
			],
		},
	];
}

function NewLocalizedInfoModal({ title, open, onClose, onLocalizedInfoCreated }: NewLocalizedInfoModalProps) {
	const theme = useTheme();
	const [loading, setLoading] = useState<boolean>(false);
	const titleUuid = useTitleEditorStore(titleEditorStoreSelector.titleUuid);
	const [editIntervalMode, setEditIntervalMode] = useState<boolean>(false);
	const locales = languageWrittenJSON.sort(sortAlphabeticallyByLabel);
	const { isCountry } = useTerritories();
	const intervals = useLocalizedInfosIntervals(title);
	const [mutationError, setMutationError] = useState<any>(undefined);
	const [addRecord] = useAddRecord();
	const localeTooltip = useTooltip('coreMetadata.basic.localizedInfos.language');
	const regionsTooltip = useTooltip('coreMetadata.basic.localizedInfos.regions');
	const excludedRegionsTooltip = useTooltip('coreMetadata.basic.localizedInfos.excludedRegions');
	const intervalTooltip = useTooltip('coreMetadata.basic.localizedInfos.targetAudiences.audience.whens');
	const intervalLabelTooltip = useTooltip('coreMetadata.basic.localizedInfos.targetAudiences.audience.whens.when');
	const intervalStartDateTooltip = useTooltip(
		'coreMetadata.basic.localizedInfos.targetAudiences.audience.whens.startDate',
	);
	const intervalEndDateTooltip = useTooltip('coreMetadata.basic.localizedInfos.targetAudiences.audience.whens.endDate');

	const {
		handleSubmit,
		control,
		formState: { errors },
		setValue,
		watch,
		reset,
	} = useForm<FormDataType>({
		resolver: ajvResolver(schema),
		mode: 'onSubmit',
		defaultValues: {
			locale: '',
			interval: {
				startDate: null,
				endDate: null,
				description: '',
			},
			regions: [],
			regionsAreExcluded: false,
		},
	});

	const regionsAreExcludedWatched = watch('regionsAreExcluded');

	useEffect(() => {
		if (open) {
			reset();
			setEditIntervalMode(false);
			setMutationError(undefined);
		}
	}, [open]);

	const onAdd = async (data: FormDataType) => {
		setLoading(true);
		try {
			setMutationError(undefined);

			const payload = {
				path: 'coreMetadata.basic.localizedInfos',
				uuid: titleUuid!,
				value: JSON.stringify({
					language: data.locale,
					[data.regionsAreExcluded ? 'excludedRegions' : 'regions']: data.regions,
					targetAudiences: getTargetAudiences(data),
				}),
			};

			const result = await addRecord({
				variables: {
					input: payload,
				},
			});

			onClose();
			reset();
			setEditIntervalMode(false);
			onLocalizedInfoCreated(result?.data?.addRecord?.recordUuid || '');
		} catch (graphQLError: any) {
			setMutationError(graphQLError);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog maxWidth="md" open={open} fullWidth onClose={onClose}>
			<DialogTitle>
				Add Localized Info
				<CloseButton onClose={onClose} />
			</DialogTitle>
			<form onSubmit={handleSubmit(onAdd)}>
				<DialogContent>
					<ContentWrapper>
						{mutationError && (
							<ErrorAlert errorMessage={mutationError.message} onClose={() => setMutationError(undefined)} />
						)}
						<Controller
							control={control}
							render={({ field }) => (
								<DropdownV2
									withSearch
									aria-label="New Localized Info Locale Dropdown"
									tooltip={localeTooltip?.tooltip}
									options={
										locales?.map((locale: any) => ({
											label: locale.label,
											value: locale.uuid,
										})) || []
									}
									error={errors?.locale?.message as string}
									value={field.value}
									onChange={async (e) => {
										field.onChange(e);
									}}
									label="Locale"
								/>
							)}
							name="locale"
						/>
						<Row>
							{!editIntervalMode && (
								<Controller
									control={control}
									render={({ field }) => (
										<div style={{ width: '100%' }}>
											<Dropdown
												withSearch
												enablePortal
												tooltip={intervalTooltip?.tooltip}
												options={intervals.options}
												error={errors?.interval?.message as string}
												value={JSON.stringify(field.value)}
												onChange={(e) => field.onChange(e ? JSON.parse(e) : undefined)}
												label="Interval"
												bottomActions={
													<AddButton
														startIcon={<Icon path={mdiPlus} size="16px" />}
														nxstyle="tertiary-light"
														onClick={() => {
															setEditIntervalMode(true);
															field.onChange({
																startDate: null,
																endDate: null,
																description: '',
															});
														}}
														style={{
															width: '100%',
															justifyContent: 'flex-start',
															borderTopLeftRadius: 0,
															borderTopWidth: 1,
															borderTopRightRadius: 0,
															height: '48px',
														}}
													>
														<AddButtonMessageWrapper>
															Add
															<CustomAlert severity="info">
																Create a new interval by clicking the add button
															</CustomAlert>
														</AddButtonMessageWrapper>
													</AddButton>
												}
											/>
										</div>
									)}
									name="interval"
								/>
							)}
							{editIntervalMode && (
								<Controller
									control={control}
									name="interval"
									render={({ field }) => (
										<EditIntervalWrapper>
											<Typography variant="s2Regular">New Interval</Typography>
											<Row>
												<div style={{ width: '100%' }}>
													<TextInput
														value={field.value.description}
														onChange={(e) =>
															field.onChange({
																...field.value,
																description: e.target.value,
															})
														}
														tooltip={intervalLabelTooltip?.tooltip}
														label="Label"
														error={errors?.interval?.message}
													/>
												</div>
											</Row>
											<Row>
												<DatePicker
													tooltip={intervalStartDateTooltip?.tooltip}
													label="Start Date"
													value={field.value.startDate ? stringToDate(field.value.startDate) : undefined}
													onChange={(e) => {
														field.onChange({
															...field.value,
															startDate: dateToString(e || null),
														});
													}}
													error={errors?.interval?.startDate?.message}
												/>
												<DatePicker
													tooltip={intervalEndDateTooltip?.tooltip}
													label="End Date"
													value={field.value.endDate ? stringToDate(field.value.endDate) : undefined}
													onChange={(e) => {
														field.onChange({
															...field.value,
															endDate: dateToString(e || null),
														});
													}}
													error={errors?.interval?.endDate?.message}
												/>
											</Row>
											{intervals.intervals.length !== 0 && (
												<Button
													onClick={() => {
														setEditIntervalMode(false);
														field.onChange({
															startDate: null,
															endDate: null,
															description: '',
														});
													}}
													nxstyle="secondary-black"
													style={{
														width: '100%',
														height: '36px',
														marginTop: '8px',
													}}
												>
													Use existing intervals
												</Button>
											)}
										</EditIntervalWrapper>
									)}
								/>
							)}
						</Row>
						<Controller
							control={control}
							render={({ field }) => (
								<TerritoriesSelector
									tooltip={regionsAreExcludedWatched ? excludedRegionsTooltip?.tooltip : regionsTooltip?.tooltip}
									multiple
									labelIcon={
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '8px',
											}}
										/>
									}
									value={field.value?.map((v) => v.country || v.countryRegion || '')}
									onChange={(values) => {
										field.onChange(
											values.map((v) => ({
												[isCountry(v) ? 'country' : 'countryRegion']: v,
											})),
										);
									}}
									label={regionsAreExcludedWatched ? 'Excluded Regions' : 'Regions'}
									error={errors?.regions?.message as string}
									bottomActions={
										<IncludeExcludeBottomAction
											spacing={20}
											value={regionsAreExcludedWatched ? 'excludes' : 'includes'}
											onChange={() => setValue('regionsAreExcluded', !regionsAreExcludedWatched)}
											defaultValue="includes"
										/>
									}
								/>
							)}
							name="regions"
						/>
					</ContentWrapper>
				</DialogContent>
				<DialogActions>
					<Button nxstyle="secondary-black" endIcon={<Icon path={mdiCloseCircle} size="16px" />} onClick={onClose}>
						Cancel
					</Button>
					<Button
						nxstyle="primary-blue"
						endIcon={loading ? null : <Icon path={mdiCheckboxMarkedCircle} size="16px" />}
						type="submit"
					>
						{loading ? <Loader size={20} color={theme.palette.light.main} /> : 'Add'}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

export default NewLocalizedInfoModal;
