import React, { useMemo } from 'react';
import { Portal } from '@mui/material';

// TYPES
import { OriginalLanguage } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

// HOOKS
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { isInherited } from '@warehouse/title/core';
import useMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// UTILS
import { fromBasic } from '../../../../src/utils/titleGetProperty';

// LIBRARY
import { TableWrapper } from '../../../../src/views/title/TitleSettings/style';
import { OriginalLanguagesTable } from './OriginalLanguagesTable';

export function OriginalLanguages() {
	const { value, commit, setValue } = useTitleAutoSave<OptionalInherited<OriginalLanguage[]>>({
		label: 'Original Languages',
		path: useMemoJsonPath(fromBasic(['originalLanguages'])),
	});
	const isInheritedValue = useMemo(
		() => (value ? isInherited<OriginalLanguage[]>({ inheritedObject: value }).isInherited : false),
		[value],
	);

	return useMemo(
		() => (
			<TableWrapper>
				<Portal container={document.body} />
				<OriginalLanguagesTable
					isInherited={isInheritedValue}
					originalLanguages={value?.displayValue || []}
					setValue={setValue}
					commit={commit}
				/>
			</TableWrapper>
		),
		[commit, isInheritedValue, setValue, value?.displayValue],
	);
}
