import React, { useMemo } from 'react';
import { ContentIdentifier, Inherited } from '@warehouse/title/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { ReadOnlyField, TextInput } from '@warehouse/shared/legacy-ui';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import { fromBasic } from '../../../../src/utils/titleGetProperty';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import { usePlatformSpecContentIdentifierRecordId } from '../use-platform-spec-content-identifier';

export function AlidField() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const contentIdentifierRecordId = usePlatformSpecContentIdentifierRecordId(title);

	if (!contentIdentifierRecordId) return <AlidFieldUnknown />;
	return <AlidFieldInternal contentIdentifierRecordId={contentIdentifierRecordId} />;
}

function AlidFieldUnknown() {
	return (
		<TitleEditableField
			label="ALID:"
			jumpToParentButtonDisabled
			tooltipDisabled
			readComponent={null}
			editComponent={
				<ReadOnlyField aria-label="ALID" $disabled style={{ width: '100%', marginTop: 0, marginRight: 0 }} />
			}
		/>
	);
}

function AlidFieldInternal({ contentIdentifierRecordId }: { contentIdentifierRecordId: string }) {
	const { commit, readOnlyValue, value, setValue } = useTitleAutoSave<Inherited<ContentIdentifier>>({
		path: useMemo(
			() => fromBasic(['versionIntent', 'audience', 'identifications', contentIdentifierRecordId]),
			[contentIdentifierRecordId],
		),
		label: 'ALID',
	});

	return (
		<TitleEditableField
			label="ALID:"
			jumpToParentButtonDisabled
			tooltipDisabled
			readComponent={
				<ReadOnlyTypography noWrap title={readOnlyValue?.displayValue.identifier} aria-label="ALID">
					{readOnlyValue?.displayValue.identifier}
				</ReadOnlyTypography>
			}
			editComponent={
				<TextInput
					height={38}
					fullWidth
					style={{ width: '100%' }}
					value={value?.displayValue.identifier}
					onBlur={() => commit()}
					aria-label="ALID"
					onChange={(e) => {
						const nextIdentifier = e.currentTarget.value;
						setValue((prev) => ({ ...prev, displayValue: { ...prev.displayValue, identifier: nextIdentifier } }));
					}}
				/>
			}
		/>
	);
}
