import React from 'react';
import styled from 'styled-components';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { ReadOnlyField } from '@warehouse/shared/legacy-ui';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import { usePlatformSpecContentIdentifier } from '../use-platform-spec-content-identifier';

export function PlatformSpecificationField() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const platformSpecification = usePlatformSpecContentIdentifier(title);
	const displayValue = platformSpecification?.audienceIdentification?.label ?? 'Unknown';

	return (
		<TitleEditableField
			label="Specification:"
			jumpToParentButtonDisabled
			tooltipDisabled
			readComponent={
				<ReadOnlyTypography noWrap title={displayValue} aria-label="Platform Specification">
					{displayValue}
				</ReadOnlyTypography>
			}
			editComponent={
				<ReadOnlyFieldStyled aria-label="Platform Specification" $disabled>
					{displayValue}
				</ReadOnlyFieldStyled>
			}
		/>
	);
}

const ReadOnlyFieldStyled = styled(ReadOnlyField)`
	margin-right: 0 !important;
	margin-top: 0;
	width: 100%;
`;
