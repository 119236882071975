import { definePersistentQueryParamStore } from '@warehouse/shared/util';

export interface SearchStore {
	searchValue: string;
	actions: {
		setSearchValue: (value: string) => void;
	};
}

export const [SearchProvider, useSearchStore] = definePersistentQueryParamStore<SearchStore>((set) => ({
	searchValue: '',
	actions: {
		setSearchValue: (value: string) => set(() => ({ searchValue: value })),
	},
}));

export const searchStoreSelector = {
	actions: (state: SearchStore) => state.actions,
	searchValue: (state: SearchStore) => state.searchValue,
};
