import React, { useEffect, useMemo } from 'react';
import { Subscription } from 'rxjs';
import { StateCreator } from 'zustand';
import isEqual from 'lodash/isEqual';
import { definePersistentQueryParamStore, isInAndDefined, PersistQueryParamsConfig } from '@warehouse/shared/util';
import { nxObjectRepositorySingleton } from '@warehouse/object-browser/infra';
import {
	FolderPathUtils,
	FolderUuidObject,
	InfraResourceIdentifier,
	NxObject,
	NxObjectFull,
	NxObjects,
	SearchMode,
	StoreResourceIdentifier,
	WatchGetNxObjectResult,
	WatchSearchResult,
} from '@warehouse/object-browser/core';
import { createPaginatorSlice, PaginatorStore, paginatorStoreSelector, TableManagerStore } from '@warehouse/shared/ui';
import {
	paginatorStoreConfig,
	tableManagerStoreConfig,
	wildCardSearchStoreConfig,
} from './object-browser.store-config';
import {
	createObjectBrowserTableManagerSliceFactory,
	objectBrowserTableManagerSelector,
	ObjectBrowserTableManagerStore,
} from '../feature-object-browser/domain/object-browser-table-manager.store';
import {
	createObjectBrowserTreeManagerSlice,
	objectBrowserTreeManagerSelector,
	ObjectBrowserTreeManagerStore,
} from '../feature-object-browser/domain/object-browser-tree-manager.store';
import {
	objectBrowserBreadcrumbsManagerSelector,
	objectBrowserBreadcrumbsManagerSlice,
	ObjectBrowserBreadcrumbsManagerStore,
} from '../feature-object-browser/domain/object-browser-breadcrumbs-manager.store';
import {
	createWildCardSearchSliceFactory,
	wildCardSearchSelector,
	WildCardSearchStore,
} from '../feature-wild-card-search/domain';
import { gridFilterModelToFilterQuery } from '../../shared/ui/table/muix.adapter';

interface PreInitObjectBrowserStore {
	status: 'waitingForResourceIdentifier';
}

interface WaitingForCurrentFolderBrowserStore extends Omit<PreInitObjectBrowserStore, 'status'> {
	status: 'waitingForCurrentFolder';
	resourceIdentifier: StoreResourceIdentifier;
	isCurrentFolderPending: boolean;
	currentFolder: NxObjectFull | undefined;
}

interface ReadyObjectBrowserStore extends Omit<WaitingForCurrentFolderBrowserStore, 'status'> {
	status: 'ready';
	isItemsPending: boolean;
	items: NxObjects;
	selectedItem: NxObject | undefined;
	selectedFullItem: NxObjectFull | undefined;
}

interface CommonObjectBrowserStoreState
	extends ObjectBrowserTableManagerStore,
		ObjectBrowserTreeManagerStore,
		PaginatorStore,
		ObjectBrowserBreadcrumbsManagerStore,
		WildCardSearchStore {
	actions: ObjectBrowserTableManagerStore['actions'] &
		ObjectBrowserTreeManagerStore['actions'] &
		PaginatorStore['actions'] &
		ObjectBrowserBreadcrumbsManagerStore['actions'] &
		WildCardSearchStore['actions'] & {
			setResourceIdentifier: (resourceIdentifier: StoreResourceIdentifier) => void;
			selectItem: (item: NxObject) => void;
		};
	internalActions: PaginatorStore['internalActions'] & {
		destroy: () => void;
		initialize: () => void;
	};
}

type ObjectBrowserStore = (PreInitObjectBrowserStore | WaitingForCurrentFolderBrowserStore | ReadyObjectBrowserStore) &
	CommonObjectBrowserStoreState;

export const createObjectBrowserStore: StateCreator<ObjectBrowserStore> = (set, get, store) => {
	const repo = nxObjectRepositorySingleton.get();
	let watchDrillDownSearchSubscription: Subscription | undefined;
	let watchCurrentFolderSubscription: Subscription | undefined;
	let watchSelectedItemsSubscription: Subscription | undefined;

	const unsubscribeFromDrillDownSearch = () => {
		if (watchDrillDownSearchSubscription) watchDrillDownSearchSubscription.unsubscribe();
	};
	const unsubscribeFromCurrentFolder = () => {
		if (watchCurrentFolderSubscription) watchCurrentFolderSubscription.unsubscribe();
	};
	const unsubscribeFromSelectedItems = () => {
		if (watchSelectedItemsSubscription) watchSelectedItemsSubscription.unsubscribe();
	};

	const subscribeToDrillDownSearch = () => {
		unsubscribeFromDrillDownSearch();
		const state = get();
		if (state.status !== 'ready') return;
		watchDrillDownSearchSubscription = repo
			.watchNxObjects({
				resourceIdentifier: getInfraResourceIdentifier(),
				filters: {
					sort: [{ field: 'itemType', sort: 'desc' }, ...state.sort],
					pagination: {
						page: state.page,
						perPage: state.perPage,
					},
					filter: gridFilterModelToFilterQuery(state.filter),
				},
				options: {
					pollInterval: 3000,
				},
			})
			.subscribe((res: WatchSearchResult) => {
				if (res.isPending) {
					set({ isItemsPending: true });
				} else if (res.error) {
					// TODO: decide upon a better error-handling strategy
					console.error(`An error has occurred: ${res.error}`);
				} else {
					set({
						isItemsPending: false,
						items: res.data.documents,
						totalPages: res.data.pagination.totalPage,
						totalCount: res.data.pagination.totalDocument,
					});
				}
			});
	};

	const subscribeToCurrentFolder = (resourceIdentifier: FolderUuidObject) => {
		unsubscribeFromCurrentFolder();

		watchCurrentFolderSubscription = repo
			.watchNxObject({
				nxObjectUuid: resourceIdentifier.folderUuid,
			})
			.subscribe((res: WatchGetNxObjectResult) => {
				const state = get();
				if (res.isPending) {
					set({
						isCurrentFolderPending: true,
					});
				} else if (res.error) {
					// TODO: decide upon a better error-handling strategy
					console.error(`An error has occurred: ${res.error}`);
				} else {
					if (state.status !== 'waitingForCurrentFolder') {
						set({ isCurrentFolderPending: false });
						return;
					}
					set({
						status: 'ready',
						resourceIdentifier: state.resourceIdentifier,
						currentFolder: res.data,
						isCurrentFolderPending: false,
						selectedItem: undefined,
						selectedFullItem: undefined,
						isItemsPending: true,
						items: [],
					} satisfies ReadyObjectBrowserStore);
					objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromNxObjectFull(res.data);
				}
			});
	};

	const subscribeToSelectedItemFull = (nxObjectUuid: string) => {
		unsubscribeFromSelectedItems();
		const state = get();
		if (state.status !== 'ready') return;
		watchSelectedItemsSubscription = repo
			.watchNxObject({
				nxObjectUuid,
			})
			.subscribe((res) => {
				if (res.isPending) return;
				if (res.error) {
					// TODO: decide upon a better error-handling strategy
					console.error(`An error has occurred: ${res.error}`);
					set({ selectedFullItem: undefined });
				} else {
					set({ selectedFullItem: res.data });
					if (wildCardSearchSelector.isInSearchMode(state)) {
						objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromNxObjectFull(res.data);
					}
				}
			});
	};

	const getInfraResourceIdentifier = (): InfraResourceIdentifier => {
		const state = get();
		if (state.status !== 'ready') throw Error('Store is not yet ready');
		if (state.resourceIdentifier.type === 'bucket') {
			return { type: 'bucket', bucketId: state.resourceIdentifier.bucketId };
		}
		if (!state.currentFolder) throw new Error('Current folder is missing in a store ready');
		return {
			type: 'folderPath',
			// The cast is safe because for folders, they key is always a folder path
			folderPath: FolderPathUtils.cast(state.currentFolder.nxObject.key),
			bucketId: state.currentFolder.nxObject.bucketId,
		};
	};

	const objectBrowserTableManagerStore = createObjectBrowserTableManagerSliceFactory({ getPaginatorStore: get })(
		set,
		get,
		store,
	);
	const objectBrowserTreeManagerStore = createObjectBrowserTreeManagerSlice(set, get, store);
	const paginatorStore = createPaginatorSlice(set, get, store);
	const objectBrowserBreadcrumbsManagerStore = objectBrowserBreadcrumbsManagerSlice(set, get, store);
	const wildCardSearchStore = createWildCardSearchSliceFactory({
		getPaginatorStore: get,
		getTableManagerStore: get,
		getResourceIdentifier: () => {
			try {
				return getInfraResourceIdentifier();
			} catch (e) {
				return undefined;
			}
		},
		onEnterSearchMode: () => {
			unsubscribeFromDrillDownSearch();
		},
		onExitSearchMode: () => {
			subscribeToDrillDownSearch();
			const state = get();
			if (isInAndDefined(state, 'currentFolder')) {
				// We were on a folder before entering the search mode
				objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromNxObjectFull(state.currentFolder);
			} else if (isInAndDefined(state, 'resourceIdentifier') && state.resourceIdentifier.type === 'bucket') {
				// We were on a bucket before entering the search mode
				objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromBucketId(state.resourceIdentifier.bucketId);
			}
		},
	})(set, get, store);

	// Subscribe to the store's state changes to update the items subscription when necessary.
	store.subscribe((state, prevState) => {
		const isInSearchMode = wildCardSearchSelector.isInSearchMode(state);

		if (isInSearchMode && shouldUpdateWildcardSearchSubscription(state, prevState)) {
			wildCardSearchStore.actions.subscribeToSearch();
		} else if (!isInSearchMode && shouldUpdateDrilldownSearchSubscription(state, prevState)) {
			subscribeToDrillDownSearch();
		}
	});

	return {
		status: 'waitingForResourceIdentifier',
		...objectBrowserTableManagerStore,
		...objectBrowserTreeManagerStore,
		...paginatorStore,
		...objectBrowserBreadcrumbsManagerStore,
		...wildCardSearchStore,
		actions: {
			...objectBrowserTableManagerStore.actions,
			...objectBrowserTreeManagerStore.actions,
			...paginatorStore.actions,
			...objectBrowserBreadcrumbsManagerStore.actions,
			...wildCardSearchStore.actions,
			setResourceIdentifier(resourceIdentifier: StoreResourceIdentifier) {
				objectBrowserBreadcrumbsManagerStore.actions.updateResourceIdentifier();
				paginatorStore.internalActions.resetPagination();

				if (resourceIdentifier.type === 'bucket') {
					set({
						status: 'ready',
						resourceIdentifier,
						currentFolder: undefined,
						isCurrentFolderPending: false,
						selectedItem: undefined,
						selectedFullItem: undefined,
						isItemsPending: true,
						items: [],
					} satisfies ReadyObjectBrowserStore);
					objectBrowserBreadcrumbsManagerStore.actions.updateBreadcrumbFromBucketId(resourceIdentifier.bucketId);
				} else {
					set({
						status: 'waitingForCurrentFolder',
						resourceIdentifier,
						isCurrentFolderPending: true,
						currentFolder: undefined,
					} satisfies WaitingForCurrentFolderBrowserStore);
					subscribeToCurrentFolder(resourceIdentifier);
				}
			},
			selectItem(item) {
				if (get().status !== 'ready') return;
				set({ selectedItem: item });
				if (wildCardSearchSelector.isInSearchMode(get())) {
					objectBrowserBreadcrumbsManagerStore.actions.updateFakeBreadcrumbFromNxObject(item);
				}
				subscribeToSelectedItemFull(item.uuid);
			},
		},
		internalActions: {
			...paginatorStore.internalActions,
			initialize() {
				subscribeToDrillDownSearch();
				objectBrowserTreeManagerStore.internalActions.initialize();
				objectBrowserBreadcrumbsManagerStore.internalActions.initialize();
			},
			destroy() {
				unsubscribeFromDrillDownSearch();
				objectBrowserTreeManagerStore.internalActions.destroy();
				objectBrowserBreadcrumbsManagerStore.internalActions.destroy();
			},
		},
	} satisfies ObjectBrowserStore;
};

const [ObjectBrowserStoreProviderInternal, useObjectBrowserStore] =
	definePersistentQueryParamStore<ObjectBrowserStore>(createObjectBrowserStore);

export { useObjectBrowserStore };

export const objectBrowserStoreSelector = {
	...objectBrowserTableManagerSelector,
	...objectBrowserTreeManagerSelector,
	...paginatorStoreSelector,
	...objectBrowserBreadcrumbsManagerSelector,
	...wildCardSearchSelector,
	resourceIdentifier: (state: ObjectBrowserStore) => {
		if (isInAndDefined(state, 'resourceIdentifier')) return state.resourceIdentifier;
		return undefined;
	},
	actions: (state: ObjectBrowserStore) => ({
		...objectBrowserTableManagerSelector.actions(state),
		...objectBrowserTreeManagerSelector.actions(state),
		...paginatorStoreSelector.actions(state),
		...objectBrowserBreadcrumbsManagerSelector.actions(state),
		...wildCardSearchSelector.actions(state),
		setResourceIdentifier: state.actions.setResourceIdentifier,
		selectItem: state.actions.selectItem,
	}),
	selectedItem: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedItem')) return undefined;
		return state.selectedItem;
	},
	selectedFullItem: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'selectedFullItem')) return undefined;
		return state.selectedFullItem;
	},
	items: (state: ObjectBrowserStore) => {
		if (!isInAndDefined(state, 'items')) return [];
		if (wildCardSearchSelector.isInSearchMode(state)) {
			return state.wildCardSearchItems;
		}
		return state.items;
	},
	isPending: (state: ObjectBrowserStore) => {
		if (isInAndDefined(state, 'isCurrentFolderPending') && state.isCurrentFolderPending) return true;
		if (!isInAndDefined(state, 'isItemsPending')) return true;
		if (wildCardSearchSelector.isInSearchMode(state)) {
			return state.isWildCardSearchPending;
		}
		return state.isItemsPending;
	},
	showBreadcrumb: (state: ObjectBrowserStore) => {
		if (
			wildCardSearchSelector.isInSearchMode(state) &&
			wildCardSearchSelector.searchMode(state) === SearchMode.EVERYWHERE &&
			state.status === 'ready' &&
			state.selectedItem === undefined
		)
			return false;
		return true;
	},
};

function ObjectBrowserStoreEffects() {
	const { initialize, destroy } = useObjectBrowserStore((state) => state.internalActions);

	useEffect(() => {
		initialize();
		return () => destroy();
	}, [initialize, destroy]);

	return null;
}

export function ObjectBrowserStoreProvider({
	children,
	configOverride,
}: {
	configOverride?: PersistQueryParamsConfig<ObjectBrowserStore>;
	children: React.ReactNode;
}) {
	const config = useMemo(
		() => ({
			...tableManagerStoreConfig,
			...paginatorStoreConfig,
			...wildCardSearchStoreConfig,
			...(configOverride || {}),
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<ObjectBrowserStoreProviderInternal config={config}>
			<ObjectBrowserStoreEffects />
			{children}
		</ObjectBrowserStoreProviderInternal>
	);
}

function shouldUpdateDrilldownSearchSubscription(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return shouldUpdateSubscriptionCommon(state, prevState) || !isCurrentFolderEqual(state, prevState);
}

function shouldUpdateWildcardSearchSubscription(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return shouldUpdateSubscriptionCommon(state, prevState) || !isColumnVisibilityEqual(state, prevState);
}

function shouldUpdateSubscriptionCommon(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return (
		!isSortEqual(state, prevState) ||
		!isPaginationEqual(state, prevState) ||
		!isFilterEqual(state, prevState) ||
		!isResourceIdentifierEqual(state, prevState)
	);
}

function isCurrentFolderEqual(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	const previousCurrentFolder = isInAndDefined(prevState, 'currentFolder') ? prevState.currentFolder : undefined;
	const newCurrentFolder = isInAndDefined(state, 'currentFolder') ? state.currentFolder : undefined;
	return isEqual(previousCurrentFolder, newCurrentFolder);
}

function isColumnVisibilityEqual(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	return isEqual(state.visibility, prevState.visibility);
}

function isSortEqual(state: TableManagerStore, prevState: TableManagerStore) {
	return isEqual(state.sort, prevState.sort);
}

function isPaginationEqual(state: PaginatorStore, prevState: PaginatorStore) {
	return state.page === prevState.page && state.perPage === prevState.perPage;
}

function isFilterEqual(state: TableManagerStore, prevState: TableManagerStore) {
	return isEqual(state.filter, prevState.filter);
}

function isResourceIdentifierEqual(state: ObjectBrowserStore, prevState: ObjectBrowserStore) {
	if (state.status !== 'ready' || prevState.status !== 'ready') return false;

	return isEqual(state.resourceIdentifier, prevState.resourceIdentifier);
}
