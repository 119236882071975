import React, { useCallback, useContext } from 'react';
import { Portal } from '@mui/material';
import { RelationshipType, TitleLightWithSelectedParent } from '@warehouse/title/core';
import { RelationshipEditor, ErrorBoundary } from '@warehouse/title/feature-edit-relationship';
import { ChildrenParentMode, RelationshipsList } from '@warehouse/title/feature-list-relationships';
import { useSnackbar } from 'notistack';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { useStateQuery } from '../../../../utils/hooks/useStateQuery';
import { UnsavedChangesContext } from '../../contexts/UnsavedChangesContext';

interface EditRelationshipState {
	relationshipType: RelationshipType;
	parentUuid: string;
	childUuid: string;
	isCompObj: boolean;
}

export function Relationships() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const [editRelationship, setEditRelationship] = useStateQuery<EditRelationshipState | undefined>({
		queryName: 'editRelationship',
		type: 'object',
	});

	const { enqueueSnackbar } = useSnackbar();

	if (!title.uuid) return null;

	const handleChildrenClick = useCallback(
		(mode: ChildrenParentMode, selectedTitle: TitleLightWithSelectedParent) => {
			if (!title.uuid) return;
			switch (mode) {
				case 'children':
					setEditRelationship({
						parentUuid: title.uuid,
						childUuid: selectedTitle.uuid,
						isCompObj: selectedTitle.selectedParent.relationshipType === RelationshipType.EntryOf,
						relationshipType: selectedTitle.selectedParent.relationshipType,
					});
					break;
				case 'parents':
					setEditRelationship({
						parentUuid: selectedTitle.selectedParent.contentId,
						childUuid: title.uuid,
						isCompObj: selectedTitle.selectedParent.relationshipType === RelationshipType.EntryOf,
						relationshipType: selectedTitle.selectedParent.relationshipType,
					});
					break;
				default:
					break;
			}
		},
		[title.uuid, setEditRelationship],
	);

	return (
		<>
			<RelationshipsList title={title} onChildrenClick={handleChildrenClick} />
			<Portal container={document.body}>
				<ErrorBoundary
					onErrorRender={() => {
						enqueueSnackbar('Child not found', { variant: 'warning' });
						setEditRelationship(null);
					}}
				>
					<RelationshipEditor
						parentUuid={editRelationship?.parentUuid || title.uuid}
						childUuid={editRelationship?.childUuid}
						{...(editRelationship && !editRelationship.isCompObj
							? {
									isCompObj: false,
									relationshipType: editRelationship.relationshipType,
							  }
							: {
									isCompObj: true,
									relationshipType: undefined,
							  })}
						onClose={(force?: boolean) => {
							if (force) setEditRelationship(null);
							else promptUserIfUnsavedChanges(() => setEditRelationship(null));
						}}
					/>
				</ErrorBoundary>
			</Portal>
		</>
	);
}
