import {
	ApolloClient,
	ApolloError,
	ApolloQueryResult,
	createHttpLink,
	InMemoryCache,
	OperationVariables,
	WatchQueryOptions,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Observable } from 'rxjs';
import { createFragmentRegistry } from '@apollo/client/cache';
import { titleFragment, titleIndexedFragment } from '@warehouse/graphql';
import { isDev } from '../../../src/utils/isDev';
import { relationshipFragment } from '../../../src/graphql/fragments/relationshipFragment';

function createApolloAdapter() {
	let tokenGetter: (() => Promise<string | null>) | null = null;

	const httpLink = createHttpLink({
		uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
	});
	const authLink = setContext(async (_, { headers }) => {
		if (tokenGetter === null) throw new ApolloError({ errorMessage: 'Token getter unset' });
		let token = null;
		try {
			token = await tokenGetter();
		} catch (e: any) {
			throw new ApolloError({
				errorMessage: e.message ?? e.toString() ?? 'Unexpected error happened during authentication',
			});
		}
		if (token === null) {
			throw new ApolloError({ errorMessage: 'An error happened during authentication' });
		}
		return {
			headers: {
				...headers,
				Authorization: `Bearer ${token}`,
			},
		};
	});

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache({
			fragments: createFragmentRegistry(titleFragment, titleIndexedFragment, relationshipFragment),
		}),
		devtools: {
			enabled: isDev(),
		},
	});

	return {
		client,
		setTokenGetter(_token: (() => Promise<string | null>) | null) {
			tokenGetter = _token;
		},
		watchObservableQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
			options: WatchQueryOptions<TVariables, T>,
		): Observable<ApolloQueryResult<T>> {
			return new Observable<ApolloQueryResult<T>>((observer) => {
				const query = client.watchQuery<T, TVariables>({ ...options, fetchPolicy: 'cache-and-network' });
				const subscription = query.subscribe(observer);
				return () => subscription.unsubscribe();
			});
		},
	};
}

export const apolloAdapter = createApolloAdapter();
