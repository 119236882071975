import { defaultLanguageWrittenUuid } from '@warehouse/global-entities/core';

export type OriginalLanguage = {
	originalLanguage: string; // localeUuid
	signed?: boolean;
	written?: boolean;
	spoken?: boolean;
	listingOrder?: number;
};

export function createOriginalLanguage({
	originalLanguage,
	listingOrder,
}: {
	originalLanguage?: string;
	listingOrder: number;
}): OriginalLanguage {
	return {
		originalLanguage: originalLanguage ?? defaultLanguageWrittenUuid,
		listingOrder,
		written: true,
		spoken: true,
		signed: undefined,
	};
}
