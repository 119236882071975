import { definePersistentQueryParamStore } from '@warehouse/shared/util';
import { SortingState } from '@warehouse/title/core';

export interface SortStore {
	sort: SortingState;
	actions: {
		setSort: (value: SortingState) => void;
	};
}

export const [SortProvider, useSortStore] = definePersistentQueryParamStore<SortStore>((set) => ({
	sort: [],
	actions: {
		setSort: (value: SortingState) => set(() => ({ sort: value })),
	},
}));

export const sortStoreSelector = {
	actions: (state: SortStore) => state.actions,
	sort: (state: SortStore) => state.sort,
};
