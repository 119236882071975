import { TitleFull } from '@warehouse/title/core';
import * as _ from 'lodash/fp';
import { emptyArrayToUndefined } from '@warehouse/shared/util';
import { getDefaultLocalizedInfo } from '../core/localized-infos';
import { IBMDurationFormatToSeconds } from '../../../src/components/library/TimeInput';
import { stringToDate } from '../../../src/components/library/DatePicker/DatePicker';
import { flattenRegions, Region } from '../core/models/region';
import { filterCreativeCountriesOfOrigin, getInheritedCountriesOfOrigin } from '../core/countries-of-origin';

export function getDryRanLanguage(dryRanTitle: TitleFull | undefined): string | undefined {
	const defaultLocalizedInfo = getDefaultLocalizedInfo(dryRanTitle);
	return defaultLocalizedInfo?.language.inherited.value;
}

export function getDryRanCountriesOfOrigin(dryRanTitle: TitleFull | undefined): string[] | undefined {
	return _.pipe(
		getInheritedCountriesOfOrigin,
		filterCreativeCountriesOfOrigin,
		flattenRegions,
		emptyArrayToUndefined,
	)(dryRanTitle);
}

export function getDryRanReleaseDate(dryRanTitle: TitleFull | undefined) {
	const dryRanDateString = dryRanTitle?.metadata.coreMetadata.basic.releaseDate?.inherited.value;
	return dryRanDateString ? stringToDate(dryRanDateString) : undefined;
}

export function getDryRanApproximateLength(dryRanTitle: TitleFull | undefined) {
	const runLength = dryRanTitle?.metadata.coreMetadata.basic?.runLength?.inherited.value;
	return runLength ? IBMDurationFormatToSeconds(runLength) : undefined;
}

export function getDryRanOriginalLanguages(dryRanTitle: TitleFull | undefined) {
	return dryRanTitle?.metadata.coreMetadata.basic.originalLanguages?.inherited?.value?.map(
		(lang) => lang.originalLanguage,
	);
}

export function getDryRanHouseSequence(dryRanTitle: TitleFull | undefined): string | undefined {
	return dryRanTitle?.metadata.coreMetadata.basic.sequenceInfo?.houseSequence?.inherited?.value?.houseSequence;
}

export function getDryRanDistributionNumber(dryRanTitle: TitleFull | undefined): string | undefined {
	return dryRanTitle?.metadata.coreMetadata.basic.sequenceInfo?.distributionNumber?.inherited?.value
		?.distributionNumber;
}

export function getDryRanEditUse(dryRanTitle: TitleFull | undefined): string | undefined {
	return dryRanTitle?.metadata.coreMetadata.basic.versionIntent?.editUse?.inherited?.value;
}

export function getDryRanEditClass(dryRanTitle: TitleFull | undefined): string[] | undefined {
	return dryRanTitle?.metadata.coreMetadata.basic.versionIntent?.editClasses?.inherited?.value;
}

export function getDryRanMadeForRegions(
	dryRanTitle: TitleFull | undefined,
): { madeForRegions: string[]; isInclude: boolean } | undefined {
	const dryRanMsdeForRegions = dryRanTitle?.metadata.coreMetadata.basic.versionIntent?.madeForRegions?.inherited?.value;
	const dryRanMadeRegionsExclude =
		dryRanTitle?.metadata.coreMetadata.basic.versionIntent?.madeForRegionsExclude?.inherited?.value;

	return computeDefaultMadeForRegions(dryRanMsdeForRegions, dryRanMadeRegionsExclude);
}

export function getDryRanVersionLanguages(dryRanTitle: TitleFull | undefined): string[] | undefined {
	return dryRanTitle?.metadata.coreMetadata.basic?.versionLanguages?.inherited?.value;
}

function computeDefaultMadeForRegions(
	dryRanMadeForRegions: Region[] | undefined,
	dryRanMadeRegionsExclude: Region[] | undefined,
): { madeForRegions: string[]; isInclude: boolean } | undefined {
	if (dryRanMadeForRegions) {
		return {
			madeForRegions: flattenRegions(dryRanMadeForRegions),
			isInclude: true,
		};
	}

	if (dryRanMadeRegionsExclude) {
		return {
			madeForRegions: flattenRegions(dryRanMadeRegionsExclude),
			isInclude: false,
		};
	}

	return undefined;
}
