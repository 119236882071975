import { TitleFull, WorkType } from '@warehouse/title/core';
import { assertUnreachable } from '@warehouse/shared/util';
import { UnreachableError } from '@warehouse/shared/errors';
import {
	BaseTitleCreationFormData,
	EditTitleCreationFormData,
	EpisodeTitleCreationFormData,
	ManifestationTitleCreationFormData,
	SeasonTitleCreationFormData,
	TitleCreationFormData,
	UserSetDefaults,
} from './title-creation-form-data';

import {
	getDryRanApproximateLength,
	getDryRanCountriesOfOrigin,
	getDryRanDistributionNumber,
	getDryRanEditClass,
	getDryRanEditUse,
	getDryRanHouseSequence,
	getDryRanLanguage,
	getDryRanMadeForRegions,
	getDryRanOriginalLanguages,
	getDryRanReleaseDate,
	getDryRanVersionLanguages,
} from './dry-run-getters';

const enUsUuid = '8fa1f157-131a-4461-93fa-acf6ed5a18f5';
const unitedStatesUuid = '005abd18-7061-458e-968a-91785bf052da';

type ComputeDefaultsInput = BaseComputeSingleRowDefaultsInput & {
	workType: WorkType;
	numberOfRowsToAdd: number;
	currentTitlesFormData?: TitleCreationFormData[];
};

export function computeDefaults(input: ComputeDefaultsInput): TitleCreationFormData[] {
	const { workType, numberOfRowsToAdd, currentTitlesFormData } = input;

	if (workType !== WorkType.Season && workType !== WorkType.Episode) {
		return Array.from({ length: numberOfRowsToAdd }, () =>
			computeSingleRowDefaults({
				workType,
				dryRanTitle: input.dryRanTitle,
				userSetDefaults: input.userSetDefaults,
				currentTitlesFormData,
			}),
		);
	}

	const definedTitlesFormData = (currentTitlesFormData || []) as (
		| EpisodeTitleCreationFormData
		| SeasonTitleCreationFormData
	)[];

	const maxSequenceNumber = getMaxSequenceNumber(definedTitlesFormData);

	return Array.from({ length: numberOfRowsToAdd }, (_, index) =>
		computeSingleRowDefaults({
			workType,
			dryRanTitle: input.dryRanTitle,
			userSetDefaults: input.userSetDefaults,
			sequenceNumber: (maxSequenceNumber + index + 1).toString(),
			currentTitlesFormData: definedTitlesFormData,
		}),
	);
}

type ComputeSingleRowDefaultsInput = (
	| { workType: WorkType.Season | WorkType.Episode; sequenceNumber: string }
	| { workType: Exclude<WorkType, WorkType.Season | WorkType.Episode>; sequenceNumber?: never }
) &
	BaseComputeSingleRowDefaultsInput;

type BaseComputeSingleRowDefaultsInput = {
	dryRanTitle?: TitleFull;
	userSetDefaults?: UserSetDefaults;
	currentTitlesFormData?: TitleCreationFormData[];
};

function computeSingleRowDefaults({
	workType,
	dryRanTitle,
	userSetDefaults,
	sequenceNumber,
}: ComputeSingleRowDefaultsInput): TitleCreationFormData {
	const baseDefaults: BaseTitleCreationFormData = {};

	// Title Language
	baseDefaults.titleLanguage = userSetDefaults?.titleLanguage ?? getDryRanLanguage(dryRanTitle) ?? enUsUuid;

	// Countries of Origin
	baseDefaults.countriesOfOrigin = userSetDefaults?.countriesOfOrigin ??
		getDryRanCountriesOfOrigin(dryRanTitle) ?? [unitedStatesUuid];

	// Release Date
	const dryRanDate = getDryRanReleaseDate(dryRanTitle);
	baseDefaults.releaseDate = userSetDefaults?.releaseDate ?? dryRanDate ?? undefined;

	// Approximate Length
	const dryRanApproximateLengthInSeconds = getDryRanApproximateLength(dryRanTitle);
	baseDefaults.approximateLength = userSetDefaults?.approximateLength ?? dryRanApproximateLengthInSeconds ?? undefined;

	// Original Languages
	baseDefaults.originalLanguages = userSetDefaults?.originalLanguages ??
		getDryRanOriginalLanguages(dryRanTitle) ?? [enUsUuid];

	if (
		workType === WorkType.Movie ||
		workType === WorkType.Compilation ||
		workType === WorkType.Series ||
		workType === WorkType.Supplemental
	) {
		return {
			workType,
			...baseDefaults,
		};
	}

	if (workType === WorkType.Episode) {
		const defaults: EpisodeTitleCreationFormData = {
			workType,
			...baseDefaults,
		};

		// Sequence Number
		defaults.sequenceNumber = sequenceNumber;
		// House Sequence
		defaults.houseSequence = userSetDefaults?.houseSequence ?? getDryRanHouseSequence(dryRanTitle);

		// Distribution Number
		defaults.distributionNumber = userSetDefaults?.distributionNumber ?? getDryRanDistributionNumber(dryRanTitle);

		return defaults;
	}

	if (workType === WorkType.Season) {
		const defaults: SeasonTitleCreationFormData = {
			workType,
			...baseDefaults,
		};

		// Sequence Number
		defaults.sequenceNumber = sequenceNumber;

		return defaults;
	}

	if (workType === WorkType.Edit) {
		const defaults: EditTitleCreationFormData = {
			workType,
			...baseDefaults,
		};

		// Edit Use (for Edit)
		defaults.editUse = userSetDefaults?.editUse ?? getDryRanEditUse(dryRanTitle);

		// Edit Classes
		defaults.editClass = userSetDefaults?.editClass ?? getDryRanEditClass(dryRanTitle);

		// Made For Regions
		defaults.madeForRegions = userSetDefaults?.madeForRegions ?? getDryRanMadeForRegions(dryRanTitle);

		// Version Languages
		defaults.versionLanguages = userSetDefaults?.versionLanguages ?? getDryRanVersionLanguages(dryRanTitle);

		return defaults;
	}

	if (workType === WorkType.Manifestation) {
		const defaults: ManifestationTitleCreationFormData = {
			workType,
			...baseDefaults,
		};

		defaults.platformSpecificationId = userSetDefaults?.platformSpecificationId;

		return defaults;
	}

	assertUnreachable(workType);
	throw new UnreachableError(`Work type ${workType} is not supported`);
}

function getMaxSequenceNumber(titlesData: (EpisodeTitleCreationFormData | SeasonTitleCreationFormData)[]) {
	const currentSequenceNumber = titlesData
		.map((title) => parseInt(title.sequenceNumber ?? 'NaN', 10))
		.filter((val) => !Number.isNaN(val));
	return currentSequenceNumber.length ? Math.max(...currentSequenceNumber) : 0;
}
