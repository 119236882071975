import { MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';
import { createJSONStorage, persist, StorageValue } from 'zustand/middleware';
import { StateCreator } from 'zustand/vanilla';
import { StoreInterface, StoreInterfaceImpl } from './store-interface';
import { IndexableObject, PersistQueryParamsConfig, UnIndexableKeys } from './types';

function cleanupDefaultValues<STORE extends IndexableObject>(
	storeInterface: StoreInterface<STORE>,
	config: PersistQueryParamsConfig<STORE>,
	storage: StorageValue<STORE>,
) {
	Object.entries(config).forEach(([fieldKey, fieldConfig]) => {
		if (!fieldConfig) return;
		const valueInStore = storage.state[fieldKey];
		const { defaultValue } = fieldConfig;
		if (defaultValue !== undefined && isEqual(valueInStore, defaultValue)) {
			storeInterface.removeItem(fieldKey);
		}
	});
}

export function usePersistQueryParamStore<STORE extends STORE_STATE, STORE_STATE extends IndexableObject>(
	config: PersistQueryParamsConfig<STORE_STATE>,
) {
	const isMountedRef = useRef(false);

	const persistentStorage = useMemo(
		() => ({
			getItem(): string {
				const storeInterface = new StoreInterfaceImpl(config);
				const storage: StorageValue<STORE> = {
					version: 0,
					state: {} as STORE,
				};
				Object.entries(config).forEach(([fieldKey, fieldConfig]) => {
					if (!fieldConfig) return;
					const valueInStore = storeInterface.getItem(fieldKey as Exclude<keyof STORE_STATE, UnIndexableKeys>);
					if (valueInStore !== undefined) {
						storage.state[fieldKey as keyof STORE] = valueInStore as STORE[keyof STORE];
					} else if (fieldConfig.defaultValue !== undefined) {
						storage.state[fieldKey as keyof STORE] = fieldConfig.defaultValue as STORE[keyof STORE];
					}
				});
				cleanupDefaultValues(storeInterface, config, storage);
				return JSON.stringify(storage);
			},
			setItem(_: string, serializedStorage: string): string {
				if (!isMountedRef.current) return serializedStorage;
				const storage = JSON.parse(serializedStorage) as StorageValue<STORE_STATE>;
				const storeInterface = new StoreInterfaceImpl(config);
				Object.entries(config).forEach(([fieldKey, fieldConfig]) => {
					if (!fieldConfig) return;
					const valueFromStorage = storage.state[fieldKey];
					storeInterface.setItem(
						fieldKey as Exclude<keyof STORE_STATE, UnIndexableKeys>,
						valueFromStorage as STORE_STATE[Exclude<keyof STORE_STATE, UnIndexableKeys>],
					);
				});
				return JSON.stringify(storage);
			},
			removeItem() {
				if (!isMountedRef.current) return;
				const storeInterface = new StoreInterfaceImpl(config);
				Object.keys(config).forEach((fieldKey) => {
					storeInterface.removeItem(fieldKey as Exclude<keyof STORE_STATE, UnIndexableKeys>);
				});
			},
		}),
		[config],
	);

	const onMount = useCallback(
		(storeRef: MutableRefObject<STORE>) => {
			isMountedRef.current = true;
			const storeInterface = new StoreInterfaceImpl(config);
			Object.entries(config).forEach(([fieldKey]) => {
				const valueInStore = storeRef.current[fieldKey];
				storeInterface.setItem(
					fieldKey as Exclude<keyof STORE_STATE, UnIndexableKeys>,
					valueInStore as STORE_STATE[Exclude<keyof STORE_STATE, UnIndexableKeys>],
				);
			});
		},
		[config],
	);

	const onUnmount = useCallback(() => {
		const storeInterface = new StoreInterfaceImpl(config);
		Object.entries(config).forEach(([fieldKey]) => {
			storeInterface.hideItem(fieldKey as Exclude<keyof STORE_STATE, UnIndexableKeys>);
		});
		isMountedRef.current = false;
	}, [config]);

	const persistentStore = useCallback(
		(stateCreator: StateCreator<STORE>) =>
			persist(stateCreator, {
				name: 'persistentQueryParamStore',
				storage: createJSONStorage<STORE_STATE>(() => persistentStorage),
			}),
		[persistentStorage],
	);

	return {
		onMount,
		onUnmount,
		persistentStore,
	};
}
