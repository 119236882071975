import { StorageTier, StorageTierLabels } from '@warehouse/object-browser/core';
import { UnreachableError } from '@warehouse/shared/errors';
import { assertUnreachable } from '@warehouse/shared/util';
import { components } from './inventory.openapi';

export class StorageTierAdapter {
	static adapt(storageTier: components['schemas']['NxObjectFile']['storageTier']): StorageTier {
		switch (storageTier) {
			case 'STANDARD':
			case 'REDUCED_REDUNDANCY':
			case 'STANDARD_IA':
			case 'ONEZONE_IA':
			case 'INTELLIGENT_TIERING':
			case 'GLACIER':
			case 'DEEP_ARCHIVE':
			case 'OUTPOSTS':
			case 'GLACIER_IR':
			case 'SNOW':
				return {
					value: storageTier,
					label: StorageTierLabels[storageTier],
				};
			default:
				assertUnreachable(storageTier, `Unknown storage tier: "${storageTier}"`);
				throw new UnreachableError(`Unknown storage tier: "${storageTier}"`);
		}
	}
}
