import { v4 as uuid } from 'uuid';
import { Bucket, NxObject, NxObjectType } from '@warehouse/object-browser/core';
import { TreeItemBucket, TreeItemFolder, TreeItemLoading, TreeItemType } from './models';

export class TreeItemAdapter {
	static createTreeItemLoading(): TreeItemLoading {
		return { id: uuid(), type: TreeItemType.Loading };
	}

	static adaptBucket(bucket: Bucket): TreeItemBucket {
		return {
			id: bucket.id,
			type: TreeItemType.Bucket,
			name: bucket.id,
			children: [TreeItemAdapter.createTreeItemLoading()],
		};
	}

	static adaptBuckets(buckets: Bucket[]): TreeItemBucket[] {
		return buckets.map<TreeItemBucket>(TreeItemAdapter.adaptBucket);
	}

	static adaptFolder(folder: NxObject): TreeItemFolder {
		if (folder.itemType !== NxObjectType.Folder) throw new Error('Trying to map an NxObjectFile to a TreeItemFolder');
		return {
			id: folder.uuid,
			type: TreeItemType.Folder,
			name: folder.name,
			bucketId: folder.bucketId,
			path: folder.folder,
			key: folder.key,
			children: [TreeItemAdapter.createTreeItemLoading()],
		};
	}

	static adaptFolders(folders: NxObject[]): TreeItemFolder[] {
		return folders.map(TreeItemAdapter.adaptFolder);
	}
}
