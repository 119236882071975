import React from 'react';
import { CommonReadonly, ReadonlyVersionLanguage } from '@warehouse/title/core';
import { Typography } from '@mui/material';
import { CustomColumnDef } from '../../../../src/components/library/SimpleTable/customColumnDef';
import ChipDisplay from '../../../../src/components/library/ChipDisplay';

export function PlatformSpecificationColumn<T extends CommonReadonly>(): CustomColumnDef<T, string> {
	return {
		header: 'Platform Specification',
		accessorKey: 'platformSpecification.label',
		id: 'platformSpecification.label',
		editable: false,
		renderReadOnly: (label: string) => (
			<Typography style={{ paddingLeft: 12 }} variant="body2">
				{label || 'Unknown'}
			</Typography>
		),
	};
}

export function AlidColumn<T extends CommonReadonly>(): CustomColumnDef<T, string> {
	return {
		header: 'ALID',
		accessorKey: 'alid',
		id: 'alid',
		editable: false,
		renderReadOnly: (alid: string) => (
			<Typography style={{ paddingLeft: 12 }} variant="body2">
				{alid}
			</Typography>
		),
	};
}

export function VersionLanguagesColumn<T extends CommonReadonly>(): CustomColumnDef<
	T,
	ReadonlyVersionLanguage[] | null | undefined
> {
	return {
		header: 'Version Languages',
		accessorKey: 'versionLanguages',
		id: 'versionLanguages',
		editable: false,
		renderReadOnly: (versionLanguages: ReadonlyVersionLanguage[] | null | undefined) => (
			<ChipDisplay
				values={versionLanguages ? versionLanguages.map(({ label }) => label) : []}
				allowOverflow
				variant="default"
				horizontalPadding={0}
				width={170}
				height={26}
			/>
		),
	};
}
