import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

// HOOKS
import { territoriesHelper } from '@warehouse/global-entities/core';

// TYPES
import { LocalizedInfo } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/LocalizedInfo';
import { TitleFull as Title } from '@warehouse/title/core';
import { LocalizedInfoRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { Locale } from '../../../../titles/localeType';

// UTILS
import sortAlphabeticallyByLabel from '../../../../../utils/sortAlphabeticallyByLabel';
import { findDefaultUuidForKey, fromBasic, getTitleData } from '../../../../../utils/titleGetProperty';

// JSON
import localesJSON from '../../../../../assets/json-administration-profiles/availableLanguageWritten.json';

const { getLabel } = territoriesHelper;
function getRegionsLabel(regions: LocalizedInfoRegion[]) {
	return regions
		?.map((reg) => getLabel(reg.country || reg.countryRegion || ''))
		.filter((i) => !!i)
		.join(', ');
}

function getLocalizedInfoLabel(localizedInfo: any, locales: Locale[]): string {
	const keyOfFirstAudience = Object.keys(
		// @ts-ignore
		localizedInfo.targetAudiences || {},
	)[0];

	const labelLocale = locales.find((locale) => locale.uuid === localizedInfo.language?.displayValue)?.label;

	const localizedInfoRegions =
		localizedInfo.regions?.displayValue?.length === 0 ? undefined : localizedInfo.regions?.displayValue;
	const localizedInfoExcludedRegions =
		localizedInfo.excludedRegions?.displayValue?.length === 0 ? undefined : localizedInfo.excludedRegions?.displayValue;

	const regions = localizedInfoRegions || localizedInfoExcludedRegions;
	const regionsLabel = getRegionsLabel(regions);

	const intervalLabel = localizedInfo.targetAudiences?.[keyOfFirstAudience]?.description?.displayValue || '';

	const arrayLabel = [labelLocale, intervalLabel, regionsLabel]?.filter((i) => !!i);
	return arrayLabel.join(' - ');
}

type Option = {
	label: string;
	value: string;
};

export interface UseLocalizedInfosDropdownReturn {
	selected: string | undefined;
	setSelected: Dispatch<SetStateAction<string | undefined>>;
	options: Option[];
}

const locales = localesJSON.sort(sortAlphabeticallyByLabel);

function useLocalizedInfosDropdown(title: Title | undefined): UseLocalizedInfosDropdownReturn {
	const [selected, setSelected] = useState<string | undefined>();

	// Set default localized infos
	useEffect(() => {
		if (!selected && title) {
			const defaultUuid = findDefaultUuidForKey(title, 'localizedInfos');

			if (defaultUuid) {
				setSelected(defaultUuid);
			}
		}
	}, [title]);

	// Compute options with concatenated label
	const options = useMemo(() => {
		const localizedInfos = getTitleData<Record<string, LocalizedInfo>>(title, fromBasic(['localizedInfos']));

		if (!localizedInfos) return [];

		const opts: Option[] = [];

		Object.keys(localizedInfos || {}).forEach((key) => {
			if (!key || !localizedInfos[key]) return;

			const label = getLocalizedInfoLabel(localizedInfos[key], locales);

			if (!label) return;

			opts.push({
				value: key,
				label,
			});
		});

		return opts;
	}, [title]);

	return {
		selected,
		setSelected,
		options,
	};
}

export default useLocalizedInfosDropdown;
