import { StateCreator } from 'zustand';
import {
	createTableManagerStoreSliceFactory,
	PaginatorStore,
	TableManagerStore,
	tableManagerStoreSelector,
} from '@warehouse/shared/ui';
import { objectBrowserTableColumns } from '../columns';

export interface ObjectBrowserTableManagerStore extends TableManagerStore {}

interface FactoryOptions {
	getPaginatorStore: () => PaginatorStore;
}

export const createObjectBrowserTableManagerSliceFactory =
	({ getPaginatorStore }: FactoryOptions): StateCreator<ObjectBrowserTableManagerStore> =>
	(set, get, store) => {
		const tableManagerStore = createTableManagerStoreSliceFactory({ getPaginatorStore })(set, get, store);

		return {
			...tableManagerStore,
			pinned: { left: ['itemType', 'name'] },
			order: objectBrowserTableColumns.map((c) => c.field),
			actions: {
				...tableManagerStore.actions,
			},
		};
	};

export const objectBrowserTableManagerSelector = {
	...tableManagerStoreSelector,
	actions: (state: ObjectBrowserTableManagerStore) => ({
		...tableManagerStoreSelector.actions(state),
	}),
};
