import { createContext } from 'react';
import { NetworkStatus } from '@warehouse/shared/core';
import { SearchTitlesV2Output } from '@warehouse/graphql';
import { FilterStep } from '@warehouse/shared/filters';

export interface ColumnSort {
	id: string;
	desc: boolean;
	nestedFilter?: string;
}

export type SortingState = ColumnSort[];

export interface TitleSearchQuery {
	(args: {
		debouncedSearch: string;
		filtering?: FilterStep;
		sorting?: SortingState;
		pollInterval: number;
		page: number;
		perPage: number;
		searchFields: string[];
		skip?: boolean;
	}): {
		loading: boolean;
		error: unknown;
		networkStatus: NetworkStatus;
		data?: {
			searchTitlesV2: SearchTitlesV2Output;
		}; // TODO: Should we use a specific front-end core?
		// TODO: Remove nested type from data
	};
}

export const TitleSearchQueryContext = createContext<TitleSearchQuery | null>(null);
