import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Divider, Typography } from '@mui/material';
import { TextInput, Button } from '@warehouse/shared/legacy-ui';
import Icon from '@mdi/react';
import { mdiUpload } from '@mdi/js/commonjs/mdi';
import { mdiClose, mdiMagnify } from '@mdi/js';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { debounce } from 'lodash';
import { SearchModeToggleButton } from './SearchModeToggleButton';

export function PageHeader() {
	const actions = useObjectBrowserStore(objectBrowserStoreSelector.actions);
	const searchQuery = useObjectBrowserStore(objectBrowserStoreSelector.searchQuery);
	const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);

	useEffect(() => {
		// If the localSearchQuery is set, but not the searchQuery from store,
		// it means the user cleared the search (by navigating back to drill down mode from search mode)
		// so we need to clear the localSearchQuery as well
		if (!searchQuery && !!localSearchQuery) setLocalSearchQuery('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const debouncedSetSearchQuery = useMemo(
		() =>
			debounce((value: string) => {
				actions.setSearchQuery(value);
			}, 300),
		[actions],
	);

	const handleSearchChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;

			setLocalSearchQuery(value);
			debouncedSetSearchQuery.cancel();
			debouncedSetSearchQuery(value);
		},
		[debouncedSetSearchQuery],
	);

	const handleClearSearch = useCallback(() => {
		setLocalSearchQuery('');
		actions.setSearchQuery('');
		debouncedSetSearchQuery.cancel();
	}, [actions, debouncedSetSearchQuery]);

	return (
		<>
			<TopPageWrapper>
				<TopActionWrapper>
					<Typography variant="h4Bold">Object Browser</Typography>
					{false && (
						<TopActionButtonWrapper>
							<Button
								nxstyle="secondary-blue"
								size="large"
								$fullHeight
								startIcon={<Icon path={mdiUpload} size="20px" />}
							>
								Upload
							</Button>
						</TopActionButtonWrapper>
					)}
				</TopActionWrapper>
				<Divider />
				<SearchBar
					placeholder="Search objects"
					startIcon={<Icon path={mdiMagnify} size="24px" />}
					endIcon={
						<Button $disablePadding nxstyle="tertiary-light" onClick={handleClearSearch}>
							<Icon path={mdiClose} size="16px" />
						</Button>
					}
					value={localSearchQuery}
					onChange={handleSearchChange}
					height={32}
				/>
				{localSearchQuery.length > 0 && <SearchModeToggleButton />}
			</TopPageWrapper>
			{/* {error && <ErrorAlert style={{ margin: 16 }} errorMessage={error.message} />} */}
		</>
	);
}

const TopPageWrapper = styled.div``;

const TopActionWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		height: 26px;
		justify-content: space-between;
		padding: ${theme.spacing(2)};
	`,
);

const TopActionButtonWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		gap: ${theme.spacing(2)};
		height: 32px;
	`,
);

const SearchBar = styled(TextInput)(
	({ theme }) => css`
		margin: 0;
		padding: ${theme.spacing(2, 2, 1, 2)};
	`,
);
