import { TitleFull } from '@warehouse/title/core';
import * as _ from 'lodash/fp';
import { isInAndDefined } from '@warehouse/shared/util';
import { TitleCreationFormData } from './title-creation-form-data';
import { CreateTitleInput } from '../infra/CreateTitleInput';
import { areCreativeCountriesOfOriginInherited, areOriginalLanguagesInherited } from '../core/creation.service';
import { filterProductionCountriesOfOrigin, getInheritedCountriesOfOrigin } from '../core/countries-of-origin';
import { createOriginalLanguage } from '../core/models/original-languages';

export function titleCreationFormDataToCreateTitleInputData({
	titleCreationFormData,
	dryRanTitle,
}: {
	titleCreationFormData: TitleCreationFormData;
	dryRanTitle: TitleFull | undefined;
}): CreateTitleInput['data'] {
	const shouldIncludeCountriesOfOrigin = !areCreativeCountriesOfOriginInherited(
		titleCreationFormData.countriesOfOrigin,
		dryRanTitle,
	);
	const shouldIncludeOriginalLanguages = !areOriginalLanguagesInherited(
		titleCreationFormData.originalLanguages,
		dryRanTitle,
	);

	return {
		...titleCreationFormData,
		countriesOfOrigin: shouldIncludeCountriesOfOrigin ? titleCreationFormData.countriesOfOrigin : undefined,
		originalLanguages: shouldIncludeOriginalLanguages
			? mapOriginalLanguages(titleCreationFormData.originalLanguages)
			: undefined,
		productionCountries: shouldIncludeCountriesOfOrigin
			? _.pipe(getInheritedCountriesOfOrigin, filterProductionCountriesOfOrigin)(dryRanTitle)
			: undefined,
		platformSpecification: isInAndDefined(titleCreationFormData, 'platformSpecificationId')
			? titleCreationFormData.platformSpecificationId
			: undefined,
	};
}

export function mapOriginalLanguages(
	originalLanguages: string[] | undefined,
): CreateTitleInput['data']['originalLanguages'] {
	if (!originalLanguages) {
		return undefined;
	}

	return originalLanguages.map((language, index) =>
		createOriginalLanguage({
			originalLanguage: language,
			listingOrder: index + 1,
		}),
	);
}
