import React, { useEffect, useMemo } from 'react';
import { PageHeader } from '@warehouse/object-browser/feature-wild-card-search';
import { ObjectBrowser as ObjectBrowserFeature } from '@warehouse/object-browser/feature-object-browser';
import {
	ObjectBrowserStoreProvider,
	objectBrowserStoreSelector,
	useObjectBrowserStore,
} from '@warehouse/object-browser/domain';
import { Card } from '@mui/material';
import { BucketIdUtils, StoreResourceIdentifier } from '@warehouse/object-browser/core';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { CardWrapper, Wrapper } from './styles';
import { ObjectBrowserRedirecter } from './ObjectBrowserRedirecter';

export function ObjectBrowserFromResourceType() {
	const params = useParams();

	const resourceIdentifier = useMemo((): null | StoreResourceIdentifier => {
		if (!params.resourceId || !['bucket', 'folder'].includes(params?.resourceType || '')) return null;

		if (params.resourceType === 'bucket') {
			return { type: 'bucket', bucketId: BucketIdUtils.cast(params.resourceId!) };
		}
		return { type: 'folderUuid', folderUuid: params.resourceId! };
	}, [params]);

	if (!resourceIdentifier) {
		return <ObjectBrowserRedirecter />;
	}
	return <ObjectBrowser resourceIdentifier={resourceIdentifier} />;
}

export function ObjectBrowser({ resourceIdentifier }: { resourceIdentifier: StoreResourceIdentifier }) {
	return (
		<ObjectBrowserStoreProvider>
			<ObjectBrowserInner resourceIdentifier={resourceIdentifier} />
		</ObjectBrowserStoreProvider>
	);
}

function ObjectBrowserInner({ resourceIdentifier }: { resourceIdentifier: StoreResourceIdentifier }) {
	const actions = useObjectBrowserStore(objectBrowserStoreSelector.actions);

	const resourceIdentifierFromStore = useObjectBrowserStore(objectBrowserStoreSelector.resourceIdentifier);

	useEffect(() => {
		if (!isEqual(resourceIdentifier, resourceIdentifierFromStore)) {
			actions.setResourceIdentifier(resourceIdentifier);
		}
	}, [actions, resourceIdentifier, resourceIdentifierFromStore]);

	return (
		<Wrapper>
			<CardWrapper>
				<Card style={{ boxShadow: 'none', borderRadius: 8 }} sx={{ minWidth: 275, overflow: 'visible' }}>
					<PageHeader />
				</Card>
				<Card style={{ flex: 1, boxShadow: 'none', borderRadius: 8 }}>
					<ObjectBrowserFeature />
				</Card>
			</CardWrapper>
		</Wrapper>
	);
}
