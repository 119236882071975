import { ReleaseRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { territoriesHelper } from '@warehouse/global-entities/core';

const { getLabel } = territoriesHelper;

function regionSortingFn(a: ReleaseRegion, b: ReleaseRegion): number {
	const aLabel = getLabel(a.country || a.countryRegion || '');
	const bLabel = getLabel(b.country || b.countryRegion || '');
	return aLabel?.localeCompare(bLabel || '') || 0;
}

export function regionUuidSortingFn(a: string, b: string): number {
	const aLabel = getLabel(a);
	const bLabel = getLabel(b);
	return aLabel?.localeCompare(bLabel || '') || 0;
}

export default regionSortingFn;
