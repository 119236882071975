import { TitleFull as Title } from '@warehouse/title/core';

function checkIfInherited(key: string, inheritedValues: any) {
	if (inheritedValues) {
		return undefined;
	}
	return key;
}

function getBasic(inheritedValues: Title | undefined) {
	return inheritedValues?.metadata?.coreMetadata?.basic;
}

function fieldsToValidateByTitleType(inheritedValues: Title | undefined) {
	return {
		Movie: [
			checkIfInherited('countriesOfOrigin', getBasic(inheritedValues)?.countriesOfOrigin?.displayValue?.length),
			checkIfInherited('releaseDate', getBasic(inheritedValues)?.releaseDate?.displayValue),
			checkIfInherited('originalLanguages', getBasic(inheritedValues)?.originalLanguages?.displayValue?.length),
			checkIfInherited('approximateLength', getBasic(inheritedValues)?.runLength?.displayValue),
		].filter((v) => !!v),
		Season: [
			checkIfInherited('countriesOfOrigin', getBasic(inheritedValues)?.countriesOfOrigin?.displayValue?.length),
			checkIfInherited('releaseDate', getBasic(inheritedValues)?.releaseDate?.displayValue),
			checkIfInherited('sequenceNumber', getBasic(inheritedValues)?.sequenceInfo?.number?.displayValue),
			checkIfInherited('originalLanguages', getBasic(inheritedValues)?.originalLanguages?.displayValue?.length),
			checkIfInherited('approximateLength', getBasic(inheritedValues)?.runLength?.displayValue),
		].filter((v) => !!v),
		Series: [
			checkIfInherited('countriesOfOrigin', getBasic(inheritedValues)?.countriesOfOrigin?.displayValue?.length),
			checkIfInherited('releaseDate', getBasic(inheritedValues)?.releaseDate?.displayValue),
			checkIfInherited('originalLanguages', getBasic(inheritedValues)?.originalLanguages?.displayValue?.length),
		].filter((v) => !!v),
		Edit: [checkIfInherited('editUse', getBasic(inheritedValues)?.versionIntent?.editUse?.displayValue)].filter(
			(v) => !!v,
		),
		Episode: [
			checkIfInherited('countriesOfOrigin', getBasic(inheritedValues)?.countriesOfOrigin?.displayValue?.length),
			checkIfInherited('releaseDate', getBasic(inheritedValues)?.releaseDate?.displayValue),
			'sequenceNumber',
			checkIfInherited('originalLanguages', getBasic(inheritedValues)?.originalLanguages?.displayValue?.length),
			checkIfInherited('approximateLength', getBasic(inheritedValues)?.runLength?.displayValue),
		].filter((v) => !!v),
		Compilation: ['releaseDate'].filter((v) => !!v),
		Supplemental: [
			checkIfInherited('countriesOfOrigin', getBasic(inheritedValues)?.countriesOfOrigin?.displayValue?.length),
			checkIfInherited('releaseDate', getBasic(inheritedValues)?.releaseDate?.displayValue),
			checkIfInherited('originalLanguages', getBasic(inheritedValues)?.originalLanguages?.displayValue?.length),
			checkIfInherited('approximateLength', getBasic(inheritedValues)?.runLength?.displayValue),
		].filter((v) => !!v),
		Manifestation: ['platformSpecification', 'alid'],
	};
}

export default fieldsToValidateByTitleType;
