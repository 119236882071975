import { Subscription } from 'rxjs';
import { Entries } from 'type-fest';
import { BucketId, FolderPath, FolderPathUtils, FullPath, NxObject } from '@warehouse/object-browser/core';
import { nxObjectRepositorySingleton } from '@warehouse/object-browser/infra';
import { PaginatedDocuments, QueryObserverResult } from '@warehouse/shared/core';

export type Subscriber = (v: QueryObserverResult<PaginatedDocuments<NxObject>>) => void;

// TODO: Implement tests for this class, how ?
export class TreeFolderSubscriptionManager {
	// Cannot use sharp type as it does not work with typeof (for the typing of Entries)
	private folderSubscription: Record<BucketId, Record<FolderPath, Subscription>> = {};

	#nxObjectRepo = nxObjectRepositorySingleton.get();

	#unsubscribeToFolders({ bucketId, path }: FullPath) {
		if (!this.folderSubscription[bucketId]) return;
		(
			Object.entries(this.folderSubscription[bucketId]) as Entries<(typeof this.folderSubscription)[typeof bucketId]>
		).forEach(([subscriptionPath, subscription]) => {
			if (FolderPathUtils.isChildOf(path, subscriptionPath)) {
				subscription.unsubscribe();
				delete this.folderSubscription[bucketId][subscriptionPath];
			}
		});
	}

	openBucket(bucketId: BucketId, subscriber: Subscriber) {
		this.#unsubscribeToFolders({ bucketId, path: FolderPathUtils.root() });
		const path = FolderPathUtils.root();
		this.folderSubscription[bucketId] = {
			[path]: this.#nxObjectRepo
				.watchTree({
					resourceIdentifier: {
						type: 'bucket',
						bucketId,
					},
				})
				.subscribe(subscriber),
		};
	}

	closeBucket(bucketId: BucketId) {
		this.#unsubscribeToFolders({ bucketId, path: FolderPathUtils.root() });
	}

	openFolder(fullPath: FullPath, subscriber: Subscriber) {
		this.#unsubscribeToFolders(fullPath);
		this.folderSubscription[fullPath.bucketId][fullPath.path] = this.#nxObjectRepo
			.watchTree({
				resourceIdentifier: {
					type: 'folderPath',
					folderPath: fullPath.path,
					bucketId: fullPath.bucketId,
				},
			})
			.subscribe(subscriber);
	}

	closeFolder(fullPath: FullPath) {
		this.#unsubscribeToFolders(fullPath);
	}

	unsubscribeAll() {
		(Object.entries(this.folderSubscription) as Entries<typeof this.folderSubscription>).forEach(([bucketId]) => {
			this.#unsubscribeToFolders({ bucketId, path: FolderPathUtils.root() });
		});
	}
}
