import React, { createContext, useMemo, useState, useEffect, useCallback } from 'react';
import {
	CompanyDisplayCredit,
	DisplayString,
} from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/TitleMetadata';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { CompanyDisplayCreditRegion } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Region';
import { isInherited, resetInheritedArrayValues } from '@warehouse/title/core';
import { v4 as uuid } from 'uuid';
import { makeItInherited, titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { deepDeleteKey } from '@warehouse/shared/util';
import useTitleAutoSave from '../../../../hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../../hooks/useMemoJsonPath';
import { useReordering } from '../../../../hooks/useReordering';

type CompanyDisplayCreditWithUuid = CompanyDisplayCredit & { __uuid: string };

export interface CompanyDisplayCreditsContextInterface {
	data: OptionalInherited<CompanyDisplayCreditWithUuid[]> | undefined;
	readonlyData: OptionalInherited<CompanyDisplayCreditWithUuid[]> | undefined;
	setRegions(regions: CompanyDisplayCreditRegion[], companyDisplayCreditIndex: number): void;
	removeDisplayString(displayStringIndexes: number[], companyDisplayCreditIndex: number): void;
	addDisplayString(displayString: DisplayString, companyDisplayCreditIndex: number): void;
	editDisplayName(displayName: string, displayStringIndex: number, companyDisplayCreditIndex: number): void;
	editLanguage(localeUuid: string, displayStringIndex: number, companyDisplayCreditIndex: number): void;
	removeCompanyDisplayCredit(index: number): void;
	addCompanyDisplayCredit(companyDisplayCredit: CompanyDisplayCredit): void;
	editDisplaySequence(displaySequence: number | undefined, companyDisplayCreditIndex: number): void;
	commit(): void;
	isInherited: boolean;
}

export const CompanyDisplayCreditContext = createContext<CompanyDisplayCreditsContextInterface>(null!);

function sortingFn(a: CompanyDisplayCredit, b: CompanyDisplayCredit): number {
	if (a.displaySequence === undefined && b.displaySequence === undefined) return 0;
	return (
		(a.displaySequence === undefined ? Infinity : a.displaySequence) -
		(b.displaySequence === undefined ? Infinity : b.displaySequence)
	);
}

function wrapWithUuids(cdcs: CompanyDisplayCredit[]): CompanyDisplayCreditWithUuid[] {
	return cdcs.map((cdc) => ({
		...cdc,
		__uuid: uuid(),
	}));
}

function removeUuids(cdcs: CompanyDisplayCreditWithUuid[]): CompanyDisplayCredit[] {
	return cdcs.map((cdc) => {
		const { __uuid: _uuidToRemove, ...rest } = cdc;
		return rest;
	});
}

export function CompanyDisplayCreditsProvider({ children }: { children: React.ReactNode }) {
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { value, commit, setValue, readOnlyValue } = useTitleAutoSave<
		OptionalInherited<CompanyDisplayCreditWithUuid[]>,
		CompanyDisplayCreditWithUuid
	>({
		label: 'Company Display Credits',
		path: useMemoJsonPath(['metadata', 'coreMetadata', 'companyDisplayCredits']),
		mutatePayloadBeforeSave: (payload) => ({
			...payload,
			displayValue: payload?.displayValue ? removeUuids(payload.displayValue) : undefined,
		}),
		mutatePayloadOnLoad: (payload) => ({ ...payload, displayValue: wrapWithUuids(payload?.displayValue || []) }),
		isRowValid: useCallback(
			(row: CompanyDisplayCreditWithUuid) =>
				(row.displayStrings?.length ?? 0) > 0 && row.displayStrings?.every((ds) => !!ds.displayString.trim()),
			[],
		),
	});

	const { getBeIndexByFeIndex, toFe, updateFeToBeIndexMap, indexMap } = useReordering<CompanyDisplayCreditWithUuid>({
		value: value?.displayValue || [],
		sortingFn,
	});

	const [data, setData] = useState<OptionalInherited<CompanyDisplayCreditWithUuid[]>>(
		value
			? {
					...value,
					displayValue: toFe(value.displayValue || []),
			  }
			: makeItInherited<OptionalInherited<CompanyDisplayCreditWithUuid[]>>([]),
	);

	useEffect(() => {
		if (value) {
			setData({
				...value,
				displayValue: toFe(value.displayValue || []),
			});
		} else {
			setData(makeItInherited<OptionalInherited<CompanyDisplayCreditWithUuid[]>>([]));
		}
	}, [indexMap, editMode]);

	const setRegions = (regions: CompanyDisplayCreditRegion[], companyDisplayCreditIndex: number) => {
		const newValue = [...(value?.displayValue || [])];
		const beCdcIndex = getBeIndexByFeIndex(companyDisplayCreditIndex);
		newValue[beCdcIndex] = { ...newValue[beCdcIndex], regions };
		setValue((prevState) => ({ ...prevState, displayValue: newValue }));
		updateFeToBeIndexMap(newValue);
	};

	const removeDisplayString = (indexes: number[], companyDisplayCreditIndex: number) => {
		const beCdcIndex = getBeIndexByFeIndex(companyDisplayCreditIndex);
		const newValue = [...(value?.displayValue || [])].map((cdc, index) => {
			if (index === beCdcIndex) {
				return {
					...cdc,
					displayStrings: cdc.displayStrings.filter((_, idx) => !indexes.includes(idx)),
				};
			}
			return cdc;
		});
		setValue((prevState) => ({ ...prevState, displayValue: newValue }));
		updateFeToBeIndexMap(newValue);
	};

	const addDisplayString = (displayString: DisplayString, companyDisplayCreditIndex: number) => {
		const beCdcIndex = getBeIndexByFeIndex(companyDisplayCreditIndex);
		const newValue = [...(value?.displayValue || [])].map((cdc, index) => {
			if (index === beCdcIndex) {
				return {
					...cdc,
					displayStrings: [...cdc.displayStrings, displayString],
				};
			}
			return cdc;
		});
		setValue((prevState) => ({ ...prevState, displayValue: newValue }));
		updateFeToBeIndexMap(newValue);
	};

	const editDisplayName = (displayName: string, displayStringIndex: number, companyDisplayCreditIndex: number) => {
		const beCdcIndex = getBeIndexByFeIndex(companyDisplayCreditIndex);
		const newValue = [
			...(value?.displayValue?.map((cdc, index) => {
				if (index === beCdcIndex) {
					return {
						...cdc,
						displayStrings: cdc.displayStrings.map((ds, dsIndex) => {
							if (dsIndex === displayStringIndex) {
								return {
									...ds,
									displayString: displayName,
								};
							}
							return ds;
						}),
					};
				}
				return cdc;
			}) || []),
		];

		setValue((prevState) => ({ ...prevState, displayValue: newValue }));
		updateFeToBeIndexMap(newValue);
	};

	const editLanguage = (localeUuid: string, displayStringIndex: number, companyDisplayCreditIndex: number) => {
		const beCdcIndex = getBeIndexByFeIndex(companyDisplayCreditIndex);

		const newValue = [
			...(value?.displayValue?.map((cdc, index) => {
				if (index === beCdcIndex) {
					return {
						...cdc,
						displayStrings: cdc.displayStrings.map((ds, dsIndex) => {
							if (dsIndex === displayStringIndex) {
								return {
									...ds,
									language: localeUuid,
								};
							}
							return ds;
						}),
					};
				}
				return cdc;
			}) || []),
		];

		setValue((prevState) => ({ ...prevState, displayValue: newValue }));
		updateFeToBeIndexMap(newValue);
	};

	const removeCompanyDisplayCredit = (index: number) => {
		const newValue = (value?.displayValue || []).filter((_, idx) => idx !== getBeIndexByFeIndex(index));
		setValue(resetInheritedArrayValues<CompanyDisplayCreditWithUuid>(newValue, title));
		updateFeToBeIndexMap(newValue);
	};

	const addCompanyDisplayCredit = (companyDisplayCredit: CompanyDisplayCredit) => {
		const newValue = [...(value?.displayValue || []), { ...companyDisplayCredit, __uuid: uuid() }];
		setValue((prevState) => ({ ...prevState, displayValue: newValue }), false);
		updateFeToBeIndexMap(newValue);
	};

	const editDisplaySequence = (displaySequence: number, companyDisplayCreditIndex: number) => {
		const newValue = [...(value?.displayValue || [])];
		const beCdcIndex = getBeIndexByFeIndex(companyDisplayCreditIndex);
		newValue[beCdcIndex] = {
			...newValue[beCdcIndex],
			displaySequence,
		};
		setValue((prevState) => ({ ...prevState, displayValue: newValue }));
		updateFeToBeIndexMap(newValue);
	};

	const isInheritedValue = value
		? isInherited<CompanyDisplayCreditWithUuid[]>({ inheritedObject: deepDeleteKey(value, '__uuid') }).isInherited
		: false;

	const values = useMemo(
		() => ({
			data,
			readonlyData: readOnlyValue
				? {
						...readOnlyValue,
						displayValue: readOnlyValue?.displayValue?.sort(sortingFn),
				  }
				: makeItInherited<OptionalInherited<CompanyDisplayCreditWithUuid[]>>([]),
			setRegions,
			removeDisplayString,
			addDisplayString,
			editDisplayName,
			editLanguage,
			removeCompanyDisplayCredit,
			addCompanyDisplayCredit,
			editDisplaySequence,
			commit,
			isInherited: isInheritedValue,
		}),
		[
			data,
			readOnlyValue,
			setRegions,
			removeDisplayString,
			addDisplayString,
			editDisplayName,
			editLanguage,
			removeCompanyDisplayCredit,
			addCompanyDisplayCredit,
			editDisplaySequence,
			commit,
			isInheritedValue,
		],
	);

	return <CompanyDisplayCreditContext.Provider value={values}>{children}</CompanyDisplayCreditContext.Provider>;
}
