import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import React from 'react';
import { CombinedWorkType } from '@warehouse/title/core';
import { TitleIcon } from '@warehouse/title/ui';
import { CheckIcon } from '../CheckIcon';
import Chip from '../../../../src/components/library/Chip';

interface DefaultTitleCardProps {
	title: string;
	subTitle1?: string;
	subTitle2?: string;
	releaseYear?: string;
	minWidth?: number;
	workType: CombinedWorkType;
	showPrimaryTick?: boolean;
	hideReleaseYear: boolean;
}

export function DefaultTitleCard({
	title,
	subTitle1,
	subTitle2,
	releaseYear,
	workType,
	minWidth,
	showPrimaryTick,
	hideReleaseYear,
}: DefaultTitleCardProps) {
	return (
		<Wrapper minWidth={minWidth}>
			<TitleIcon combinedWorkType={workType} variant="default" />
			<ContentWrapper>
				<Title variant="bodyMediumRegular">{title}</Title>
				{subTitle1 && (
					<Title color="text.secondary" variant="buttonSmallRegular">
						{subTitle1}
					</Title>
				)}
				{subTitle2 && (
					<Title color="text.secondary" variant="buttonSmallRegular">
						{subTitle2}
					</Title>
				)}
			</ContentWrapper>
			<LeftWrapper>
				{showPrimaryTick && (
					<div data-testid="primary-tick">
						<CheckIcon />
					</div>
				)}
				{releaseYear && !hideReleaseYear && (
					<ChipWrapper>
						<Chip chipsWidth={91} label={releaseYear} />
					</ChipWrapper>
				)}
			</LeftWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div<{ minWidth?: number }>(
	({ minWidth }) => css`
		align-items: center;
		display: flex;
		height: 48px;
		${minWidth &&
		css`
			min-width: ${minWidth}px;
		`}
		width: 100%;
	`,
);

const Title = styled(Typography)(
	() => css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
);

const ContentWrapper = styled.div(
	() => css`
		display: flex;
		flex: 1;
		flex-direction: column;
		max-width: 500px;
		overflow: hidden;
	`,
);

const ChipWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		height: 48px;
		margin-right: ${theme.spacing(2)};
		padding: ${theme.spacing(0, 1)};
	`,
);

const LeftWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		margin-left: auto;
		padding-left: ${theme.spacing(2)};
	`,
);
