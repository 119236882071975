import { Inherited, isUnknownInherited, TitleFull } from '@warehouse/title/core';
import lodash from 'lodash';
import { useLocation } from 'react-router-dom';

type JumpToParentButtonPresenterOutput =
	| {
			shouldDisplayJumpToParentButton: true;
			parentTitleLink: string;
	  }
	| {
			shouldDisplayJumpToParentButton: false;
			parentTitleLink?: never;
	  };

export function jumpToParentButtonPresenter({
	path,
	title,
	disabled,
}: {
	path?: string;
	title: TitleFull;
	disabled?: boolean;
}): JumpToParentButtonPresenterOutput {
	if (disabled)
		return {
			shouldDisplayJumpToParentButton: false,
		};

	if (!path)
		return {
			shouldDisplayJumpToParentButton: false,
		};

	const value = lodash.get(title, path);

	if (!isUnknownInherited(value)) {
		return {
			shouldDisplayJumpToParentButton: false,
		};
	}

	const { origin } = (value as Inherited<unknown>).inherited;

	if (!origin) {
		return {
			shouldDisplayJumpToParentButton: false,
		};
	}

	const { hash, search } = useLocation();

	return {
		shouldDisplayJumpToParentButton: true,
		parentTitleLink: `/title/${origin}${search}${hash}`,
	};
}
