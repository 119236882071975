import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

// TYPES
import { Release } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { isInherited, resetInheritedArrayValues } from '@warehouse/title/core';
import { ReleaseRegion } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { Row, SortingFn } from '@tanstack/react-table';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { Typography } from '@mui/material';

// HOOKS
import { territoriesHelper } from '@warehouse/global-entities/core';
import useTitleAutoSave from '../../../../hooks/useTitleAutoSave/useTitleAutoSave';
import useMemoJsonPath from '../../../../hooks/useMemoJsonPath';

// UTILS
import { fromBasic } from '../../../../../../utils/titleGetProperty';

// LIBRARY
import TitleEditableField from '../../../../../../components/titles/TitleEditableField';
import { dateToString, stringToDate } from '../../../../../../components/library/DatePicker/DatePicker';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import ChipDisplay from '../../../../../../components/library/ChipDisplay';
import { CustomColumnDef } from '../../../../../../components/library/SimpleTable/customColumnDef';
import CellTerritory from './CellTerritory';
import CellReleaseType from './CellReleaseType';
import CellDate from './CellDate';

// JSON
import releaseTypeList from '../../../../../../assets/json-administration-profiles/releaseTypeList.json';
import computeErrors from './computeErrors';
import regionSortingFn from './regionSortingFn';
import deepEquality from '../../../../../../utils/deepEquality';
import useTooltip from '../../../../../../utils/hooks/useTooltips';

const StyledDiv = styled.div(
	({ theme }) => css`
		.table-wrapper {
			margin-left: ${theme.spacing(0.75)};
			margin-right: ${theme.spacing(0.75)};
			width: auto !important;
		}
	`,
);

const { territories, getKey } = territoriesHelper;

const territoriesSortingFn: SortingFn<Release> = (rowA: Row<Release>, rowB: Row<Release>) => {
	const aFirstDistr = rowA.original.distrTerritories?.sort(regionSortingFn)?.[0];
	const bFirstDistr = rowB.original.distrTerritories?.sort(regionSortingFn)?.[0];

	if (!aFirstDistr || !bFirstDistr) return 0;
	const aLabel = territories.find(
		(territory) => territory.value === (aFirstDistr.country || aFirstDistr.countryRegion),
	)?.label;
	const bLabel = territories.find(
		(territory) => territory.value === (bFirstDistr.country || bFirstDistr.countryRegion),
	)?.label;
	return aLabel?.localeCompare(bLabel || '') || 0;
};

function territoriesFilterFn(row: Row<Release>, _: string, value: string): boolean {
	if (value === 'default') return true;
	const foundUuids = row.original.distrTerritories?.map(
		(t: ReleaseRegion) => territories.find((territory) => territory.value === (t.country || t.countryRegion))?.value,
	);
	return foundUuids?.find((uuid) => uuid === value) !== undefined;
}

function ReleasesTable({ selectedTerritoryUuid }: { selectedTerritoryUuid?: string }) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);
	const { value, setValue, readOnlyValue, hasInvalidRow, commit } = useTitleAutoSave<
		OptionalInherited<Release[]>,
		Release
	>({
		label: 'Release',
		path: useMemoJsonPath(fromBasic(['releaseHistories'])),
		isRowValid: (row) => (row.distrTerritories?.length ?? 0) >= 1 && !!row.releaseType.trim() && !!row.date.trim(),
	});
	const [internalRows, setInternalRows] = useState<Release[]>([]);
	const territoriesTooltip = useTooltip('coreMetadata.basic.releaseHistories.distrTerritories');
	const releaseTypeTooltip = useTooltip('coreMetadata.basic.releaseHistories.releaseType');
	const dateTooltip = useTooltip('coreMetadata.basic.releaseHistories.date');
	const isInheritedValue = value ? isInherited<Release[]>({ inheritedObject: value }).isInherited : false;

	const onInternalRowsChange = (rows: Row<Release>[]) => {
		if (
			!deepEquality(
				rows.map((row) => row.original),
				internalRows,
			)
		) {
			setInternalRows(rows.map((row) => row.original));
		}
	};

	const releasesColumns: CustomColumnDef<Release, Release>[] = useMemo(
		() => [
			{
				id: 'territories',
				header: 'Territories',
				tooltip: territoriesTooltip?.tooltip,
				sortingFn: territoriesSortingFn,
				filterFn: territoriesFilterFn,
				accessorFn: (row) => row,
				renderReadOnly: (row) => {
					if (!Array.isArray(row.distrTerritories) || row.distrTerritories?.length === 0) {
						return null;
					}

					const foundLabels = row.distrTerritories?.map(
						(t: ReleaseRegion) =>
							territories.find((territory) => territory.value === (t.country || t.countryRegion))?.label || 'Unknown',
					);
					return (
						<ChipDisplay
							variant={isInheritedValue ? 'prefilled' : 'default'}
							values={foundLabels}
							width={200}
							height={26}
							allowOverflow
						/>
					);
				},
				width: 180,
				cell: CellTerritory,
			},
			{
				id: 'type',
				header: 'Release Type',
				tooltip: releaseTypeTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: (row) => {
					const releaseTypeLabel = releaseTypeList.find((r) => r.name === row.releaseType)?.label;
					return (
						<Typography
							style={{ marginLeft: 12 }}
							className={isInheritedValue ? 'inherited' : 'default'}
							fontWeight={400}
							fontSize="14px"
						>
							{releaseTypeLabel || 'Unknown'}
						</Typography>
					);
				},
				cell: CellReleaseType,
			},
			{
				id: 'date',
				header: 'Date',
				tooltip: dateTooltip?.tooltip,
				accessorFn: (row) => row,
				renderReadOnly: (row) => (
					<Typography
						style={{ marginLeft: 12 }}
						className={isInheritedValue ? 'inherited' : 'default'}
						fontWeight={400}
						fontSize="14px"
					>
						{dateToString(stringToDate(row.date))}
					</Typography>
				),
				cell: CellDate,
				width: 110,
			},
		],
		[selectedTerritoryUuid, isInheritedValue],
	);

	const onRowAdd = () => {
		const newRelease: Release = {
			releaseType: '',
			date: '',
			distrTerritories:
				selectedTerritoryUuid && selectedTerritoryUuid !== 'default'
					? [
							{
								[getKey(selectedTerritoryUuid)]: selectedTerritoryUuid,
							},
					  ]
					: [],
		};

		setValue((prev) => ({
			...prev,
			displayValue: [...(prev?.displayValue || []), newRelease],
		}));
	};

	const onRowDelete = (selectedIndexes: string[]) => {
		const newData = value?.displayValue?.filter((el, idx) => !selectedIndexes.map((x) => +x).includes(idx));
		setValue(resetInheritedArrayValues<Release>(newData, title));
		commit();
	};

	return (
		<StyledDiv>
			<TitleEditableField
				label="Releases"
				path="coreMetadata.basic.releaseHistories"
				direction="column"
				readComponent={undefined}
				editComponent={undefined}
			/>
			<InheritanceOnValueChangeSimpleTableWrapper<Release>
				sorting={[{ id: 'territories', desc: false }]}
				columnFilters={[{ id: 'territories', value: selectedTerritoryUuid || 'default' }]}
				editMode={editMode}
				showBottomActionsBar={editMode}
				tableStyle="border"
				columns={releasesColumns}
				onInternalRowsChange={onInternalRowsChange}
				errors={editMode ? computeErrors(internalRows) : {}}
				data={(editMode ? value?.displayValue : readOnlyValue?.displayValue) || []}
				onRowAdd={onRowAdd}
				canAddRow={hasInvalidRow === false}
				onRowEdit={() => {}}
				onRowDelete={(selectedIndexes: string[]) => {
					onRowDelete(selectedIndexes);
				}}
				enableAddMultipleRows={false}
				selectRowModeByDefault
				customPropsCell={{ setValue, commit, isInherited: isInheritedValue }}
				noOverflow
			/>
		</StyledDiv>
	);
}

export default ReleasesTable;
