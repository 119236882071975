import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import React from 'react';
import {
	TitleOverviewLeftWrapperBase,
	Data,
	getPlaceholderArea,
	NonCenteredData,
	TiniestSummary,
	LicensorField,
} from '@warehouse/legacy-title-overview';
import styled, { css } from 'styled-components';
import { ManifestationOverviewByAbstractTypeProps } from './types';
import { useTiniestSummaryTitleData } from './use-tiniest-summary';

// FIELDS
import { CountriesOfOriginField } from '../../CountriesOfOriginField';
import { ReleaseDateField } from '../../ReleaseDateField';
import { ProductionCountriesField } from '../../ProductionCountriesField';
import { AspectRatioField } from '../../AspectRatioField';
import { OriginalLanguagesField } from '../../OriginalLanguagesField';
import { FrameRateField } from '../../FrameRateField';
import { RunLengthField } from '../../RunLengthField';
import { AlidField } from '../../AlidField';
import { PlatformSpecificationField } from '../../PlatformSpecificationField';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar topbar topbar'
			'licensor countriesOfOrigin ph-1 synopsis'
			'releaseDate productionCountries ph-2 synopsis'
			'aspectRatio originalLanguage specification synopsis'
			'frameRate runLength alid synopsis';
		grid-template-columns: 25% 25% 25% 25%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

export function Series({ topBar }: ManifestationOverviewByAbstractTypeProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	return (
		<TitleOverviewLeftWrapper>
			{topBar}
			{/* ROW 1 */}
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField title={title} />
			</Data>
			<Data area="countriesOfOrigin">
				<CountriesOfOriginField />
			</Data>
			{getPlaceholderArea({ left: true, bottom: true, name: 'ph-1' })}
			<NonCenteredData enableRightBorder enableBottomBorder={false} area="synopsis">
				<TiniestSummary
					tiniestSummary={tiniestSummary?.data?.displayValue || ''}
					isInherited={tiniestSummary.isInherited}
				/>
			</NonCenteredData>
			{/* ROW 2 */}
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			<Data area="productionCountries">
				<ProductionCountriesField />
			</Data>
			{getPlaceholderArea({ left: true, bottom: true, name: 'ph-2' })}
			{/* ROW 3 */}
			<Data enableLeftBorder={false} area="aspectRatio">
				<AspectRatioField />
			</Data>
			<Data area="originalLanguage">
				<OriginalLanguagesField />
			</Data>
			<Data area="specification">
				<PlatformSpecificationField />
			</Data>
			{/* ROW 4 */}
			<Data enableBottomBorder={false} enableLeftBorder={false} area="frameRate">
				<FrameRateField />
			</Data>
			<Data enableBottomBorder={false} area="runLength">
				<RunLengthField />
			</Data>
			<Data enableBottomBorder={false} area="alid">
				<AlidField />
			</Data>
		</TitleOverviewLeftWrapper>
	);
}
