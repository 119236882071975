import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import { JumpToParentButton, JumpToParentButtonHoverWrapper } from '@warehouse/title/feature-jump-to-parent-button';
import TitleTooltip from './TitleTooltip';

interface TitleEditableFieldProps {
	required?: boolean;
	ariaLabel?: string;
	label: string;
	forcedEditMode?: boolean;
	readComponent?: ReactNode;
	editComponent?: ReactNode;
	disableFlexGrow?: boolean;
	direction?: 'row' | 'column';
	fullWidth?: boolean;
	height?: number;
	labelStyle?: React.CSSProperties;
	labelWidth?: number;
	path?: string;
	wrapperStyle?: React.CSSProperties;
	jumpToParentButtonDisabled?: boolean;
	tooltipDisabled?: boolean;
}

function TitleEditableField({
	required,
	label,
	ariaLabel,
	readComponent,
	editComponent,
	forcedEditMode,
	disableFlexGrow = true,
	direction = 'row',
	fullWidth,
	labelStyle = {},
	height,
	labelWidth,
	path,
	wrapperStyle,
	jumpToParentButtonDisabled = false,
	tooltipDisabled = false,
}: TitleEditableFieldProps) {
	const editMode = forcedEditMode ?? useTitleEditorStore(titleEditorStoreSelector.editMode);

	return (
		<JumpToParentButtonHoverWrapper>
			<Wrapper
				direction={direction}
				fullWidth={fullWidth}
				height={height}
				disableFlexGrow={disableFlexGrow}
				className="title-editable-field"
				aria-label={ariaLabel}
				style={wrapperStyle}
			>
				<LabelWrapper labelWidth={labelWidth}>
					<HeaderTypography variant="buttonMediumMedium" className="title-editable-field-label" style={labelStyle}>
						{`${label}${required ? '*' : ''}`}
						{path && !tooltipDisabled && <TitleTooltip path={path} />}
						{path && <JumpToParentButton path={path} disabled={jumpToParentButtonDisabled} />}
					</HeaderTypography>
				</LabelWrapper>
				{editMode ? editComponent : readComponent}
			</Wrapper>
		</JumpToParentButtonHoverWrapper>
	);
}

export default TitleEditableField;

interface WrapperProps {
	direction: string;
	isInherited?: boolean;
	fullWidth?: boolean;
	height?: number;
	labelWidth?: number;
	disableFlexGrow?: boolean;
}

const InheritanceIcon = styled.div`
	${({ theme }) => css`
		svg {
			color: ${theme.palette.text.secondary};
			transform: rotate(90deg);
		}
		cursor: pointer;
		display: none;
		position: absolute;
		right: 5px;
		top: calc(50% - 8px);
	`}
`;

const LabelWrapper = styled.div<{ labelWidth?: number }>(
	({ theme, labelWidth }) => css`
		align-items: center;
		display: flex;

		${labelWidth
			? css`
					width: ${labelWidth}px;
			  `
			: css`
					min-width: 144px;
			  `}

		svg {
			color: ${theme.palette.text.secondary};
		}
	`,
);

const Wrapper = styled.div<WrapperProps>`
	${({ theme, direction, fullWidth, height, disableFlexGrow }) => css`
		display: flex;
		position: relative;
		flex-direction: ${direction};
		padding:  0 ${theme.spacing(1)};
		box-sizing: border-box;
		
		${
			height &&
			css`
				height: ${height}px;
			`
		}
		
		> * {
			${
				disableFlexGrow
					? css`
							flex-grow: 0;
					  `
					: css`
							flex-grow: 1;
					  `
			}
        }
		
		&:hover {
			${InheritanceIcon} {
				display: block;
			}
        }
		
			${
				fullWidth &&
				css`
					align-items: stretch;
					width: 100%;
				`
			}
		

			${
				direction === 'column'
					? css`
							.MuiTypography-root {
								align-items: center;
								display: inline-flex;
								min-height: 38px;
							}
					  `
					: css`
							align-items: center;
							.MuiTypography-root {
								margin-right: ${theme.spacing(1)};
							}
					  `
			}
		}
	`}
`;

const HeaderTypography = styled(Typography)(
	() => css`
		align-items: center;
		display: flex;
		gap: 8px;
		min-height: 28px !important;
		white-space: nowrap;
	`,
);

type ReadOnlyTypographyProps = {
	variant?: 'prefilled' | 'default' | undefined;
	style?: CSSProperties;
	title?: string;
} & Omit<TypographyTypeMap['props'], 'variant'>;

export const ReadOnlyTypography = styled((props: ReadOnlyTypographyProps) => (
	<Typography
		{...props}
		variant="buttonMediumRegular"
		color="text.secondary"
		className={`read-only-value ${props.variant === 'prefilled' ? 'inherited' : ''}`}
	/>
))(() => css``);
