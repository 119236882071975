import React, { ChangeEvent, useEffect, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

// TYPES
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { Rating, RatingDescription } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';

// HOOKS
import { isInherited } from '@warehouse/title/core';
import { WithUuid } from '../../../../src/views/title/hooks/useTitleRecordsAutoSave/recordToArray';
import { TableTextInput } from '../../../../src/views/title/tab/productMetadata/style';

// UTILS
import deepEquality from '../../../../src/utils/deepEquality';
import { CellWrapper } from '../../../../src/components/library/SimpleTable/style';

function CellDescription(info: CellContext<WithUuid<Inherited<Rating>>, WithUuid<Inherited<Rating>>>) {
	const { table, getValue } = info;
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;
	const defaultLocale = table.options?.meta?.customPropsCell?.defaultLocale;
	const [localState, setLocaleState] = useState<RatingDescription[]>(getValue()?.displayValue?.descriptions || []);

	useEffect(() => {
		setLocaleState(getValue()?.displayValue?.descriptions || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getValue()]);

	const handleOnBlur = () => {
		const realDescriptions = getValue()?.displayValue?.descriptions || [];

		const validDescriptions = localState.filter((desc) => desc.description.length > 0);
		if (!deepEquality(localState, realDescriptions)) {
			editRecord({
				...getValue(),
				displayValue: {
					...getValue()?.displayValue,
					descriptions: validDescriptions,
				},
			});
		}
	};

	const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const newDescription = evt.target.value;
		setLocaleState((prevState) => {
			const newState = [...prevState];
			const index = newState.findIndex((desc) => desc.language === defaultLocale || desc.language === undefined);
			if (index !== -1 && newState[index].description !== newDescription) {
				newState[index] = { ...newState[index], description: newDescription };
			} else if (index === -1) {
				newState.push({
					description: newDescription,
					language: defaultLocale || undefined,
				});
			}
			return newState;
		});
	};

	const defaultLocaleDescription = localState?.find((desc) => desc.language === defaultLocale);

	const undefinedLocaleDescription = localState?.find((desc) => desc.language === undefined);

	return (
		<CellWrapper>
			<TableTextInput
				variant={isInherited({ inheritedObject: getValue() }).isInherited ? 'prefilled' : 'default'}
				value={defaultLocaleDescription?.description || undefinedLocaleDescription?.description || ''}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
			/>
		</CellWrapper>
	);
}

// eslint-disable-next-line import/no-default-export
export default CellDescription;
