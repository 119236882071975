import React from 'react';
import { TextInput } from '@warehouse/shared/legacy-ui';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import useTooltip from '../../../../utils/hooks/useTooltips';
import getLabelWithRequired from '../getLabelWithRequired';

export interface AlidFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
}

export function AlidField({ control, setValue, trigger, errors, isRequired }: AlidFieldProps) {
	const alidTooltip = useTooltip('coreMetadata.basic.versionIntent.audience.identifications.identifier');

	return (
		<Controller
			control={control}
			name="alid"
			render={({ field }) => (
				<TextInput
					tooltip={alidTooltip?.tooltip}
					ariaLabel="ALID field"
					value={field.value}
					error={isRequired ? (errors?.alid?.message as string) : ''}
					onChange={async (e) => {
						setValue('alid', e.target.value);
						if (isRequired) {
							await trigger('alid');
						}
					}}
					label={getLabelWithRequired('ALID', isRequired === true)}
				/>
			)}
		/>
	);
}
