import { useMemo } from 'react';
import { PlatformSpec, PlatformSpecService } from '@warehouse/global-entities/core';
import { ContentIdentifier, TitleFull } from '@warehouse/title/core';

export function findPlatformSpecContentIdentifier(title: TitleFull): ContentIdentifier | undefined {
	const audienceIdentifications = title.metadata.coreMetadata.basic.versionIntent?.audience?.identifications;
	if (!audienceIdentifications) return undefined;

	return Object.values(audienceIdentifications).find(
		(identification) =>
			PlatformSpecService.getPlatformSpecificationByContentIdentifier(identification.displayValue) !== undefined,
	)?.displayValue;
}

export function findPlatformSpecContentIdentifierRecordId(title: TitleFull): string | undefined {
	const audienceIdentifications = title.metadata.coreMetadata.basic.versionIntent?.audience?.identifications;
	if (!audienceIdentifications) return undefined;

	const platformSpecContentIdentifierRecordTuple = Object.entries(audienceIdentifications).find(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		([_, identification]) =>
			PlatformSpecService.getPlatformSpecificationByContentIdentifier(identification.displayValue) !== undefined,
	);

	return platformSpecContentIdentifierRecordTuple?.[0];
}

export function getPlatformSpec(title: TitleFull): PlatformSpec | undefined {
	const contentIdentifier = findPlatformSpecContentIdentifier(title);
	if (!contentIdentifier) return undefined;
	return PlatformSpecService.getPlatformSpecificationByContentIdentifier(contentIdentifier);
}

export function usePlatformSpecContentIdentifierRecordId(title: TitleFull) {
	return useMemo(
		(): string | undefined => findPlatformSpecContentIdentifierRecordId(title),
		// eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to re-compute when identifications change
		[title.metadata.coreMetadata.basic.versionIntent?.audience?.identifications],
	);
}

export function usePlatformSpecContentIdentifier(title: TitleFull) {
	return useMemo(
		(): PlatformSpec | undefined => getPlatformSpec(title),
		// eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to re-compute when identifications change
		[title.metadata.coreMetadata.basic.versionIntent?.audience?.identifications],
	);
}
