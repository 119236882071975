import { useMemo } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';

// HOOKS
import { PlatformSpecService, useTerritories } from '@warehouse/global-entities/core';

// TYPES
import { Locale } from '../localeType';

// JSON
import languagesWritten from '../../../assets/json-administration-profiles/availableLanguageWritten.json';

// LIBRARY
import { CustomColumnDef } from '../../../components/library/SimpleTable/customColumnDef';
import {
	DatePickerCell,
	DropdownCell,
	NumberInputCell,
	SearchDropdownCell,
	SelectDropdownCell,
	SortTitleCell,
	StringInputCell,
	TimeInputCell,
	TitleCell,
	MadeForRegionsCell,
	AlidCell,
} from './cells';
import editUseJson from '../../../assets/json-administration-profiles/editUse.json';
import editClassJson from '../../../assets/json-administration-profiles/editClass.json';
import locales from '../../../assets/json-administration-profiles/availableBasicOriginalLanguage.json';
import { MadeForRegionsSubHeader } from './MadeForRegionsSubHeader';
import {
	getDryRanApproximateLength,
	getDryRanCountriesOfOrigin,
	getDryRanDistributionNumber,
	getDryRanEditClass,
	getDryRanEditUse,
	getDryRanHouseSequence,
	getDryRanLanguage,
	getDryRanMadeForRegions,
	getDryRanOriginalLanguages,
	getDryRanReleaseDate,
	getDryRanVersionLanguages,
} from '../../../../libs/title/feature-create-titles/dry-run-getters';

export function useColumnsMetadata(displayTitles: string[], inheritedValues?: Title | undefined) {
	const { categorizedTerritories } = useTerritories();
	// const inheritedTitleSort = getTitleData<string>(
	// 	inheritedValues,
	// 	fromBasic(['localizedInfos', '__default', 'titleSort', 'displayValue']),
	// );

	return useMemo(
		() => ({
			title: {
				minWidth: 300,
			},
			titleLanguage: {
				cellProps: {
					inheritedValue: getDryRanLanguage(inheritedValues),
				},
				minWidth: 300,
				subHeaderDropdownType: 'searchDropdown' as const,
				subHeaderDropdownOptions:
					languagesWritten?.map((locale: any) => ({
						label: locale.label,
						value: locale.uuid,
					})) || [],
			},
			countriesOfOrigin: {
				minWidth: 250,
				cellProps: {
					inheritedValue: getDryRanCountriesOfOrigin(inheritedValues),
				},
				subHeaderDropdownType: 'multiSelect' as const,
				subHeaderDropdownOptions: categorizedTerritories,
			},
			madeForRegions: {
				cellProps: {
					inheritedValue: getDryRanMadeForRegions(inheritedValues),
				},
				minWidth: 250,
				subHeaderDropdownOptions: categorizedTerritories,
				customSubHeader: MadeForRegionsSubHeader,
			},
			platformSpecificationId: {
				minWidth: 250,
				subHeaderDropdownOptions: PlatformSpecService.getOptions(),
				subHeaderDropdownType: 'searchDropdown' as const,
			},
			alid: {
				minWidth: 250,
			},
			// TODO: Delete this column
			mode: {
				minWidth: 100,
				// cellProps: {
				// 	placeholder: inheritedValues?.mode?.value || '',
				// 	placeholderStyle: 'italic',
				// },
				subHeaderDropdownType: 'default' as const,
				subHeaderDropdownOptions: [
					{ value: 'audio', label: 'Audio' },
					{ value: 'visual', label: 'Visual' },
					{ value: 'audioVisual', label: 'AudioVisual' },
				],
			},
			releaseDate: {
				width: 150,
				subHeaderDropdownType: 'date' as const,
				cellProps: {
					inheritedValue: getDryRanReleaseDate(inheritedValues),
				},
			},
			approximateLength: {
				subHeaderDropdownType: 'time' as const,
				cellProps: {
					inheritedValue: getDryRanApproximateLength(inheritedValues),
				},
			},
			distributionNumber: {
				subHeaderDropdownType: 'string' as const,
				subHeaderProps: {
					textInputProps: {
						width: '100%',
					},
				},
				cellProps: {
					inheritedValue: getDryRanDistributionNumber(inheritedValues),
				},
			},
			sequenceNumber: {
				minWidth: 60,
			},
			originalLanguages: {
				width: 300,
				cellProps: {
					inheritedValue: getDryRanOriginalLanguages(inheritedValues),
				},
				subHeaderDropdownType: 'multiSelect' as const,
				subHeaderDropdownOptions:
					locales?.map((locale: Locale) => ({
						label: locale.label,
						value: locale.uuid,
					})) || [],
			},
			versionLanguages: {
				width: 300,
				cellProps: {
					inheritedValue: getDryRanVersionLanguages(inheritedValues),
				},
				subHeaderDropdownType: 'multiSelect' as const,
				subHeaderDropdownOptions:
					locales?.map((locale: any) => ({
						label: locale.label,
						value: locale.uuid,
					})) || [],
			},
			editUse: {
				minWidth: 150,
				subHeaderDropdownType: 'default' as const,
				subHeaderDropdownOptions: editUseJson,
				cellProps: {
					inheritedValue: getDryRanEditUse(inheritedValues),
				},
			},
			editClass: {
				minWidth: 150,
				subHeaderDropdownType: 'multiSelect' as const,
				subHeaderDropdownOptions: editClassJson,
				cellProps: {
					inheritedValue: getDryRanEditClass(inheritedValues),
				},
			},
			sortTitle: {
				minWidth: 150,
			},
			houseSequence: {
				subHeaderDropdownType: 'string' as const,
				subHeaderProps: {
					textInputProps: {
						width: '100%',
					},
				},
				inheritedValue: getDryRanHouseSequence(inheritedValues),
			},
		}),
		[inheritedValues, locales, languagesWritten],
	);
}

const formatHeader = (header: string) => {
	if (header === 'platformSpecificationId') return 'Platform Specification';
	if (header === 'madeForRegions') return 'Made For Regions';
	if (header === 'approximateLength') return 'Run Length';
	const result = header.replace(/([A-Z])/g, ' $1').replace(/sequence number/gi, 'Sequence #');
	return result
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export function useGeneratedColumns(
	fields: readonly { field: string; required: boolean }[],
	columnMetadata: { [key: string]: any },
): CustomColumnDef<any>[] {
	return useMemo(() => {
		let columns = fields?.map(({ field, required }: { field: string; required: boolean }) => {
			const metadata = columnMetadata[field];

			const column: Partial<CustomColumnDef<any>> = {
				id: field,
				header: formatHeader(field),
				accessorKey: field,
				required,
				...metadata,
			};

			if (['titleLanguage', 'compilationClass', 'platformSpecificationId'].includes(field)) {
				column.cell = SearchDropdownCell;
			} else if (['mode', 'editUse'].includes(field)) {
				column.cell = DropdownCell;
			} else if (['originalLanguages', 'versionLanguages', 'countriesOfOrigin', 'editClass'].includes(field)) {
				column.cell = SelectDropdownCell;
			} else if (field === 'madeForRegions') {
				column.cell = MadeForRegionsCell;
			} else if (['releaseDate'].includes(field)) {
				column.cell = DatePickerCell;
			} else if (['approximateLength'].includes(field)) {
				column.cell = TimeInputCell;
			} else if (['sequenceNumber'].includes(field)) {
				column.cell = NumberInputCell;
			} else if (['distributionNumber', 'houseSequence'].includes(field)) {
				column.cell = StringInputCell;
			} else if (['title'].includes(field)) {
				column.cell = TitleCell;
			} else if (['sortTitle'].includes(field)) {
				column.cell = SortTitleCell;
			} else if (['alid'].includes(field)) {
				column.cell = AlidCell;
			}

			return column as CustomColumnDef<any>;
		});

		if (fields.find(({ field }) => field === 'sequenceNumber')) {
			const sequenceNumberColumn = columns.find((col) => col.id === 'sequenceNumber');
			if (sequenceNumberColumn) {
				columns = columns.filter((col) => col.id !== 'sequenceNumber');
				columns = [sequenceNumberColumn, ...columns];
			}
		}

		return columns;
	}, [columnMetadata, fields]);
}
