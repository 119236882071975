export type BucketId = string & { __brand: 'Bucket' };

export class BucketIdUtils {
	static isBucketId(bucketId: string): bucketId is BucketId {
		return !!bucketId;
	}

	static cast(bucketId: string): BucketId {
		return bucketId as BucketId;
	}
}
