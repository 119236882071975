import { graphql } from '@warehouse/graphql';

export const SEARCH_RELATIONSHIPS = graphql(`
	query searchRelationships($search: SearchInput!) {
		searchRelationships(search: $search) {
			documents {
				direction
				indexed {
					...TitleIndexedFragment
				}
				relationship {
					...RelationshipFragment
				}
				state
				titleUuid
				uuid
			}
			page {
				currentPage
				perPage
				totalDocument
				totalPage
			}
		}
	}
`);
