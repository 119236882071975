import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import { WorkType } from '@warehouse/title/worktype';
import { AutoButtonGroup } from '../../../src/components/library/AutoButtonGroup';
import { ChildrenParentMode } from './children-parent-mode';

interface CreateRelationshipsButtonProps {
	workType: WorkType;
	childrenParentMode: ChildrenParentMode;
	openExistingChildrenModal: () => void;
	parentUuid: string;
}

type ActionId = 'add-multiple-children' | 'add-existing-children';

export function CreateRelationshipsButton({
	workType,
	childrenParentMode,
	openExistingChildrenModal,
	parentUuid,
}: CreateRelationshipsButtonProps) {
	const isCompilation = workType === WorkType.Compilation;
	const navigate = useNavigate();

	const handleAddChildrenClick = (id: ActionId) => {
		switch (id) {
			case 'add-multiple-children':
				navigate(`/titles/create`, {
					state: {
						selectedParentUuid: parentUuid,
						createTitleMode: 'relationships',
					},
				});
				break;
			case 'add-existing-children':
				openExistingChildrenModal();
				break;
			default:
				break;
		}
	};

	const options = useMemo(() => {
		const opts = [];
		if (shouldDisplayAddMultipleChildren(childrenParentMode, isCompilation)) opts.push(addMultipleChildrenOption);
		if (shouldDisplayAddExistingChildren(childrenParentMode)) opts.push(addExistingChildrenOption);
		return opts;
	}, [childrenParentMode, isCompilation]);

	return (
		<AutoButtonGroup<ActionId>
			nxstyle="secondary-blue"
			onOptionClick={(actionId) => handleAddChildrenClick(actionId as ActionId)}
			expandButtonAriaLabel="Expand Add Children Menu"
			storeKey="title-add-relationships"
			height={34}
			options={options}
		/>
	);
}

function shouldDisplayAddMultipleChildren(childrenParentMode: ChildrenParentMode, isCompilation: boolean) {
	return childrenParentMode === 'children' && !isCompilation;
}

function shouldDisplayAddExistingChildren(childrenParentMode: ChildrenParentMode) {
	return childrenParentMode === 'children';
}

const addMultipleChildrenOption = {
	id: 'add-multiple-children' as ActionId,
	icon: <Icon path={mdiPlus} size="18px" />,
	label: `Create Multiple Children`,
};

const addExistingChildrenOption = {
	id: 'add-existing-children' as ActionId,
	icon: <Icon path={mdiPlus} size="18px" />,
	label: `Add Existing Children`,
};
