import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { alpha } from '@mui/material';
import InputError, { WrapperInputError } from './InputError';

export type TextAreaVariant = 'default' | 'prefilled';

const CustomTextField = styled(TextField)<{
	error?: boolean;
	$textAreaStyle?: 'prefilled' | 'default';
}>`
	${({ theme, $textAreaStyle, error }) => css`
		background-color: ${error ? alpha(theme.palette.error.main, 0.05) : theme.palette.light.background};

		${$textAreaStyle === 'prefilled' &&
		css`
			font-style: italic;
		`}

		border: 1px solid ${theme.palette.light.backgroundAlt};
		border-radius: 8px;

		${!error &&
		css`
			&:hover {
				background-color: ${theme.palette.light.main};
				border: solid 1px ${theme.palette.blue.main};
			}

			&:focus-within {
				background-color: ${theme.palette.light.main};
				border: solid 1px ${theme.palette.blue.main};
			}
		`}
		.MuiInputBase-root {
			border: none;
			box-sizing: border-box;
			color: ${theme.palette.text.secondary};
			${error &&
			css`
				color: ${theme.palette.red.text};
			`}
			font-family: inherit;
			font-size: 14px;
			font-weight: 400;
			height: 100%;
			line-height: 120%;
			min-height: 38px;
			padding: ${theme.spacing(1.25)};
			resize: none;
			width: 100%;

			&:hover {
				color: ${theme.palette.text.primary};
			}

			&:focus-within {
				color: ${theme.palette.text.primary};
			}
		}

		box-sizing: border-box;

		fieldset {
			border: none;
		}

		${error &&
		css`
			border: solid 1px ${theme.palette.error.main};
			border-radius: 8px 8px 0 8px;
		`}

		width: 100%;
	`}
`;

export type TextAreaProps = Omit<TextFieldProps, 'variant'> & {
	errorMessage?: string;
	variant?: TextAreaVariant;
};

const InputWrapper = styled.div(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: end;
	`,
);

function TextArea({ errorMessage, onBlur, variant = 'default', className, ...props }: TextAreaProps) {
	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const trimmedValue = event.target.value?.trim();
		if (onBlur) {
			onBlur({ ...event, target: { ...event.target, value: trimmedValue } });
		}
	};

	return (
		<InputWrapper>
			<WrapperInputError>
				<CustomTextField
					className={classNames({ inherited: variant === 'prefilled' }, className)}
					multiline
					onBlur={handleBlur}
					{...props}
					value={props.value ?? ''}
				/>
				{errorMessage ? <InputError message={errorMessage} /> : null}
			</WrapperInputError>
		</InputWrapper>
	);
}

export default TextArea;
