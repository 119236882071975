import React, { forwardRef } from 'react';
import { TreeItem2Props, useTreeItem2 } from '@mui/x-tree-view-pro';
import { assertUnreachable } from '@warehouse/shared/util';
import { TreeItem, TreeItemType } from '../../core/models';
import { CustomTreeItemBucket } from './CustomTreeItemBucket';
import { CustomTreeItemFolder } from './CustomTreeItemFolder';
import { CustomTreeItemLoading } from './CustomTreeItemLoading';

export const CustomTreeItem = forwardRef((props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) => {
	const { publicAPI } = useTreeItem2({
		id: props.id,
		itemId: props.itemId,
		children: props.children,
		label: props.label,
		disabled: props.disabled,
		rootRef: ref,
	});
	// This is an issue related to our outdated typescript verion. When typescript is updated to 5.6,
	// We can remove the any casting.
	// TODO: Upgrade typescript to 5.6 and remove the any casting.
	const item = (publicAPI as any).getItem(props.itemId) as TreeItem | undefined;
	if (!item) throw new Error('Received unknown item in tree');

	switch (item.type) {
		case TreeItemType.Loading:
			return <CustomTreeItemLoading {...props} ref={ref} />;
		case TreeItemType.Bucket:
			return <CustomTreeItemBucket {...props} item={item} ref={ref} />;
		case TreeItemType.Folder:
			return <CustomTreeItemFolder {...props} item={item} ref={ref} />;
		default:
			assertUnreachable(item, 'Trying to render an unknown CustomTreeItem type');
			return null;
	}
});
