import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import { DropdownV2 } from '@warehouse/shared/ui';
import editUseJson from '../../../../assets/json-administration-profiles/editUse.json';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface EditUseFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function EditUseField({ control, errors, setValue, trigger, isRequired = false, dryRanTitle }: EditUseFieldProps) {
	const tooltip = useTooltip('coreMetadata.basic.versionIntent.editUse');

	const inheritedEditUse = getTitleData<OptionalInherited<string>>(
		dryRanTitle,
		fromBasic(['versionIntent', 'editUse']),
	);

	return useMemo(
		() => (
			<Controller
				name="editUse"
				control={control}
				render={({ field }) => (
					<DropdownV2
						ariaLabel="Select Edit Use"
						tooltip={tooltip?.tooltip}
						placeholder={inheritedEditUse?.displayValue}
						options={editUseJson}
						value={field.value}
						onChange={async (e) => {
							setValue('editUse', e as string);
							if (isRequired && !inheritedEditUse?.displayValue?.length) {
								await trigger('editUse');
							}
						}}
						label={getLabelWithRequired('Edit Use', isRequired)}
						error={errors?.editUse?.message as string}
					/>
				)}
			/>
		),
		[control, setValue, trigger, errors?.editUse?.message, inheritedEditUse?.displayValue],
	);
}

export default EditUseField;
