import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import SimpleTable, { SimpleTableProps } from '../index';
import { CustomColumnDef } from '../customColumnDef';
import { InheritedWrapper } from './utils';

type InheritanceOnValueChangeSimpleTableWrapperProps<TData> = SimpleTableProps & {
	editMode: boolean;
	isRowReadonlyFn?: (row: TData) => boolean;
	newRowPayload?: TData;
};

// TODO: Find a way to memoize read only cells without violating the rules of hooks
function ReadonlyOverrideCell<TData>({ column, cell }: CellContext<TData, any>) {
	const { renderReadOnly } = column.columnDef as CustomColumnDef<any>;

	if (renderReadOnly) {
		const rendered = renderReadOnly(cell.getValue());

		if (typeof rendered === 'string') {
			return (
				<InheritedWrapper>
					<Typography variant="tableCell">{rendered}</Typography>
				</InheritedWrapper>
			);
		}

		return renderReadOnly(cell.getValue());
	}

	return (
		<InheritedWrapper>
			<Typography variant="tableCell">{cell.getValue()}</Typography>
		</InheritedWrapper>
	);
}

function RenderCell<TData>(column: CustomColumnDef<any, any>, isRowReadonlyFn: undefined | ((row: TData) => boolean)) {
	return function cell(info: CellContext<any, any>) {
		if (isRowReadonlyFn && isRowReadonlyFn(info.row.original)) {
			return ReadonlyOverrideCell(info);
		}
		if (typeof column.cell === 'function') {
			return column.cell?.(info);
		}
		return column.cell;
	};
}

function InheritanceOnValueChangeSimpleTableWrapper<TData>({
	editMode,
	isRowReadonlyFn,
	...simpleTableProps
}: InheritanceOnValueChangeSimpleTableWrapperProps<TData>) {
	const getSetDataHandler = () => simpleTableProps.setData;

	const getData = () => simpleTableProps.data || [];

	const getOnRowAddHandler = () => simpleTableProps.onRowAdd;

	const getOnRowDeleteHandler = () => simpleTableProps.onRowDelete;

	const renderEditableCells = () =>
		simpleTableProps.columns.map((column) => ({
			...column,
			cell: column.editable === false ? ReadonlyOverrideCell<TData> : RenderCell<TData>(column, isRowReadonlyFn),
		}));

	const renderReadOnlyCells = () =>
		simpleTableProps.columns.map((column) => ({
			...column,
			cell: ReadonlyOverrideCell<TData>,
		}));

	const columns = useMemo(() => {
		if (editMode) {
			return renderEditableCells();
		}
		return renderReadOnlyCells();
	}, [simpleTableProps.columns, editMode]);

	return (
		<SimpleTable
			{...simpleTableProps}
			data={getData()}
			setData={getSetDataHandler()}
			onRowAdd={getOnRowAddHandler()}
			onRowDelete={getOnRowDeleteHandler()}
			editable={editMode}
			columns={columns}
			showBottomActionsBar={simpleTableProps.showBottomActionsBar}
		/>
	);
}

export default InheritanceOnValueChangeSimpleTableWrapper;
