import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@mdi/react';
import classNames from 'classnames';
import {
	Cell,
	ColumnFiltersState,
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	Row,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import { mdiDotsVertical } from '@mdi/js';
import { DragDropContext, Draggable, DraggableProvided, DropResult } from '@hello-pangea/dnd';

import { alpha, useTheme } from '@mui/material';
import Droppable from '../../../utils/dnd/StrictModeDroppable';
import MergeIcons from '../MergeIcons';
import Checkbox from '../Checkbox';
import {
	DragButtonWrapper,
	RowContent,
	RowInput,
	RowWrapper,
	StyledCheckboxContainer,
	StyledTable,
	StyledTHead,
	StyledTR,
	TableWrapper,
	TBody,
	TDDndWrapper,
} from './style';
import { CustomColumnDef } from './customColumnDef';
import ExpandableRow from './ExpandableRow';
import SimpleRow from './SimpleRow';
import ActionRow from './ActionRow';
import { ErrorHandlingMode } from './ErrorHandlingMode';
import { SubHeader } from './SubHeader/SubHeader';
import { TopActionBar } from './TopActionBar';
import { TableStyle } from './TableStyle';
import { ActionHead } from './ActionHead';
import { DefaultTableHead } from './DefaultHead';
import { CanDeleteFn } from './can-delete';

export type OnRowEditPayload =
	| { rowUuid: string; payload: { key: string; value: any } }
	| { rowUuid: string; payload: { key: string; value: any } }[];

export interface SimpleTableProps {
	ariaLabel?: string;
	columns: CustomColumnDef<any>[];
	data: any[];
	setData?: (data: any[]) => void;
	onRowEdit?: (edit: OnRowEditPayload) => void;
	editable?: boolean;
	paddedCells?: boolean;
	optionsData?: any[];
	onRowAdd?: (numberOfRows: number, selectedDropdownValues: Record<string, any>) => void;
	onRowDelete?: (selectedIndexes: string[]) => void;
	onRowDeleteByKey?: (selectedKeys: string[]) => void;
	selectionKey?: string;
	height?: number;
	tableStyle: TableStyle;
	variant?: 'default' | 'prefilled';
	draggable?: boolean;
	cantSelectRows?: boolean;
	showBottomActionsBar?: boolean;
	showTopActionsBar?: boolean;
	showSubHeader?: boolean;
	onClickRow?: (row: any, e: React.MouseEvent<HTMLTableRowElement>) => void;
	errors?: {
		[columnId: string]: number[];
	};
	warnings?: {
		[columnId: string]: number[];
	};
	onInternalRowsChange?: (rows: Row<any>[]) => void;
	customPropsCell?: any;
	canDelete?: CanDeleteFn;
	expandedByDefault?: boolean;
	hideIndexColumn?: boolean;
	onDragEnd?: () => void;
	addRowLabel?: string;
	canAddRow?: boolean;
	enableAddMultipleRows?: boolean;
	selectRowModeByDefault?: boolean;
	forceHover?: boolean;
	sorting?: SortingState;
	columnFilters?: ColumnFiltersState;
	noOverflow?: boolean;
	errorHandlingMode?: ErrorHandlingMode;
}

function SimpleTable({
	ariaLabel,
	columns,
	data,
	setData,
	paddedCells = false,
	onRowEdit,
	editable,
	optionsData,
	onRowAdd,
	onRowDelete,
	selectionKey,
	height,
	tableStyle,
	variant = 'default',
	cantSelectRows = false,
	draggable,
	showBottomActionsBar,
	showSubHeader,
	onClickRow,
	showTopActionsBar,
	hideIndexColumn,
	errors = {},
	warnings = {},
	onDragEnd,
	customPropsCell,
	canDelete = () => ({ canDelete: true }),
	canAddRow = true,
	addRowLabel = 'Add row',
	expandedByDefault = false,
	enableAddMultipleRows = true,
	selectRowModeByDefault = false,
	forceHover = false,
	sorting,
	onInternalRowsChange,
	columnFilters,
	noOverflow = false,
	errorHandlingMode = ErrorHandlingMode.Default,
}: SimpleTableProps) {
	const [isSelectionEnabled, setIsSelectionEnabled] = useState<boolean>(false);
	const [rowSelection, setRowSelection] = useState({});
	// A record of columnId: subheaderValue
	const [subheaderValues, setSubheaderValues] = useState<Record<string, any>>({});
	const theme = useTheme();

	const [addRowNumber, setAddRowNumber] = useState<string>('');

	const tableRef = useRef<HTMLTableElement>(null);

	const [expanded, setExpanded] = useState<ExpandedState>({});

	const computedColumns = useMemo(() => {
		if ((draggable || showBottomActionsBar) && !cantSelectRows && !columns.some((column) => column.id === 'actions')) {
			return [{ id: 'actions' }, ...columns];
		}
		return [...columns];
	}, [columns, draggable, showBottomActionsBar, cantSelectRows]);

	/**
	 * Stateful constants/functions definitions
	 */

	const table = useReactTable({
		data,
		state: {
			expanded,
			rowSelection,
			sorting,
			columnFilters,
		},
		onExpandedChange: setExpanded,
		onRowSelectionChange: setRowSelection,
		columns: computedColumns,
		defaultColumn: modifiedDefaultColumn,
		getSubRows: (row) => row.subRows,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		// Provide our updateData function to our table
		meta: {
			updateData: (rowIndex: number, columnId: string, value: any) => {
				setData?.(
					data.map((row, index) => {
						if (index === rowIndex) {
							if (!(value instanceof Date) && typeof value === 'object' && !Array.isArray(value)) {
								return { ...row, ...value };
							}
							return { ...row, [columnId]: value };
						}
						return row;
					}),
				);
			},
			editable,
			optionsData,
			setStateAction: setData,
			onRowEdit,
			customPropsCell,
		},
	});
	useEffect(() => {
		onInternalRowsChange?.(table.getRowModel().rows);
	}, [table.getRowModel().rows]);

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const res = Array.from(data);
		const [removed] = res.splice(result.source.index, 1);
		res.splice(result.destination.index, 0, removed);
		const updatedData = res.map((currData, index) => ({
			...currData,
			preference: index + 1,
		}));
		setData?.(updatedData);

		if (onDragEnd) onDragEnd();
	};

	// Whenever a subheader value is changed, we overwrite all the values in the associated column to take the
	// new default value defined in the subheader
	const handleSubheaderChange = useCallback(
		(columnId: string, value: any) => {
			setSubheaderValues((prev) => ({
				...prev,
				[columnId]: value,
			}));
			const newData = data.map((row) => {
				const newRow = { ...row };
				newRow[columnId] = value;
				return newRow;
			});
			if (editable) setData?.(newData);
			else setData?.(data);
		},
		[subheaderValues, data, setData, setSubheaderValues],
	);

	const renderSelectedRowCell = (
		cell: Cell<any, unknown>,
		row: Row<any>,
		draggableProvided: DraggableProvided,
		index: number,
	) => {
		if (cell.column.id === 'actions') {
			if ((isSelectionEnabled && showBottomActionsBar) || selectRowModeByDefault) {
				return (
					<StyledCheckboxContainer ref={draggableProvided.innerRef}>
						<Checkbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
					</StyledCheckboxContainer>
				);
			}
			return (
				<TDDndWrapper ref={draggableProvided.innerRef}>
					{!draggable ? null : (
						<DragButtonWrapper>
							<MergeIcons
								{...draggableProvided.draggableProps}
								{...draggableProvided.dragHandleProps}
								className="drag-button"
								style={{ cursor: 'all-scroll' }}
							>
								<Icon path={mdiDotsVertical} size="20px" />
								<Icon path={mdiDotsVertical} size="20px" />
							</MergeIcons>
							{!hideIndexColumn && <div className="preferences">{index + 1}</div>}
						</DragButtonWrapper>
					)}
				</TDDndWrapper>
			);
		}

		return <RowWrapper>{flexRender(cell.column.columnDef.cell, cell.getContext())}</RowWrapper>;
	};

	/**
	 * Effects
	 */

	// Expand all rows by default
	useEffect(() => {
		if (expandedByDefault && table) table.toggleAllRowsExpanded();
	}, [expandedByDefault]);

	// Reset row selection when data changes
	useEffect(() => {
		setRowSelection({});
	}, [data]);

	return (
		<div className="table-wrapper" style={{ width: '100%' }}>
			{showTopActionsBar && (
				<TopActionBar
					onExpandAll={() => table.toggleAllRowsExpanded(true)}
					onCollapseAll={() => table.toggleAllRowsExpanded(false)}
				/>
			)}
			<TableWrapper height={height} noOverflow={noOverflow}>
				<StyledTable
					role="table"
					aria-label={ariaLabel}
					tableStyle={tableStyle}
					showBottomActionsBar={showBottomActionsBar}
					ref={tableRef}
				>
					<StyledTHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<Fragment key={headerGroup.id}>
								<StyledTR style={{ backgroundColor: 'inherit' }} key={headerGroup.id}>
									{headerGroup.headers.map((header, idx) => {
										if (header.id === 'actions') {
											return (
												<ActionHead
													key={header.id}
													ariaColindex={idx}
													colSpan={header.colSpan}
													tableStyle={tableStyle}
													checked={table.getIsAllRowsSelected()}
													onCheck={table.getToggleAllRowsSelectedHandler() as () => void}
													showActionCheckbox={(isSelectionEnabled && showBottomActionsBar) || selectRowModeByDefault}
												/>
											);
										}
										return (
											<DefaultTableHead key={header.id} ariaColindex={idx} header={header} tableStyle={tableStyle} />
										);
									})}
								</StyledTR>
								{showSubHeader && (
									<SubHeader
										headerGroup={headerGroup}
										subheaderValues={subheaderValues}
										handleSubheaderChange={handleSubheaderChange}
									/>
								)}
							</Fragment>
						))}
					</StyledTHead>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="droppable-table-body">
							{(droppableProvided) => (
								<TBody
									className={classNames({ inherited: variant === 'prefilled' })}
									hoverable={editable || forceHover}
									ref={droppableProvided.innerRef}
									{...droppableProvided.droppableProps}
								>
									{table.getRowModel().rows.map((row, index) => (
										<Draggable
											key={row.id}
											draggableId={row.id}
											index={row.index}
											isDragDisabled={!draggable || isSelectionEnabled}
										>
											{(draggableProvided) => (
												<StyledTR
													key={row.id}
													role="row"
													aria-rowindex={index}
													{...draggableProvided.draggableProps}
													ref={draggableProvided.innerRef}
													onClick={useCallback(
														(e: React.MouseEvent<HTMLTableRowElement>) => {
															const selection = document.getSelection();
															if (selection && selection.type === 'Range') return;
															if (onClickRow) onClickRow(row.original, e);
														},
														[onClickRow],
													)}
													canExpand={row.getCanExpand()}
													style={{
														...(onClickRow ? { cursor: 'pointer' } : {}),
														...(index % 2 === 0
															? { backgroundColor: theme.palette.light.main }
															: {
																	backgroundColor: theme.palette.light.background,
															  }),
														...draggableProvided.draggableProps.style,
														...(isOnError(errors, errorHandlingMode, index) &&
														errorHandlingMode === ErrorHandlingMode.MultiTitleCreate
															? {
																	backgroundColor: alpha(theme.palette.error.background, 0.5),
															  }
															: {}),
													}}
												>
													{row.getCanExpand() ? (
														<ExpandableRow row={row} />
													) : (
														<SimpleRow
															paddedCells={paddedCells}
															editable={editable}
															row={row}
															errors={errors}
															warnings={warnings}
															rowIndex={index}
															renderSelectedRowCell={(cell) =>
																renderSelectedRowCell(cell, row, draggableProvided, index)
															}
															errorHandlingMode={errorHandlingMode}
														/>
													)}
												</StyledTR>
											)}
										</Draggable>
									))}
									{droppableProvided.placeholder}
								</TBody>
							)}
						</Droppable>
					</DragDropContext>
				</StyledTable>
			</TableWrapper>
			{showBottomActionsBar && (
				<ActionRow
					selectionKey={selectionKey}
					getRow={table.getRow}
					enableAddMultipleRows={enableAddMultipleRows}
					canDelete={canDelete}
					tableStyle={tableStyle}
					onRowAdd={onRowAdd}
					addRowNumber={addRowNumber}
					selectedDropdownValues={{ ...subheaderValues }}
					setAddRowNumber={setAddRowNumber}
					onRowDelete={onRowDelete}
					addRowLabel={addRowLabel}
					isSelectionEnabled={isSelectionEnabled}
					setIsSelectionEnabled={setIsSelectionEnabled}
					selectedIndexes={rowSelection}
					canAddRow={canAddRow}
					selectRowModeByDefault={selectRowModeByDefault}
				/>
			)}
		</div>
	);
}

export default SimpleTable;

export const modifiedDefaultColumn: Partial<CustomColumnDef<any>> = {
	cell: ({ getValue, row: { index }, column: { id }, table }) => {
		const initialValue = getValue();

		const [value, setValue] = useState(initialValue);

		const onBlur = () => {
			table.options.meta?.updateData?.(index, id, typeof value === 'string' ? value?.trim() : value);
		};

		useEffect(() => {
			setValue(initialValue);
		}, [initialValue]);

		if (!table.options.meta?.editable) {
			return <RowContent>{value as string}</RowContent>;
		}
		return <RowInput value={(value as string) || ''} onChange={(e) => setValue(e.target.value)} onBlur={onBlur} />;
	},
};

// True if either the row contains a errored cell, or the row is itself is an errored row
function isOnError(errors: SimpleTableProps['errors'], errorHandlingMode: ErrorHandlingMode, rowIndex: number) {
	const hasErroredCell = Object.keys(errors || {}).some((key: string) => errors?.[key]?.includes(rowIndex));
	const isErroredRow = errors?.__rows?.includes(rowIndex) || false;
	return hasErroredCell || isErroredRow;
}
