import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { MadeForRegionField } from '@warehouse/title/feature-create-single-title';
import { TitleFull as Title } from '@warehouse/title/core';
import { AlidField } from '../fields/AlidField';

// LIBRARIES
import { PlatformSpecificationField } from '../fields/PlatformSpecificationField';
import { Row } from './styles';

interface ManifestationProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	dryRanTitle?: Title | undefined;
}

export function Manifestation({ dryRanTitle, control, errors, setValue, trigger }: ManifestationProps) {
	return (
		<div>
			<Row>
				<PlatformSpecificationField
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					isRequired
				/>
				<AlidField control={control} setValue={setValue} trigger={trigger} errors={errors} isRequired />
			</Row>
			<Row>
				<MadeForRegionField
					dryRanTitle={dryRanTitle}
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
				/>
			</Row>
		</div>
	);
}
