import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Alert from '@mui/material/Alert';
import { Dialog, DialogContent, DialogTitle, Link } from '@mui/material';
import styled, { css } from 'styled-components';
import NxLoader from '../../../src/components/library/NxLoader';

export function InteractionRequiredModal({ open, error, retry }: InteractionRequiredModalProps) {
	const { logout } = useAuth0();

	return (
		<Dialog open={open} fullWidth>
			<DialogTitle>Your session could not be renewed</DialogTitle>
			<DialogContent>
				<InfoMessage>Additional information is needed from the identity provider</InfoMessage>
				{error === null ? (
					<LoaderWrapper>
						<NxLoader />
					</LoaderWrapper>
				) : (
					<div>
						<Alert severity="error">{error}</Alert>
						<LinkWrapper>
							<RetryLink onClick={() => retry()} variant="body2">
								Retry
							</RetryLink>
							<LogoutLink onClick={() => logout()} variant="body2">
								Logout
							</LogoutLink>
						</LinkWrapper>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
}

export interface InteractionRequiredModalProps {
	open: boolean;
	error: string | null;
	retry: () => void;
}

const LoaderWrapper = styled.div(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		padding-top: ${theme.spacing(1)} !important;
	`,
);

const InfoMessage = styled.div(
	({ theme }) => css`
		padding-bottom: ${theme.spacing(2)};
	`,
);

const LinkWrapper = styled.div(
	({ theme }) => css`
		padding-top: ${theme.spacing(1)};
		text-align: center;
	`,
);

const RetryLink = styled(Link)`
	cursor: pointer;
	padding-right: 2em;
`;

const LogoutLink = styled(Link)`
	cursor: pointer;
`;
