import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TitleFull as Title } from '@warehouse/title/core';
import { alpha, Portal, Typography, useTheme } from '@mui/material';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiPencil, mdiPlus } from '@mdi/js';

// TYPES
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';
import {
	LocalizedInfoExcludedRegion,
	LocalizedInfoRegion,
} from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Region';
import { Audience } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/VersionIntent';

// HOOKS
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

import {
	CopyrightLineField,
	DisplayTitleField,
	KeywordsField,
	OriginalTitleField,
	SortTitleField,
	Summary190Field,
	Summary4000Field,
	Summary400Field,
	GenresField,
	AlternateTitlesTable,
	EditLocalizedInfoModal,
} from '@warehouse/title/feature-localized-info-tab';
import { DropdownV2 } from '@warehouse/shared/ui';
import { useTerritories } from '@warehouse/global-entities/core';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import useLocalizedInfosDropdown from './useLocalizedInfosDropdown';
import { useURLFragment } from '../../../../../utils/hooks/useURLFragment';
import useSnackbar from '../../../../../utils/hooks/useSnackbar';

// LIBRARY
import { Column, BottomRow } from '../style';
import { FullWidthHeightWrapper } from '../../../../../components/common';
import ChipDisplay from '../../../../../components/library/ChipDisplay';
import { findDefaultUuidForKey, fromBasic, getTitleData } from '../../../../../utils/titleGetProperty';
import Button from '../../../../../components/library/Button';
import NewLocalizedInfoModal from './NewLocalizedInfoModal';
import recordToArray from '../../../hooks/useTitleRecordsAutoSave/recordToArray';
import { dateToString, stringToDate } from '../../../../../components/library/DatePicker/DatePicker';
import CustomMetadata from './CustomMetadata';
import sortAlphabeticallyByLabel from '../../../../../utils/sortAlphabeticallyByLabel';
import { UnsavedChangesContext } from '../../../contexts/UnsavedChangesContext';

// JSON
import localesJSON from '../../../../../assets/json-administration-profiles/availableLanguageWritten.json';

const localizedInfoFragmentStartWith = 'product-metadata/localized-info/';
const tenantId = process.env.REACT_APP_TENANT_ID;
const customMetadataAllowedFor = ['admin', 'adminstg', 'admindev', 'dev'];

export const AddButtonLocalizedDropdown = styled(Button)(
	({ theme }) => css`
		border: 1px solid ${alpha(theme.palette.light.backgroundAlt, 0.45)};
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		height: 36px;
		justify-content: flex-start;
		width: 100%;
	`,
);

export const TopRow = styled.div(
	({ theme }) => css`
		background-color: ${theme.palette.light.background};
		border-bottom: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		gap: ${theme.spacing(2)};
		height: 56px !important;
		justify-content: start;
		min-height: 56px !important;
		padding-left: ${theme.spacing(3)};
	`,
);

export const Field = styled.div<{ rightBorder?: boolean }>(
	({ theme, rightBorder }) => css`
		align-items: center;
		${rightBorder &&
		css`
			border-right-color: ${theme.palette.light.backgroundAlt};
			border-right-style: solid;
			border-right-width: 1px;
			padding-right: ${theme.spacing(2)};
		`}
		display: flex;
		gap: ${theme.spacing(1)};
	`,
);

export const Pre = styled.span(
	() => css`
		margin: 0;
		min-height: 20px;
		padding: 10px 0;
		white-space: pre-wrap;
	`,
);

export const RegionWrapper = styled.div(
	() => css`
		width: 300px;
	`,
);

export const Row = styled.div(
	({ theme }) => css`
		display: flex;
		flex-direction: row;
		gap: ${theme.spacing(2)};

		& > div {
			width: 50%;
		}
	`,
);

function ProductMetadataTabLocalizedInfoTab() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const { promptUserIfUnsavedChanges } = useContext(UnsavedChangesContext);
	const locales = localesJSON.sort(sortAlphabeticallyByLabel);
	const { territories } = useTerritories();
	const [fragment, setFragment] = useURLFragment();
	const theme = useTheme();
	const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);
	const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
	const localizedInfosDropdown = useLocalizedInfosDropdown(title);
	const defaultLocalizedInfo = findDefaultUuidForKey(title as Title, 'localizedInfos');
	const snackbar = useSnackbar();
	const localizedInfoUuid = useMemo(() => {
		if (!fragment.startsWith(localizedInfoFragmentStartWith)) {
			return 'default';
		}
		return fragment.slice(localizedInfoFragmentStartWith.length);
	}, [fragment]);

	useEffect(() => {
		if (localizedInfoUuid === 'default') {
			localizedInfosDropdown.setSelected(defaultLocalizedInfo);
		} else {
			const hasOption = localizedInfosDropdown.options.find((opt) => opt.value === localizedInfoUuid);
			if (!hasOption) {
				if (localizedInfoUuid)
					snackbar.setMessage(
						'Localized info not found',
						'It may have been deleted, redirecting you to the default localized info',
						'warning',
					);
				setFragment(`product-metadata/localized-info/default`);
			} else localizedInfosDropdown.setSelected(localizedInfoUuid);
		}
	}, [localizedInfoUuid, localizedInfosDropdown.options]);

	const locale = getTitleData<Inherited<string>>(
		title,
		fromBasic(['localizedInfos', localizedInfosDropdown.selected || '__default', 'language']),
	);

	const isDefaultLocalizedInfo = getTitleData<boolean>(
		title,
		fromBasic(['localizedInfos', localizedInfosDropdown.selected || '__default', 'default', 'displayValue']),
	);

	const regions = getTitleData<OptionalInherited<LocalizedInfoRegion[]>>(
		title,
		fromBasic(['localizedInfos', localizedInfosDropdown.selected || '__default', 'regions']),
	);

	const excludedRegions = getTitleData<OptionalInherited<LocalizedInfoExcludedRegion[]>>(
		title,
		fromBasic(['localizedInfos', localizedInfosDropdown.selected || '__default', 'excludedRegions']),
	);

	const targetAudiencesRecord = getTitleData<Record<string, Audience>>(
		title,
		fromBasic(['localizedInfos', localizedInfosDropdown.selected || '__default', 'targetAudiences']),
	);
	const firstTargetAudience = recordToArray<Audience>(targetAudiencesRecord)?.[0];
	const when = firstTargetAudience?.whens?.displayValue?.[0];
	const description = firstTargetAudience?.description?.displayValue;

	const customMetadataField = useMemo(
		() => <CustomMetadata selectedLocalizedInfo={localizedInfosDropdown.selected} />,
		[title, localizedInfosDropdown.selected],
	);

	const selectedLocalizedInfoOrDefaultUuid = useMemo(
		() => localizedInfosDropdown.selected || defaultLocalizedInfo,
		[localizedInfosDropdown.selected, defaultLocalizedInfo],
	);
	if (!selectedLocalizedInfoOrDefaultUuid) return null;

	return (
		<>
			<Portal container={document.body}>{snackbar.snackbar()}</Portal>
			<FullWidthHeightWrapper style={{ flexDirection: 'column' }}>
				<TopRow>
					<Field rightBorder>
						<DropdownV2
							disabledMargin
							// verticalPadding={0}
							height={36}
							listItemHeight={36}
							width={300}
							ariaLabel="Localized Info Dropdown"
							withSearch
							// @ts-ignore
							backgroundColor={theme.palette.light.main}
							options={localizedInfosDropdown.options.sort(sortAlphabeticallyByLabel) || []}
							value={localizedInfosDropdown.selected || null}
							onChange={(v) => {
								promptUserIfUnsavedChanges(() => setFragment(`product-metadata/localized-info/${v || 'default'}`));
							}}
							onKeyDown={(e) => {
								if (e.key === 'Escape' && defaultLocalizedInfo) {
									promptUserIfUnsavedChanges(() => setFragment(`product-metadata/localized-info/default`));
								}
							}}
							bottomActions={
								<AddButtonLocalizedDropdown
									startIcon={<Icon path={mdiPlus} size="16px" />}
									nxstyle="tertiary-light"
									onClick={() => promptUserIfUnsavedChanges(() => setNewDialogOpen(true))}
								>
									Add
								</AddButtonLocalizedDropdown>
							}
						/>
					</Field>
					<Field>
						<Typography style={{ lineHeight: '100%' }} variant="subtitle2">
							Locale:{' '}
						</Typography>
						<Typography color="text.secondary" variant="buttonMediumRegular" aria-label="Selected Localized Info">
							{locale?.displayValue ? locales.find((l) => l.uuid === locale.displayValue)?.label : ''}
						</Typography>
					</Field>
					{(when?.when || when?.startDate || when?.endDate) && (
						<Field>
							<Typography style={{ lineHeight: '100%' }} variant="subtitle2">
								Interval:{' '}
							</Typography>
							<Typography color="text.secondary" variant="buttonMediumRegular">
								{description} ({when?.startDate ? dateToString(stringToDate(when?.startDate)) : '-/-'} to{' '}
								{when?.endDate ? dateToString(stringToDate(when?.endDate)) : '-/-'})
							</Typography>
						</Field>
					)}
					{regions?.displayValue && regions?.displayValue?.length > 0 && (
						<Field>
							<Typography style={{ lineHeight: '100%' }} variant="subtitle2">
								Regions:{' '}
							</Typography>
							<RegionWrapper>
								<ChipDisplay
									allowOverflow
									chipBackground="#D9E2EB33"
									horizontalPadding={0}
									values={
										regions?.displayValue?.map(
											(reg) =>
												territories.find((territory) => territory.value === (reg.country || reg.countryRegion || ''))
													?.label || '',
										) || []
									}
								/>
							</RegionWrapper>
						</Field>
					)}
					{excludedRegions?.displayValue && excludedRegions?.displayValue?.length > 0 && (
						<Field>
							<Typography style={{ lineHeight: '100%' }} variant="subtitle2">
								Excluded Regions:{' '}
							</Typography>
							<RegionWrapper>
								<ChipDisplay
									allowOverflow
									chipBackground="#D9E2EB33"
									horizontalPadding={0}
									values={
										excludedRegions?.displayValue?.map(
											(reg) =>
												territories.find((territory) => territory.value === (reg.country || reg.countryRegion || ''))
													?.label || '',
										) || []
									}
								/>
							</RegionWrapper>
						</Field>
					)}
					<Field rightBorder>
						{!isDefaultLocalizedInfo && (
							<Button onClick={() => setEditDialogOpen(true)} nxstyle="secondary-blue">
								<Icon size="16px" path={mdiPencil} />
							</Button>
						)}
					</Field>
				</TopRow>
				<BottomRow>
					<Column>
						<Row>
							<DisplayTitleField localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
							<SortTitleField localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						</Row>
						<OriginalTitleField localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						<GenresField localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						<CopyrightLineField localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						<KeywordsField localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						{customMetadataAllowedFor.includes(tenantId ?? '') ? customMetadataField : null}
					</Column>
					<Column>
						<Summary190Field localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						<Summary400Field localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						<Summary4000Field localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid} />
						<AlternateTitlesTable
							localizedInfoUuid={selectedLocalizedInfoOrDefaultUuid}
							defaultLocale={locale?.displayValue}
						/>
					</Column>
				</BottomRow>
			</FullWidthHeightWrapper>
			<NewLocalizedInfoModal
				onClose={() => setNewDialogOpen(false)}
				onLocalizedInfoCreated={(uuid) => setFragment(`product-metadata/localized-info/${uuid}`)}
				open={newDialogOpen}
				title={title}
			/>
			<EditLocalizedInfoModal
				setSelectedLocalizedInfo={(uuid) => setFragment(`product-metadata/localized-info/${uuid}`)}
				selectedLocalizedInfo={localizedInfoUuid === 'default' ? undefined : localizedInfoUuid}
				onClose={() => setEditDialogOpen(false)}
				open={editDialogOpen}
			/>
		</>
	);
}

export default ProductMetadataTabLocalizedInfoTab;
