import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { getWorkTypeObject } from '@warehouse/title/infra';
import { getCombinedWorktype } from '@warehouse/title/core';
import { Color, getIconByEntityNameAndTitleType } from '../../assets/icons';

const Wrapper = styled.div(
	({ theme }) => css`
		border: solid 1px ${theme.palette.light.backgroundAlt};
		border-radius: ${theme.spacing(0.5)};
		display: flex;
	`,
);

const TextWrapper = styled.div(
	({ theme }) => css`
		border-left: solid 1px ${theme.palette.light.backgroundAlt};
		display: flex;
		flex-direction: column;
		padding: ${theme.spacing(0.5, 1)};
	`,
);

const ImageWrapper = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		justify-content: center;
		padding: ${theme.spacing(0.5)};

		& > img {
			height: 32px;
			width: 32px;
		}
	`,
);

function TitleTypeInfo() {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const workType = useTitleEditorStore(titleEditorStoreSelector.workType);
	const l1workType = useTitleEditorStore(titleEditorStoreSelector.l1WorkType);

	const combinedWorkType = getCombinedWorktype(title.readonly);
	const icon = getIconByEntityNameAndTitleType(combinedWorkType, Color.Colored);

	return (
		<Wrapper aria-label="Title Type">
			<ImageWrapper>
				<img src={icon || undefined} alt="eidrIcon" />
			</ImageWrapper>
			<TextWrapper>
				<Typography variant="buttonLargeMedium">{getWorkTypeObject(workType).label}</Typography>
				<Typography color="text.secondary" variant="buttonSmallRegular">
					{getWorkTypeObject(l1workType).label}
				</Typography>
			</TextWrapper>
		</Wrapper>
	);
}

export default TitleTypeInfo;
