import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)<{ $fullWidth?: boolean }>(
	({ $fullWidth = false }) => css`
		${$fullWidth &&
		css`
			width: 100% !important;
		`}

		all: unset;
		cursor: pointer;
	`,
);
