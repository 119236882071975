import { StateCreator } from 'zustand';
import {
	BucketId,
	FolderPathUtils,
	NxObject,
	NxObjectFull,
	NxObjectType,
	StoreResourceIdentifier,
} from '@warehouse/object-browser/core';

type StoreBreadcrumbItem = {
	label: string;
	resourceIdentifier?: StoreResourceIdentifier;
	type: 'bucket' | 'folder' | 'file';
};

export interface ObjectBrowserBreadcrumbsManagerStore {
	isBreadcrumbsItemsPending: boolean;
	breadcrumbsItems: StoreBreadcrumbItem[] | undefined;
	actions: {
		updateResourceIdentifier: () => void;
		updateBreadcrumbFromNxObjectFull: (currentFolder: NxObjectFull) => void;
		updateBreadcrumbFromBucketId: (bucketId: BucketId) => void;
		updateFakeBreadcrumbFromNxObject: (nxObject: NxObject) => void;
	};
	internalActions: {
		initialize: () => void;
		destroy: () => void;
	};
}

export const objectBrowserBreadcrumbsManagerSlice: StateCreator<ObjectBrowserBreadcrumbsManagerStore> = (set) => {
	const updateResourceIdentifier = () => {
		set({ isBreadcrumbsItemsPending: true });
	};

	const updateFakeBreadcrumbFromNxObject = (nxObject: NxObject) => {
		set({
			isBreadcrumbsItemsPending: false,
			breadcrumbsItems: [
				{
					label: nxObject.bucketId,
					resourceIdentifier: {
						type: 'bucket',
						bucketId: nxObject.bucketId,
					},
					type: 'bucket',
				},
				...getFakeBreadcrumbItemsFromNxObject(nxObject),
			],
		});
	};

	const updateBreadcrumbFromBucketId = (bucketId: BucketId) => {
		set({
			isBreadcrumbsItemsPending: false,
			breadcrumbsItems: [
				{
					label: bucketId,
					resourceIdentifier: {
						type: 'bucket',
						bucketId,
					},
					type: 'bucket',
				},
			],
		});
	};

	const updateBreadcrumbFromNxObjectFull = (selectedNxObject: NxObjectFull) => {
		const breadcrumbBucketItem: StoreBreadcrumbItem = {
			label: selectedNxObject.nxObject.bucketId,
			resourceIdentifier: {
				type: 'bucket',
				bucketId: selectedNxObject.nxObject.bucketId,
			},
			type: 'bucket',
		};

		// We need to determine the type of the last item in the path, all the others are folders
		const getType = (index: number) => {
			if (index === selectedNxObject.metadata.path.length - 1) {
				return selectedNxObject.nxObject.itemType === NxObjectType.Folder ? 'folder' : 'file';
			}
			return 'folder';
		};

		const newBreadcrumbsItems: StoreBreadcrumbItem[] =
			selectedNxObject.metadata.path.map((item, index) => ({
				label: item.name,
				// Files are not clickable
				resourceIdentifier:
					getType(index) === 'folder'
						? {
								type: 'folderUuid',
								folderUuid: item.uuid,
							}
						: undefined,
				type: getType(index),
			})) || [];

		set({
			isBreadcrumbsItemsPending: false,
			breadcrumbsItems: [breadcrumbBucketItem, ...newBreadcrumbsItems],
		});
	};

	return {
		isBreadcrumbsItemsPending: true,
		breadcrumbsItems: [],
		actions: {
			updateResourceIdentifier,
			updateBreadcrumbFromBucketId,
			updateBreadcrumbFromNxObjectFull,
			updateFakeBreadcrumbFromNxObject,
		},
		internalActions: {
			initialize: () => {},
			destroy: () => {},
		},
	};
};

function getFakeBreadcrumbItemsFromNxObject(nxObject: NxObject): StoreBreadcrumbItem[] {
	const items: StoreBreadcrumbItem[] = FolderPathUtils.decompose(FolderPathUtils.cast(nxObject.key))
		.slice(1)
		.map((folderPath) => ({
			label: FolderPathUtils.getFileNameFromFullPath(folderPath.slice(0, -1)),
			type: 'folder',
		}));

	if (nxObject.itemType === NxObjectType.File) {
		items.push({
			label: nxObject.name,
			type: 'file',
		});
	}

	return items;
}

export const objectBrowserBreadcrumbsManagerSelector = {
	actions: (state: ObjectBrowserBreadcrumbsManagerStore) => state.actions,
	breadcrumbsItems: (state: ObjectBrowserBreadcrumbsManagerStore) => state.breadcrumbsItems,
	isBreadcrumbsItemsPending: (state: ObjectBrowserBreadcrumbsManagerStore) => state.isBreadcrumbsItemsPending,
};
