import { useEffect } from 'react';
import { apolloAdapter } from '@warehouse/shared/infra';
import { inventoryFetchClientAdapter } from '@warehouse/object-browser/infra';
import { TokenGetterOutput } from '../hooks';

export function TokenGetterSetter({ tokenGetter }: { tokenGetter: TokenGetterOutput['tokenGetter'] }) {
	useEffect(() => {
		apolloAdapter.setTokenGetter(tokenGetter);
		inventoryFetchClientAdapter.setTokenGetter(tokenGetter);
	}, [tokenGetter]);
	return null;
}
