import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger, useWatch } from 'react-hook-form';

// TYPES
import { TitleFull } from '@warehouse/title/core';

// LIBRARY
import { TerritoriesService } from '@warehouse/global-entities/core';
import { isSetEqual } from '@warehouse/shared/util';
import { TerritoriesSelector } from '../../../../src/components/titles/TerritoriesSelector';
import getLabelWithRequired from '../../../../src/views/titles/createTitle/getLabelWithRequired';
import useTooltip from '../../../../src/utils/hooks/useTooltips';
import IncludeExcludeBottomAction from '../../../../src/components/titles/IncludeExcludeBottomAction';
import { flattenRegion } from '../../core/models/region';

interface MadeForRegionsFormData {
	madeForRegions: string[];
	isInclude: boolean | undefined;
}

interface MadeForRegionFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: TitleFull | undefined;
}

export function MadeForRegionField({
	control,
	errors,
	setValue,
	dryRanTitle,
	trigger,
	isRequired = false,
}: MadeForRegionFieldProps) {
	const madeForRegionsTooltip = useTooltip('coreMetadata.basic.versionIntent.madeForRegions');
	const madeForRegionsValue: MadeForRegionsFormData = useWatch({
		control,
		name: 'madeForRegions',
	});
	const inheritedMadeForRegionsValue: undefined | MadeForRegionsFormData = useMemo(
		() => getInheritedMadeForRegionsFormData(dryRanTitle),
		[dryRanTitle],
	);

	const label = useMemo(
		() =>
			getLabel({
				inherited: inheritedMadeForRegionsValue,
				explicit: madeForRegionsValue,
				required: isRequired,
			}),
		[inheritedMadeForRegionsValue, isRequired, madeForRegionsValue],
	);

	const isIncludeValue = useMemo(
		() =>
			getIsIncludeValue({
				inherited: inheritedMadeForRegionsValue,
				explicit: madeForRegionsValue,
			}),
		[inheritedMadeForRegionsValue, madeForRegionsValue],
	);

	// We know we are inheriting the field if the explicit value is empty and the inherited value is not
	const isInheriting =
		!madeForRegionsValue.madeForRegions.length && !!inheritedMadeForRegionsValue?.madeForRegions.length;

	const updateData = (newData: Partial<MadeForRegionsFormData>) => {
		const computedNewData = {
			...madeForRegionsValue,
			...newData,
		};

		// If the new data is the same as the inherited data, we reset the field to make it inherit back.
		if (areMadeForRegionsEqual(computedNewData, inheritedMadeForRegionsValue)) {
			setValue('madeForRegions', {
				madeForRegions: [],
				isInclude: undefined,
			});
			return;
		}

		// Otherwise, we update the field with the new data
		setValue('madeForRegions', computedNewData);
	};

	const onSelect = async (selected: string) => {
		// If we were inheriting the field, and we add a new territory, we stop inheriting, but we keep the isInclude value from the inheritance
		if (isInheriting) {
			updateData({ madeForRegions: [selected], isInclude: inheritedMadeForRegionsValue?.isInclude });
		} else {
			// Otherwise, we just add the new territory
			updateData({ madeForRegions: [...madeForRegionsValue.madeForRegions, selected] });
		}
		if (isRequired) await trigger('madeForRegions.madeForRegions');
	};

	const onDeselect = async (unselected: string) => {
		const filtered = madeForRegionsValue.madeForRegions.filter((item: any) => item !== unselected);

		// If we unselect the last territory, we set the isInclude value as undefined to make sure it will display the inheritance isInclude value
		if (filtered.length === 0) {
			updateData({ madeForRegions: [], isInclude: undefined });
		} else {
			// Otherwise, we just remove the territory
			updateData({ madeForRegions: filtered });
		}
		if (isRequired) await trigger('madeForRegions.madeForRegions');
	};

	const onIncludeExcludeChange = (isInclude: boolean) => {
		// If we were inheriting the field, and we change the include/exclude value, we stop inheriting, but we keep the territories from the inheritance
		if (isInheriting) {
			updateData({
				isInclude,
				madeForRegions: inheritedMadeForRegionsValue?.madeForRegions || [],
			});
			return;
		}

		// Otherwise, we just change the include/exclude value
		updateData({ isInclude });
	};

	return (
		<Controller
			name="madeForRegions"
			control={control}
			render={() => (
				<TerritoriesSelector<string[]>
					ariaLabel="Made For Regions"
					tooltip={madeForRegionsTooltip?.tooltip}
					label={label}
					placeholder={inheritedMadeForRegionsValue?.madeForRegions.map(TerritoriesService.getLabelByTerritoryUuid)}
					value={madeForRegionsValue.madeForRegions}
					multiple
					bottomActions={
						<IncludeExcludeBottomAction
							value={isIncludeValue}
							defaultValue="includes"
							onChange={(e) => onIncludeExcludeChange(e.target.value === 'includes')}
						/>
					}
					onSelect={onSelect}
					onDeselect={onDeselect}
					error={errors?.countriesOfOrigin?.message as string}
					getPopupContainer={() => document.body}
				/>
			)}
		/>
	);
}

function shouldShowExcl({
	inherited,
	explicit,
}: {
	explicit: MadeForRegionsFormData;
	inherited: MadeForRegionsFormData | undefined;
}) {
	if (explicit.madeForRegions.length > 0) return explicit.isInclude === false;
	return inherited?.isInclude === false;
}

function getLabel({
	explicit,
	inherited,
	required,
}: {
	explicit: MadeForRegionsFormData;
	inherited: MadeForRegionsFormData | undefined;
	required: boolean;
}): string {
	const base = `${shouldShowExcl({ inherited, explicit }) ? 'Exclude' : ''} Made For Regions`;
	return getLabelWithRequired(base, required);
}

function getInheritedMadeForRegionsFormData(dryRanTitle: TitleFull | undefined): MadeForRegionsFormData | undefined {
	if (!dryRanTitle) return undefined;

	if (
		!dryRanTitle.metadata.coreMetadata.basic.versionIntent?.madeForRegions?.displayValue?.length &&
		!dryRanTitle.metadata.coreMetadata.basic.versionIntent?.madeForRegionsExclude?.displayValue?.length
	) {
		return undefined;
	}
	const isInclude = !!dryRanTitle.metadata.coreMetadata.basic.versionIntent.madeForRegions?.displayValue?.length;

	return {
		isInclude,
		madeForRegions: (isInclude
			? dryRanTitle.metadata.coreMetadata.basic.versionIntent.madeForRegions?.displayValue || []
			: dryRanTitle.metadata.coreMetadata.basic.versionIntent.madeForRegionsExclude?.displayValue || []
		).map(flattenRegion),
	};
}

function areMadeForRegionsEqual(a: MadeForRegionsFormData | undefined, b: MadeForRegionsFormData | undefined): boolean {
	if (!a && !b) return true;
	if (!a || !b) return false;

	return a.isInclude === b.isInclude && isSetEqual(a.madeForRegions, b.madeForRegions);
}

function getIsIncludeValue({
	inherited,
	explicit,
}: {
	explicit: MadeForRegionsFormData;
	inherited: MadeForRegionsFormData | undefined;
}): 'includes' | 'excludes' {
	if (explicit.isInclude !== undefined) return explicit.isInclude ? 'includes' : 'excludes';
	if (inherited) return inherited.isInclude ? 'includes' : 'excludes';

	return 'includes';
}
