import { Filters } from '@warehouse/object-browser/core';

export class WildCardSearchAdapter {
	static adapt(wildCardSearch: Filters['textSearch']): { query: string; fields: string[] } | undefined {
		if (!wildCardSearch) return undefined;
		if (!wildCardSearch.value || !wildCardSearch.keysToSearch) return undefined;
		if (wildCardSearch.keysToSearch.length === 0) return undefined;
		return {
			query: wildCardSearch.value,
			fields: wildCardSearch.keysToSearch,
		};
	}
}
