import { useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import { CommonTitleFilters } from '@warehouse/title/domain';
import { FilterStep } from '@warehouse/shared/filters';
import { RelationshipType, WorkType } from '@warehouse/title/core';
import { getRelationshipTypeObject, getWorkTypeObject } from '@warehouse/title/infra';
import useSearchTitlesV2 from '../../../utils/hooks/titles/useSearchTitlesV2';

const loadingNetworkStatus = [NetworkStatus.setVariables, NetworkStatus.loading];

export function useHasEditChildren(titleUuid: string, showHidden: boolean) {
	const { data, networkStatus } = useSearchTitlesV2({
		searchFields: [],
		debouncedSearch: '',
		pollInterval: 2000,
		filtering: useMemo<FilterStep>(
			() => ({
				step: {
					combinationOperator: 'AND',
					filters: [
						{
							nested: 'parents',
							step: {
								combinationOperator: 'AND',
								filters: [
									{ field: 'parents.parentContentId', operator: 'equals', value: titleUuid },
									{
										field: 'parents.relationshipType',
										operator: 'equals',
										value: getRelationshipTypeObject(RelationshipType.DerivedFrom).relationshipTypeId,
									},
								],
							},
						},
						...(showHidden ? [] : [CommonTitleFilters.excludeHiddenTitles()]),
						CommonTitleFilters.searchByWorkType(getWorkTypeObject(WorkType.Edit).uuid),
					],
				},
			}),
			[titleUuid, showHidden],
		),
		page: 1,
		perPage: 1,
	});

	return [
		!!data?.searchTitlesV2?.page?.totalDocument && data.searchTitlesV2.page.totalDocument > 0,
		loadingNetworkStatus.includes(networkStatus),
	];
}
