import { Dispatch, SetStateAction } from 'react';
import { TitleFull } from '@warehouse/title/core';
import { useEditPreventer } from '@warehouse/title/feature-edit-preventer-popup';
import { WithUuid } from './recordToArray';
import { EditObject, OnAddSuccessFn, OnEditSuccessFn } from '../../contexts/TitleAutoSaveQueueContext';
import { JsonPointer } from '../../../../utils/getDeepProperty';
import { removeInheritanceStructure } from '../utils';
import { buildCommonPayload, dataToState, getFormattedPath, isATmpRow, withNewUuid } from './common';

interface UseEditProps<T> {
	value: WithUuid<T>[];
	setValue: Dispatch<SetStateAction<WithUuid<T>[]>>;
	setDirtyValue: Dispatch<SetStateAction<WithUuid<T>[] | undefined>>;
	title: TitleFull | undefined;
	path: JsonPointer;
	isValidRow: (row: WithUuid<T>) => boolean;
	label: string;
	addToQueue: (editObject: EditObject<T>) => void;
}

export default function useEdit<T>({
	value,
	setValue,
	setDirtyValue,
	title,
	path,
	isValidRow,
	label,
	addToQueue,
}: UseEditProps<T>) {
	const { act } = useEditPreventer();
	const resetDirtyValue = () => setDirtyValue(undefined);

	const onAddSuccess: OnAddSuccessFn = (record: unknown, recordUuid: string) => {
		resetDirtyValue();
		setValue((prev) => addNewRecordToPrev(prev, withNewUuid(record as T, recordUuid)));
	};

	const onEditSuccess: OnEditSuccessFn = (updatedTitle: TitleFull) => {
		resetDirtyValue();
		setValue(dataToState(updatedTitle, path));
	};

	const addAddRecordObjectToQueue = (el: WithUuid<T>) => {
		const formattedData = removeInheritanceStructure(el);

		if (!title) return;

		act({
			save: () =>
				addToQueue({
					mutation: 'add',
					value: { ...formattedData, __uuid: el.__uuid },
					onSuccess: onAddSuccess,
					...buildCommonPayload(path, title, label),
				}),
			onDenied: () => {
				setDirtyValue(undefined);
			},
			current: el,
			title,
			path,
		});
	};

	const addEditObjectOnExistingRowToQueue = (el: WithUuid<T>) => {
		const formattedData = removeInheritanceStructure(el);

		if (!title) return;

		act({
			save: () =>
				addToQueue({
					mutation: 'edit',
					value: formattedData,
					type: 'explicit',
					onSuccess: onEditSuccess,
					...buildCommonPayload(path, title, label),
					path: [...getFormattedPath(path, title)!, el.__uuid],
				}),
			onDenied: () => {
				setDirtyValue(undefined);
			},
			current: el,
			title,
			path,
		});
	};

	const editRecord = (el: WithUuid<T>) => {
		setDirtyValue((prev) => updateRecordInPrev(prev || value || [], el));

		if (!isValidRow(el) || !title || !getFormattedPath(path, title)) return;

		if (isATmpRow(el)) {
			addAddRecordObjectToQueue(el);
		} else {
			addEditObjectOnExistingRowToQueue(el);
		}
	};

	return {
		editRecord,
	};
}

function addNewRecordToPrev<T>(prev: WithUuid<T>[], record: WithUuid<T>) {
	return [...prev, record];
}

function updateRecordInPrev<T>(prev: WithUuid<T>[], record: WithUuid<T>) {
	return prev.map((el) => {
		if (el.__uuid === record.__uuid) return record;
		return el;
	});
}
