export type FolderPath = string & { __brand: 'FolderPath' };

export class FolderPathUtils {
	static isFolderPath(path: string): path is FolderPath {
		return path.endsWith('/');
	}

	static isRoot(path: FolderPath): boolean {
		return path === FolderPathUtils.root();
	}

	static root(): FolderPath {
		return '/' as FolderPath;
	}

	static split(path: FolderPath): string[] {
		return path.split('/');
	}

	static cast(path: string): FolderPath {
		return path as FolderPath;
	}

	static toSegments(folderPath: FolderPath): string[] {
		// Remove leading and trailing slashes
		const trimmedPath = folderPath.replace(/^\/|\/$/g, '');

		if (trimmedPath === '') {
			return [];
		}

		return trimmedPath.split('/');
	}

	static decompose(path: FolderPath): FolderPath[] {
		if (path === FolderPathUtils.root()) return [path];
		const segments = FolderPathUtils.split(path);
		return segments.map((_, idx, arr) =>
			arr.slice(0, idx).reduce<FolderPath>((acc, segment) => {
				const prev = acc.slice(0, -1);
				return FolderPathUtils.cast(`${prev}${prev.length ? '/' : ''}${segment}/`);
			}, FolderPathUtils.root()),
		);
	}

	static fullPath(parentPath: FolderPath, fileName: string): FolderPath {
		return `${parentPath}/${fileName}` as FolderPath;
	}

	static parsePath(fullPath: string): [FolderPath, string] {
		const constructedPath = fullPath.split('/');
		const parentPath =
			constructedPath.length > 1 ? constructedPath.slice(0, -1).join('/').concat('/') : FolderPathUtils.root();
		const fileName = constructedPath.length > 1 ? constructedPath.slice(-1)[0] : fullPath;
		return [parentPath as FolderPath, fileName];
	}

	static getFileNameFromFullPath(fullPath: string): string {
		return this.parsePath(fullPath)[1];
	}

	static isChildOf(parent: FolderPath, child: FolderPath): boolean {
		if (parent === FolderPathUtils.root()) return true;
		return child.startsWith(parent);
	}

	static drillInto(path: FolderPath, folderName: string): FolderPath {
		if (path === '/') {
			return `${folderName}/` as FolderPath;
		}

		return `${path}${folderName}/` as FolderPath;
	}

	static moveUpOnce(path: FolderPath): FolderPath {
		if (path === '/') {
			return FolderPathUtils.root();
		}

		const parts = path.split('/');
		parts.pop();

		if (parts.length === 0) {
			return FolderPathUtils.root();
		}

		return parts.join('/') as FolderPath;
	}
}
