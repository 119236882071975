import React, { useMemo } from 'react';
import { TitleFull as Title, WorkType } from '@warehouse/title/core';
import { PaginationNavigator, SearchInput } from '@warehouse/shared/ui';
import styled, { css } from 'styled-components';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { LoaderWrapper } from '../../../src/components/library/LoaderWrapper';
import getDeepProperty from '../../../src/utils/getDeepProperty';
import useWorkTypes from '../../../src/utils/hooks/titles/useWorkTypes';
import { fromBasic } from '../../../src/utils/titleGetProperty';
import { useSearchChildrenQuery } from '../domain/useSearchChildrenQuery';
import { ChildrenColumnType } from './children-compilation-extended.model';
import { computeWarnings } from './computeWarnings';
import { mapEntryClassAndEntryNumberToData } from './helpers/map-entry-class-and-entry-number-to-data';
import { ChildrenListPaginationStoreProvider, ChildrenListSearchStoreProvider } from './store-config';
import { useChildrenColumnsPerWorkType } from './useChildrenColumnsPerWorkType';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../src/components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { useEditEntryNumber, UseEditEntryNumberOutput } from './inline-edit/entry-number/use-edit-entry-number';

interface ChildrenListProps {
	label: string;
	title: Title;
	relationshipType?: string;
	childWorkType: WorkType;
	searchInputAction?: React.ReactNode;
	onChildrenClick?: (child: IndexedTitleV2) => void;
	includedWorkTypes?: WorkType[];
}

function ChildrenListComp({
	label,
	title,
	searchInputAction,
	relationshipType,
	onChildrenClick,
	includedWorkTypes,
	childWorkType,
}: ChildrenListProps) {
	const editMode = useTitleEditorStore(titleEditorStoreSelector.editMode);
	const workTypes = useWorkTypes();
	const workTypeUuid = useMemo(() => getDeepProperty<string>(title, fromBasic(['workType'])), [title]);
	const titleStatus = useMemo(
		() => getDeepProperty<string>(title, fromBasic(['terms', 'titleStatus', 'displayValue'])),
		[title],
	);

	const [columns, searchFields] = useChildrenColumnsPerWorkType(workTypeUuid!, childWorkType);

	const { data, loading } = useSearchChildrenQuery({
		titleUuid: title.uuid,
		searchFields,
		showHiddenTitles: titleStatus === 'hidden',
		relationshipType,
		includedWorkTypes,
	});

	const adaptedData = useMemo(
		() => (workTypeUuid === workTypes.compilationUuid() ? mapEntryClassAndEntryNumberToData(data, title) : data),
		[title, workTypes, workTypeUuid, data],
	);

	const editEntryNumber = useEditEntryNumber();

	return (
		<>
			<TopRow>
				<SearchInput label={label} />
				{searchInputAction}
			</TopRow>
			<LoaderWrapper loading={loading} opacity={0.3} style={{ flex: 1 }}>
				<InheritanceOnValueChangeSimpleTableWrapper<ChildrenColumnType>
					paddedCells
					columns={columns}
					editMode={editMode}
					data={adaptedData}
					tableStyle="border"
					forceHover
					customPropsCell={{ editEntryNumber } satisfies { editEntryNumber: UseEditEntryNumberOutput | null }}
					warnings={computeWarnings(adaptedData)}
					onClickRow={(row: IndexedTitleV2) => onChildrenClick?.(row)}
				/>
			</LoaderWrapper>
			<PaginationNavigator centered />
		</>
	);
}

export function ChildrenList({
	label,
	title,
	searchInputAction,
	relationshipType,
	onChildrenClick,
	includedWorkTypes,
	childWorkType,
}: ChildrenListProps) {
	return (
		<ChildrenListSearchStoreProvider>
			<ChildrenListPaginationStoreProvider>
				<ChildrenListComp
					label={label}
					title={title}
					searchInputAction={searchInputAction}
					relationshipType={relationshipType}
					onChildrenClick={onChildrenClick}
					includedWorkTypes={includedWorkTypes}
					childWorkType={childWorkType}
				/>
			</ChildrenListPaginationStoreProvider>
		</ChildrenListSearchStoreProvider>
	);
}

const TopRow = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(2)};
		width: 100%;
	`,
);
