import { Observable } from 'rxjs';
import { singletonFactory } from '@warehouse/shared/util';
import {
	Filters,
	InfraResourceIdentifier,
	NxObject,
	NxObjectFull,
	NxObjectRepository,
	SearchMode,
	WatchGetNxObjectResult,
	WatchNxObjectParams,
	WatchNxObjectsParams,
	WatchTreeParams,
} from '@warehouse/object-browser/core';
import { TableColumnSort } from '@warehouse/shared/ui';
import { PaginatedDocuments } from '@warehouse/shared/core';
import { inventoryFetchClientAdapter } from './nx-object-fetch-client';
import { NxObjectAdapter } from './nx-object.adapter';
import { NxObjectFiltersBuilder } from './nx-object-filters-builder';
import { NxObjectHashBuilder } from './nx-object-hash-builder';
import { WildCardSearchAdapter } from './wild-card-search.adapter';
import { NxObjectFullAdapter } from './nx-object-full.adapter';

type DefinedTableColumnSort = TableColumnSort & { sort: NonNullable<TableColumnSort['sort']> };

export class NxObjectRepositoryHttp implements NxObjectRepository {
	#inventoryFetchClientAdapter = inventoryFetchClientAdapter;

	watchNxObject({ nxObjectUuid, options }: WatchNxObjectParams): Observable<WatchGetNxObjectResult> {
		return this.#inventoryFetchClientAdapter.watchObservableQuery({
			queryKey: NxObjectHashBuilder.createGetQueryHash({ nxObjectUuid }),
			queryFn: () => this.#getNxObject({ nxObjectUuid }),
			refetchInterval: options?.pollInterval,
		});
	}

	watchNxObjects({
		filters = { pagination: { page: 1, perPage: 50 } },
		options,
		resourceIdentifier,
		searchMode,
	}: WatchNxObjectsParams) {
		return this.#inventoryFetchClientAdapter.watchObservableQuery({
			queryKey: NxObjectHashBuilder.createSearchQueryHash({ filters, resourceIdentifier, searchMode }),
			queryFn: () => this.#searchNxObject({ filters, resourceIdentifier, searchMode }),
			refetchInterval: options?.pollInterval,
		});
	}

	async #getNxObject({ nxObjectUuid }: { nxObjectUuid: string }): Promise<NxObjectFull> {
		const { error, data } = await this.#inventoryFetchClientAdapter.client.GET('/nx-object/{uuid}', {
			params: {
				path: {
					uuid: nxObjectUuid,
				},
			},
		});
		if (error) throw new Error('Error fetch nx object');

		return NxObjectFullAdapter.adapt(data);
	}

	async #searchNxObject({
		filters = { pagination: { page: 1, perPage: 50 } },
		resourceIdentifier,
		searchMode,
	}: {
		filters?: Filters;
		resourceIdentifier?: InfraResourceIdentifier;
		searchMode?: SearchMode;
	}): Promise<PaginatedDocuments<NxObject>> {
		const { error, data } = await this.#inventoryFetchClientAdapter.client.POST('/nx-objects/search', {
			body: {
				orderBy: filters.sort
					?.filter((value): value is DefinedTableColumnSort => !!value.sort)
					?.map((value) => ({
						field: value.field,
						direction: value.sort === 'asc' ? 'ASC' : ('DESC' as 'ASC' | 'DESC'),
					})),
				pagination: filters.pagination,
				filters: NxObjectFiltersBuilder.buildFilters({
					filters: filters.filter,
					resourceIdentifier,
					searchMode,
				}),
				wildcardSearch: WildCardSearchAdapter.adapt(filters.textSearch),
			},
		});

		if (error) throw new Error('Error fetching nx objects');

		return {
			documents: NxObjectAdapter.adaptArray(data.documents),
			pagination: data.page,
		};
	}

	watchTree({ resourceIdentifier, options }: WatchTreeParams) {
		return this.watchNxObjects({
			filters: {
				filter: {
					combinationOperator: 'AND',
					filters: [{ field: 'itemType', operator: 'equals', value: 'folder' }],
				},
				sort: [{ field: 'name', sort: 'asc' }],
				pagination: {
					page: 1,
					perPage: 5000,
				},
			},
			options,
			resourceIdentifier,
		});
	}
}

export const nxObjectRepositorySingleton = singletonFactory<NxObjectRepository>({
	factory: () => new NxObjectRepositoryHttp(),
});
