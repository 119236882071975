import styled, { css } from 'styled-components';

export const CardWrapper = styled.div(
	({ theme }) => css`
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing(2)};
		padding: ${theme.spacing(2)};
		width: 100%;
	`,
);

export const Wrapper = styled.div(
	() => css`
		background-color: #dde0e3;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		width: 100%;
	`,
);
