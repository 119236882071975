import { useEffect } from 'react';
import { useWatchObservableQuery } from '@warehouse/shared/util';
import { titleRepositorySingleton } from '@warehouse/title/infra';
import { titleEditorStoreSelector, useTitleEditorStore } from './title-editor.store';

export interface UsePosterProps {
	pollInterval?: number;
}

export function usePoster({ pollInterval }: UsePosterProps = {}) {
	const titleUuid = useTitleEditorStore(titleEditorStoreSelector.titleUuid);
	const alternateIdsForPosterHash = useTitleEditorStore(titleEditorStoreSelector.hashes.alternateIdsForPoster);

	const repo = titleRepositorySingleton.get();

	// if the alternateIdsForPoster hash changes we want to refetch the poster
	useEffect(() => {
		if (!titleUuid) return;
		repo.refetchPoster(titleUuid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alternateIdsForPosterHash, repo]);

	return useWatchObservableQuery({
		queryKey: ['titlePoster', titleUuid],
		queryFn: () => (titleUuid ? repo.watchPoster(titleUuid, { pollInterval }) : null),
	});
}
