import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { TitleFull } from '@warehouse/title/core';
import { manifestationUuid } from '@warehouse/title/infra';
import TitleSearch from '../../../../components/titles/titleSearch';
import useWorkTypes, { filterOutTypesByName } from '../../../../utils/hooks/titles/useWorkTypes';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';
import { ParentTitleCard } from './ParentTitleCard';

interface ParentFieldProps {
	workType: string;
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired: boolean;
	parentTitle?: {
		title: IndexedTitleV2 | undefined;
		parentType: string | undefined;
	};
	selectedParentTitle?: TitleFull;
	licensor?: string;
}

function ParentField({
	workType,
	control,
	errors,
	setValue,
	trigger,
	isRequired,
	parentTitle,
	selectedParentTitle,
	licensor,
}: ParentFieldProps) {
	const { getSupportedParentTypesByUuid } = useWorkTypes();
	const tooltipPath = 'coreMetadata.basic.parent';
	const tooltip = useTooltip(tooltipPath);

	const titleSearch = useMemo(
		() => (
			<Controller
				render={({ field }) => (
					<TitleSearch
						label={getLabelWithRequired('Parent Title', isRequired)}
						tooltip={tooltip?.tooltip}
						error={
							isRequired
								? // @ts-ignore
								  (errors.parentTitle?.title?.message as string)
								: undefined
						}
						workType={field.value.parentType}
						setWorkType={(_titleType: string) => setValue('parentTitle.parentType', _titleType)}
						workTypes={
							workType !== manifestationUuid()
								? filterOutTypesByName(['Compilation'], getSupportedParentTypesByUuid(workType)) || []
								: getSupportedParentTypesByUuid(workType)
						}
						onSelect={async (_title) => {
							setValue('parentTitle.title', _title);
							if (isRequired) {
								await trigger(['parentTitle', 'parentTitle.title']);
							}
						}}
						licensor={licensor}
						selected={field.value.title}
					/>
				)}
				name="parentTitle"
				control={control}
			/>
		),
		[
			isRequired,
			setValue,
			trigger,
			control,
			workType,
			licensor,
			// @ts-ignore
			errors?.parentTitle?.title?.message,
			parentTitle?.title,
			parentTitle?.parentType,
		],
	);

	return !selectedParentTitle?.uuid ? titleSearch : <ParentTitleCard selectedParentTitle={selectedParentTitle} />;
}

export default ParentField;
