import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { HouseSequence } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/SequenceInfo';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import TextInput from '../../../../components/library/TextInput';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface HouseSequenceFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function HouseSequenceField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	dryRanTitle,
}: HouseSequenceFieldProps) {
	const tooltip = useTooltip('coreMetadata.basic.sequenceInfo.houseSequence.houseSequence');

	const inheritedHouseSequence = getTitleData<OptionalInherited<HouseSequence>>(
		dryRanTitle,
		fromBasic(['sequenceInfo', 'houseSequence']),
	);

	return useMemo(
		() => (
			<Controller
				control={control}
				name="houseSequence"
				render={({ field }) => (
					<TextInput
						placeholder={inheritedHouseSequence?.inherited.value?.houseSequence}
						variant={!field.value ? 'prefilled' : 'default'}
						tooltip={tooltip?.tooltip}
						value={field.value}
						error={isRequired ? (errors?.title?.message as string) : ''}
						onChange={async (e) => {
							setValue('houseSequence', e.target.value);
							if (isRequired && !inheritedHouseSequence?.inherited.value?.houseSequence) {
								await trigger('houseSequence');
							}
						}}
						label={getLabelWithRequired('House Sequence', isRequired)}
					/>
				)}
			/>
		),
		[
			control,
			inheritedHouseSequence?.inherited.value?.houseSequence,
			isRequired,
			errors?.title?.message,
			setValue,
			trigger,
		],
	);
}

export default HouseSequenceField;
