import React, { useMemo } from 'react';
import { isInherited, OptionalInherited, titlePaths } from '@warehouse/title/core';
import { TextInput } from '@warehouse/shared/legacy-ui';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import { HouseSequence } from '../../core/models/sequence-info';

export function HouseSequenceNumberField() {
	const { readOnlyValue, value, setValue, commit } = useTitleAutoSave<OptionalInherited<HouseSequence | null>>({
		label: 'House Sequence',
		path: useMemo(() => titlePaths.houseSequence.split('.'), []),
	});
	const { isInherited: isHouseSequenceNumberInherited } = isInherited({ inheritedObject: value });

	return (
		<TitleEditableField
			label="House Sequence:"
			path={titlePaths.houseSequence}
			jumpToParentButtonDisabled
			tooltipDisabled
			readComponent={
				<ReadOnlyTypography
					aria-label="House Sequence"
					variant={isHouseSequenceNumberInherited ? 'prefilled' : 'default'}
				>
					{readOnlyValue?.displayValue?.houseSequence || ''}
				</ReadOnlyTypography>
			}
			editComponent={
				<TextInput
					type="number"
					aria-label="House Sequence"
					fullWidth
					height={38}
					value={value?.displayValue?.houseSequence.toString() || ''}
					onBlur={() => commit()}
					onChange={(e) => {
						const nextValue = e.target.value;
						setValue((prev) => ({
							...prev,
							displayValue: {
								domain: prev.displayValue?.domain,
								houseSequence: nextValue,
							},
						}));
					}}
					variant={isHouseSequenceNumberInherited ? 'prefilled' : 'default'}
					disabledMargin
					width="50%"
				/>
			}
		/>
	);
}
