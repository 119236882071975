import { TitleFull as Title } from '@warehouse/title/core';
import { LocalizedInfo } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/LocalizedInfo';

export function getDefaultLocalizedInfo(title: Title | undefined): LocalizedInfo | undefined {
	if (!title) return undefined;

	const { localizedInfos } = title.metadata.coreMetadata.basic;
	const localizedInfoArray = Object.values(localizedInfos);
	return localizedInfoArray.find((localizedInfo) => localizedInfo.default.displayValue);
}
