import React from 'react';

// LIBRARY
import AncillaryTab from './AncillaryTab';

function ProductMetadataTabAncillaryTab() {
	return <AncillaryTab />;
}

export default ProductMetadataTabAncillaryTab;
