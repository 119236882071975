import React from 'react';
import styled, { css } from 'styled-components';

// TYPES
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// HOOKS
import {
	AspectRatioField,
	CountriesOfOriginField,
	FrameRateField,
	MadeForRegionField,
	OriginalLanguagesField,
	ProductionCountriesField,
	ReleaseDateField,
	RunLengthField,
} from '@warehouse/title/feature-title-overview';
import useMemoJsonPath from '../../../../views/title/hooks/useMemoJsonPath';
import useTiniestSummaryTitleData from '../../../../views/title/hooks/useTiniestSummaryTitleData';
import useTitleAutoSave from '../../../../views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// UTILS
import { fromBasic } from '../../../../utils/titleGetProperty';

// LIBRARY
import TiniestSummary from '../fields/TiniestSummary';
import { TitleOverviewByTypeProps } from './type';
import { TitleOverviewLeftWrapperBase, Data, NonCenteredData } from './style';
import { LicensorField, SequenceNumberField } from '../fields';
import getPlaceholderArea from './getPlaceholderArea';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar topbar topbar'
			'licensor countriesOfOrigin sequenceNumber synopsis'
			'releaseDate productionCountries madeForRegion synopsis'
			'aspectRatio originalLanguage ph-2 synopsis'
			'frameRate runLength ph-3 synopsis';
		grid-template-columns: 25% 25% 25% 25%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

function Season({ editMode, topBar, displayVideoPlayer, title }: TitleOverviewByTypeProps) {
	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	const sequenceNumber = useTitleAutoSave<OptionalInherited<number | null>>({
		label: 'Sequence Number',
		path: useMemoJsonPath(fromBasic(['sequenceInfo', 'number'])),
	});

	return (
		<TitleOverviewLeftWrapper displayVideoPlayer={displayVideoPlayer}>
			{topBar}
			<NonCenteredData enableRightBorder enableBottomBorder={false} area="synopsis">
				<TiniestSummary
					tiniestSummary={tiniestSummary?.data?.displayValue || ''}
					isInherited={tiniestSummary.isInherited}
				/>
			</NonCenteredData>
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField title={title} />
			</Data>
			<Data enableRightBorder area="countriesOfOrigin">
				<CountriesOfOriginField />
			</Data>
			<Data enableLeftBorder={false} area="sequenceNumber">
				<SequenceNumberField
					isInherited={!!sequenceNumber?.value?.inherited?.value}
					sequenceNumber={
						editMode
							? sequenceNumber?.value?.displayValue?.toString() || ''
							: sequenceNumber?.readOnlyValue?.displayValue?.toString() || ''
					}
					setSequenceNumber={(v) =>
						sequenceNumber?.setValue((prev) => ({
							...prev,
							displayValue: parseInt(v, 10) || null,
						}))
					}
					onBlur={sequenceNumber.commit}
				/>
			</Data>
			<Data enableLeftBorder={false} area="madeForRegion">
				<MadeForRegionField />
			</Data>
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			<Data enableRightBorder area="productionCountries">
				<ProductionCountriesField />
			</Data>
			<Data enableLeftBorder={false} area="aspectRatio">
				<AspectRatioField />
			</Data>
			<Data enableRightBorder area="originalLanguage">
				<OriginalLanguagesField />
			</Data>
			<Data enableBottomBorder={false} enableLeftBorder={false} area="frameRate">
				<FrameRateField />
			</Data>
			<Data enableRightBorder enableBottomBorder={false} area="runLength">
				<RunLengthField />
			</Data>
			{getPlaceholderArea({ bottom: true, name: 'ph-2' })}
			{getPlaceholderArea({ name: 'ph-3' })}
		</TitleOverviewLeftWrapper>
	);
}

export default Season;
