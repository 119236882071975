import React, { useEffect, useMemo, useState } from 'react';
import { Skeleton, Typography } from '@mui/material';
import styled from 'styled-components';
import { usePoster } from '@warehouse/title/domain';
import SmoothImage from '../../../../src/components/common/SmoothImage';

export function Poster() {
	const [isLoadingPosterImg, setIsLoadingPosterImg] = useState(true);
	const { isPending, data: posterData } = usePoster();

	useEffect(() => {
		if (posterData?.poster) setIsLoadingPosterImg(true);
	}, [posterData?.poster]);

	const hasPoster = useMemo(() => !isPending && !!posterData?.poster, [isPending, posterData]);

	const isLoading = isPending || (hasPoster && isLoadingPosterImg);

	return (
		<Wrapper>
			<OpacifiedWrapper opacity={isLoading || !hasPoster ? 1 : 0}>
				<Skeleton
					className="skeleton"
					variant="rectangular"
					height="100%"
					style={{ borderRadius: '8px' }}
					animation={isLoading ? 'pulse' : false}
				/>
			</OpacifiedWrapper>
			<OpacifiedWrapper className="not-avail" opacity={isLoading || hasPoster ? 0 : 1}>
				<NotAvailWrapper variant="bodySmallRegular">Poster not available</NotAvailWrapper>
			</OpacifiedWrapper>
			<SmoothImage
				transitionTime={500}
				src={posterData?.poster}
				onLoad={() => setIsLoadingPosterImg(false)}
				alt="Poster"
			/>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	height: 100%;
	position: relative;
	width: 100%;

	.skeleton {
		aspect-ratio: 0.675; // Most common aspect ratio for posters
		border-radius: 8px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	img {
		height: 100%;
		position: absolute;
		left: 50%;
		top: 0;
		border-radius: 8px;
		transform: translateX(-50%);
	}

	.not-avail {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const NotAvailWrapper = styled(Typography)`
	opacity: 0.5;
`;

const OpacifiedWrapper = styled.div<{ opacity: number }>(
	({ opacity }) => `
    position: absolute;
    top: 0;
    left: 0;
    
    height: 100%;
    opacity: ${opacity};
    transition: opacity 0.5s;
    width: 100%;
`,
);
