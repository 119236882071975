import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { mdiContentCopy, mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import { TooltipIcon } from '@warehouse/shared/legacy-ui';
import Tooltip from '../../../../../components/library/Tooltip';
import Button from '../../../../../components/library/Button';

export const Wrapper = styled.div(() => css``);

export const Ellipsis = styled.div(
	() => css`
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: row;
		overflow: hidden;
	`,
);

export const Row = styled.div<{ displayHiddenOnHover?: boolean }>(
	({ displayHiddenOnHover }) => css`
		align-items: center;
		display: flex;
		flex-direction: row;

		${displayHiddenOnHover &&
		css`
			&:hover {
				.displayOnHover {
					visibility: visible !important;
				}
			}
		`}
	`,
);

export const Label = styled((props) => <Typography {...props} variant="buttonMediumMedium" />)(
	() =>
		css`
			white-space: nowrap;
		`,
);

export const Value = styled((props) => <Typography {...props} variant="buttonMediumRegular" />)<{ width?: string }>(
	({ theme, width }) => css`
		color: ${theme.palette.text.secondary};
		margin-left: ${theme.spacing(1)};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		${width ? `width: ${width};` : 'width: 100%'}
	`,
);

const TitleRow = styled.div(
	({ theme }) => css`
		svg {
			color: ${theme.palette.text.secondary};
		}
	`,
);

export function SectionTitle({ title, tooltip }: { tooltip: string | ReactNode; title: string }) {
	return (
		<TitleRow>
			<Typography variant="s1Regular">{title}</Typography>
			<Tooltip title={tooltip} placement="right">
				<TooltipIcon path={mdiInformation} size="16px" />
			</Tooltip>
		</TitleRow>
	);
}

export function LabelValue({ label, value, copyable = false }: { label: string; value?: string; copyable?: boolean }) {
	const onCopy = () => {
		if (value) navigator.clipboard.writeText(value || '');
	};

	return (
		<Row displayHiddenOnHover={copyable}>
			<Label>{label}</Label>
			<Ellipsis>
				<Value width={copyable ? '80%' : undefined}>{value}</Value>
				<Button
					onClick={onCopy}
					style={{ visibility: 'hidden' }}
					className="displayOnHover"
					$disablePadding
					nxstyle="tertiary-light"
				>
					<Icon style={{ marginLeft: 8 }} path={mdiContentCopy} size="14px" />
				</Button>
			</Ellipsis>
		</Row>
	);
}
