import React from 'react';
import { titlePaths } from '@warehouse/title/core';
import { TitleNumberInputField } from '../../ui/fields/TitleNumberInputField';

export function SequenceNumberField() {
	return (
		<TitleNumberInputField
			view="overview"
			label="Sequence Number:"
			ariaLabel="Sequence Number"
			path={titlePaths.sequenceNumber}
			jumpToParentButtonDisabled
			tooltipDisabled
		/>
	);
}
