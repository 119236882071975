import { apolloAdapter } from '@warehouse/shared/infra';
import { map, Observable } from 'rxjs';
import { Order, SearchRelationshipsOutput as SearchRelationshipsOutputGraphQL } from '@warehouse/graphql';
import { singletonFactory } from '@warehouse/shared/util';
import { getRelationshipType, indexedTitleToCommonReadonly } from '@warehouse/title/infra';
import { SEARCH_RELATIONSHIPS } from '../../../src/graphql/queries/relationships';
import { RelationshipRepository, WatchSearchOptions } from '../core/relationship.repository';
import {
	computeAPISort,
	computeWildcardSearch,
	formatFilters,
} from '../../../src/utils/hooks/titles/useSearchTitlesV2';
import { SearchRelationshipsOutput } from '../core/relationship';

export class GqlRelationshipRepository implements RelationshipRepository {
	private _apolloAdapter = apolloAdapter;

	watchSearch(options: WatchSearchOptions): Observable<SearchRelationshipsOutput> {
		const apiSort: Order[] | undefined = computeAPISort(options.sorting);
		const apiFilters = formatFilters(options.filtering);
		const wildcardSearch = computeWildcardSearch(options.searchValue, options.searchFields);

		const response$ = this._apolloAdapter.watchObservableQuery<{
			searchRelationships: SearchRelationshipsOutputGraphQL;
		}>({
			query: SEARCH_RELATIONSHIPS,
			variables: {
				search: {
					pagination: { page: options.page, perPage: options.perPage },
					orderBy: apiSort,
					filters: JSON.stringify(apiFilters),
					wildcardSearch,
				},
			},
			pollInterval: options.pollInterval,
		});

		return response$.pipe(
			map((apolloResponse) => {
				const data = apolloResponse.data.searchRelationships;

				const titleLights =
					data?.documents.map((doc) => ({
						...indexedTitleToCommonReadonly(doc.indexed),
						selectedParent: {
							...doc.relationship,
							contentId: doc.titleUuid,
							primary: doc.relationship.primary ?? false,
							relationshipType: getRelationshipType(doc.relationship.type),
							sequenceInfo: {
								distributionNumber: doc.relationship.sequenceInfo?.distributionNumber?.distributionNumber ?? undefined,
								houseSequence: doc.relationship.sequenceInfo?.houseSequence?.houseSequence ?? undefined,
								number: doc.relationship.sequenceInfo?.number ?? undefined,
							},
							relationshipSubtypes: doc.relationship.subtypes?.map((subtype) => subtype.uuid),
						},
					})) || [];

				return {
					page: data?.page,
					documents: titleLights,
				};
			}),
		);
	}
}

export const relationshipRepositorySingleton = singletonFactory<RelationshipRepository>({
	factory: () => new GqlRelationshipRepository(),
});
