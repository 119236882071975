import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { IndexedTitleV2 } from '@warehouse/graphql';
import { TitleFull as Title, TitleFull } from '@warehouse/title/core';
import DisplayTitleField from '../fields/DisplayTitle';
import ParentField from '../fields/ParentField';

interface SeasonProps {
	workType: string;
	control: Control<any>;
	watch: UseFormWatch<any>;
	isDryRunDirty: boolean;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	parentTitle?: {
		title: IndexedTitleV2 | undefined;
		parentType: string | undefined;
	};
	selectedParentTitle?: TitleFull;
	dryRanTitle?: Title | undefined;
}

function Season({
	workType,
	control,
	errors,
	setValue,
	isDryRunDirty,
	trigger,
	watch,
	parentTitle,
	selectedParentTitle,
	dryRanTitle,
}: SeasonProps) {
	const sequenceNumber = watch('sequenceNumber');
	const licensor = watch('licensor');

	return (
		<div>
			<ParentField
				control={control}
				setValue={setValue}
				trigger={trigger}
				parentTitle={parentTitle}
				errors={errors}
				workType={workType}
				selectedParentTitle={selectedParentTitle}
				isRequired
				licensor={licensor}
			/>
			<DisplayTitleField
				titlePlaceholder={sequenceNumber ? `Season ${sequenceNumber}` : undefined}
				control={control}
				isDryRunDirty={isDryRunDirty}
				setValue={setValue}
				errors={errors}
				isRequired={sequenceNumber === undefined || sequenceNumber === ''}
				trigger={trigger}
				dryRanTitle={dryRanTitle}
			/>
		</div>
	);
}

export default Season;
