import Icon from '@mdi/react';
import { mdiDelete, mdiPlus, mdiTable } from '@mdi/js';
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useMemo, useState } from 'react';
import { CoreInstance } from '@tanstack/react-table';
import Button from '../Button';
import { ActionRowWrapper, LeftWrapper, SelectionButton, StyledNumberInput } from './style';
import Loader from '../Loader';
import { CanDeleteFn, getReason } from './can-delete';
import { ConditionalTree } from '../../../utils/ConditionalTree';
import Tooltip from '../Tooltip';

interface ActionRowProps {
	tableStyle: 'default' | 'border' | 'subheader';
	onRowAdd?: (numberOfRows: number, selectedDropdownValues: Record<string, any>) => void;
	addRowNumber: string;
	selectedDropdownValues: Record<string, any>;
	setAddRowNumber: Dispatch<SetStateAction<string>>;
	onRowDelete: ((selectedIndexes: string[]) => void) | undefined;
	isSelectionEnabled: boolean;
	setIsSelectionEnabled: Dispatch<SetStateAction<boolean>>;
	selectedIndexes: Record<string, boolean>;
	canDelete: CanDeleteFn;
	canAddRow: boolean;
	enableAddMultipleRows: boolean;
	selectRowModeByDefault: boolean;
	addRowLabel: string;
	getRow: CoreInstance<any>['getRow'];
	selectionKey: string | undefined;
}

function recordsIndexesToArray(records: Record<string, boolean>) {
	return Object.keys(records || {}).map((key) => key);
}

// TODO: Add generic type for row data
function ActionRow({
	tableStyle,
	onRowAdd,
	addRowNumber,
	selectedDropdownValues,
	setAddRowNumber,
	onRowDelete,
	isSelectionEnabled,
	setIsSelectionEnabled,
	selectedIndexes,
	canDelete,
	canAddRow,
	enableAddMultipleRows,
	selectRowModeByDefault,
	addRowLabel,
	getRow,
	selectionKey,
}: ActionRowProps) {
	const [loading, setLoading] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

	const canDeleteResult = useMemo(
		() => canDelete(recordsIndexesToArray(selectedIndexes)),
		[selectedIndexes, canDelete],
	);

	const canNotDeleteReason = useMemo(() => getReason(canDeleteResult), [canDeleteResult]);

	const deleteButtonIsDisabled = useMemo(() => {
		if (canDeleteResult?.canDelete === false) return true;
		return Object.keys(selectedIndexes || {})?.length === 0;
	}, [selectedIndexes, canDeleteResult]);

	const TooltipWrapper = useCallback(
		(children: ReactElement | ReactElement[]) => (
			<Tooltip title={canNotDeleteReason} placement="right">
				<span>{children}</span>
			</Tooltip>
		),
		[canNotDeleteReason],
	);

	const onClickDelete = async () => {
		const safeGetRow = (id: string) => {
			try {
				return getRow(id);
			} catch {
				return undefined;
			}
		};
		if (!onRowDelete) return;
		setDeleteLoading(true);
		let rowsToDelete = recordsIndexesToArray(selectedIndexes);
		if (selectionKey) {
			rowsToDelete = rowsToDelete
				.map((id) => safeGetRow(id)?.original?.[selectionKey])
				.filter((id): id is string => !!id);
		}
		await onRowDelete(rowsToDelete);
		setDeleteLoading(false);
	};

	return (
		<ActionRowWrapper tableStyle={tableStyle}>
			<LeftWrapper>
				{onRowAdd ? (
					<>
						<Button
							startIcon={loading ? <Loader size={25} /> : <Icon path={mdiPlus} size="25px" />}
							disabled={!canAddRow}
							nxstyle="tertiary-light"
							$fullHeight
							onClick={async () => {
								const numberOfRows = addRowNumber ? parseInt(addRowNumber, 10) : 1;
								setLoading(true);
								onRowAdd(Number.isNaN(numberOfRows) ? 1 : numberOfRows, selectedDropdownValues);
								setLoading(false);
							}}
						>
							{loading ? 'Adding...' : addRowLabel}
						</Button>
						{!loading && enableAddMultipleRows && (
							<StyledNumberInput
								value={addRowNumber}
								onChange={(e) => {
									const num = Math.min(99, Math.max(0, parseInt(e.target.value, 10)));
									setAddRowNumber(num.toString());
								}}
								disableRightIcon
							/>
						)}
					</>
				) : null}

				{!selectRowModeByDefault && onRowDelete && (
					<SelectionButton
						$isSelectionEnabled={isSelectionEnabled}
						startIcon={<Icon path={mdiTable} size="25px" />}
						nxstyle="tertiary-light"
						$fullHeight
						onClick={() => setIsSelectionEnabled(!isSelectionEnabled)}
					>
						Select Rows
					</SelectionButton>
				)}
			</LeftWrapper>

			{(isSelectionEnabled || selectRowModeByDefault) && onRowDelete && (
				<div>
					<ConditionalTree condition={!!canNotDeleteReason} wrapper={TooltipWrapper}>
						<Button
							aria-label="Delete selected rows"
							style={{ left: '-6px' }}
							nxstyle="secondary-red"
							disabled={deleteButtonIsDisabled}
							$fullHeight
							onClick={onClickDelete}
						>
							{deleteLoading ? <Loader color="white" size={20} /> : <Icon path={mdiDelete} size="25px" />}
						</Button>
					</ConditionalTree>
				</div>
			)}
		</ActionRowWrapper>
	);
}

export default ActionRow;
