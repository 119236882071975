import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { DistributionNumber } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/SequenceInfo';
import React, { useMemo } from 'react';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { TitleFull as Title } from '@warehouse/title/core';
import TextInput from '../../../../components/library/TextInput';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import getLabelWithRequired from '../getLabelWithRequired';
import useTooltip from '../../../../utils/hooks/useTooltips';

interface DistributionNumberFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

function DistributionNumberField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	dryRanTitle,
}: DistributionNumberFieldProps) {
	const distributionNumberTooltip = useTooltip('coreMetadata.basic.sequenceInfo.distributionNumber.distributionNumber');

	const inheritedDistributionNumber = getTitleData<OptionalInherited<DistributionNumber>>(
		dryRanTitle,
		fromBasic(['sequenceInfo', 'distributionNumber']),
	);

	return useMemo(
		() => (
			<Controller
				name="distributionNumber"
				control={control}
				render={({ field }) => (
					<TextInput
						placeholder={inheritedDistributionNumber?.inherited.value?.distributionNumber}
						tooltip={distributionNumberTooltip?.tooltip}
						variant={!field.value ? 'prefilled' : 'default'}
						error={errors?.distributionNumber?.message as string}
						value={field.value}
						onChange={async (e) => {
							setValue('distributionNumber', e.target.value);
							if (isRequired && !inheritedDistributionNumber?.inherited.value?.distributionNumber) {
								await trigger('distributionNumber');
							}
						}}
						label={getLabelWithRequired('Distribution Number', isRequired)}
					/>
				)}
			/>
		),
		[
			control,
			errors?.distributionNumber?.message,
			inheritedDistributionNumber?.inherited.value?.distributionNumber,
			setValue,
			trigger,
		],
	);
}

export default DistributionNumberField;
