import React from 'react';
import styled, { css } from 'styled-components';

// TYPES
import {
	DistributionNumber,
	HouseSequence,
} from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/SequenceInfo';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// HOOKS
import {
	ReleaseDateField,
	AspectRatioField,
	FrameRateField,
	EditUseField,
	CountriesOfOriginField,
	ProductionCountriesField,
	RunLengthField,
	EditClassesField,
	OriginalLanguagesField,
	MadeForRegionField,
} from '@warehouse/title/feature-title-overview';
import useMemoJsonPath from '../../../../views/title/hooks/useMemoJsonPath';
import useTiniestSummaryTitleData from '../../../../views/title/hooks/useTiniestSummaryTitleData';
import useTitleAutoSave from '../../../../views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// UTILS
import { fromBasic } from '../../../../utils/titleGetProperty';
import DomOrderer from './DomOrderer';
import getPlaceholderArea from './getPlaceholderArea';

// LIBRARY
import TiniestSummary from '../fields/TiniestSummary';
import { TitleOverviewByTypeProps } from './type';
import { TitleOverviewLeftWrapperBase, Data, NonCenteredData } from './style';
import { DistributionNumberField, LicensorField, SequenceNumberField } from '../fields';
import HouseSequenceNumberField from '../fields/HouseSequenceNumberField';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)<{
	parentAbstractionWorkType: 'Supplemental' | 'Episode' | 'Movie';
}>(
	({ parentAbstractionWorkType }) => css`
		${parentAbstractionWorkType === 'Episode' &&
		css`
			grid-template-areas:
				'topbar topbar topbar topbar'
				'licensor countriesOfOrigin sequenceNumber editUse'
				'releaseDate productionCountries distributionNumber editClass'
				'aspectRatio originalLanguage houseSequence madeForRegion'
				'frameRate runLength epi-ph-1 epi-ph-2';
			grid-template-columns: 25% 25% 25% 25%;
			grid-template-rows: 80px repeat(4, 44px);
		`}

		${parentAbstractionWorkType === 'Supplemental' &&
		css`
			grid-template-areas:
				'topbar topbar topbar topbar'
				'licensor countriesOfOrigin sequenceNumber editUse'
				'releaseDate productionCountries supp-ph-1 editClass'
				'aspectRatio originalLanguage supp-ph-2 madeForRegion'
				'frameRate runLength supp-ph-3 supp-ph-4';
			grid-template-columns: 25% 25% 25% 25%;
			grid-template-rows: 80px repeat(4, 44px);
		`}

    ${parentAbstractionWorkType === 'Movie' &&
		css`
			grid-template-areas:
				'topbar topbar topbar topbar'
				'licensor countriesOfOrigin editUse synopsis'
				'releaseDate productionCountries editClass synopsis'
				'aspectRatio originalLanguage madeForRegion synopsis'
				'frameRate runLength . synopsis';
			grid-template-columns: 25% 25% 25% 25%;
			grid-template-rows: 80px repeat(4, 44px);
		`}
	`,
);

function Performance({
	editMode,
	topBar,
	displayVideoPlayer,
	title,
	parentAbstractionWorkType = 'Episode',
}: TitleOverviewByTypeProps) {
	const genDataOrder = (episodeOrder?: number, supplementalOrder?: number, movieOrder?: number) => {
		switch (parentAbstractionWorkType) {
			case 'Episode':
				return episodeOrder;
			case 'Supplemental':
				return supplementalOrder;
			case 'Movie':
				return movieOrder;
			default:
				return undefined;
		}
	};

	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	const sequenceNumber = useTitleAutoSave<OptionalInherited<number | null>>({
		label: 'Sequence Number',
		path: useMemoJsonPath(fromBasic(['sequenceInfo', 'number'])),
	});

	const distributionNumber = useTitleAutoSave<OptionalInherited<DistributionNumber | null>>({
		label: 'Distribution Number',
		path: useMemoJsonPath(fromBasic(['sequenceInfo', 'distributionNumber'])),
	});

	const houseSequenceNumber = useTitleAutoSave<OptionalInherited<HouseSequence | null>>({
		label: 'House Sequence',
		path: useMemoJsonPath(fromBasic(['sequenceInfo', 'houseSequence'])),
	});

	return (
		<TitleOverviewLeftWrapper
			parentAbstractionWorkType={parentAbstractionWorkType}
			displayVideoPlayer={displayVideoPlayer}
		>
			{topBar}
			<DomOrderer>
				{!['Supplemental', 'Episode'].includes(parentAbstractionWorkType) && (
					<NonCenteredData enableRightBorder enableBottomBorder={false} area="synopsis">
						<TiniestSummary
							tiniestSummary={tiniestSummary?.data?.displayValue || ''}
							isInherited={tiniestSummary.isInherited}
						/>
					</NonCenteredData>
				)}
				<Data enableLeftBorder={false} area="licensor" data-order={genDataOrder(1, 1, 1)}>
					<LicensorField title={title} />
				</Data>
				<Data enableRightBorder area="countriesOfOrigin" data-order={genDataOrder(2, 2, 2)}>
					<CountriesOfOriginField />
				</Data>
				<Data enableLeftBorder={false} area="releaseDate" data-order={genDataOrder(5, 5, 5)}>
					<ReleaseDateField />
				</Data>
				<Data enableRightBorder area="productionCountries" data-order={genDataOrder(6, 6, 6)}>
					<ProductionCountriesField />
				</Data>
				<Data
					enableBottomBorder={false}
					enableLeftBorder={false}
					area="frameRate"
					data-order={genDataOrder(13, 13, 13)}
				>
					<FrameRateField />
				</Data>
				<Data enableLeftBorder={false} area="aspectRatio" data-order={genDataOrder(9, 9, 9)}>
					<AspectRatioField />
				</Data>
				<Data enableRightBorder enableBottomBorder={false} area="runLength" data-order={genDataOrder(14, 14, 14)}>
					<RunLengthField />
				</Data>
				{parentAbstractionWorkType === 'Episode' && (
					<>
						<Data
							enableLeftBorder={false}
							enableRightBorder={parentAbstractionWorkType === 'Episode'}
							area="distributionNumber"
							data-order={genDataOrder(7, undefined, undefined)}
						>
							<DistributionNumberField
								isInherited={!!distributionNumber?.value?.inherited?.value}
								distributionNumber={
									editMode
										? distributionNumber?.value?.displayValue?.distributionNumber || ''
										: distributionNumber?.readOnlyValue?.displayValue?.distributionNumber || ''
								}
								setDistributionNumber={(v) => {
									if (!v) {
										distributionNumber?.setValue((prev) => ({
											...prev,
											displayValue: null,
										}));
										return;
									}
									if (distributionNumber?.value?.displayValue?.domain) {
										distributionNumber?.setValue((prev) => ({
											...prev,
											displayValue: {
												distributionNumber: v,
												domain: distributionNumber?.value?.displayValue?.domain,
											},
										}));
									} else {
										distributionNumber?.setValue((prev) => ({
											...prev,
											displayValue: {
												distributionNumber: v,
												domain: 'nexspec.com/warehouse',
											},
										}));
									}
								}}
								onBlur={distributionNumber.commit}
							/>
						</Data>
						<Data
							enableLeftBorder={false}
							enableRightBorder={parentAbstractionWorkType === 'Episode'}
							area="houseSequence"
							data-order={genDataOrder(11, undefined, undefined)}
						>
							<HouseSequenceNumberField
								isInherited={!!houseSequenceNumber?.value?.inherited?.value}
								houseSequenceNumber={
									editMode
										? houseSequenceNumber.value?.displayValue?.houseSequence || ''
										: houseSequenceNumber.readOnlyValue?.displayValue?.houseSequence || ''
								}
								setHouseSequenceNumber={(v) => {
									if (!v) {
										houseSequenceNumber?.setValue((prev) => ({
											...prev,
											displayValue: null,
										}));
										return;
									}
									if (houseSequenceNumber?.value?.displayValue?.domain) {
										houseSequenceNumber?.setValue((prev) => ({
											...prev,
											displayValue: {
												houseSequence: v,
												domain: houseSequenceNumber?.value?.displayValue?.domain,
											},
										}));
									} else {
										houseSequenceNumber?.setValue((prev) => ({
											...prev,
											displayValue: {
												houseSequence: v,
												domain: 'nexspec.com/warehouse',
											},
										}));
									}
								}}
								onBlur={houseSequenceNumber.commit}
							/>
						</Data>
					</>
				)}
				{['Supplemental', 'Episode'].includes(parentAbstractionWorkType) && (
					<Data
						enableRightBorder={parentAbstractionWorkType !== 'Supplemental'}
						enableLeftBorder={false}
						area="sequenceNumber"
						data-order={genDataOrder(3, 3, undefined)}
					>
						<SequenceNumberField
							isInherited={!!sequenceNumber?.value?.inherited?.value}
							sequenceNumber={
								editMode
									? sequenceNumber?.value?.displayValue?.toString() || ''
									: sequenceNumber?.readOnlyValue?.displayValue?.toString() || ''
							}
							setSequenceNumber={(v) =>
								sequenceNumber?.setValue((prev) => ({
									...prev,
									displayValue: parseInt(v, 10) || null,
								}))
							}
							onBlur={sequenceNumber.commit}
						/>
					</Data>
				)}
				<Data enableRightBorder area="originalLanguage" data-order={genDataOrder(10, 10, 10)}>
					<OriginalLanguagesField />
				</Data>
				<Data
					enableRightBorder={parentAbstractionWorkType !== 'Movie'}
					enableLeftBorder={parentAbstractionWorkType === 'Supplemental'}
					area="editUse"
					data-order={genDataOrder(4, 4, 3)}
				>
					<EditUseField />
				</Data>
				<Data
					enableLeftBorder={parentAbstractionWorkType === 'Supplemental'}
					enableRightBorder={parentAbstractionWorkType !== 'Movie'}
					area="editClass"
					data-order={genDataOrder(8, 8, 7)}
				>
					<EditClassesField />
				</Data>
				<Data
					enableRightBorder={parentAbstractionWorkType !== 'Movie'}
					enableLeftBorder={parentAbstractionWorkType === 'Supplemental'}
					area="madeForRegion"
					data-order={genDataOrder(12, 12, 11)}
				>
					<MadeForRegionField />
				</Data>
				{parentAbstractionWorkType === 'Episode' && (
					<>
						{getPlaceholderArea({ right: true, name: 'epi-ph-1' })}
						{getPlaceholderArea({
							right: true,
							name: 'epi-ph-2',
						})}
					</>
				)}
				{parentAbstractionWorkType === 'Supplemental' && (
					<>
						{getPlaceholderArea({ bottom: true, name: 'supp-ph-1' })}
						{getPlaceholderArea({ bottom: true, name: 'supp-ph-2' })}
						{getPlaceholderArea({ name: 'supp-ph-3' })}
						{getPlaceholderArea({
							left: true,
							right: true,
							name: 'supp-ph-4',
						})}
					</>
				)}
			</DomOrderer>
		</TitleOverviewLeftWrapper>
	);
}

export default Performance;
