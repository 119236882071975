import { TitleFull, WorkType } from '@warehouse/title/core';
import { TitleCreationFormData } from './title-creation-form-data';
import {
	getDryRanLanguage,
	getDryRanCountriesOfOrigin,
	getDryRanReleaseDate,
	getDryRanApproximateLength,
	getDryRanOriginalLanguages,
	getDryRanHouseSequence,
	getDryRanDistributionNumber,
	getDryRanEditUse,
	getDryRanEditClass,
	getDryRanMadeForRegions,
	getDryRanVersionLanguages,
} from './dry-run-getters';

export function reinheritForm(
	form: TitleCreationFormData[],
	dryRanTitle: TitleFull | undefined,
): TitleCreationFormData[] {
	if (!dryRanTitle) return form;
	return form.map((row) => reinheritRow(row, dryRanTitle));
}

function reinheritRow(formRow: TitleCreationFormData, dryRanTitle: TitleFull): TitleCreationFormData {
	const newFormRow = { ...formRow };

	if (!formRow.titleLanguage) {
		newFormRow.titleLanguage = getDryRanLanguage(dryRanTitle);
	}
	if (isUndefinedOrEmptyArray(formRow.countriesOfOrigin))
		newFormRow.countriesOfOrigin = getDryRanCountriesOfOrigin(dryRanTitle);
	if (!formRow.releaseDate) newFormRow.releaseDate = getDryRanReleaseDate(dryRanTitle);
	if (!formRow.approximateLength || formRow.approximateLength === 0)
		newFormRow.approximateLength = getDryRanApproximateLength(dryRanTitle);
	if (isUndefinedOrEmptyArray(formRow.originalLanguages))
		newFormRow.originalLanguages = getDryRanOriginalLanguages(dryRanTitle);

	if (newFormRow.workType === WorkType.Episode) {
		if (newFormRow.houseSequence === undefined) newFormRow.houseSequence = getDryRanHouseSequence(dryRanTitle);
		if (newFormRow.distributionNumber === undefined)
			newFormRow.distributionNumber = getDryRanDistributionNumber(dryRanTitle);
	}

	if (newFormRow.workType === WorkType.Edit) {
		if (!newFormRow.editUse) newFormRow.editUse = getDryRanEditUse(dryRanTitle);
		if (isUndefinedOrEmptyArray(newFormRow.editClass)) newFormRow.editClass = getDryRanEditClass(dryRanTitle);
		if (!newFormRow.madeForRegions || isUndefinedOrEmptyArray(newFormRow.madeForRegions.madeForRegions))
			newFormRow.madeForRegions = getDryRanMadeForRegions(dryRanTitle);
		if (isUndefinedOrEmptyArray(newFormRow.versionLanguages))
			newFormRow.versionLanguages = getDryRanVersionLanguages(dryRanTitle);
	}

	return newFormRow;
}

function isUndefinedOrEmptyArray(value: unknown[] | undefined) {
	return value === undefined || value.length === 0;
}
