import React from 'react';
import { Inherited } from '@nexspec/warehouse-shared-types/dist/titles/TitleMetadata/Inherited';

// JSON
import { ContentIdentifier, isInherited } from '@warehouse/title/core';
import availContentIDNS from '../../../../../src/assets/json-administration-profiles/availableContentIDNamespaces.json';
import workTypes from '../../../../../src/assets/json-administration-profiles/workTypes.json';
import { InheritedReadOnlyText } from '../../../ui/table/InheritedReadOnlyText';

export function RenderReadOnlyNamespace(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;
	const contentIDNamespace = (() => availContentIDNS.find(({ value: _value }) => _value === displayValue.namespace))();

	if (!contentIDNamespace) return null;
	return <InheritedReadOnlyText isInherited={isInheritedValue}>{contentIDNamespace.label}</InheritedReadOnlyText>;
}

export function RenderReadOnlyScope(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;
	if (!displayValue.scope?.scope) return null;
	const workType = workTypes.find((wt) => wt.value === displayValue.scope?.scope);
	if (!workType) return null;
	return <InheritedReadOnlyText isInherited={isInheritedValue}>{workType.label}</InheritedReadOnlyText>;
}

export function RenderReadOnlyIdentifier(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;

	return <InheritedReadOnlyText isInherited={isInheritedValue}>{displayValue.identifier}</InheritedReadOnlyText>;
}

export function RenderReadOnlyLocation(value: Inherited<ContentIdentifier>) {
	const { displayValue } = value;
	const isInheritedValue = isInherited<ContentIdentifier>({ inheritedObject: value }).isInherited;

	return <InheritedReadOnlyText isInherited={isInheritedValue}>{displayValue.location}</InheritedReadOnlyText>;
}
