import React, { useMemo } from 'react';
import { isInherited, OptionalInherited, titlePaths } from '@warehouse/title/core';
import { TextInput } from '@warehouse/shared/legacy-ui';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';
import { DistributionNumber } from '../../core/models/sequence-info';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';

export function DistributionNumberField() {
	const { readOnlyValue, setValue, value, commit } = useTitleAutoSave<OptionalInherited<DistributionNumber>>({
		path: useMemo(() => titlePaths.distributionNumber.split('.'), []),
		label: 'Distribution Number',
	});
	const { isInherited: isDistributionNumberInherited } = isInherited({ inheritedObject: value });

	return (
		<TitleEditableField
			label="Distribution Number:"
			path={titlePaths.distributionNumber}
			direction="row"
			jumpToParentButtonDisabled
			tooltipDisabled
			readComponent={
				<ReadOnlyTypography
					aria-label="Distribution Number"
					variant={isDistributionNumberInherited ? 'prefilled' : 'default'}
				>
					{readOnlyValue?.displayValue?.distributionNumber || ''}
				</ReadOnlyTypography>
			}
			editComponent={
				<TextInput
					type="number"
					aria-label="Distribution Number"
					fullWidth
					height={38}
					value={value?.displayValue?.distributionNumber.toString() || ''}
					onBlur={() => commit()}
					onChange={(e) => {
						const nextValue = e.target.value;
						setValue((prev) => ({
							...prev,
							displayValue: {
								domain: prev.displayValue?.domain,
								distributionNumber: nextValue,
							},
						}));
					}}
					variant={isDistributionNumberInherited ? 'prefilled' : 'default'}
					disabledMargin
					width="50%"
				/>
			}
		/>
	);
}
