import React from 'react';
import styled, { css } from 'styled-components';
import {
	Data,
	LicensorField,
	TitleOverviewLeftWrapperBase,
	getPlaceholderArea,
} from '@warehouse/legacy-title-overview';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';

// FIELDS
import { CountriesOfOriginField } from '../../CountriesOfOriginField';
import { EditUseField } from '../../EditUseField';
import { ReleaseDateField } from '../../ReleaseDateField';
import { ProductionCountriesField } from '../../ProductionCountriesField';
import { EditClassesField } from '../../EditClassesField';
import { AspectRatioField } from '../../AspectRatioField';
import { OriginalLanguagesField } from '../../OriginalLanguagesField';
import { MadeForRegionField } from '../../MadeForRegionField';
import { FrameRateField } from '../../FrameRateField';
import { RunLengthField } from '../../RunLengthField';
import { PlatformSpecificationField } from '../../PlatformSpecificationField';
import { AlidField } from '../../AlidField';
import { ManifestationOverviewByAbstractTypeProps } from './types';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar topbar topbar'
			'licensor countriesOfOrigin ph-1 editUse'
			'releaseDate productionCountries ph-2 editClass'
			'aspectRatio originalLanguage ph-3 madeForRegion'
			'frameRate runLength specification alid';
		grid-template-columns: 25% 25% 25% 25%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

export function Movie({ topBar }: ManifestationOverviewByAbstractTypeProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);

	return (
		<TitleOverviewLeftWrapper>
			{topBar}
			{/* ROW 1 */}
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField title={title} />
			</Data>
			<Data area="countriesOfOrigin">
				<CountriesOfOriginField />
			</Data>
			{getPlaceholderArea({ bottom: true, left: true, name: 'ph-1' })}
			<Data area="editUse" enableRightBorder>
				<EditUseField />
			</Data>
			{/* ROW 2 */}
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			<Data area="productionCountries">
				<ProductionCountriesField />
			</Data>
			{getPlaceholderArea({ bottom: true, left: true, name: 'ph-2' })}
			<Data area="editClass" enableRightBorder>
				<EditClassesField />
			</Data>
			{/* ROW 3 */}
			<Data enableLeftBorder={false} area="aspectRatio">
				<AspectRatioField />
			</Data>
			<Data area="originalLanguage">
				<OriginalLanguagesField />
			</Data>
			{getPlaceholderArea({ bottom: true, left: true, name: 'ph-3' })}
			<Data area="madeForRegion" enableRightBorder>
				<MadeForRegionField />
			</Data>
			{/* ROW 4 */}
			<Data enableBottomBorder={false} enableLeftBorder={false} area="frameRate">
				<FrameRateField />
			</Data>
			<Data enableBottomBorder={false} area="runLength">
				<RunLengthField />
			</Data>
			<Data enableBottomBorder={false} area="specification">
				<PlatformSpecificationField />
			</Data>
			<Data enableBottomBorder={false} enableRightBorder area="alid">
				<AlidField />
			</Data>
		</TitleOverviewLeftWrapper>
	);
}
