import type { StateCreator } from 'zustand/vanilla';
import {
	GridFilterModel,
	GridLogicOperator,
	TablePinnedColumns,
	TableVisibility,
} from '@warehouse/object-browser/core';
import isEqual from 'lodash/isEqual';
import { PaginatorStore } from '../paginator/paginator.store';
import { gridFilterModelToFilterQuery } from '../table/muix.adapter';

export interface TableColumnSort {
	field: string;
	sort: 'asc' | 'desc' | null | undefined;
}

export interface TableManagerStore {
	sort: TableColumnSort[];
	filter: GridFilterModel;
	visibility: TableVisibility;
	pinned: TablePinnedColumns;
	order: string[];
	actions: {
		setSort: (sortModel: TableColumnSort[]) => void;
		setVisibility: (visibilityModel: TableVisibility) => void;
		setPinned: (pinnedModel: TablePinnedColumns) => void;
		setOrder: (order: string[]) => void;
		setFilter: (filter: GridFilterModel) => void;
	};
}

interface FactoryOptions {
	getPaginatorStore: () => PaginatorStore;
}

export const createTableManagerStoreSliceFactory =
	({ getPaginatorStore }: FactoryOptions): StateCreator<TableManagerStore> =>
	(set, get) => ({
		sort: [],
		filter: {
			items: [],
			logicOperator: GridLogicOperator.AND,
		},
		visibility: {},
		pinned: {
			left: [],
			right: [],
		},
		order: [],
		actions: {
			setSort: (value: TableColumnSort[]) => {
				set({ sort: value });
				getPaginatorStore().internalActions.resetPagination();
			},
			setVisibility: (value: TableVisibility) => set({ visibility: value }),
			setPinned: (pinnedModel: TablePinnedColumns) => set({ pinned: pinnedModel }),
			setOrder: (order: string[]) => set({ order }),
			setFilter: (filter: GridFilterModel) => {
				if (!isEqual(gridFilterModelToFilterQuery(filter), gridFilterModelToFilterQuery(get().filter))) {
					getPaginatorStore().internalActions.resetPagination();
				}
				set({ filter });
			},
		},
	});

export const tableManagerStoreSelector = {
	filter: (state: TableManagerStore) => state.filter,
	sort: (state: TableManagerStore) => state.sort,
	visibility: (state: TableManagerStore) => state.visibility,
	pinned: (state: TableManagerStore) => state.pinned,
	order: (state: TableManagerStore) => state.order,
	actions: (state: TableManagerStore) => state.actions,
};
