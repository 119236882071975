import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { NxObject, NxObjectType } from '@warehouse/object-browser/core';
import React from 'react';
import { TypoLink } from '@warehouse/shared/ui';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { ObjectBrowserLinkBuilder } from '../../domain/object-browser-link-builder';

function FolderNameCellDrillDownMode({ row }: GridRenderCellParams<NxObject, string>) {
	return <TypoLink to={ObjectBrowserLinkBuilder.buildFolderLink(row.uuid)}>{row.name}</TypoLink>;
}

export function NameCell(props: GridRenderCellParams<NxObject, string>) {
	const isInSearchMode = useObjectBrowserStore(objectBrowserStoreSelector.isInSearchMode);
	const { row } = props;

	if (!isInSearchMode && row.itemType === NxObjectType.Folder) return <FolderNameCellDrillDownMode {...props} />;

	// This function is used to render the name cell, if we return undefined, data grid renders the default cell
	return undefined;
}
