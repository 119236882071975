import { CountryOfOriginRegion } from './models/region';
import { TitleFull } from './title';
import { OptionalInherited } from './models/inherited';

export function getCountriesOfOrigin(title: TitleFull): OptionalInherited<CountryOfOriginRegion[]> {
	return title.metadata.coreMetadata.basic.countriesOfOrigin;
}

export function getInheritedCountriesOfOrigin(title: TitleFull | undefined): CountryOfOriginRegion[] {
	if (!title) return [];
	return getCountriesOfOrigin(title).inherited.value || [];
}

export function addCreativeInterpretation(countryOfOrigin: CountryOfOriginRegion): CountryOfOriginRegion {
	return { ...countryOfOrigin, interpretation: 'creative' };
}

export function addCreativeInterpretations(countriesOfOrigin: CountryOfOriginRegion[]): CountryOfOriginRegion[] {
	return countriesOfOrigin.map(addCreativeInterpretation);
}

export function filterCreativeCountriesOfOrigin(
	countriesOfOrigin: CountryOfOriginRegion[] | undefined,
): CountryOfOriginRegion[] {
	return (countriesOfOrigin || []).filter((co) => co.interpretation === 'creative');
}

export function filterProductionCountriesOfOrigin(
	countriesOfOrigin: CountryOfOriginRegion[] | undefined,
): CountryOfOriginRegion[] {
	return (countriesOfOrigin || []).filter((co) => co.interpretation === 'production');
}
