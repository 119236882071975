import { Bucket, BucketIdUtils } from '@warehouse/object-browser/core';
import availableNxObjectBucket from '../../../src/assets/json-administration-profiles/available-nx-object-buckets/available-nx-object-buckets.json';

type InfraType = (typeof availableNxObjectBucket)[0];

export class BucketAdapter {
	static adapt(bucket: InfraType): Bucket {
		switch (bucket.provider) {
			case 'aws':
				return {
					provider: 'aws',
					id: BucketIdUtils.cast(bucket.name),
					region: bucket.region,
					accountId: bucket.accountId,
				};
			// This should never happen as this is asserted in the codegen
			default:
				throw new Error(`Received an unknown bucket provider "${bucket.provider}"`);
		}
	}
}
