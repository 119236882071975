import React, { useContext, useMemo } from 'react';
import { CharacterName } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/People';
import styled, { css } from 'styled-components';
import { CustomColumnDef } from '../../../../../../components/library/SimpleTable/customColumnDef';
import InheritanceOnValueChangeSimpleTableWrapper from '../../../../../../components/library/SimpleTable/Wrappers/InheritanceOnValueChangeSimpleTableWrapper';
import { CastAndCrewContext } from '../providers/castAndCrewProvider';
import LocaleCell from './LocaleCell';
import TextInputCell from './TextInputCell';
import computeErrors from '../../../../utils/computeErrors';

interface CharacterNamesTableProps {
	editMode: boolean;
	characterNames: CharacterName[] | undefined;
	canAddRow: boolean;
	placement?: 'bottom' | 'top';
	peopleIndex: number;
	roleIndex: number;
	characterIndex: number;
}

const Wrapper = styled.div(
	({ theme }) => css`
		.title-editable-field {
			padding: 0;
		}
		.title-editable-field-label {
			color: ${theme.palette.text.secondary};
			font-size: 14px;
		}
	`,
);

function CharacterNamesTable({
	editMode,
	characterNames,
	canAddRow,
	placement = 'bottom',
	peopleIndex,
	roleIndex,
	characterIndex,
}: CharacterNamesTableProps) {
	const { addToArray, removeFromArray, currentLocale, data } = useContext(CastAndCrewContext);

	const displayNamesColumns: CustomColumnDef<CharacterName>[] = useMemo(
		() => [
			{
				header: 'Character Name',
				id: 'characterName',
				cell: (info) =>
					TextInputCell({
						info,
						width: undefined,
						pathPrefix: `people.${peopleIndex}.jobs.${roleIndex}.characterInfos.${characterIndex}.characterNames`,
						ariaLabel: 'Character Name Cell',
					}),
				accessorFn: (originalRow) => originalRow.characterName,
			},
			{
				id: 'languages',
				header: 'Languages',
				cell: (info) =>
					LocaleCell(
						info,
						placement,
						`people.${peopleIndex}.jobs.${roleIndex}.characterInfos.${characterIndex}.characterNames`,
					),
				accessorFn: (originalRow) => originalRow.language,
			},
		],
		[peopleIndex, roleIndex, characterIndex],
	);

	const getNewRoadPayload = (): CharacterName => {
		const characterList =
			data?.displayValue?.[peopleIndex].jobs?.[roleIndex].characterInfos?.[characterIndex].characterNames;

		const localeToSelect = characterList?.some((character) => character.language === currentLocale)
			? ''
			: currentLocale;

		return {
			characterName: '',
			language: localeToSelect || '',
		};
	};

	const onRowAdd = () => {
		addToArray(
			`people.${peopleIndex}.jobs.${roleIndex}.characterInfos.${characterIndex}.characterNames`,
			getNewRoadPayload(),
		);
	};

	const onRowDelete = (selectedIndexes: string[]) => {
		removeFromArray(
			`people.${peopleIndex}.jobs.${roleIndex}.characterInfos.${characterIndex}.characterNames`,
			selectedIndexes,
		);
	};

	const errors: { [key: string]: number[] } = useMemo(
		() => ({
			languages: computeErrors<CharacterName>(characterNames || [], 'language'),
			characterName: computeErrors<CharacterName>(characterNames || [], 'characterName'),
		}),
		[characterNames],
	);

	return (
		<Wrapper>
			<InheritanceOnValueChangeSimpleTableWrapper<CharacterName>
				onRowDelete={(selectedIndexes) => onRowDelete(selectedIndexes)}
				selectRowModeByDefault
				enableAddMultipleRows={false}
				showBottomActionsBar={editMode}
				tableStyle="border"
				canDelete={(selectedIndexes: string[]) => ({
					canDelete: !selectedIndexes.includes('0') || selectedIndexes.length !== (characterNames || []).length,
				})}
				columns={displayNamesColumns}
				canAddRow={canAddRow}
				errors={errors}
				data={characterNames || []}
				onRowAdd={onRowAdd}
				newRowPayload={getNewRoadPayload()}
				onRowEdit={() => {}}
				editMode={editMode}
			/>
		</Wrapper>
	);
}

// eslint-disable-next-line import/no-default-export
export default CharacterNamesTable;
