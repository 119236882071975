import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { TitleFull as Title } from '@warehouse/title/core';
import { DropdownV2 } from '@warehouse/shared/ui';
import useWorkTypes from '../../../utils/hooks/titles/useWorkTypes';
import { ChildTabDefinition } from '../../title/hooks/useChildrenTabs';
import { TitleInfoWrapper } from './styles';

interface WorkTypeSelectorProps {
	selectedParent?: Title | undefined;
	loadingParent?: boolean;
	workType: string | undefined;
	setWorkType: (titleType: string) => void;
	setParentType: (parentType: string) => void;
	childrenProperties?: ChildTabDefinition;
	forcedWorkTypeUuid?: string;
}

function WorkTypeSelector({
	selectedParent,
	loadingParent = false,
	workType,
	setWorkType,
	setParentType,
	childrenProperties,
	forcedWorkTypeUuid,
}: WorkTypeSelectorProps) {
	const { options, getWorkTypesByRelationshipSource } = useWorkTypes();

	const computedOptions = useMemo(() => {
		if (selectedParent) {
			if (!childrenProperties?.relationshipType) return [];
			return getWorkTypesByRelationshipSource(childrenProperties.relationshipType);
		}
		return options;
	}, [childrenProperties, options, getWorkTypesByRelationshipSource, selectedParent]);

	const isReadOnly = useMemo(
		() => !!forcedWorkTypeUuid || (!loadingParent && computedOptions.length <= 1),
		[forcedWorkTypeUuid, loadingParent, computedOptions],
	);

	return (
		<TitleInfoWrapper>
			<Typography variant="s2Medium" color="text.primary">
				Title Type* :
			</Typography>
			<div style={{ width: '150px' }}>
				<DropdownV2
					ariaLabel="Select Work Type"
					withSearch
					loading={loadingParent}
					height={40}
					listItemHeight={40}
					readOnly={isReadOnly}
					// For some strange reason that I don't understand, computedOptions can be empty when forcedWorkTypeUuid is enabled.
					// If computedOptions is empty, and we pass a value to DropdownV2 anyway, it won't be able to find the label in the list, and will therefore display the uuid.
					// As I see no reason to have an empty computedOptions, we simply pass the options (a list of all work types) in this case.
					// To make sure this doesn't break anything, we only allow this behavior when the component is in readOnly mode.
					options={(computedOptions || [])?.length === 0 && isReadOnly ? options : computedOptions}
					value={forcedWorkTypeUuid || workType || null}
					onChange={(_workType) => {
						setWorkType(_workType as string);
						setParentType('all');
					}}
				/>
			</div>
		</TitleInfoWrapper>
	);
}

export default WorkTypeSelector;
