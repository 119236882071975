import React, { useMemo } from 'react';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { isInherited } from '@warehouse/title/core';
import { TextInput } from '@warehouse/shared/legacy-ui';
import TitleEditableField, { ReadOnlyTypography } from '../../../../src/components/titles/TitleEditableField';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';

interface TitleNumberInputFieldProps {
	label: string;
	ariaLabel?: string;
	path: string;
	view?: 'overview';
	style?: React.CSSProperties;
	forcedEditMode?: boolean;
	jumpToParentButtonDisabled?: boolean;
	tooltipDisabled?: boolean;
}

function getVariant(value: OptionalInherited<number> | undefined): 'default' | 'prefilled' {
	if (value === undefined) return 'default';

	return isInherited<number>({ inheritedObject: value }).isInherited ? 'prefilled' : 'default';
}

export function TitleNumberInputField({
	label,
	ariaLabel,
	path,
	view = 'overview',
	style,
	forcedEditMode,
	jumpToParentButtonDisabled = false,
	tooltipDisabled = false,
}: TitleNumberInputFieldProps) {
	const { readOnlyValue, setValue, value, commit } = useTitleAutoSave<OptionalInherited<number>>({
		path: useMemo(() => path.split('.'), [path]),
		label,
	});

	return (
		<TitleEditableField
			wrapperStyle={style}
			label={label}
			path={path}
			direction={view === 'overview' ? 'row' : 'column'}
			forcedEditMode={forcedEditMode}
			jumpToParentButtonDisabled={jumpToParentButtonDisabled}
			tooltipDisabled={tooltipDisabled}
			readComponent={
				<ReadOnlyTypography aria-label={ariaLabel} variant={getVariant(value)}>
					{readOnlyValue?.displayValue || ''}
				</ReadOnlyTypography>
			}
			editComponent={
				<TextInput
					type="number"
					aria-label={ariaLabel}
					fullWidth
					height={38}
					value={value?.displayValue?.toString() || ''}
					onBlur={() => commit()}
					onChange={(e) => {
						const nextValue = e.target.value;
						setValue((prev) => ({
							...prev,
							displayValue: parseInt(nextValue, 10),
						}));
					}}
					variant={getVariant(value)}
					disabledMargin
					width="50%"
				/>
			}
		/>
	);
}
