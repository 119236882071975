import React, { useMemo } from 'react';

import styled, { css } from 'styled-components';
import { AlternateIDs, PictureColorTypeField, PictureFormatField } from '@warehouse/title/feature-ancillary-tab';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { FullWidthHeightWrapper } from '../../../../../components/common';
import { ThreeDField, VersionLanguages, AssociatedOrgsTable } from './fields';
import { Column } from '../style';
import getDeepProperty from '../../../../../utils/getDeepProperty';
import { fromBasic } from '../../../../../utils/titleGetProperty';
import useWorkTypes from '../../../../../utils/hooks/titles/useWorkTypes';
import CompanyDisplayCreditList from './CompanyDisplayCredits/CompanyDisplayCreditList';
import { CompanyDisplayCreditsProvider } from './providers/companyDisplayCreditsProvider';

const Row = styled.div(
	({ theme }) => css`
		align-items: center;
		display: flex;
		gap: ${theme.spacing(1)};
		margin-bottom: ${theme.spacing(1)};

		.title-editable-field {
			min-width: 0 !important;
			padding: 0 !important;
			width: 100%;
		}
	`,
);

const CompanyDisplayCreditWrapper = styled.div(
	({ theme }) => css`
		margin-top: ${theme.spacing(2)};
	`,
);

function AncillaryTab() {
	const { editUuid, manifestationUuid } = useWorkTypes();
	const title = useTitleEditorStore(titleEditorStoreSelector.title);

	const workType = useMemo(() => getDeepProperty<string | undefined>(title, fromBasic(['workType'])), [title]);

	return (
		<FullWidthHeightWrapper>
			<Column width="40%">
				<Row>
					<PictureColorTypeField />
					<ThreeDField />
					<PictureFormatField />
				</Row>
				{(workType === editUuid() || workType === manifestationUuid()) && <VersionLanguages />}
				<AssociatedOrgsTable />
				<CompanyDisplayCreditWrapper>
					<CompanyDisplayCreditsProvider>
						<CompanyDisplayCreditList />
					</CompanyDisplayCreditsProvider>
				</CompanyDisplayCreditWrapper>
			</Column>
			<Column width="60%">
				<AlternateIDs />
			</Column>
		</FullWidthHeightWrapper>
	);
}

// eslint-disable-next-line import/no-default-export
export default AncillaryTab;
