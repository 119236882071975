import React from 'react';
import styled, { css } from 'styled-components';
import {
	Data,
	LicensorField,
	NonCenteredData,
	TiniestSummary,
	TitleOverviewLeftWrapperBase,
} from '@warehouse/legacy-title-overview';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { useTiniestSummaryTitleData } from './use-tiniest-summary';
import { ManifestationOverviewByAbstractTypeProps } from './types';

// FIELDS
import { ReleaseDateField } from '../../ReleaseDateField';
import { PlatformSpecificationField } from '../../PlatformSpecificationField';
import { AlidField } from '../../AlidField';

const TitleOverviewLeftWrapper = styled(TitleOverviewLeftWrapperBase)(
	() => css`
		grid-template-areas:
			'topbar topbar'
			'licensor synopsis'
			'releaseDate synopsis'
			'specification synopsis'
			'alid synopsis';
		grid-template-columns: 25% 75%;
		grid-template-rows: 80px repeat(4, 44px);
	`,
);

export function Compilation({ topBar }: ManifestationOverviewByAbstractTypeProps) {
	const title = useTitleEditorStore(titleEditorStoreSelector.title);
	const tiniestSummary = useTiniestSummaryTitleData({
		title,
	});

	return (
		<TitleOverviewLeftWrapper>
			{topBar}
			{/* ROW 1 */}
			<Data enableLeftBorder={false} area="licensor">
				<LicensorField title={title} />
			</Data>
			<NonCenteredData enableRightBorder enableBottomBorder={false} area="synopsis">
				<TiniestSummary
					tiniestSummary={tiniestSummary?.data?.displayValue || ''}
					isInherited={tiniestSummary.isInherited}
				/>
			</NonCenteredData>
			{/* ROW 2 */}
			<Data enableLeftBorder={false} area="releaseDate">
				<ReleaseDateField />
			</Data>
			{/* ROW 3 */}
			<Data enableLeftBorder={false} area="specification">
				<PlatformSpecificationField />
			</Data>
			{/* ROW 4 */}
			<Data enableLeftBorder={false} enableBottomBorder={false} area="alid">
				<AlidField />
			</Data>
		</TitleOverviewLeftWrapper>
	);
}
