import { TitleIndexedParent } from '@warehouse/graphql';
import { TitleCommon, TitleLightParent, TitleLightWithSelectedParent } from '@warehouse/title/core';
import { SelectedParentNotFoundError } from '../feature-edit-relationship/common-relationship.error';

const isTitleLightParent = (v: TitleLightParent | TitleIndexedParent): v is TitleLightParent => 'contentId' in v;
const isTitleIndexedParent = (v: TitleLightParent | TitleIndexedParent): v is TitleLightParent =>
	'parentContentId' in v;

export function selectIndexedTitleParent<T extends TitleLightParent | TitleIndexedParent>(
	parents: T[],
	titleUuid: string,
): T {
	const selectedParent = parents.find((parent) => {
		if (isTitleLightParent(parent)) return parent.contentId === titleUuid;
		if (isTitleIndexedParent(parent)) return parent.parentContentId === titleUuid;
		return false;
	});
	if (!selectedParent) throw new SelectedParentNotFoundError(`Missing parent with selected uuid ${titleUuid}`);
	return selectedParent;
}

export function addSelectedParent(titleCommon: TitleCommon, titleUuid: string): TitleLightWithSelectedParent {
	const selectedParent = selectIndexedTitleParent<TitleLightParent>(titleCommon.readonly.parents ?? [], titleUuid);
	return {
		...titleCommon.readonly,
		selectedParent,
	};
}
