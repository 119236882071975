import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import type { StateCreator } from 'zustand/vanilla';
import { create, StoreApi, useStore } from 'zustand';
import { PersistQueryParamsConfig, IndexableObject, usePersistQueryParamStore } from './persist-query-param-store';

export function defineStore<STORE>(stateCreator: StateCreator<STORE>) {
	function StoreProvider({ children }: { children: React.ReactNode }) {
		const store = useMemo(() => create<STORE>(stateCreator), []);
		return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
	}

	const StoreContext = createContext<StoreApi<STORE> | null>(null);

	function useZustandStore<R>(selector: (state: STORE) => R) {
		const store = useContext(StoreContext);
		if (!store) throw new Error('Store was not provided');
		return useStore(store, selector);
	}

	return [StoreProvider, useZustandStore] as const;
}

export function definePersistentQueryParamStore<STORE extends STORE_STATE, STORE_STATE extends IndexableObject = STORE>(
	stateCreator: StateCreator<STORE>,
) {
	function StoreProvider({ children, config }: { children: ReactNode; config: PersistQueryParamsConfig<STORE_STATE> }) {
		const { persistentStore, onMount, onUnmount } = usePersistQueryParamStore<STORE, STORE_STATE>(config);

		const store = useMemo(
			() => create<STORE, [['zustand/persist', STORE_STATE]]>(persistentStore(stateCreator)),
			[persistentStore],
		);
		const state = useStore(store);
		const stateRef = useRef(state);
		stateRef.current = state;

		// This effect runs the necessary logic to set/delete query params based on the store configuration
		useEffect(() => {
			onMount(stateRef);
			return () => {
				onUnmount();
			};
		}, [stateRef, config, onMount, onUnmount]);

		return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
	}

	const StoreContext = createContext<StoreApi<STORE> | null>(null);

	function useZustandStore<R>(selector: (state: STORE) => R) {
		const store = useContext(StoreContext);
		if (!store) throw new Error('Store was not provided');
		return useStore(store, selector);
	}

	return [StoreProvider, useZustandStore] as const;
}
