import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { QueryParamsManagerImpl } from '@warehouse/shared/util';

export type SearchParamsData = { [k: string]: string };

export interface URLSearchParamsHook {
	searchParams: SearchParamsData;
	setQuery: (label: string, value?: string) => void;
	deleteQuery: (label: string) => void;
}

function getCurrentSearchParams() {
	const sp = new URLSearchParams(window.location.search);
	return Object.fromEntries(sp.entries());
}

export function useURLSearchParams(): URLSearchParamsHook {
	const location = useLocation();

	const searchParams = useMemo(() => getCurrentSearchParams(), [location.search]);

	const setQuery = useCallback((label: string, value?: string) => {
		QueryParamsManagerImpl.getInstance().set(label, value ?? '');
	}, []);

	const deleteQuery = useCallback((label: string) => {
		QueryParamsManagerImpl.getInstance().delete(label);
	}, []);

	return {
		searchParams,
		setQuery,
		deleteQuery,
	};
}
