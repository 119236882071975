import React from 'react';
import { Control, FieldErrors, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARY
import CountriesOfOriginField from '../fields/CountriesOfOrigin';
import ReleaseDate from '../fields/ReleaseDate';
import OriginalLanguagesField from '../fields/OriginalLanguages';
import RunLengthField from '../fields/RunLength';
import SortTitleField from '../fields/SortTitle';
import { Row } from './styles';

interface MovieProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	dryRanTitle?: Title | undefined;
}

function Movie({ control, errors, setValue, trigger, dryRanTitle }: MovieProps) {
	return (
		<div>
			<Row>
				<CountriesOfOriginField
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
					isRequired
				/>
				<ReleaseDate
					control={control}
					setValue={setValue}
					isRequired
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
				/>
			</Row>
			<Row>
				<SortTitleField
					control={control}
					setValue={setValue}
					trigger={trigger}
					errors={errors}
					dryRanTitle={dryRanTitle}
				/>
				<RunLengthField
					control={control}
					setValue={setValue}
					trigger={trigger}
					isRequired
					dryRanTitle={dryRanTitle}
					errors={errors}
				/>
			</Row>
			<div style={{ marginTop: 16 }}>
				<OriginalLanguagesField
					control={control}
					setValue={setValue}
					trigger={trigger}
					dryRanTitle={dryRanTitle}
					errors={errors}
					isRequired
				/>
			</div>
		</div>
	);
}

export default Movie;
