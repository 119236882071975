import React, { forwardRef, Ref } from 'react';
import { TreeItem2Props } from '@mui/x-tree-view-pro';
import { objectBrowserStoreSelector, useObjectBrowserStore } from '@warehouse/object-browser/domain';
import { TypoLink } from '@warehouse/shared/ui';
import { TreeItemBucket } from '../../core/models';
import { ObjectBrowserLinkBuilder } from '../../domain/object-browser-link-builder';
import { StyledTreeItem2 } from './styled-tree-item2';
import { BucketIcon } from '../icons';

export interface CustomTreeItemBucketProps extends TreeItem2Props {
	item: TreeItemBucket;
}

export const CustomTreeItemBucket = forwardRef(
	({ item, ...props }: CustomTreeItemBucketProps, ref: Ref<HTMLLIElement>) => {
		const resourceIdentifier = useObjectBrowserStore(objectBrowserStoreSelector.resourceIdentifier);

		return (
			<StyledTreeItem2
				{...props}
				ref={ref}
				$isHighlighted={resourceIdentifier?.type === 'bucket' && resourceIdentifier?.bucketId === item.name}
				label={
					<TypoLink to={ObjectBrowserLinkBuilder.buildBucketLink(item.name)}>
						<BucketIcon />
						&nbsp;
						{item.name}
					</TypoLink>
				}
			/>
		);
	},
);
