import { IndexedTitleV2, Maybe, TitleIndexedParent } from '@warehouse/graphql';
import {
	CompilationReadonly,
	EditReadonly,
	EpisodeReadonly,
	ManifestationReadonly,
	MovieReadonly,
	SeasonReadonly,
	SeriesReadonly,
	SupplementalReadonly,
	CommonReadonly,
	TitleLightIdLabel,
	TitleLightParent,
	WorkType,
	CommonReadonlyBase,
	TitleCommon,
	TitleFull,
	SoftTitleCommon,
	isManifestationDerivableWorkType,
	isEditDerivableWorkType,
} from '@warehouse/title/core';
import { getWorkType, titleIndexedParentToTitleLightParent } from '@warehouse/title/infra';
import { Title } from '@nexspec/warehouse-shared-types';

export function titleGQLResponseToTitleFull({
	ultraMezzUuid,
	indexed,
	state,
	uuid,
	soft,
}: {
	ultraMezzUuid?: string | null;
	indexed: IndexedTitleV2;
	state: Title;
	uuid: string;
	soft?: boolean;
}): TitleFull {
	// Indexed should always be defined, the undefined in the interface is an artifact that should be removed
	if (!indexed) throw new Error('Missing indexed title');

	const commonReadonly = soft ? softIndexedTitleToTitleCommon(indexed).readonly : indexedTitleToCommonReadonly(indexed);

	return {
		uuid,
		ultraMezzUuid: ultraMezzUuid ?? undefined,
		readonly: commonReadonly,
		metadata: state.metadata,
	};
}

export function indexedTitleToTitleCommon(indexedTitle: IndexedTitleV2): TitleCommon {
	const readonly = indexedTitleToCommonReadonly(indexedTitle);

	if (!readonly.releaseYear) throw new Error(`Missing releaseYear in indexed title ${readonly.uuid}`);

	return {
		uuid: indexedTitle.uuid,
		readonly,
	};
}

export function softIndexedTitleToTitleCommon(indexedTitle: IndexedTitleV2): SoftTitleCommon {
	return {
		uuid: indexedTitle.uuid,
		readonly: indexedTitleToCommonReadonly(indexedTitle),
	};
}

export function indexedTitleToCommonReadonly(indexedTitle: IndexedTitleV2): CommonReadonly {
	if (!indexedTitle.workType?.uuid) {
		throw new Error(`Missing workType in indexed title ${indexedTitle.uuid}`);
	}

	const workType = getWorkType(indexedTitle.workType.uuid);

	switch (workType) {
		case WorkType.Movie:
			return indexedTitleToMovieReadonly(indexedTitle);
		case WorkType.Edit:
			return indexedTitleToEditReadonly(indexedTitle);
		case WorkType.Season:
			return indexedTitleToSeasonReadonly(indexedTitle);
		case WorkType.Series:
			return indexedTitleToSeriesReadonly(indexedTitle);
		case WorkType.Compilation:
			return indexedTitleToCompilationReadonly(indexedTitle);
		case WorkType.Supplemental:
			return indexedTitleToSupplementalReadonly(indexedTitle);
		case WorkType.Manifestation:
			return indexedTitleToManifestationReadonly(indexedTitle);
		case WorkType.Episode:
			return indexedTitleToEpisodeReadonly(indexedTitle);
		default:
			throw new Error(`Work type ${workType} is not supported`);
	}
}

interface MinimumIdLabel {
	uuid: string;
	label: string;
}
export function anyToTitleLightIdLabel<T extends MinimumIdLabel>(value: T): TitleLightIdLabel {
	return {
		uuid: value.uuid,
		label: value.label,
	};
}

function indexedTitleToMovieReadonly(indexedTitle: IndexedTitleV2): MovieReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Movie,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToEpisodeReadonly(indexedTitle: IndexedTitleV2): EpisodeReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Episode,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToSeasonReadonly(indexedTitle: IndexedTitleV2): SeasonReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Season,
		runLength: indexedTitle.runLength ?? undefined,
		madeForRegion: indexedTitle.madeForRegions?.map(anyToTitleLightIdLabel),
	};
}

function indexedTitleToSeriesReadonly(indexedTitle: IndexedTitleV2): SeriesReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Series,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToCompilationReadonly(indexedTitle: IndexedTitleV2): CompilationReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Compilation,
	};
}

function indexedTitleToSupplementalReadonly(indexedTitle: IndexedTitleV2): SupplementalReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);
	return {
		...commonTitleLightData,
		workType: WorkType.Supplemental,
		runLength: indexedTitle.runLength ?? undefined,
	};
}

function indexedTitleToManifestationReadonly(indexedTitle: IndexedTitleV2): ManifestationReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);

	if (!indexedTitle.parentAbstractionWorkType?.uuid) {
		throw new Error(`Missing parent abstraction workType in indexed title ${indexedTitle.uuid}`);
	}

	const parentWorkType = getWorkType(indexedTitle.parentAbstractionWorkType.uuid);

	if (!isManifestationDerivableWorkType(parentWorkType)) {
		throw new Error(`Parent work type ${parentWorkType} is not derivable for manifestations`);
	}

	return {
		...commonTitleLightData,
		workType: WorkType.Manifestation,
		parentAbstractionWorkType: parentWorkType,
		runLength: indexedTitle.runLength ?? undefined,
		alid: indexedTitle.alid?.identifier,
		platformSpecification: indexedTitle.alid?.platformSpecification
			? {
					uuid: indexedTitle.alid.platformSpecification.uuid,
					label: indexedTitle.alid.platformSpecification.label,
			  }
			: undefined,
	};
}

function indexedTitleToEditReadonly(indexedTitle: IndexedTitleV2): EditReadonly {
	const commonTitleLightData = getCommonTitleLightData(indexedTitle);

	if (!indexedTitle.parentAbstractionWorkType?.uuid) {
		throw new Error(`Missing parent abstraction workType in indexed title ${indexedTitle.uuid}`);
	}

	const parentWorkType = getWorkType(indexedTitle.parentAbstractionWorkType.uuid);

	if (!isEditDerivableWorkType(parentWorkType)) {
		throw new Error(`Parent work type ${parentWorkType} is not derivable for edits`);
	}

	return {
		...commonTitleLightData,
		workType: WorkType.Edit,
		parentAbstractionWorkType: parentWorkType,
		runLength: indexedTitle.runLength ?? undefined,
		madeForRegion: indexedTitle.madeForRegions?.map(anyToTitleLightIdLabel),
	};
}

function getCommonTitleParents(parents?: Maybe<TitleIndexedParent[]>): TitleLightParent[] | undefined {
	if (!parents) return undefined;
	return parents.map(titleIndexedParentToTitleLightParent);
}

function getCommonTitleLightData(indexedTitle: IndexedTitleV2): Omit<CommonReadonlyBase, 'parentAbstractionWorkType'> {
	const titleSearchDisplayNameFullHTML = indexedTitle.titleSearchDisplayNameFullHTML ?? undefined;
	if (!titleSearchDisplayNameFullHTML)
		throw new Error(`Missing titleSearchDisplayNameFullHTML in indexed title ${indexedTitle.uuid}`);

	const { titleSearchDisplayNameFull } = indexedTitle;

	const structuredCloneWithoutParentAbstractionWorkType = structuredClone(indexedTitle);
	delete structuredCloneWithoutParentAbstractionWorkType.parentAbstractionWorkType;

	return {
		...structuredCloneWithoutParentAbstractionWorkType,

		uuid: indexedTitle.uuid,
		titleSearchDisplayNameFull,
		releaseYear: indexedTitle.releaseYear,
		releaseDate: indexedTitle.releaseDate ?? undefined,
		titleSearchDisplayNameFullHTML,
		parents: getCommonTitleParents(indexedTitle.parents),
		licensor: {
			label: indexedTitle.licensor.label,
			uuid: indexedTitle.licensor.uuid,
		},
	};
}
