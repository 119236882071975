import mappedCountriesJson from '../../../src/assets/json-administration-profiles/availableCountry.map.value.json';
import mappedCountriesRegionsJson from '../../../src/assets/json-administration-profiles/availableCountryRegion.map.value.json';
import mappedTerritoryGroupJson from '../../../src/assets/json-administration-profiles/availableTerritoryGroup.map.value.json';
import countriesJson from '../../../src/assets/json-administration-profiles/availableCountry.json';
import countryRegionsJson from '../../../src/assets/json-administration-profiles/availableCountryRegion.json';
import territoryGroupJson from '../../../src/assets/json-administration-profiles/availableTerritoryGroup.json';

const territories = [...countriesJson, ...territoryGroupJson, ...countryRegionsJson];

export function isCountry(uuid: string): boolean {
	return !!mappedCountriesJson[uuid as keyof typeof mappedCountriesJson];
}

export function isCountryRegion(uuid: string): boolean {
	return !!mappedCountriesRegionsJson[uuid as keyof typeof mappedCountriesRegionsJson];
}

export class TerritoriesService {
	public static readonly territories = territories;

	static isCountry(uuid: string): boolean {
		return isCountry(uuid);
	}

	static isCountryRegion(uuid: string): boolean {
		return isCountryRegion(uuid);
	}

	static getLabelByTerritoryUuid(uuid: string): string {
		if (isCountry(uuid)) {
			return mappedCountriesJson[uuid as keyof typeof mappedCountriesJson]?.label || 'Unknown Region';
		}

		if (isCountryRegion(uuid)) {
			return mappedCountriesRegionsJson[uuid as keyof typeof mappedCountriesRegionsJson]?.label || 'Unknown Region';
		}

		return mappedTerritoryGroupJson[uuid as keyof typeof mappedTerritoryGroupJson]?.label || 'Unknown Region';
	}
}
