import React, { useEffect, useMemo } from 'react';
import { Inherited, OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { episodeUuid, seasonUuid } from '@warehouse/title/infra';
import { Control, Controller, FieldErrors, UseFormSetValue, UseFormTrigger, useWatch } from 'react-hook-form';

// TYPES
import { TitleFull as Title } from '@warehouse/title/core';

// LIBRARIES
import { DropdownV2 } from '@warehouse/shared/ui';
import { useLocales } from '@warehouse/global-entities/core';
import { transformEmptyStrings } from '@warehouse/shared/util';
import TextInput from '../../../../components/library/TextInput';
import { fromBasic, getTitleData } from '../../../../utils/titleGetProperty';
import useTooltip from '../../../../utils/hooks/useTooltips';
import getLabelWithRequired from '../getLabelWithRequired';

// STYLES
import { DetailsWrapper } from '../style';

interface DisplayTitleFieldProps {
	control: Control<any>;
	setValue: UseFormSetValue<any>;
	trigger: UseFormTrigger<any>;
	errors: FieldErrors<any>;
	isDryRunDirty?: boolean;
	titlePlaceholder?: string;
	isRequired?: boolean;
	dryRanTitle?: Title | undefined;
}

const titleTypesWithLanguageInheritedFromParent = [seasonUuid(), episodeUuid()];

function DisplayTitleField({
	control,
	errors,
	setValue,
	trigger,
	isRequired = false,
	isDryRunDirty = false,
	titlePlaceholder,
	dryRanTitle,
}: DisplayTitleFieldProps) {
	const { defaultLanguageWritten, languagesWritten } = useLocales();
	const parentTitleValue = useWatch({ name: 'parentTitle', control });
	const titleTypeValue = useWatch({ name: 'titleType', control });
	const titleLanguageValue = useWatch({ name: 'titleLanguage', control });

	const titleDisplayTooltip = useTooltip('coreMetadata.basic.localizedInfos.titleDisplayUnlimited');
	const titleLanguageTooltip = useTooltip('coreMetadata.basic.localizedInfos.language');

	const inheritedDisplayLanguage = getTitleData<Inherited<string>>(
		dryRanTitle,
		fromBasic(['localizedInfos', '__default', 'language']),
	);
	const inheritedDisplayTitle = getTitleData<OptionalInherited<string>>(
		dryRanTitle,
		fromBasic(['localizedInfos', '__default', 'titleDisplayUnlimited']),
	);

	// Set inherited language value if available
	useEffect(() => {
		if (
			titleLanguageValue === '' &&
			inheritedDisplayLanguage?.inherited.value &&
			titleTypesWithLanguageInheritedFromParent.includes(titleTypeValue)
		)
			setValue('titleLanguage', inheritedDisplayLanguage?.inherited.value);
	}, [inheritedDisplayLanguage?.displayValue]);

	// Set default language value if no inherited value is available
	useEffect(() => {
		if (titleLanguageValue === '' && !titleTypesWithLanguageInheritedFromParent.includes(titleTypeValue))
			setValue('titleLanguage', defaultLanguageWritten?.uuid);
	}, [titleTypeValue]);

	const isTitleLanguageDisabled = useMemo(
		() =>
			titleTypesWithLanguageInheritedFromParent.includes(titleTypeValue) && parentTitleValue.title?.uuid === undefined,
		[titleTypeValue, parentTitleValue.title?.uuid],
	);

	const titleLanguageField = useMemo(
		() => (
			<Controller
				name="titleLanguage"
				control={control}
				render={({ field }) => {
					const inheritedLang = inheritedDisplayLanguage?.displayValue;
					const placeholderValue = inheritedLang
						? languagesWritten?.find((lang: any) => lang.termUid === inheritedLang)?.label || 'Unknown'
						: undefined;

					return (
						<DropdownV2
							withSearch
							ariaLabel="Title Language"
							tooltip={titleLanguageTooltip?.tooltip}
							placeholder={placeholderValue}
							loading={!isTitleLanguageDisabled && field.value === '' && isDryRunDirty}
							disabled={isTitleLanguageDisabled}
							variant={field.value === inheritedDisplayLanguage?.inherited.value ? 'prefilled' : 'default'}
							title={isTitleLanguageDisabled ? 'You must set a parent before selecting a title language' : undefined}
							label={getLabelWithRequired('Title Language', isRequired)}
							error={errors?.titleLanguage?.message as string}
							options={
								languagesWritten?.map((lang: any) => ({
									label: lang.label,
									value: lang.uuid,
								})) || []
							}
							value={field.value}
							onChange={async (e) => {
								setValue('titleLanguage', e);
								if (isRequired && !inheritedLang) {
									await trigger('titleLanguage');
								}
							}}
						/>
					);
				}}
			/>
		),
		[
			isTitleLanguageDisabled,
			isDryRunDirty,
			control,
			inheritedDisplayLanguage?.displayValue,
			inheritedDisplayLanguage?.inherited.value,
			errors?.titleLanguage?.message,
			languagesWritten,
			setValue,
			trigger,
		],
	);

	const titleField = useMemo(
		() => (
			<Controller
				control={control}
				name="title"
				render={({ field }) => (
					<TextInput
						placeholder={titlePlaceholder}
						variant={titlePlaceholder && !transformEmptyStrings(field.value) ? 'prefilled' : 'default'}
						tooltip={titleDisplayTooltip?.tooltip}
						value={field.value}
						error={isRequired ? (errors?.title?.message as string) : ''}
						onChange={async (e) => {
							setValue('title', e.target.value);
							if (isRequired && !inheritedDisplayTitle?.displayValue) {
								await trigger('title');
							}
						}}
						label={getLabelWithRequired('Title', isRequired)}
					/>
				)}
			/>
		),
		[
			control,
			titlePlaceholder,
			inheritedDisplayTitle?.displayValue,
			isRequired,
			errors?.title?.message,
			setValue,
			trigger,
		],
	);

	return (
		<DetailsWrapper>
			{titleField}
			{titleLanguageField}
		</DetailsWrapper>
	);
}

export default DisplayTitleField;
