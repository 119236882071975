import React from 'react';
import { RelationshipType, CommonReadonly } from '@warehouse/title/core';
import { RelationshipsService } from '@warehouse/title/domain';
import { RelationshipTypeSelector } from '@warehouse/title/ui';
import { addChildrenStoreSelector, useAddChildrenStore } from '@warehouse/title/feature-add-children';

export function RelationshipTypeSelectorContainer({ title }: RelationshipTypeSelectorProps) {
	const supportedRelationshipTypes = RelationshipsService.getRelationshipTypesForParentWorkType(title.workType);
	const selected = useAddChildrenStore(addChildrenStoreSelector.relationshipType);
	const actions = useAddChildrenStore(addChildrenStoreSelector.actions);

	const onSelectWrapper = (_selected: RelationshipType) => {
		actions.setRelationshipType(_selected);
		actions.setSelectedWorkTypeUuid(null);
	};

	return (
		<RelationshipTypeSelector
			selected={selected}
			onSelect={onSelectWrapper}
			supportedRelationshipTypes={supportedRelationshipTypes}
			ariaLabel="Select Relationship Type"
		/>
	);
}

interface RelationshipTypeSelectorProps {
	title: CommonReadonly;
}
